<template>
  <div class="MainBox" @click.stop="SelectActive(-1, $self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true, 1)"><span
            class="icon iconfont iconicon-test1"></span>码标替换</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.CodeNumber')+'(组)'"></th>
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item, index) in datas" v-bind:key="index">
            <td v-text="item.count"></td>
            <td class="TdStatus">
              <a><i class="pass"></i>成功</a>
            </td>
            <td v-text="item.created_at"></td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 码标激活窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{ $t('b.CodeActive.CodeActive') }}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback"
              @click="HandleModel(false, 1)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
             
              <!-- 被替换码标 -->
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>被替换码标（{{ $t('b.CodeActive.HintFour') }}）</p>
                <p><input placeholder="在此键入需要被替换码标" v-model="code" v-on:keyup.13="AddCode" /></p>
              </li>
             
              <!-- 新码标 -->
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>新码标（{{ $t('b.CodeActive.HintFour') }}）</p>
                <p><input placeholder="在此键入新码标" v-model="newCode" v-on:keyup.13="AddCode('new')" /></p>
              </li>

            </ul>

            <div class="CodeActiveListBox" v-if="Codes.length > 0 || newCodes.length > 0">
              <span class="CodeActiveListTitle">{{ $t('c.Common.CurrentCodeList') }}<i
                  v-text="Codes.length"></i>组<a class="CodeActiveListRomoveAll feedback NoSelect"
                  @click="Remove('all')">{{ $t('c.Common.Clear') }}</a></span>
              

                  <div class="list-box">
                    <ul class="CodeActiveList">
                      <li>替换码标：</li>
                      <li v-for="(item, index) in Codes" v-bind:key="index">{{ item }}<i
                          class="icon iconfont iconicon-test33 CodeActiveListRomove feedback" @click="Remove(index)"></i></li>
                    </ul>
                    <ul class="CodeActiveList">
                      <li>新码标：</li>
                      <li v-for="(item, index) in newCodes" v-bind:key="index">{{ item }}<i
                          class="icon iconfont iconicon-test33 CodeActiveListRomove feedback" @click="Remove(index, 'new')"></i></li>
                    </ul>
                  </div>

            </div>
            
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span
                class="icon iconfont iconicon-test28"></span>{{ $t('c.Common.Document') }}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false, 1)"
                v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(1)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

  </div>
</template>

<script>
let self;
import axios from 'axios'
import $axios from '@/hooks/axios-common.js'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import SelectView from '@/components/common/SelectView.vue'
export default {
  name: 'ProductManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast,
    'K-Select': SelectView
  },
  data() {
    return {
      $self: null,
      page: 1,
      subHint: '数据加载中...',
      isLoding: true,
      datas: null,
      rootid: null,
      orgid: null,
      totalPages: 0,
      ModelShow: false,
      loading: false,
      tradeType: null,
      Toast: {
        state: false,
        type: 0,
        txt: null
      },
      Codes: [],
      newCodes: [],
      code: null,
      newCode: null,
    }
  },
  created() {
    self = this;
    this.$self = this;
    self.rootid = localStorage.getItem("wwmxt_Rootid");
    self.orgid = self.rootid.substring(0, 4);
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    $axios.getList.call(this,"codeReplaceList/"); //产品不为空，加载列表
  },
  methods: {
    ToastFun(type, txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
      self.Toast.time = 5000;
    },
    ChangeToast() {
      self.Toast.state = false;
    },
    ChangePage(data) {  //上下翻页
      if (data.te == 1) {
        if (self.page > 1) {
          self.page--
        }
      } else {
        if (self.page < self.totalPages) {
          self.page++
        }
      }
      $axios.getList.call(self,"codeReplaceList/"); //加载列表
    },
    SkipPage(data) {  //跳转分页
      self.page = data.ix;
      $axios.getList.call(self,"codeReplaceList/"); //加载列表
    },
    SubmitForm(te) {  //提交表单
      if (self.Codes.length < 1) {
        self.ToastFun(1, "被替换码标不能为空！");
        return;
      }
      if (self.newCodes.length < 1) {
        self.ToastFun(1, "新码标不能为空！");
        return;
      }
      if(self.Codes.length != self.newCodes.length){
        self.ToastFun(1, "码标数量不一致！");
        return;
      }

      const Datas = { codes: self.Codes, new_codes: self.newCodes };
      self.loading = true;
      axios.post(self.GetServerUrl(1) + 'replace', Datas)
        .then(function (response) {
          self.loading = false;
          self.ModelShow = false;

          self.ToastFun(0, response.data.message);
          $axios.getList.call(self,"codeReplaceList/"); //加载列表

          //清空历史数据
          self.Codes = [];
          self.newCodes = [];
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
        })
    },
    Remove(ix, type) {
      console.log('zz', type)
      if (ix == "all") {
        this.newCodes = [];
        this.Codes = [];
      } else if(type === 'new') {
        this.newCodes.splice(ix, 1);
      }else{
        this.Codes.splice(ix, 1);
      }
    },
    AddCode(type) {
      if(type === 'new'){
        this.codeCheck(self.newCodes, self.newCode);
      }else{
        this.codeCheck(self.Codes, self.code);
      }
    },
    codeCheck(codes, scode){
      let code = self.FiltrationCode(scode);
      if (code) {
        //重复校验
        if (self.RepeatCheck(codes, code) == 1) {
          //跨级校验
          if (codes.length > 0) {
            if (code.substring(0, 1) != codes[0].substring(0, 1)) {
              self.ToastFun(1, "请勿跨级激活码标！");
              return;
            } else {
              codes.push(code);
              self.code = null;
              self.newCode = null;
            }
          } else {
            codes.push(code);
            self.code = null;
            self.newCode = null;
          }
        } else {
          self.ToastFun(1, "不能重复插入码标！");
        }
      } else {
        self.ToastFun(1, "码标不正确！");
      }
    },
    HandleModel(status, te, id='') {  //隐藏显示生码模态框
      self.ac_id = id;
      self.ModelShow = status;
    }
  }
}
</script>

<style scoped lang="scss">
.iconicon-test38 {
  font-size: 15px !important;
}
.list-box{
  display: flex;
  ul{
    width: 50%;
  }
}
</style>
