<template>
	<div class="breadcrumb">
	  <ul class="breadcrumbLeft">
	    <li v-for="(item,index) in column" v-bind:key="index" @click="SkipPage(index,item.url,item.name)">
        <a class="breadcrumbRow feedback">
        {{$t(`c.Column.${item.name}`)}}
        <i v-if="index != column.length-1">（{{$t('c.Column.ClickTo')}}）</i>
        </a>
        <a class="breadcrumLine" v-if="index < column.length-1">/</a>
      </li>
	  </ul>
	</div>
</template>

<script>
export default {
  name: 'Column',
  props:['column'],
  mounted () {
    localStorage.setItem("wwmxt_pageArray",JSON.stringify(this.column));
  },
  methods: {
    SkipPage (ix,url,name) {
      this.$router.push({path:url}); // 路由跳转
    }
  }
}
</script>

<style scoped>
  .breadcrumb{
    padding:20px;
    border-bottom:1px solid #F3F3F3;
  }
  .breadcrumbLeft{
    overflow: hidden;
  }
  .breadcrumbLeft li{
    float: left;
  }
  .breadcrumbRow{
    color:#585A64;
    cursor: pointer;
  }
  .breadcrumLine{
    padding:0 8px;
  }
</style>
