<template>
	<div class="MainBox">
    <div class="BatchQueryBox">
      <div class="FormStyleThree FormStyleThreeQuery">
        <input placeholder="请键入查询的激活或发货批次号" />
        <a class="FormStyleThreeBtn blueBackColor" @click="BatchQuery">查询</a>
      </div>
    </div>

    <!-- 表格内容 -->
    <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas && QueryStatus"></K-Transition>
    <table class="table TableMain StripeTable" v-if="datas">
      <thead>
        <tr>
          <th>编号</th>
          <th>批次</th>
          <th>产品</th>
          <th>标签数量</th>
          <th>激活数量</th>
          <th>发货</th>
          <th>收货</th>
          <th>状态</th>
          <th>时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody class="TRHoverStyle">
        <tr v-for="(item,index) in datas" v-bind:key="index">
          <td v-text="item.id"></td>
          <td v-text="item.batch"></td>
          <td v-text="item.name"></td>
          <td v-text="item.number"></td>
          <td v-text="item.achieveNumber"></td>
          <td v-text="item.shipping"></td>
          <td v-text="item.receiving "></td>
          <td v-text="item.state"></td>
          <td v-text="item.date"></td>
          <td>
            <div class="btnArray">
              <a class="btnStyleTwo btnRadius blueBackColor">下载</a>
              <a class="btnStyleTwo btnRadius cyanBackColor">替换数据</a>
              <a class="btnStyleTwo btnRadius greenBackColor">查看详情</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
	</div>
</template>

<script>
var self;
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'BatchQuery',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      page:1,
      subHint:'请输入批次号查询',
      isLoding:false,
      totalPages:0,
      datas:null,
      QueryStatus:false
    }
  },
  created () {
    self = this;
  },
  mounted () {

  },
  methods: {
    BatchQuery () {
      self.datas = null;
      self.QueryStatus = true;
      self.isLoding = true;
      self.subHint = "输入加载中..."
      setTimeout(function(){
        self.datas = [{
          id:6532158468,
          batch:20191004,
          name:'红牛',
          number:30000,
          achieveNumber:20000,
          shipping:'温州链接科技有限公司',
          receiving:'长沙经销商',
          state:'已通过',
          date:'2019-09-08 13:22'
        },{
          id:6546515622,
          batch:20191004,
          name:'红牛',
          number:50000,
          achieveNumber:50000,
          shipping:'温州链接科技有限公司',
          receiving:'武汉经销商',
          state:'已通过',
          date:'2019-09-08 13:22'
        },{
          id:6544345454,
          batch:20191004,
          name:'红牛',
          number:20000,
          achieveNumber:20000,
          shipping:'温州链接科技有限公司',
          receiving:'龙华经销商',
          state:'已通过',
          date:'2019-09-08 13:22'
        },{
          id:8756465345,
          batch:20191004,
          name:'红牛',
          number:10000,
          achieveNumber:10000,
          shipping:'温州链接科技有限公司',
          receiving:'罗湖经销商',
          state:'已通过',
          date:'2019-09-08 13:22'
        }];
        self.isLoding = false;
        self.QueryStatus = false;
        self.subHint = "请输入批次号查询";
      },1000)
    }
  }
}
</script>

<style scoped>
  .FormStyleThree{
    width: 402px;
  }
  .BatchQueryBox{
    padding:20px;
  }
</style>
