<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 表单内容 -->
    <div class="box FloatBox">
      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <viewer @inited="inited" ref="viewer">
          <li class="FormStyleTwoImgBox FormStyleTwoRow">
            <div class='FormStyleTwoImg backImg' v-if="FormDatas.cover_img" :style="{backgroundImage: 'url(' + FormDatas.cover_img + ')'}">
              <img v-if="FormDatas.cover_img" class="tableImgShow" :src="FormDatas.cover_img" @click="PreviewImgs">
              <span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg"></span>
            </div>
            <div class='FormStyleTwoImg backImg' v-else></div>
            <div class="FormStyleTwoImgMore">
              <p class="hint" style="width:230px">上传经销商图片不能大于2M，格式为 JPG、PNG或GIF</p>
              <p class="btnStyleTwo btnRadius blueBackColor" style="display:table;" @click="HandleModel(1,true)">上传图片</p>
            </div>
          </li>
          </viewer>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>经销商名称</p>
            <p><input placeholder="请输入经销商名称" v-model="FormDatas.name" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">经销商别名</p>
            <p><input placeholder="请输入经销商别名" v-model="FormDatas.alias" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="!id">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>账号</p>
            <p><input placeholder="请输入登录账号" v-model="FormDatas.username" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="!id">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>密码</p>
            <p><input placeholder="请输入登录密码" type="password" v-model="FormDatas.password" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>经纬度</p>
            <p><input placeholder="请点击获取经销商经纬度" v-model="FormDatas.lon_lat" @focus="ClickMap(true)" @click="ClickMap(true)" /></p>
          </li>
        </ul>
      </div>
      <div class="FormStyleTwoRight FloatBoxRow">
        <div class="FormStyleTwo">
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">联系人</p>
            <p><input placeholder="可输入联系人名称" v-model="FormDatas.linkman" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">联系电话</p>
            <p><input placeholder="可输入联系人电话" v-model="FormDatas.linkphone" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">地址</p>
            <p><input placeholder="可输入联系人地址" v-model="FormDatas.linkaddress" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">描述</p>
            <p>
              <textarea class="form-control" v-model="FormDatas.intro" placeholder="可输入有关经销商的描述介绍"></textarea>
            </p>
          </li>
        </div>
      </div>
    </div>

    <!-- 顶部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>保存</a>
      <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>返回</a>
    </div>

    <!-- 图片空间窗口 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          图片空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>上传图片
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click.stop="SelectActive(0,$self)" v-if="SelectArr[0].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(0,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox ModalMainImgBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table">
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(false)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <div class="mapbox" v-if="mapShow">
      <div class="mapShade" @click="ClickMap(false)"></div>
      <baidu-map :center="{lng:114.033928,lat:22.678453}" :zoom="14" @ready="handler" :scroll-wheel-zoom="true" class="MapMain" @click="getClickInfo">
        <bm-marker :position="{lng:114.033928, lat:22.678453}" :dragging="true">
          <bm-label content="温州链接科技有限公司" :labelStyle="{color:'red',fontSize:'13px'}" :offset="{width:-58,height:30}"/>
        </bm-marker>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
      </baidu-map>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Column from '@/components/common/Column.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'DealerEdit',
  components: {
    'K-Paging': Paging,
    'K-Column': Column,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      mapShow:false,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      ModelShow:false,
      pid:0, //分组id
      imgsList:null,
      column:[
        {name:'DealerList',url:'DealerList'}
      ],
      SelectArr:[{
        ix:0,
        active:false,
        datas:[]
      }],
      FormDatas:{
        cover_img:null,
        lon_lat:null,
        address_detail:null,
        linkaddress:null,
        province:null,
        city:null,
        district:null
      }
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.$route.query.id){
      self.id = self.$route.query.id;
      self.column[1] = {name:'DealerEdit',url:'DealerEdit?id='+self.$route.query.id};
      self.GetData();
    }else{
      self.column[1] = {name:'AddDealer',url:'DealerEdit'};
    }
    self.GetPacketList();
    self.GetImgsList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.page = 1;  //重置页数
      self.totalPages = null;  //清空分页总数
      self.pid = id;  //清楚分组ID
      self.imgsList = null;  //清除图片列表
      self.GetImgsList();  //获取空间图片列表
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
    },
    selectImg (img) {
      self.FormDatas.cover_img = img;
      self.HandleModel(false);
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        javascript:history.back();
      }
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"dealer/"+self.id)
      .then(function (response){
        self.FormDatas = response.data.meta;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[0].datas = response.data.meta
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SubmitForm () {

      if(!self.id && !self.FormDatas.username && !self.FormDatas.password && !self.FormDatas.name && !self.FormDatas.lon_lat){
        self.ToastFun(2,"*号必填项不能为空！");
        return;
      }

      let type;
      if(self.id){
        type = "patch";
        self.FormDatas.id = self.id;
      }else{
        type = "post";
      }
      self.loading = true;
      axios({
        method:type,
        url:self.GetServerUrl(1)+"dealer",
        data:self.FormDatas
      }).then(function (response) {
        self.loading = false;
        self.Confirm.style = 1;
        self.Confirm.state = true;
        self.Confirm.txt = response.data.message+"，是否确定返回列表？"
      }).catch(function (error) {
        console.log(error);
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    ClickMap (status) {
      self.mapShow = status;
    },
    handler ({BMap, map}) {},
    getClickInfo (e) {
      var point = new BMap.Point(e.point.lng, e.point.lat);
      var gc = new BMap.Geocoder();
      gc.getLocation(point, function (rs) {
        var addComp = rs.addressComponents;
        self.FormDatas.linkaddress = rs.address;
        self.FormDatas.address_detail = addComp;
        self.FormDatas.province = addComp.province;
        self.FormDatas.city = addComp.city;
        self.FormDatas.district = addComp.district;
      });
      self.FormDatas.lon_lat = e.point.lng+","+e.point.lat;
      self.mapShow = false;
    },
    RemoveImg () {
      self.FormDatas.cover_img = null;
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    }
  }
}
</script>

<style scoped>
  
</style>
