<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableHint">
      <p>后付费月结算单的可开票金额为当月实际结算金额。</p>
      <p>本月产生的后付费业务月结算单在下个月二号以后才可以索取发票。</p>
      <p>发票基于订单、月结算单开具（单个订单、月结算单不可拆分开票）。</p>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="tableTwo" v-else>
        <thead>
          <tr>
            <th style="width:10px"><input type="checkbox" /></th>
            <th>订单编号</th>
            <th>产品名称</th>
            <th>订单实付金额</th>
            <th>可开票金额</th>
            <th>订单支付时间</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index" :class="{read:item.status}">
            <td><input type="checkbox" /></td>
            <td v-text="item.id"></td>
            <td v-text="item.name"></td>
            <td v-text="'￥'+item.money"></td>
            <td v-text="'￥'+item.invoice"></td>
            <td v-text="item.date"></td>
          </tr>
        </tbody>
      </table>

      <div class="NotificationsBottom NoSelect">
        <ul class="NotificationsBottomLeft">
          <li class="NotificationsBottomLeftBtn" :class="{NoClick:!selectDatas}">下一步</li>
        </ul>
        <ul class="NotificationsBottomRight">
          <li class="NotificationsBottomArrows"><<</li>
          <li class="NotificationsBottomArrows"><</li>
          <li class="NotificationsBottomActive">1</li>
          <li>2</li>
          <li>3</li>
          <li class="NotificationsBottomArrows">>></li>
          <li class="NotificationsBottomArrows">></li>
        </ul>
      </div>

    </div>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
export default {
  name: 'InvoiceManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column
  },
  data () {
    return {
      te:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      FormDatas:{
        name:null,
        phone:null,
        address:null,
        zipCode:null
      },
      column:[],
      selectDatas:[]
    }
  },
  created () {
    self = this;
    self.te = self.$route.query.type;
    if(self.te == "home"){
      self.column = [{name:'首页展示',url:'BusinessHomePage'},{name:'发票索取',url:'InvoiceManage?type='+self.te}];
    }else{
      self.column = [{name:'费用中心',url:'BillingManagement'},{name:'发票索取',url:'InvoiceManage'}];
    }
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        id:204786252520670,
        name:"基础版套餐购买",
        money:300,
        invoice:300,
        date:"2019-11-11 11:58:31"
      }];
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"invoiceUser")
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    }
  }
}
</script>

<style scoped>
  .NotificationsBottomLeftBtn{
    margin-left:0
  }
  .TableHint{
    padding:15px 20px;
    margin:20px;
    background-color: #F1F1F1;
    color:#444;
    font-size:13px;
    border:1px solid #E1E1E1;
  }
  .TableHint p:first-child{
    margin-top:0;
  }
  .TableHint p{
    margin-top:10px;
  }
</style>
