<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>添加导航</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable">
        <thead>
          <tr>
            <th>排序</th>
            <th>类型</th>
            <th>名称</th>
            <th>图标</th>
            <th>时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.sort"></td>
            <td>
              <a v-if="item.type == 0">一级</a>
              <a v-else-if="item.type == 1">二级</a>
            </td>
            <td v-text="item.name"></td>
            <td v-text="item.icon"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,item)">编辑</a>
                <a class="btnStyleTwo btnRadius greenBackColor" @click="GoToPage('DocsDatasSetting?id='+item.id)">文档编辑</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.id)">删除</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 添加导航 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            添加导航
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>导航类型</p>
                <p>
                  <div class='SelectStyleArr'>
                    <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                      <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix]}}</div>
                      <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                        <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                        </ul>
                      </ul>
                      <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                    </div>
                    <div class="clear"></div>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>排序</p>
                <p><input placeholder="请输入图标名称" v-model="FormDatas.sort" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>导航名称</p>
                <p><input placeholder="请输入模板编号" v-model="FormDatas.name" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>导航图标</p>
                <p><input placeholder="请输入图标名称" v-model="FormDatas.icon" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'DocsSetting',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast,
    'K-Confirm': Confirm
  },
  data () {
    return {
      $self:null,
      id:null,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      datas:null,
      ModelShow:false,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["一级","二级"]
      }],
      FormDatas:{
        type:null,
        name:null,
        icon:null,
        sort:null
      }
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    SonToastFun (data) {
      self.Toast.state = true;
      self.Toast.type = data.te;
      self.Toast.txt = data.txt;
    },
    HandleModel (status,data) {
      self.ModelShow = status;
      if(data){
        self.id = data.id;
        self.SelectArr[0].ix = data.type;
        self.FormDatas.name = data.name;
        self.FormDatas.icon = data.icon;
        self.FormDatas.sort = data.sort;
      }else{
        self.id = null;
        self.SelectArr[0].ix = 0;
        self.FormDatas.name = null;
        self.FormDatas.icon = null;
        self.FormDatas.sort = null;
      }
    },
    SelectData (ix,six,id,rank) {
      self.SelectArr[ix].ix = six;
    },
    GetList () {
      axios.get(self.GetServerUrl(1)+"docList")
      .then(function (response) {
        self.datas = response.data.meta
        self.isLoding = false;
      }).catch(function (error){
        self.datas = null;
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    SubmitForm () {
      let te = "post";
      if(self.id){
        te = "patch"
        self.FormDatas.id = self.id;
      }else{
        delete self.FormDatas.id;
      }
      self.FormDatas.type = self.SelectArr[0].ix;

      axios({
        method:te,
        url:self.GetServerUrl(1)+"doc",
        data:self.FormDatas
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveData (id) {
      self.id = id;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该规则？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"doc/"+self.id,
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    }
  }
}
</script>

<style scoped>
  .ListBtnArray{
    overflow: hidden;
  }
  .ListBtnArray a{
    float: left;
    padding:10px 16px;
    border:1px solid #DDD;
    border-radius: 5px;
    margin-right:17px;
    color:#585A64;
    cursor: pointer;
    margin-top:15px;
  }
  .ListBtnActive{
    color:#278BF5 !important;
    border:1px solid #278BF5 !important;
  }
  .SelectStyle2{
    float: left;
    width:200px;
    margin-right:18px;
  }
  .SelectStyle2:last-child{
    margin-right: 0;
  }
  .SelectStyleArrRightBtn{
    position: absolute;
    right:0;top:0;
  }
</style>
