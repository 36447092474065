<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('OrganizationEdit')"><span class="icon iconfont iconicon-test20"></span>添加组织</li>
        <!-- <li class="TableHeadLeftRow btnStyleTwo cyanBackColor btnRadius"><span class="icon iconfont iconicon-test2"></span>二维码分享</li> -->
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input placeholder="输入组织名称查询" v-model="QueryName" v-on:keyup.13="QueryData(2)"/>
          <a class="SearchBtn" @click="QueryData(2)">搜索</a>
        </div>
      </ul>
      <ul class="TableHeadRight" style="margin-right:20px;">
        <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
          {{SelectArr[0].datas[SelectArr[0].ix]}}
          <ul class="OptionStyleBox" v-if="SelectArr[0].active" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
            <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
          </ul>
          <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable">
        <thead>
          <tr>
            <th>组织ID</th>
            <th>组织类型</th>
            <th>组织名称</th>
            <th>联系人</th>
            <th>联系电话</th>
            <th>地址</th>
            <th>用户名</th>
            <th>注册时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.rootid"></td>
            <td>
              <a v-if="item.org_type == 1">经销代理</a>
              <a v-if="item.org_type == 2">高级定制</a>
            </td>
            <td v-text="item.name"></td>
            <td v-text="item.linkman"></td>
            <td v-text="item.linkphone"></td>
            <td>
              <a v-if="item.province" v-text="item.province.address_name+' '"></a>
              <a v-if="item.city" v-text="item.city.address_name+' '"></a>
              <a v-if="item.area" v-text="item.area.address_name"></a>
            </td>
            <td v-text="item.username"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="GoToPage('JuniorBusiness?id='+item.rootid+'&name='+item.name)">下级商户</a>
                <a class="btnStyleTwo btnRadius cyanBackColor">推送通知</a>
                <a class="btnStyleTwo btnRadius redBackColor">禁用</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'Organization',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      $self:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      QueryName:null,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["全部","经销代理","高级定制"]
      }]
    }
  },
  created () {
    self = this;
    this.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.QueryData(1);
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.post(self.GetServerUrl(1)+"org_list",{page:self.page})
      .then(function (response){
          self.datas = response.data.meta.data;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    QueryData (te) {
      self.datas = null;
      self.isLoding = true;

      let datas;
      if(te == 1){
        datas = {org_type:self.SelectArr[0].ix};
      }else{
        datas = {name:self.QueryName};
      }

      axios.post(self.GetServerUrl(1)+"org_list",datas)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.datas = null;
      })
    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width:100px;
  }
</style>
