<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <div class="MTERightHeadBox">
      <div class="MTERightHeadLeft" @click="ClickNav('prev')"><span class="icon iconfont iconicon-test24"></span></div>
      <div class='MTERightHeadMain NoSelect swiper-container' id="NavList">
        <ul class="swiper-wrapper">
          <li class="swiper-slide" v-for="(item,index) in NavLists" v-bind:key="index" v-text="item" :class="{ActiveNav:currentIndex == index,feedback:currentIndex != index}" @click="ClickNav('to',index)"></li>
        </ul>
      </div>
      <div class="MTERightHeadRight" @click="ClickNav('next')"><span class="icon iconfont iconicon-test25"></span></div>
    </div>

    <div class="MTERightBox">

      <!-- 基本信息默认值 -->
      <div class="box FloatBox" v-if="currentIndex == 0">
        <div class="FormStyleTwoLeft FloatBoxRow">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>扫码次数</p>
              <p><input style="width:278px" placeholder="请输入默认扫码次数" v-model="FormDatas.basic.count" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>首次扫码时间</p>
              <p>
                <el-date-picker
                  v-model="FormDatas.basic.first_query_time"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <!-- 产品信息默认值 -->
      <div class="box FloatBox" v-if="currentIndex == 1">
        <div class="FormStyleTwoLeft FloatBoxRow">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>产品编号</p>
              <p><input placeholder="请输入默认产品编号" v-model="FormDatas.product.id" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>产品名称</p>
              <p><input placeholder="请输入默认产品名称" v-model="FormDatas.product.name" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>保质期</p>
              <p><input placeholder="请输入默认保质期" v-model="FormDatas.product.period" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>规格</p>
              <p><input placeholder="请输入默认规格" v-model="FormDatas.product.unit" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>描述</p>
              <p>
                <textarea class="form-control" v-model="FormDatas.product.introduce" placeholder="请输入默认产品描述介绍"></textarea>
              </p>
            </li>
          </ul>
        </div>
        <div class="FormStyleTwoRight FloatBoxRow">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>农药登记类别</p>
              <p><input placeholder="请输入默认产品编号" v-model="FormDatas.product.reg_code_type" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>登记证号</p>
              <p><input placeholder="请输入默认产品名称" v-model="FormDatas.product.reg_code_str" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>登记证号持有人</p>
              <p><input placeholder="请输入默认保质期" v-model="FormDatas.product.reg_code_owner" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>产品规格码</p>
              <p><input placeholder="请输入默认规格" v-model="FormDatas.product.product_code" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>农药名称</p>
              <p><input placeholder="请输入默认规格" v-model="FormDatas.product.alias_name" /></p>
            </li>
          </ul>
        </div>
      </div>

      <!-- 防伪信息 -->
      <div class="box FloatBox" v-if="currentIndex == 2">
        <div class="FormStyleTwoLeft FloatBoxRow">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>防伪码</p>
              <p><input placeholder="请输入默认防伪码" v-model="FormDatas.antifake.code" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>首次查询语句</p>
              <p>
                <textarea class="form-control" v-model="FormDatas.antifake.succeed" placeholder="请输入查询首次查询语句"></textarea>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>多次查询语句</p>
              <p>
                <textarea class="form-control" v-model="FormDatas.antifake.error" placeholder="请输入查询多次查询语句"></textarea>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <!-- 溯源信息 -->
      <div class="box FloatBox" v-if="currentIndex == 3" style="padding-top: 0;">
        <div class="CustomRefBox">

          <!-- 工序流程 -->
          <div class="CustomRefRow">
            <div class="CustomRefHead">
              <a class="CustomRefTitle onlyOneRow">工序流程</a>
              <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                <li @click="ClearDatas('process')">清空</li>
              </ul>
            </div>
            <div class="CustomRefMaiBox">

              <div class="CustomRefMain" v-for="(item,index) in FormDatas.process" v-bind:key="index">
                <div class="CustomRefHead">
                  <a class="CustomRefTitle onlyOneRow" v-text="item.name"></a>
                  <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                    <li @click="HandleModel(true,1,item,1,index)">编辑</li>
                    <li @click="RemoveData(1,index)">删除</li>
                  </ul>
                </div>
              </div>

              <div class="btnStyleTwo greenBackColor btnRadius AddDefine CustomRefBtnAdd" @click="HandleModel(true,1,null,1)"><span class="icon iconfont iconicon-test20"></span>添加工序流程</div>
            </div>
          </div>

          <!-- 工序演示流程 -->
          <div class="CustomRefRow">
            <div class="CustomRefHead">
              <a class="CustomRefTitle onlyOneRow">工序演示流程</a>
              <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                <li @click="ClearDatas('process_demo')">清空</li>
              </ul>
            </div>
            <div class="CustomRefMaiBox">

              <div class="CustomRefMain" v-for="(item,index) in FormDatas.process_demo" v-bind:key="index">
                <div class="CustomRefHead">
                  <a class="CustomRefTitle onlyOneRow" v-text="item.name"></a>
                  <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                    <li @click="HandleModel(true,1,item,2,index)">编辑</li>
                    <li @click="RemoveData(2,index)">删除</li>
                  </ul>
                </div>
              </div>

              <div class="btnStyleTwo greenBackColor btnRadius AddDefine CustomRefBtnAdd" @click="HandleModel(true,1,null,2)"><span class="icon iconfont iconicon-test20"></span>添加演示流程</div>
            </div>
          </div>

          <!-- 经销商流程 -->
          <div class="CustomRefRow">
            <div class="CustomRefHead">
              <a class="CustomRefTitle onlyOneRow">经销商流程</a>
              <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                <li @click="ClearDatas('dealer')">清空</li>
              </ul>
            </div>
            <div class="CustomRefMaiBox">

              <div class="CustomRefMain" v-for="(item,index) in FormDatas.dealer" v-bind:key="index">
                <div class="CustomRefHead">
                  <a class="CustomRefTitle onlyOneRow" v-text="item.name"></a>
                  <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                    <li @click="HandleModel(true,1,item,3,index)">编辑</li>
                    <li @click="RemoveData(3,index)">删除</li>
                  </ul>
                </div>
              </div>

              <div class="btnStyleTwo greenBackColor btnRadius AddDefine CustomRefBtnAdd" @click="HandleModel(true,1,null,3)"><span class="icon iconfont iconicon-test20"></span>添加经销商流程</div>
            </div>
          </div>

        </div>
      </div>

      <!-- 促销信息 -->
      <div class="box FloatBox" v-if="currentIndex == 4">
        <div class="FormStyleTwoLeft FloatBoxRow">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>活动类型</p>
              <p>
                <div class="SelectStyle" style="width:300px;" @click.stop="SelectActive(1,$self)">
                  <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix]}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[1].datas.length > 6}">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item" @click="SelectData(1,index)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>活动介绍</p>
              <p>
                <div id="wangeditor">
                  <div ref="editorElem"></div>
                </div>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <!-- 底部菜单 -->
      <div class="box FloatBox" v-if="currentIndex == 5">
        <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="HandleModel(true,2)"><span class="icon iconfont iconicon-test20"></span>添加底部菜单</div>
        <div class="CustomBannerBox" v-if="FormDatas.menu.length > 0">
          <div class="CustomBannerRow" v-for="(item,index) in FormDatas.menu" v-bind:key="index">
            <div class="backImg CustomBannerImg" v-if="item.icon" :style="{backgroundImage: 'url(' + item.icon + ')'}"></div>
            <div class='backImg CustomBannerImg' v-else></div>

            <div class="CustomBannerMain">
              <p class="onlyOneRow" v-text="item.name"></p>
              <p class="onlyOneRow" v-text="item.describe"></p>
            </div>

            <ul class="CustomMenuBtnArr">
              <li @click="HandleModel(true,2,item,null,index)">编辑</li>
              <li @click="RemoveData(4,index)">删除</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 焦点图 -->
      <div class="box FloatBox" v-if="currentIndex == 6">
        <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="HandleModel(true,3)"><span class="icon iconfont iconicon-test20"></span>添加焦点图</div>
        <div class="CustomBannerBox" v-if="FormDatas.focus.length > 0">
          <div class="CustomBannerRow" v-for="(item,index) in FormDatas.focus" v-bind:key="index">
            <div class="backImg CustomBannerImg" v-if="item.icon" :style="{backgroundImage: 'url(' + item.icon + ')'}"></div>
            <div class='backImg CustomBannerImg' v-else></div>

            <div class="CustomBannerMain">
              <p class="onlyOneRow" v-text="item.name"></p>
              <p class="onlyOneRow" v-text="item.url"></p>
            </div>

            <ul class="CustomMenuBtnArr">
              <li @click="HandleModel(true,3,item,null,index)">编辑</li>
              <li @click="RemoveData(5,index)">删除</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 底部按钮 -->
      <div class="btnArray formFootBtnArr">
        <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>保存</a>
        <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>返回</a>
      </div>

    </div>

    <!-- 添加溯源信息 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加溯源信息
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,ModelType)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>图片信息</p>
              <p>
                <p class="FormStyleOneImgs">
                  <ul class="FormStyleTwoImgsRow">
                    <li class="FormStyleTwoImgsBox backImg" @click="ImagesListModelFun({type:true},3)">
                      <div class="UploadFinishImg backImg" v-if="trace.icon" :style="{backgroundImage:'url('+trace.icon+')'}"><span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(1)"></span></div>
                    </li>
                    <li class='FormStyleTwoImgTitle'>（网点图标）</li>
                  </ul>
                </p>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 网点名称</p>
              <p><input placeholder="请输入模板编号" style="width:493px" v-model="trace.name" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 流通时间</p>
              <p>
                <el-date-picker
                  v-model="trace.date"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,ModelType)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddTrace">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加底部菜单 -->
    <div class="ModalBox" v-if="ModelTwoShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加底部菜单
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,ModelType)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>图片信息</p>
              <p>
                <p class="FormStyleOneImgs">
                  <ul class="FormStyleTwoImgsRow">
                    <li class="FormStyleTwoImgsBox backImg" @click="ImagesListModelFun({type:true},1)">
                      <div class="UploadFinishImg backImg" v-if="menu.icon" :style="{backgroundImage:'url('+menu.icon+')'}"><span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(2,1)"></span></div>
                    </li>
                    <li class='FormStyleTwoImgTitle'>（菜单图标）</li>
                  </ul>
                  <ul class="FormStyleTwoImgsRow" v-if="SelectArr[0].ix == 2" @click="ImagesListModelFun({type:true},2)">
                    <li class="FormStyleTwoImgsBox backImg">
                      <div class="UploadFinishImg backImg" v-if="menu.qr" :style="{backgroundImage:'url('+menu.qr+')'}"><span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(2,2)"></span></div>
                    </li>
                    <li class='FormStyleTwoImgTitle'>（二维码图片）</li>
                  </ul>
                </p>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>菜单类型</p>
              <p>
                <div class='SelectStyleArr'>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix]}}</div>
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
                        <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                  <div class="clear"></div>
                </div>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 菜单名称</p>
              <p><input placeholder="请输入模板编号" v-model="menu.name" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 菜单描述</p>
              <p><input placeholder="请输入模板描述" v-model="menu.describe" /></p>
            </li>
            <li class="FormStyleTwoRow" v-if="SelectArr[0].ix != 2">
              <p class="FormStyleTwoTitle"><i>*</i> 菜单链接</p>
              <p><input placeholder="请输入模板名称" v-model="menu.url" /></p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,ModelType)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddMenu">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加焦点图 -->
    <div class="ModalBox" v-if="ModelThreeShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加焦点图
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,ModelType)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>图片信息</p>
              <p>
                <p class="FormStyleOneImgs">
                  <ul class="FormStyleTwoImgsRow">
                    <li class="FormStyleTwoImgsBox backImg" @click="ImagesListModelFun({type:true},4)">
                      <div class="UploadFinishImg backImg" v-if="focus.icon" :style="{backgroundImage:'url('+focus.icon+')'}"><span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(3)"></span></div>
                    </li>
                  </ul>
                </p>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 名称</p>
              <p><input placeholder="请输入焦点图名称" v-model="focus.name" /></p>
            </li>
            <li class="FormStyleTwoRow" v-if="SelectArr[0].ix != 2">
              <p class="FormStyleTwoTitle">跳转链接（填写后点击图片可跳转）</p>
              <p><input placeholder="链接前需要加：http://或https://" v-model="focus.url" /></p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,ModelType)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddFocus">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 图片列表 -->
    <K-ImagesList :ImgsData="ImgsData" @SonToastFun="SonToastFun" @ImagesListModelFun="ImagesListModelFun"></K-ImagesList>

	</div>
</template>

<script>
var self,category;
import axios from 'axios'
import E from "wangeditor";
import Swiper from 'swiper'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import ImagesList from '@/components/common/ImagesList.vue'
export default {
  name: 'CreateCodeAudit',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-ImagesList': ImagesList,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      ModelShow:false,
      ModelTwoShow:false,
      ModelThreeShow:false,
      totalPages:0,
      currentIndex:0,
      NavLists:["基本信息","产品信息","防伪信息","溯源信息","促销信息","底部菜单","焦点图"],
      FormDatas:{
        basic:{count:null,first_query_time:null},
        product:{id:null,name:null,period:null,unit:null,period:null,reg_code_type:null,reg_code_str:null,reg_code_owner:null,product_code:null,alias_name:null},
        antifake:{code:null,succeed:null,error:null},
        process:[],
        process_demo:[],
        dealer:[],
        activity_type:null,
        activity_shark_rule:null,
        activity_redpacket_rule:null,
        menu:[],
        focus:[]
      },
      ModelType:null,
      EditType:null,
      EditIndex:null,
      te:null,
      menu:null,
      focus:null,
      trace:null,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["官网地址","商城跳转","微信公众号"]
      },{
        ix:0,
        active:false,
        datas:["摇一摇","独立红包"]
      }],
      ImgsData:{
        type:false
      },
      editor:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  updated () {
    if(self.currentIndex == 4 && !self.editor){
      self.createRichText();
    }else if(self.currentIndex != 4){
      self.editor = null;
    }
  },
  mounted () {
    self.GetList();
    category = new Swiper('#NavList', {
      slidesPerView : "auto",
      slidesPerGroup : 1,
      observer:true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents:true,//修改swiper的父元素时，自动初始化swiper
    })
  },
  methods: {
    ImagesListModelFun (data,te) {
      self.ImgsData.type = data.type;
      if(te){
        self.te = te;
      }
      if(data.img){
        if(self.te == 1){
          self.menu.icon = data.img;
        }else if(self.te == 2){
          self.menu.qr = data.img;
        }else if(self.te == 3){
          self.trace.icon = data.img;
        }else if(self.te == 4){
          self.focus.icon = data.img;
          self.focus.name = data.name;
        }
      }
    },
    ClickNav (te,ix) {
      //暂存富文本编辑器内容
      if(self.editor){
        if(self.SelectArr[1].ix == 0){
          self.FormDatas.activity_shark_rule = self.editor.txt.html();
        }else{
          self.FormDatas.activity_redpacket_rule = self.editor.txt.html();
        }
      }
      if(te == "prev"){
        if(self.currentIndex > 0){
          self.currentIndex--;
        }
      }else if(te == "to"){
        self.currentIndex = ix;
      }else{
        if(self.NavLists.length-1 > self.currentIndex){
          self.currentIndex++;
        }
      }
      category.slideTo(self.currentIndex, 500, false);
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    SonToastFun (data) {
      self.Toast.state = true;
      self.Toast.type = data.te;
      self.Toast.txt = data.txt;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    HandleModel (status,te,data,ste,ix) {  //隐藏显示生码模态框
      self.ModelType = te;
      
      if(!status){
        self.ImagesListModelFun({type:false})
      }
      
      if(te == 1){
        if(ste){
          self.ModelSType = ste;
        }
        self.ModelShow = status;
        if(data){
          self.EditType = "change";
          self.EditIndex = ix;
          self.trace = data;
          self.SelectArr[1].ix = data.type;
        }else{
          self.EditType = "add";
          self.trace = {icon:null,type:null,name:null,date:null,datas:[]};
          self.SelectArr[1].ix = 0;
        }
      }else if(te == 2){
        self.ModelTwoShow = status;
        if(data){
          self.EditType = "change";
          self.EditIndex = ix;
          self.menu = data;
          self.SelectArr[0].ix = data.type;
        }else{
          self.EditType = "add";
          self.menu = {icon:null,type:null,name:null,describe:null,url:null,qr:null};
          self.SelectArr[0].ix = 0;
        }
      }else if(te == 3){
        self.ModelThreeShow = status;
        if(data){
          self.EditType = "change";
          self.EditIndex = ix;
          self.focus = data;
        }else{
          self.EditType = "add";
          self.focus = {icon:null,name:null,url:null};
        }
      }

    },
    SelectData (ix,six,id,rank) {
      self.SelectArr[ix].ix = six;

      if(ix == 1){
        if(self.SelectArr[1].ix == 0){
          self.editor.txt.html(self.FormDatas.activity_shark_rule);
        }else{
          self.editor.txt.html(self.FormDatas.activity_redpacket_rule);
        }
      }
    },
    createRichText () {
      self.editor = new E(self.$refs.editorElem);
      self.editor.customConfig.zIndex = 90
      self.editor.create(); // 创建富文本实例
      if(self.SelectArr[1].ix == 0){
        self.editor.txt.html(self.FormDatas.activity_shark_rule); //渲染内容
      }else{
        self.editor.txt.html(self.FormDatas.activity_redpacket_rule); //渲染内容
      }
    },
    GetList () {
      axios.get(self.GetServerUrl(1)+"config/template")
      .then(function (response) {
        let datas = response.data.meta.config_value;
        if(datas){
          self.FormDatas = JSON.parse(datas);
        }
        self.isLoding = false;
      }).catch(function (error){
        self.subHint = "数据为空";
        self.isLoding = false;
      })
    },
    SubmitForm () {
      let datas={};
      datas.config_name = "template";
      datas.config_value = self.FormDatas;

      if(self.editor){
        if(self.SelectArr[1].ix == 0){
          datas.config_value.activity_shark_rule = self.editor.txt.html();
        }else{
          datas.config_value.activity_redpacket_rule = self.editor.txt.html();
        }
      }

      self.loading = true;
      axios({
        method:"post",
        url:self.GetServerUrl(1)+"admin_config",
        data:datas
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveData (te,ix) {
      if(te == 1){
        self.FormDatas.process.splice(ix,1);
      }else if(te == 2){
        self.FormDatas.process_demo.splice(ix,1);
      }else if(te == 3){
        self.FormDatas.dealer.splice(ix,1);
      }else if(te == 4){
        self.FormDatas.menu.splice(ix,1);
      }
    },
    ClearDatas (key) {
      self.FormDatas[key] = [];
    },
    AddTrace () {
      if(self.ModelSType == 1){
        if(self.EditType == "add"){
          self.FormDatas.process.push({icon:self.trace.icon,name:self.trace.name,date:self.trace.date});
        }else{
          self.FormDatas.process[self.EditIndex] = {icon:self.trace.icon,name:self.trace.name,date:self.trace.date};
        }
      }else if(self.ModelSType == 2){
        if(self.EditType == "add"){
          self.FormDatas.process_demo.push({icon:self.trace.icon,name:self.trace.name,date:self.trace.date});
        }else{
          self.FormDatas.process_demo[self.EditIndex] = {icon:self.trace.icon,name:self.trace.name,date:self.trace.date};
        }
      }else if(self.ModelSType == 3){
        if(self.EditType == "add"){
          self.FormDatas.dealer.push({icon:self.trace.icon,name:self.trace.name,date:self.trace.date});
        }else{
          self.FormDatas.dealer[self.EditIndex] = {icon:self.trace.icon,name:self.trace.name,date:self.trace.date};
        }
      }
      self.ModelShow = false;
    },
    AddMenu () {
      if(self.EditType == "add"){
        self.FormDatas.menu.push({icon:self.menu.icon,type:self.SelectArr[0].ix,qr:self.menu.qr,name:self.menu.name,describe:self.menu.describe,url:self.menu.url});
      }else{
        self.FormDatas.menu[self.EditIndex] = {icon:self.menu.icon,type:self.SelectArr[0].ix,qr:self.menu.qr,name:self.menu.name,describe:self.menu.describe,url:self.menu.url};
      }
      self.ModelTwoShow = false;
    },
    AddFocus () {
      if(self.EditType == "add"){
        self.FormDatas.focus.push({icon:self.focus.icon,name:self.focus.name,url:self.focus.url});
      }else{
        self.FormDatas.focus[self.EditIndex] = {icon:self.focus.icon,name:self.focus.name,url:self.focus.url};
      }
      self.ModelThreeShow = false;
    },
    RemoveImg (te,ste) {
      if(te == 1){
        self.trace.icon = null;
      }else if(te == 2){
        if(ste == 1){
          self.menu.icon = null;
        }else{
          self.menu.qr = null;
        }
      }else if(te == 3){
        self.focus.icon = null;
      }
    }
  }
}
</script>

<style scoped>
  .box{
    min-width: 1000px;
  }
  .MTERightHeadMain{
    margin:0 50px;
    overflow: hidden;
  }
  .AddDefine{
    margin-top:0;
  }
  .FormStyleTwoImgs{
    overflow: hidden;
  }
  .FormStyleTwoImgsRow{
    float: left;
    overflow: hidden;
    margin-right:20px;
  }
  .FormStyleTwoImgsBox{
    width:100px;
    height:100px;
    border:1px solid #D8E4ED;
    position: relative;
    background-image: url('/public/static/images/add.png');
  }
  .FormStyleTwoImgTitle{
    padding-top:11px;
    text-align: center;
    color:#585A64;
  }
  .MTERightBox{
    position: relative;
  }
  .MTESaveBtn{
    position: absolute;
    top:25px;right:25px;
  }

  .CustomRefBox{
    margin-top:0;
  }
  .CustomRefRow{
    margin-top: 20px;
  }
  .ActiveNav{
    color:#FFF;
    background-color: #278BF5;
    border-right: 1px solid #278BF5;
    box-shadow:0 0 10px rgba(53,149,255,0.5);
  }
</style>
