<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 普通行业 -->
    <div class="box FloatBox">
      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <viewer @inited="inited" ref="viewer">
          <li class="FormStyleTwoImgBox FormStyleTwoRow">
            <div class='FormStyleTwoImg backImg' v-if="FormDatas.img" :style="{backgroundImage: 'url(' + FormDatas.img + ')'}">
              <img v-if="FormDatas.img" class="tableImgShow" :src="FormDatas.img" @click="PreviewImgs">
              <span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg"></span>
            </div>
            <div class='FormStyleTwoImg backImg' v-else></div>
            <div class="FormStyleTwoImgMore">
              <p class="hint" style="width:230px">{{$t('c.Common.ImageSize')}}</p>
              <p class="btnStyleTwo blueBackColor" style="display:table;" @click="HandleModel(1,true)">{{$t('b.Material.UploadImages')}}</p>
            </div>
          </li>
          </viewer>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>设别名称</p>
            <p><input placeholder="请输入设别名称" v-model="FormDatas.name" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>设别编号</p>
            <p><input placeholder="请输入设别编号" v-model="FormDatas.device_id" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>经纬度</p>
            <p><input placeholder="请点击获取经销商经纬度" v-model="FormDatas.lon_lat" @focus="ClickMap(true)" @click="ClickMap(true)" /></p>
          </li>
        </ul>
      </div>
      
      <div class="FormStyleTwoRight FloatBoxRow">
        <div class="hint">{{$t('b.ProductManage.LineHint')}}</div>
        <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item,index) in FormDatas.customize_data" v-bind:key="index" >
          <li class="FormStyleTwo" style="padding:2px 0;">
            <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key"/>
            <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value"/>
            <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
              <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(2,true,index)">{{$t('c.Common.Image')}}</a>
              <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{$t('c.Common.Delete')}}</a>
            </p>
          </li>
        </ul>
        <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="addAttributeRow"><span class="icon iconfont iconicon-test20"></span>{{$t('b.ProductManage.AddLine')}}</div>
      </div>

    </div>
    <!-- 普通行业 end -->

    <!-- 底部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(1)"><span class="icon iconfont iconicon-test17"></span>{{$t('c.Common.Save')}}</a>
      <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>{{$t('c.Common.Back')}}</a>
    </div>

    <!-- 图片空间 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          图片空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>上传图片
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click.stop="SelectActive(1,$self)" v-if="SelectArr[1].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(1,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox ModalMainImgBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table" >
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <div class="mapbox" v-if="mapShow">
      <div class="mapShade" @click="ClickMap(false)"></div>
      <baidu-map :center="{lng:114.033928,lat:22.678453}" :zoom="14" @ready="handler" :scroll-wheel-zoom="true" class="MapMain" @click="getClickInfo">
        <bm-marker :position="{lng:114.033928, lat:22.678453}" :dragging="true">
          <bm-label content="温州链接科技有限公司" :labelStyle="{color:'red',fontSize:'13px'}" :offset="{width:-58,height:30}"/>
        </bm-marker>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
      </baidu-map>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Column from '@/components/common/Column.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
import Swiper from 'swiper'
export default {
  name: 'HandleProduct',
  components: {
    'K-Paging': Paging,
    'K-Column': Column,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,  //设备ID
      mapShow:false,
      copy:null,
      totalPages:0,
      uploadType:null,
      uploadImgIndex:null,
      subHint:'数据加载中...',
      isLoding:true,
      isShow:false,
      column:[
        {name:'DeviceManage',url:'DeviceManage'}
      ],
      SelectArr:[{
        ix:0,
        active:false,
        datas:["no","yes"]
      },{
        ix:0,
        active:false,
        datas:[]
      }],
      navix:0,
      leftIconTop:25,
      ModelShow:false,
      pid:0, //分组id
      imgsList:null,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      FormDatas:{
        img:null,
        name:null,
        device_id:null,
        lon_lat:null,
        customize_data:null,
      },
    }
  },
  created () {
    self = this;
    self.$self = this;
    self.OID = localStorage.getItem("wwmxt_OID");
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(this.$route.query.id){
      self.id = self.$route.query.id;
      self.copy = self.$route.query.copy;
      self.column[1] = {name:'EditDevice',url:'DeviceEdit?id='+self.$route.query.id};
      self.GetData();
    }else{
      self.column[1] = {name:'AddDevice',url:'DeviceEdit'};
    }
    let role = localStorage.getItem("wwmxt_Role");
    if(role && parseInt(role) < 30){
      self.isShow = true;
    }
    self.GetPacketList();
    self.GetImgsList();
  },
  methods: {
    ClickMap (status) {
      self.mapShow = status;
    },
    handler ({BMap, map}) {},
    getClickInfo (e) {
      var point = new BMap.Point(e.point.lng, e.point.lat);
      var gc = new BMap.Geocoder();
      gc.getLocation(point, function (rs) {
        var addComp = rs.addressComponents;
        self.FormDatas.linkaddress = rs.address;
        self.FormDatas.address_detail = addComp;
        self.FormDatas.province = addComp.province;
        self.FormDatas.city = addComp.city;
        self.FormDatas.district = addComp.district;
      });
      self.FormDatas.lon_lat = e.point.lng+","+e.point.lat;
      self.mapShow = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if(ix == 0){
        
      }else if(ix == 1){  //列表分组选择
        self.page = 1;  //重置页数
        self.totalPages = null;  //清空分页总数
        self.pid = id;  //清楚分组ID
        self.imgsList = null;  //清除图片列表
        self.GetImgsList();  //获取空间图片列表
      }
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"device/"+self.id)
      .then(function (response){
        let datas = response.data.meta;
        self.loading = false;
        if(datas.customize_data){
          datas.customize_data = JSON.parse(datas.customize_data);
        }
       
        self.FormDatas = datas;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    ClickOIDNav (ix,id) {
      self.navix = ix;
      for(let item in self.OIDDatas){
        self.OIDDatas[item].icon = "icon_0"+(parseInt(item)+1)+".jpg"
      }
      self.OIDDatas[ix].icon = "icon_0"+(parseInt(ix)+1)+"_.jpg"
      if(ix == 0){
        self.leftIconTop = 25;
      }else if(ix == 1){
        self.leftIconTop = 135;
      }else if(ix == 2){
        self.leftIconTop = 250;
      }
    },
    SubmitForm (te) {
      if(te == 1){
        let datas = JSON.parse(JSON.stringify(self.FormDatas));
        if(datas.customize_data){
          datas.customize_data = JSON.stringify(datas.customize_data)
        }
        let te = "post";

        if(!self.FormDatas.name){
          self.ToastFun(1,"设备名称不能为空！");
          return;
        }

        //编辑
        if(self.id && !self.copy){
          te = "patch";
          datas.id = self.id;
        }

        //复制
        if(self.copy){
          delete datas.id
          delete datas.created_at
        }

        self.loading = true;
        axios({
          method:te,
          url:self.GetServerUrl(1)+"device",
          data:datas
        }).then(function (response) {
          self.loading = false;
          self.Confirm.style = 1;
          self.Confirm.state = true;
          self.Confirm.txt = response.data.message+"，是否确定返回列表？"
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }else{
        self.ModelShow = false;
      }
    },
    HandleModel (te,status,ix) {  //隐藏显示生码模态框
      self.uploadType = te;
      self.ModelShow = status;
      self.uploadImgIndex = ix;
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[1].datas = response.data.meta
        self.SelectArr[1].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[1].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    selectImg (img) {
      if(self.uploadType == 1){
        self.FormDatas.img = img;
      }else{
        self.FormDatas.customize_data[self.uploadImgIndex].value = img;
      }
      self.HandleModel(false);
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    addAttributeRow () {
      if(!self.FormDatas.customize_data){
        self.FormDatas.customize_data = [];
      }
      self.FormDatas.customize_data.push({key:"",value:""});
    },
    RemoveRow (ix) {
      self.FormDatas.customize_data.splice(ix,1);
    },
    GetCodeUse (id) {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"code_use")
      .then(function (response){
        let datas = [{datas:[]},{datas:[]},{datas:[]}];
        for(let item in response.data.meta){
          if(response.data.meta[item].type_id > 0 && response.data.meta[item].type_id < 4){
            response.data.meta[item].active = false;
            datas[response.data.meta[item].type_id-1].datas.push(response.data.meta[item]);
          }
        }
        self.OIDDatas[2].datas = datas[0].datas;
        self.OIDDatas[1].datas = datas[1].datas;
        self.OIDDatas[0].datas = datas[2].datas;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    GetCodeUseList (rank,name,id,ix,six,code) {
      if(rank == 1){
        self.OID = localStorage.getItem("wwmxt_OID") +"/"+ id +".";
        self.codeuse_code = id;
        for(let item in self.OIDDatas[self.navix].datas){
          self.OIDDatas[self.navix].datas[item].active = false;
        }
        self.OIDDatas[self.navix].datas[ix].active = true;  //激活
      }else{
        for(let item in self.OIDSubDatas[ix]){
          self.OIDSubDatas[ix][item].active = false;
        }
        self.OIDSubDatas[ix][six].active = true;  //子分类激活
      }

      self.loading = true;
      axios.get(self.GetServerUrl(1)+"industrycategory/"+rank+"/"+id)
      .then(function (response){
        if(rank == 1){
          let datas = response.data.meta;
          for(let item in datas){
            datas[item].active = false;
          }
          self.OIDSubDatas = [datas]; //选中子类数组
          self.OIDSelect = [{id:id,name:name}];  //选中用途存储
          self.OIDName = [self.OIDDatas[self.navix].datas[ix].codeuse_name];  //设置用途名称
        }else{
          for(let item in self.OIDSubDatas){
            if(item > ix){
              self.OIDSubDatas.splice(item,1);  //删除子类数组
            }
          }
          let datas = response.data.meta;
          for(let item in datas){
            datas[item].active = false;
          }
          self.OIDSubDatas[ix+1] = datas;
          self.OIDName[ix+1] = self.OIDSubDatas[ix][six].industrycategory_name;  //设置用途名称

          self.OIDSelect[ix+1] = {id:id,name:name};  //选中用途存储
        }
        self.loading = false;
      }).catch(function (error){
        self.OIDSubDatas.splice(ix+1,1);  //删除多余子类
        self.OIDSelect.splice(ix+1,1);  //删除多余用途
        self.OIDName.push(ix+1,1);
        //设置OID备案ID
        if(rank == 2){
          for(let item in self.OIDDatas[self.navix].datas){
            if(self.OIDDatas[self.navix].datas[item].active){
              self.OID = localStorage.getItem("wwmxt_OID") +"/"+ self.OIDDatas[self.navix].datas[item].codeuse_code +"." + id;
              self.industrycategory_id = id;
              self.industrycategory_code = code;
              self.OIDSelect[ix+1] = {id:id,name:name};  //选中用途存储
            }
          }
        }
        self.loading = false;
        self.ToastFun(1,self.ErrorHint(error));  //弹窗提醒
      })
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        javascript:history.back();
      }
    },
    RemoveImg () {
      self.FormDatas.img = null;
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    },
    CodeFun (te,ix) {
      if(te == 1){
        eval("self.code" + ix + "= null");
      }else{
        if(!eval("self.code" + ix)){
          eval("self.code" + ix + "= 0");
        }
      }
    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width:300px;
  }
  .OIDProductBox{
    padding:20px;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
  }
  .OIDProductID{
    padding-right:20px;
  }
  .OIDProductMain{
    overflow: hidden;
    margin-top:20px;
  }
  .OIDProductLeft{
    float: left;
    position: relative;
    padding-right: 40px;
    border-right: 2px solid #278BF5;
  }
  .OIDProductLeftRow{
    margin-top:20px;
    text-align: center;
    width:70px;
    cursor: pointer;
  }
  .OIDProductLeftRow:first-child{
    margin-top:0;
  }
  .OIDProductLeftIcon{
    width:65px;
  }
  .OIDProductLeftRowArrows{
    position: absolute;
    right:0;top:0;
    width: 8px;
  }
  .OIDProductRight{
    width: calc(100% - 120px);
  }
  .idboxRightMain{
  	width:220px !important;
  	height:316px !important;
  	border:1px solid #e3e3e3;
  }
  .idboxRight i{
  	color:#f30;
  }
  .ibrmBox{
  	padding:10px;
    height:257px;
    overflow-y: scroll;
  }
  .idboxRightMain span{
  	font-weight: bold;
  	padding:10px;
  	display: block;
  	font-size:0.95em;
  	background-color: #EEEEEE;
  	border-bottom:1px solid #e3e3e3;
  }
  .idboxRightMain p{
  	border-bottom:1px solid #eee;
  	padding:8px 10px;
  	font-size:0.85em;
  	color:#666;
  	cursor: pointer;
  }
  .idboxRightMain p:hover{
  	background-color: #EEEEEE !important;
  }
  .OIDProductLeftTitle{
    margin-top:5px;
    color:#585A64;
  }
  .activeNav{
    background-color: #EEE;
  }
  .FormInputArrRow:first-child{
    margin-left:0;
  }
  .FormInputArrRow{
    width:76px;
    margin-left:10px;
  }
</style>
