<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft" v-if="!['10','20'].includes(role)">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('ProductEdit')"><span class="icon iconfont iconicon-test20"></span>{{$t('b.ProductManage.AddProduct')}}</li>
        <li class="TableHeadLeftRow btnStyleTwo cyanBackColor btnRadius">
          <span class="icon iconfont iconicon-test19"></span>{{$t('b.ProductManage.DataImport')}}
          <input type="file" class="upload" @change="UploadFile" ref="file" />
        </li>
        <li class="TableHeadLeftRow btnStyleTwo btnRadius greenBackColor" @click="selectTemplate('set')"><span class="icon iconfont iconicon-test3"></span>模版设置</li>
        <li class="TableHeadLeftRow btnStyleTwo btnRadius orangeBackColor" @click="selectTemplate('follow')"><span class="icon iconfont iconicon-test38"></span>模版跟随</li>
      </ul>
      <ul class="TableHeadLeft" v-else>
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input :placeholder="$t('b.ProductManage.SearchHint')" v-model="QueryName" v-on:keyup.13="QueryData" />
          <a class="SearchBtn" @click="QueryData" v-text="$t('c.Common.Search')"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th><input type="checkbox" @change="checkChange('all')" /></th>
            <th>编号</th>
            <th v-if="['10','20'].includes(role)">溯源率</th>
            <th v-text="$t('c.Common.SecurityAuthentication')"></th>
            <th v-if="tradeType == 1">登记证号</th>
            <th v-if="tradeType == 1">农药名称</th>
            <th v-text="$t('c.Common.Name')"></th>
            <th v-if="tradeType == 1">规格码</th>
            <th v-text="$t('c.Common.Image')"></th>
            <th>模版跟随</th>
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td><input type="checkbox" :checked="item.active" @change="checkChange(index)"/></td>
            <td v-text="item.production_id"></td>
            <td :class="{activeText: item.trace_ratio>0}" v-if="['10','20'].includes(role)" v-text="item.trace_ratio || 0"></td>
            <td>
              <span v-if="item.is_antifake" class="icon iconfont iconicon-test61 whether blueColor"></span>
              <span v-else class="icon iconfont iconicon-test62 whether"></span>
            </td>
            <td v-text="item.reg_code_str" v-if="tradeType == 1"></td>
            <td v-text="item.alias_name" v-if="tradeType == 1"></td>
            <td v-text="item.name"></td>
            <td v-text="item.product_code" v-if="tradeType == 1"></td>
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.img + ')'}" v-if="item.img"><img v-if="item.img" class="tableImgShow" :src="item.img" @click="PreviewImgs"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td>
              <a v-if="item.follow_template" v-text="item.follow_template.production_id"></a>
              <a v-else>无</a>
            </td>
            <td class="TdStatus">
              <a v-if="item.estate == 1"><i class="wait"></i>{{$t('c.Common.InReview')}}</a>
              <a v-else-if="item.estate == 2"><i class="reject"></i>{{$t('c.Common.Refer')}}</a>
              <a v-else-if="item.estate == 3"><i class="pass"></i>{{$t('c.Common.Passed')}}</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray" v-if="['10','20'].includes(role)">
                <a style="min-width: 30px;" class="btnStyleTwo btnRadius greenBackColor" @click="GoToPage('ProductEdit?id='+item.production_id)" v-text="$t('c.Common.Edit')"></a>
              </div>
              <div class="btnArray" v-else>
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,item.production_id)" v-text="$t('c.Common.SecurityAuthentication')"></a>
                <a class="btnStyleTwo btnRadius cyanBackColor" @click="GoToPage('MobileTemplate?id='+item.production_id)" v-text="$t('b.ProductManage.TemplateSettings')"></a>
                <a class="btnStyleTwo btnRadius greenBackColor" @click="GoToPage('ProductEdit?id='+item.production_id)" v-text="$t('c.Common.Edit')"></a>
                <a class="btnStyleTwo btnRadius orangeBackColor" @click="GoToPage('ProductEdit?id='+item.production_id+'&copy=true')" v-text="$t('c.Common.Copy')"></a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.production_id)" v-text="$t('c.Common.Delete')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 添加商标模板窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{ModelType === 'template'?'模版跟随':$t('c.Common.SecurityAuthentication')}}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              
              <template v-if="ModelType === 'brand'">
                <li class="FormStyleTwoRow" v-if="SelectArr[0].datas.length > 0">
                  <p class="FormStyleTwoTitle"><i>*</i> {{$t('b.Brand.Brand')}}（{{$t('b.Brand.HintTwo')}}）</p>
                  <p>
                    <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                      {{SelectArr[0].datas[SelectArr[0].ix].trademark_name}}
                      <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                        <li class='OptionStyleQuery' @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery('trademark')">查询</a></li>
                        <ul class="OptionStyleMain">
                          <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[0].ix == index && !SelectArr[0].isHover}" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.trademark_name" @click="SelectData(0,index)" @mouseover="SelectHover($self,0,true)" @mouseout="SelectHover($self,0,false)"></li>
                          <li class="OptionStyleLoading" @click.stop="SelectArrLoading(0)" v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page < SelectArr[0].totalPages">加载更多</li>
                          <li class="OptionStyleLoading" @click.stop v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page >= SelectArr[0].totalPages">无更多内容</li>
                        </ul>
                      </ul>
                      <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                    </div>
                  </p>
                </li>
                <div class="BrandHint" v-else @click="OpenLeftNavPage(true,'Brand')"><i class="redColor">*</i> 还没有可使用的商标模板，点击<a>前往添加</a></div>
              </template>
              
              <template v-if="ModelType === 'template'">
                <li class="FormStyleTwoRow" v-if="SelectArr[1].datas.length > 0">
                  <p class="FormStyleTwoTitle"><i>*</i> 商品名称（请选择需要跟随模版的产品）</p>
                  <p>
                    <div class="SelectStyle" @click.stop="SelectActive(1,$self)">
                      {{SelectArr[1].datas[SelectArr[1].ix].name}}
                      <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                        <li class='OptionStyleQuery' @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery('production')">查询</a></li>
                        <ul class="OptionStyleMain">
                          <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[1].ix == index && !SelectArr[1].isHover}" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.name" @click="SelectData(1,index)" @mouseover="SelectHover($self,1,true)" @mouseout="SelectHover($self,1,false)"></li>
                          <li class="OptionStyleLoading" @click.stop="SelectArrLoading(1)" v-if="SelectArr[1].totalPages > 1 && SelectArr[1].page < SelectArr[1].totalPages">加载更多</li>
                          <li class="OptionStyleLoading" @click.stop v-if="SelectArr[1].totalPages > 1 && SelectArr[1].page >= SelectArr[1].totalPages">无更多内容</li>
                        </ul>
                      </ul>
                      <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                    </div>
                  </p>
                </li>
              </template>

            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">{{$t('c.Common.Cancel')}}</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">{{$t('c.Common.Confirm')}}</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>
<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'ProductManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      role: 30,
      page:1,
      spage:1,
      id:null,
      btype:null,
      tradeType:null,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      stotalPages:0,
      datas:null,
      ModelShow:false,
      ModelType: 'brand',
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      ConfirmType:null,
      isHover:false,
      SelectArr:[{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      },{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      }],
      QueryName:null,
      SQueryName:null,
      fileTxt:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    self.role = localStorage.getItem("wwmxt_Role");
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    self.btype = localStorage.getItem("wwmxt_BType");  //商户类型
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetList();

    //普通行业
    if(!['10','20'].includes(self.role)){
      self.GetBrandList();
      self.GetProductList();
    }
    
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      if(self.QueryName){
        self.QueryData();
      }else{
        self.GetList();
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      if(self.QueryName){
        self.QueryData();
      }else{
        self.GetList();
      }
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.SelectArr[0].isHover = false;
    },
    HandleModel (status,id) {  //隐藏显示生码模态框
      self.id = id;
      self.ModelShow = status;
      self.ModelType = 'brand';
      self.SQueryName = ''; //清空关键词
    },
    SubmitForm () {  //提交表单
      let _url = "bind_trademark_product"; //品牌绑定
      let _data = {};
      if(self.ModelType === 'template'){
        const tid = self.SelectArr[1].datas[self.SelectArr[1].ix].template_id;
        const pid = self.SelectArr[1].datas[self.SelectArr[1].ix].production_id;
        let obj = tid?{template_id:tid,production_id:pid}:null;
        let pArray = [];
        self.datas.forEach(item=>{
          if(item.active){
            pArray.push(item.production_id);
          }
        })
        _url = "bind_follow_templates_product"; //模版绑定
        _data = {template: JSON.stringify(obj),production_array: pArray}
      }else{
        if(!self.SelectArr[0].datas.length){
          return;
        }
        _data = {trademark_id:self.SelectArr[0].datas[self.SelectArr[0].ix].trademark_id,production_id:self.id};
      }
      self.loading = true;
      axios({
        method:"PATCH",
        url:self.GetServerUrl(1)+ _url,
        data:_data
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      let url = "production_list/";
      if(self.role == 10 || localStorage.getItem("wwmxt_Rootid") == '1011'){
        url = "production_page/";
      }
      axios.get(self.GetServerUrl(1)+url+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
            if(datas[item].follow_template){
              datas[item].follow_template = JSON.parse(datas[item].follow_template);
            }
          }
          self.datas = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    GetBrandList (te) {
      axios.get(self.GetServerUrl(1)+"trademark_list/"+self.spage+"/1")
      .then(function (response){
        if(!te){
          self.SelectArr[0].datas = response.data.meta;
          self.SelectArr[0].totalPages = response.data.meta.last_page;
        }else{
          self.loading = false;
          let datas = response.data.meta.data;
          for(let item in datas){
            self.SelectArr[0].datas.push(datas[item]);
          }
        }
      }).catch(function (error){
        if(!te){
          self.loading = false;
        }
        console.log("产品信息获取失败！");
      })
    },
    GetProductList() {
      axios({
        method: "post",
        url: self.GetServerUrl(1) + "production_list_condition",
        data: {no_page: true}
      }).then(function (response) {
        self.SelectArr[1].totalPages = response.data.meta.last_page;
        self.SelectArr[1].datas = response.data.meta;
        self.SelectArr[1].datas.unshift({production_id:null,name:'不绑定'})

        //商品名称别名
        for (let i in self.SelectArr[1].datas) {
          let datas = self.SelectArr[1].datas[i];
          if (self.tradeType == 1) {
            datas.nyname = datas.name + '(' + datas.product_code + '、' + datas.unit + '、' + datas.reg_code + '、' + datas.alias_name + ')'
          } else {
            datas.nyname = datas.name
          }
        }

      }).catch(function (error) {
          if (self.SQueryName) {
            self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
          }
      })
    },
    RemoveData (id) {
      self.id = id;
      self.ConfirmType = 1;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该产品？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        if(self.ConfirmType == 1){  //删除产品
          axios({
            method:"delete",
            url:self.GetServerUrl(1)+"production/"+self.id,
          }).then(function (response) {
              self.loading = false;
              self.ToastFun(0,response.data.message);  //弹窗提醒
              self.GetList();
          }).catch(function (error) {
            self.loading = false;
            self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
          })
        }else{  //导入数据

          let fileDOM;
          fileDOM = this.$refs.file;

          //FormDatas格式提交
          let Datas = new FormData();
          Datas.append("file",fileDOM.files[0]);
          Datas.append("rootid",localStorage.getItem("wwmxt_Rootid"));

          axios.post(self.GetServerUrl(1)+"productImport",Datas)
          .then(function (response){
            if(self.ConfirmType != 1){
              self.$refs.file.value = null; //清空input-file
            }
            self.loading = false;
            self.ToastFun(0,response.data.message);
            self.GetList();
          }).catch(function (error){
            if(self.ConfirmType != 1){
              self.$refs.file.value = null; //清空input-file
            }
            self.loading = false;
            self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
          })
        }
      }
    },
    QueryData () {
      self.datas = null;
      self.isLoding = true;
      let url = "production_list_condition/";
      if(self.role == 10){
        url = `production_page/${self.page}`;
      }
      axios.post(self.GetServerUrl(1)+url,{name:self.QueryName})
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.datas = null;
      })
    },
    SelectArrQuery (type) {
      self.loading = true;
      let _url = 'trademark_list_condition';
      if(type === 'production'){
        _url = 'production_list_condition'
      }
      axios.post(self.GetServerUrl(1)+_url,{name:self.SQueryName})
      .then(function (response){
        self.SelectArr[type === 'production'?1:0].ix = 0;
        self.SelectArr[type === 'production'?1:0].datas = response.data.meta.data;
        if(type === 'production'){
          self.SelectArr[1].datas.unshift({production_id:null,name:'不绑定'})
        }
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SelectArrLoading () {
      self.SelectArr[0].page++;
      self.loading = true;
      self.GetProductList(true);
    },
    OpenLeftNavPage (te,url) {
      self.$emit('OpenLeftNavPage',{te:te,url:url});
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    },
    UploadFile () {
      let fileTxt;
      // if(this.$refs.file.files[0].type != "text/plain"){
      //   self.ToastFun(1,"文件格式必须为txt！");
      //   fileTxt = null;
      //   return;
      // }
      let size = (this.$refs.file.files[0].size/(1024 * 1024)).toFixed(2);
      if(size < 0.01){
        size = 0.01;
      }
      if(size > 20){
        self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
        fileTxt = null;
        return;
      }
      fileTxt = '（'+size+'M）' + this.$refs.file.files[0].name;
      self.ConfirmType = 2;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定导入 "+fileTxt+" 文件？"
    },
    checkChange (ix){
      if(ix === 'all'){
        self.datas.forEach(item=>{
          item.active = !item.active;
        })
      }else{
        self.datas[ix].active = !self.datas[ix].active;
      }
    },
    selectTemplate (type='set'){
      let ids = [];
      self.datas.forEach(item=>{
        if(item.active){
          ids.push(item.production_id);
        }
      })
      if(!ids.length){
        self.ToastFun(1, "请选择商品！");  //弹窗提醒
        return
      };

      if(type === 'set'){
        localStorage.setItem('wwmxt_tids',ids); //缓存产品id
        this.$router.push({path:'MobileTemplate'}) // 路由跳转
      }else if(type === 'follow'){
        self.ModelType = 'template';
        self.ModelShow = true;
      }
      
    }
  }
}
</script>

<style scoped>
  .BrandHint{
    color:#404A59;
  }
  .BrandHint a{
    color:#278BF5;
    cursor: pointer;
    margin-left:5px;
  }

  .activeText{
    color:#278BF5;
    font-weight: bold;
  }
  .BrandHint a:hover{
    text-decoration: underline;
  }
</style>
