<template>
  <div class="video">
    <div id="video-ezuikit" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import EZUIKit from 'ezuikit-js';
export default {
  name: 'klive',
  props: {
    data: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      player: null,
      isPlay: false,
    }
  },
  beforeDestroy() {
    this.player.stop()
  },
  mounted() {
    const templateList = {
      header: {
        color: '#fff',
        activeColor: '#FFFFFF',
        backgroundColor: '#000000',
        btnList: [
          // {
          //   iconId: 'deviceID',
          //   part: 'left',
          //   defaultActive: 0,
          //   memo: '顶部设备序列号',
          //   isrender: 1,
          // },
          // {
          //   iconId: 'deviceName',
          //   part: 'left',
          //   defaultActive: 0,
          //   memo: '顶部设备名称',
          //   isrender: 1,
          // },
          // {
          //   iconId: 'cloudRec',
          //   part: 'right',
          //   defaultActive: 0,
          //   memo: '云存储',
          //   isrender: 0,
          // },
          // {
          //   iconId: 'rec',
          //   part: 'right',
          //   defaultActive: 0,
          //   memo: 'SD卡回放',
          //   isrender: 0,
          // },
        ],
      },
      footer: {
        color: '#FFFFFF',
        activeColor: '#1890FF',
        backgroundColor: '#00000021',
        btnList: [
          {
            iconId: 'play',
            part: 'left',
            defaultActive: 1,
            memo: '播放',
            isrender: 1,
          },
          {
            iconId: 'capturePicture',
            part: 'left',
            defaultActive: 0,
            memo: '截屏按钮',
            isrender: 1,
          },
          {
            iconId: 'sound',
            part: 'left',
            defaultActive: 0,
            memo: '声音按钮',
            isrender: 1,
          },
          // {
          //   iconId: 'talk',
          //   part: 'left',
          //   defaultActive: 0,
          //   memo: '对讲按钮',
          //   isrender: 1,
          // },
          {
            iconId: 'hd',
            part: 'right',
            defaultActive: 0,
            memo: '清晰度切换按钮',
            isrender: 1,
          },
          {
            iconId: "webExpend",
            part: "right",
            defaultActive: 0,
            memo: "网页全屏按钮",
            isrender: 1
          },
          {
            iconId: 'expend',
            part: 'right',
            defaultActive: 0,
            memo: '全局全屏按钮',
            isrender: 1,
          },
        ],
      },
    }

    if(localStorage.getItem('wwmxt_Token')){
      const list = [{
        iconId: 'pantile',
        part: 'left',
        defaultActive: 0,
        memo: '云台控制按钮',
        isrender: 1,
      },
      {
        iconId: 'zoom',
        part: 'left',
        defaultActive: 0,
        memo: '电子放大',
        isrender: 1,
      },
      {
        iconId: 'recordvideo',
        part: 'left',
        defaultActive: 0,
        memo: '录制按钮',
        isrender: 1,
      }]
      templateList.footer.btnList.push(...list);
    }

    if (this.data.url) {
      this.player = new EZUIKit.EZUIKitPlayer({
        id: 'video-ezuikit', // 视频容器ID
        accessToken: this.data.key,
        themeData: templateList,
        url: this.data.url //url 为你莹石云监控的url地址信息
      });
    }
  },
  methods: {
    controlHandler(key) {
      console.log(key);
    }
  },
}
</script>

<style scoped>
.video {
  width: 100%;
  height: 100%;
  background-color: black;
}

.live-control {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  background-color: black;

  .left,
  .right {
    display: flex;
    gap: 25px;
  }

  .left {}

  .right {}

  .icon {
    width: 28px;
    height: 28px;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: .9;
    }
  }
}
</style>
