<template>
	<div class="PagingBox NoSelect" v-if="totalPages > 1">
	  <ul class="PagingMain">
	    <li class="icon iconfont iconicon-test24 PagingArrows PagingNormal PagingArrowsHight" @click="ChangePage(1)" v-if="page > 1"></li>
	    <li class="icon iconfont iconicon-test24 PagingArrows PagingNormal PagingArrowsDisabled" v-else></li>
	    <li class="PagingNunber PagingNormal" v-if="totalPages > 3 && page >= 4" @click="SkipPage(1)">1</li>
	    <li class="PagingMore PagingNormal" v-if="totalPages > 3 && page > 4">
	      <a><i></i><i></i><i></i></a>
	    </li>
	    <li class="PagingNunber PagingNormal" v-for="(item,index) in totalPages" v-bind:key="index" v-text="item" v-if="page < index+4 && page+2 > index" :class="{PagingNunberActive:index+1 == page}" @click="SkipPage(index+1)"></li>
	    <li class="PagingMore PagingNormal" v-if="totalPages > 3 && totalPages > page+3">
	      <a><i></i><i></i><i></i></a>
	    </li>
	    <li class="PagingNunber PagingNormal" v-if="totalPages > 3 && totalPages >= page+3" v-text="totalPages" @click="SkipPage(totalPages)"></li>
	    <li class="icon iconfont iconicon-test25 PagingArrows PagingNormal PagingArrowsHight" @click="ChangePage(2)" v-if="page < totalPages"></li>
	    <li class="icon iconfont iconicon-test25 PagingArrows PagingNormal PagingArrowsDisabled" v-else></li>
	  </ul>
	</div>
</template>

<script>
export default {
  name: 'Transition',
  props: ['page','totalPages'],
  data () {
    return {
      loadingImg:'static/images/loading.gif',
      errorImg:'static/images/error.png'
    }
  },
  mounted () {

  },
  methods: {
    ChangePage (te) {  //上下翻页
      this.$emit('ChangePage',{te:te});
    },
    SkipPage (ix) {  //跳转分页
      if(this.page != ix){
        this.$emit('SkipPage',{ix:ix});
      }
    }
  }
}
</script>

<style scoped>
  .transition{
    margin:50px 0;
  }
  .transitionImg{
    height: 200px;
    display: table;
    margin:0 auto;
  }
  .transitionImg img{
    display: block;
    height: 200px;
  }
  .transitionHint{
    color:#585A64;
    text-align: center;
  }
</style>
