<template>
	<div class="BMBox">
    <div class="BMHeadBox">
      <div class="BMHeadRow BMHeadRowOne">
        <ul>
          <li>
            <p class="BMHeadTitle">{{$t('b.HomePage.Balance')}}</p>
            <p class="redColor">￥<a class="BMHeadMoney">85.00</a></p>
          </li>
          <li>
            <p class="BMHeadTitle">{{$t('c.Common.TotalConsumption')}}</p>
            <p class="grayColor">￥<a class="BMHeadMoney">885.00</a></p>
          </li>

          <div class="BMHeadRowOneBottom">
            <a class="Recharge">{{$t('b.HomePage.Recharge')}}</a>
            <a class="WithdrawBtn">{{$t('b.HomePage.Withdraw')}}</a>
            <a class="NavbarStyleOneMore feedback" @click="GoToPage('IncomeDisbursement')">{{$t('b.HomePage.BillingDetails')}}<i class="icon iconfont iconicon-test25"></i></a>
          </div>
        </ul>
      </div>
      <div class="BMHeadRow BMHeadRowTwo">
        <ul>
          <li class="BMHeadTitle">2019年消费</li>
          <li id="ConsumeType"></li>
          <li>{{$t('c.Common.YearConsumption')}}<a>885</a>{{$t('c.Common.Yuan')}}</li>
        </ul>
      </div>
      <!-- <div class="BMHeadRow BMHeadRowThree">
        <ul class="HomeExpenseMain">
          <li v-text="$t('b.HomePage.InvoiceManagement')"></li>
          <li class="HomeExpenseMainTwoBalance">
            <p v-text="$t('b.HomePage.InvoicedAmount')"></p>
            <p>￥885</p>
            <a class="HomeExpenseMainTwoBalanceBtn feedback" @click="GoToPage('InvoiceManage')">{{$t('b.HomePage.AskingForInvoice')}}</a>
          </li>
          <li class="HomeExpenseMainTwoBtnArray NoSelect">
            <a v-text="$t('b.HomePage.SetInvoiceTitle')" @click="GoToPage('SetInvoiceTitle')"></a>
            <a v-text="$t('b.HomePage.InvoiceMailingAddress')" @click="GoToPage('InvoiceAddress')"></a>
          </li>
        </ul>
      </div> -->
    </div>

    <div class="BMMain" id="ConsumeRecord"></div>
	</div>
</template>

<script>
var self,ConsumeType,ConsumeRecord;
var echarts = require('echarts')
export default {
  name: 'BillingManagement',
  data () {
    return {

    }
  },
  created () {
    self = this;
    self.loadConsumeType();
    self.loadConsumeRecord();
  },
  updated () {
    self.loadConsumeType();
    self.loadConsumeRecord();
  },
  mounted () {
    //屏幕宽度改变图表自动适应
    window.addEventListener('resize',function(){
      self.ChangeChartWidth();
    })
  },
  methods: {
    loadConsumeType () {
      ConsumeType = echarts.init(document.getElementById('ConsumeType'));
      ConsumeType.setOption({
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
            orient: 'vertical',
            top: '15%',
            right: '0',
            data:[this.$t('b.Statistics.FunctionPackage'),this.$t('b.Statistics.FunctionModule'),this.$t('b.Statistics.Promotion')]
        },
        series: [{
          name:this.$t('b.Statistics.ConsumptionType'),
          type:'pie',
          radius: ['55%', '70%'],
          center: ['46px', '50%'],
          avoidLabelOverlap: false,
          label: {
              normal: {
                  show: false,
                  position: 'center'
              }
          },
          labelLine: {
              normal: {
                  show: false
              }
          },
          data:[
            {value:500, name:this.$t('b.Statistics.FunctionPackage')},
            {value:200, name:this.$t('b.Statistics.FunctionModule')},
            {value:350, name:this.$t('b.Statistics.Promotion')}
          ],
          itemStyle:{
            normal:{
              color:function(params) {
                var colorList = ['#DD4B39', '#1DA1F2', '#3B5998'];
                return colorList[params.dataIndex]
              }
            }
          }
        }]
      });
    },
    loadConsumeRecord () {
      ConsumeRecord = echarts.init(document.getElementById('ConsumeRecord'));
      ConsumeRecord.setOption({
          title: {
            text: this.$t('c.Common.HintOne'),
            color: '#162029',
            left:'20px',
            top:'20px',
            textStyle: {
              fontSize: '16',
              fontWeight: 'lighter'
            },
            subtext: this.$t('c.Common.HintTwo')
          },
          color: ['#3595FF'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '100px',
            left: '70px',
            right: '40px',
            bottom: '40px'
          },
          xAxis: {
            axisLine: {
                lineStyle: {
                    color: ['#999'],
                }
            },
            data: ["2019-09-12","2019-09-13","2019-09-14","2019-09-15","2019-09-16","2019-09-17","2019-09-18"]
          },
          yAxis: {
            axisLine: {
                lineStyle: {
                    color: ['#999'],
                }
            }
          },
          series: [{
              name: this.$t('b.Statistics.Monetary'),
              type: 'bar',
              barWidth: '30%',
              data: [1100,320, 420, 580, 220, 510, 720]
          }]
      });
    },
    ChangeChartWidth () {
      ConsumeType.resize();
      ConsumeRecord.resize();
    }
  }
}
</script>

<style scoped>
  .BMBox{
    margin:20px;
    min-width:1000px;
  }
  .BMHeadBox{
    overflow: hidden;
  }
  .BMHeadRow{
    float: left;
    width: 50%;
  }
  .BMHeadRow ul{
    background-color: #FFF;
    margin-right:15px;
    border-radius: 5px;
    border:1px solid #E4E8EB;
  }
  .BMHeadRow:last-child ul{
    margin-right:0;
  }
  .BMHeadRowTwo ul,.BMHeadRowThree ul{
    padding:20px;
  }
  .BMHeadRowOne ul{
    height:210px;
    overflow: hidden;
  }
  .BMHeadRowOne ul li{
    float: left;
    width: calc(50% - 40.5px);
    padding-bottom:20px;
    border-bottom: 1px solid #F3F3F3;
  }
  .BMHeadRowOne ul li:first-child{
    border-right:1px solid #EEE;
  }
  .BMHeadRowOne ul li{
    padding:20px;
    padding-bottom:40px;
  }
  .BMHeadTitle{
    font-size:15px;
  }
  .BMHeadMoney{
    font-size:22px;
  }
  .BMHeadRowOne ul li p:last-child{
    margin-top:10px;
  }
  .BMHeadRowOneBottom{
    width: calc(100% - 50px);
    padding:25px;
    overflow: hidden;
    position: relative;
  }

  #ConsumeType{
    height:130px;
  }
  .BMHeadRowTwo li:last-child a{
    color:#FF272D;
    font-size:17px;
    padding:0 5px;
  }
  .Recharge,.WithdrawBtn{
    cursor: pointer;
    padding:8px 13px;
    border-radius: 5px;
  }
  .Recharge{
    color:#FFF;
    background-color: #3595FF;
    border:1px solid #3595FF;
    margin-right:10px;
  }
  .Recharge:hover{
    background-color: #278BF5;
    border:1px solid #278BF5;
  }
  .WithdrawBtn{
    color:#3595FF;
    border:1px solid #3595FF;
  }
  .WithdrawBtn:hover{
    color:#278BF5;
    border:1px solid #278BF5;
  }
  .NavbarStyleOneMore{
    right:25px;
    top:25px;
  }

  .BMMain{
    height:500px;
    margin-top: 15px;
    background-color: #FFF;
    border-radius: 5px;
    border:1px solid #E4E8EB;
  }
</style>
