<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true,1)"><span class="icon iconfont iconicon-test20"></span>添加分组</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>分组名称</th>
            <th>时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.group_name"></td>
            <td v-text="item.created_at"></td>
            <td class="LastTdWidth">
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,2,item.space_group_id,item.group_name)">编辑</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.space_group_id)">删除</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 添加生码套餐窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            添加生码套餐
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 分组名称</p>
                <p><input placeholder="请填写分组名称" v-model="FormDatas.group_name" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>
    
	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
import Confirm from '@/components/common/Confirm.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'StaticPacket',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      te:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      totalPages:0,
      ModelShow:false,
      datas:null,
      column:[],
      FormDatas:{
        space_group_id:null,
        group_name:null
      }
    }
  },
  created () {
    self = this;
    self.te = self.$route.query.type;
    if(self.te == "img"){
      self.column = [{name:'图片空间',url:'ImagesSpace'}];
    }else if(self.te == "audio"){
      self.column = [{name:'音频管理',url:'AudioSpace'}];
    }else{
      self.column = [{name:'视频管理',url:'VideosSpace'}];
    }
    self.column.push({name:'分组管理',url:'StaticPacket?type='+self.te});

    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    HandleModel (status,te,id,name) {  //隐藏显示生码模态框
      self.ModelShow = status;
      if(!status){  //清空套餐ID并清除填充内容
        self.FormDatas.space_group_id = null;

        for(let item in self.FormDatas){
          self.FormDatas[item] = null;
        }
      }
      if(te == 2){  //编辑套餐
        self.FormDatas.space_group_id = id;
        self.FormDatas.group_name = name;
      }
    },
    GetList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"space_group_list/"+self.te)
      .then(function (response) {
          self.datas = response.data.meta
          self.isLoding = false;
      }).catch(function (error){
        let msg;
        if (error.response) {
          if(error.response.data.message){
            msg = error.response.data.message;
          }else{
            msg = error.response.statusText;
          }
        }else{
          msg = "请检查网络连接！";
        }
        self.subHint = msg;
        self.isLoding = false;
      })
    },
    SubmitForm () {  //提交表单
      self.loading = true;

      //有套餐ID等于编辑
      self.FormDatas.type = self.te;
      axios.post(self.GetServerUrl(1)+"space_group",self.FormDatas)
        .then(response => {
          self.HandleModel(false); //隐藏弹出框
          self.GetList();

          self.loading = false;
          self.ToastFun(0,response.data.message);  //弹窗提醒
      })
      .catch(error => {
        self.HandleModel(false); //隐藏弹出框

        let msg;
        if (error.response) {
          msg = error.response.data.message;
        } else {
          msg = error.message;
        }
        self.loading = false;
        self.ToastFun(2,msg);  //弹窗提醒
      })
    },
    RemoveData (id) {
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该分组？"
      self.FormDatas.space_group_id = id;
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;
        axios.delete(self.GetServerUrl(1)+"space_group/"+self.FormDatas.space_group_id)
          .then(response => {
            self.FormDatas.space_group_id = null;  //清空ID
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        })
        .catch(error => {
          let msg;
          if (error.response) {
            msg = error.response.data.message;
          } else {
            msg = error.message;
          }
          self.FormDatas.space_group_id = null;  //清空ID
          self.loading = false;
          self.ToastFun(2,msg);  //弹窗提醒
        })
      }
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
  tbody > tr > td{
    padding:22px 20px;
  }
</style>
