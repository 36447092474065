<template>
	<div :style="{minWidth}">
    <table>
      <thead :style="{border: `1px solid ${headLine}`}">
        <tr>
          <th :style="{paddingTop: space, paddingBottom: space, fontSize, ...item.customStyle}" v-for="(item, index) in data.column" :key="item.key+index">{{ item.name }}</th>
        </tr>
      </thead>
      <tbody v-if="data.list && data.list.length">
        <tr v-for="item in data.list">
          <td @click="$emit('itemClick', item)" :style="{textAlign: align, paddingTop: space, paddingBottom: space, fontSize}" v-for="(value, index) in data.column" :key="index">{{item[value.key]}}</td>
        </tr>
      </tbody>
    </table>
    <K-Transition v-if="!data.list.length" middle :customStyle="{ margin: '30rem auto', color: '#24e9ea', opacity: .7 }" errorImg="/static/images/error.svg" subHint="暂无数据" />
  </div>
</template>

<script>
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'ktable',
  components: {
    'K-Transition': Transition,
  },
  props: {
    data: {
      type: Object,
      default: ()=>{}
    },
    width: {
      type: String,
      default: '100%'
    },
    minWidth: {
      type: String,
      default: '100%'
    },
    align: {
      type: String,
      default: 'center'
    },
    space: {
      type: String,
      default: '7rem'
    },
    headLine: {
      type: String,
      default: 'rgba(15, 84, 155, 0.3)'
    },
    fontSize: {
      type: String,
      default: '5.5rem'
    }
  },
  data () {
    return {
      
    }
  },
  created () {
    
  }
}
</script>

<style scoped>
table{
  width: 100%;
}
th, td{
  padding-left: 5px;
  padding-right: 5px;
  line-height: 8rem;
}
tbody tr{
  cursor: pointer;
  &:hover{
    background-color: #053664;
  }
}
</style>
