<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>添加规则</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable">
        <thead>
          <tr>
            <th>生码规则名称</th>
            <th>生码器地址</th>
            <th>行业</th>
            <th>时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td v-text="item.rule"></td>
            <td style="max-width: 200px;" class="ListBtnArray">
              <a v-for="(val,index) in item.trade" v-bind:key="index" v-text="val.trade_name"></a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,item)">编辑</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.code_rule_id)">删除</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 添加生码规则窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            添加生码规则
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 生码规则名称</p>
                <p><input placeholder="请输入生码规则名称" v-model="FormDatas.name" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 生码器地址</p>
                <p><input placeholder="请输入生码器地址" v-model="FormDatas.rule" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>选择行业（选择后点击后方 “添加” 按钮，添加到列表）</p>
                <p>
                  <div class='SelectStyleArr'>
                    <div class="SelectStyle SelectStyle2" @click.stop="SelectActive(0,$self)" v-if="SelectArr[0].datas">
                      <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].trade_name}}</div>
                      <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                        <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.trade_name" @click="SelectData(0,index,item.trade_id,item.trade_level)"></li>
                        </ul>
                      </ul>
                      <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                    </div>
                    <div class="SelectStyle SelectStyle2" @click.stop="SelectActive(1,$self)" v-if="SelectArr[1].datas">
                      <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix].trade_name}}</div>
                      <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                        <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[1].datas.length > 6}" v-if="SelectArr[1].datas.length">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.trade_name" @click="SelectData(1,index,item.trade_id,item.trade_level)"></li>
                        </ul>
                      </ul>
                      <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                    </div>
                    <div class="SelectStyleArrRightBtn btnStyleTwo btnRadius greenBackColor" @click="AddTrade">添加</div>
                    <div class="clear"></div>
                  </div>
                </p>
              </li>
              <li class="ListBtnArray">
                <a class="feedback NoSelect" v-if="FormDatas.trade" v-for="(item,index) in FormDatas.trade" v-bind:key="index" v-text="item.trade_name" @click="RemoveTrade(item)"></a>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'CreateCodeAudit',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast,
    'K-Confirm': Confirm
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      totalPages:0,
      datas:null,
      ModelShow:false,
      SelectArr:[{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      }],
      FormDatas:{
        name:null,
        rule:null,
        trade:null
      }
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
    self.GetTrade();
  },
  methods: {
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    HandleModel (status,data) {  //隐藏显示生码模态框
      self.ModelShow = status;
      if(data){
        self.id = data.code_rule_id;
        self.FormDatas.name = data.name;
        self.FormDatas.rule = data.rule;
        self.FormDatas.trade = data.trade;
      }else{
        self.id = null;
        self.FormDatas.name = null;
        self.FormDatas.rule = null;
        self.FormDatas.trade = null;
      }
    },
    SelectData (ix,six,id,rank) {
      self.SelectArr[ix].ix = six;
      if(ix == 0){  //选中行业
        self.SelectArr[1].ix = 0;
        self.SelectArr[1].datas = null;
        self.GetTrade(rank,id);
      }
    },
    GetTrade (rank,id) {
      var tid = 0;
      var trank = 0;
      if(id){
        tid = id;
      }
      if(rank){
        trank = rank;
      }
      axios.get(self.GetServerUrl(1)+"trade/"+tid+"/")
      .then(function (response) {
          if(trank == 0){
            self.SelectArr[0].datas = response.data.meta
            self.GetTrade(response.data.meta[0].trade_level,response.data.meta[0].trade_id);
          }else{
            self.SelectArr[1].datas = response.data.meta
          }
      }).catch(function (error) {
        console.log("获取行业失败");
      })
    },
    GetList () {
      axios.get(self.GetServerUrl(1)+"code_rule_list")
      .then(function (response) {
        let datas = response.data.meta
        for(let item in datas){
          if(datas[item].trade){
            datas[item].trade = JSON.parse(datas[item].trade);
          }
        }
        self.datas = datas
        self.isLoding = false;
      }).catch(function (error){
        self.subHint = "数据为空";
        self.isLoding = false;
      })
    },
    AddTrade () {
      let ix = self.SelectArr[1].ix;
      let iSRepeat = false;
      if(self.FormDatas.trade&&self.FormDatas.trade.length > 0){
        for(let item in self.FormDatas.trade){
          if(self.FormDatas.trade[item].trade_id == self.SelectArr[1].datas[ix].trade_id){
            iSRepeat = true;
            self.ToastFun(2,"不允许重复插入！");
            return;
          }
          if(item == self.FormDatas.trade.length-1 && !iSRepeat){
            self.FormDatas.trade.push(self.SelectArr[1].datas[ix]);
          }
        }
      }else{
        self.FormDatas.trade = [self.SelectArr[1].datas[ix]];
      }
    },
    RemoveTrade (data) {
      for(let item in self.FormDatas.trade){
        if(self.FormDatas.trade[item].trade_id == data.trade_id){
          self.FormDatas.trade.splice(item,1);
        }
      }
    },
    SubmitForm () {
      let te = "post";
      if(self.id){
        te = "patch"
        self.FormDatas.id = self.id;
      }

      axios({
        method:te,
        url:self.GetServerUrl(1)+"code_rule",
        data:self.FormDatas
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveData (id) {
      self.id = id;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该规则？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"code_rule/"+self.id,
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    }
  }
}
</script>

<style scoped>
  .ListBtnArray{
    overflow: hidden;
  }
  .ListBtnArray a{
    float: left;
    padding:10px 16px;
    border:1px solid #DDD;
    border-radius: 5px;
    margin-right:17px;
    color:#585A64;
    cursor: pointer;
    margin-top:15px;
  }
  .ListBtnActive{
    color:#278BF5 !important;
    border:1px solid #278BF5 !important;
  }
  .SelectStyle2{
    float: left;
    width:200px;
    margin-right:18px;
  }
  .SelectStyle2:last-child{
    margin-right: 0;
  }
  .SelectStyleArrRightBtn{
    position: absolute;
    right:0;top:0;
  }
</style>
