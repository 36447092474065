<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 表单内容 -->
    <div class="box FloatBox">
      <div class='FunctionListHeadBox'>
        <ul class='FunctionListHead'>
          <li class="FunctionListHeadRow" v-for="(item,index) in NavList" v-bind:key="index" v-text="$t('b.ProductManage.'+item+'')" :class="{FunctionListHeadActive:index==NavIndex}" @click="SwicthHeadNav(index)"></li>
        </ul>
      </div>

      <div class="MobileTemplateBox FloatBox">
        <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
        <div v-else>
          <ul class="FloatBoxRow" 
            v-for="(item,index) in datas" 
            v-bind:key="index" 
            v-if="item.isShow != false">

            <li class="MobileTemplateImg">
              <img :src="item.cover_img">
              <div class="TemplateDemoBox" v-if="item.DemoQR"><img :src="item.DemoQR"></div>
            </li>
            <li class="MobileTemplateTxt">
              <p v-text="item.name"></p>
              <p v-text="item.describe"></p>
            </li>
            <li class="MobileTemplateBtnArray btnArray">
              <a class="btnStyleTwo btnRadius cyanBackColor" @mouseenter="CreateDemoQr(index,true,item.template_id,item.id)" @mouseleave="CreateDemoQr(index,false)">{{$t('c.Common.Preview')}}</a>
              <a class="btnStyleTwo btnRadius blueBackColor" :class="{MobileTemplateBtnArrayInactive:!item.active}" @click="MobileTemplateEdit(index,item.template_id)">{{$t('c.Common.Edit')}}</a>
              <a class="btnStyleTwo btnRadius redBackColor" v-if="item.active" :class="{MobileTemplateBtnArrayInactive:index == 0}" @click="CancelSelect(index)">{{$t('b.ProductManage.CancelSelect')}}</a>
              <a class="btnStyleTwo btnRadius greenBackColor" v-else @click="SelectMoblieTemplate(index,item.template_id)">{{$t('b.ProductManage.TemplateSelect')}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import QRCode from 'qrcode'
export default {
  name: 'MobileTemplate',
  components: {
    'K-Column': Column,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      selectType:1, //1单个商品、2多个商品选择模版
      rootid:null,  //用户ID
      page:1,
      tid:null,  //当前选择模板
      pid:null,
      NavIndex: 0,
      tradeType: 0,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      column:[{
          name:'ProductManage',url:'ProductManage'
        }],
      NavList:["CommonTemplate","AntiFakeTemplate","PromotionTemplate"],
      datas:null
    }
  },
  created () {
    self = this;
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.rootid = localStorage.getItem("wwmxt_Rootid");  //用户ID
    if(this.$route.query.id){
      self.selectType = 1;
      self.pid = self.$route.query.id;
      self.column.push({name:'TemplateSettings',url:'MobileTemplate?id='+this.$route.query.id})
      self.GetProductDetail();
    }else{
      self.selectType = 2;
      self.GetList();
    }
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    SwicthHeadNav (ix) {
      self.NavIndex = ix;
      self.GetList();
    },
    SelectMoblieTemplate (ix,tid) {
      for(let item in self.datas){
        self.datas[item].active = false;
      }
      self.datas[ix].active = true;
      self.SetDefaultTemplate(tid);
    },
    CancelSelect (ix) {
      if(ix != 0){
        self.datas[ix].active = false;
        self.datas[0].active = true;
      }else{
        alert("至少选择一个模板！");
      }
    },
    GetProductDetail () {
      axios.get(self.GetServerUrl(1)+"production/"+self.pid)
      .then(function (response){
        self.tid = response.data.meta.template_id;
        
        //如果等于农药行业且模版等于初始模版，将默认更换为农药默认模版
        if(self.tradeType == 1 && self.tid == 1){
          self.tid = null;
        }

        self.GetList();
      }).catch(function (error){
        console.log(self.ErrorHint(error));
      })
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      self.subHint = '数据加载中...';
      axios.get(self.GetServerUrl(1)+"template_list/"+self.NavIndex+"/"+self.page)
      .then(function (response) {
        let datas = response.data.meta.data;
        for(let item in datas){
          datas[item].DemoQR = null;

          //隐藏模版
          if(datas[item].id === 'Normal18' && self.rootid !== '100010002'){
            datas[item].isShow = false;
          }

          //鲜品达
          if(datas[item].id === 'Normal05' && self.rootid.substring(0,4) !== '1004' && self.rootid != '100010022'){
            datas[item].isShow = false;
          }

          if(self.selectType === 1){ //单个产品模版选择
            if(item == 0 && !self.tid){
              datas[item].active = true;
              self.tid = response.data.meta.data[0].template_id;
              self.SetDefaultTemplate(response.data.meta.data[0].template_id);
            }else{
              if(self.tid == response.data.meta.data[item].template_id){
                datas[item].active = true;
              }else{
                datas[item].active = false;
              }
            }
          }else{ //多个产品模版选择

          }
          
        }
        self.datas = datas
        self.isLoding = false;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.datas = null;
      })
    },
    MobileTemplateEdit (ix,tid) {
      if(self.datas[ix].active){
        self.GoToPage("MobileTemplateEdit?pid="+self.pid+"&tid="+tid);
      }
    },
    CreateDemoQr (ix,te,tid,tname) {
      if(te){
        let UrlData = self.GetViewURL() +'/#/?tid='+tid+'&tname='+tname+'&pid='+self.pid+'&rid='+self.rootid
        console.log(UrlData)
        QRCode.toDataURL(UrlData,{errorCorrectionLevel:"H",version: 8},
        function(err,url){
          if(!err){
            self.datas[ix].DemoQR = url;
          }
        })
      }else{
        self.datas[ix].DemoQR = null;
      }
    },
    SetDefaultTemplate (tid) {

      let _url = 'bind_template_product';
      let _datas = {production_id:self.pid,template_id:tid};
      if(self.selectType === 2){ //多个商品模版选择
        _url = 'bind_templates_product';
        _datas = {production_ids:localStorage.getItem('wwmxt_tids').split(','),template_id:tid};
      }

      self.loading = true;
      axios.patch(self.GetServerUrl(1)+_url,_datas)
      .then(function (response){
        self.tid = tid;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  .MobileTemplateBox{
    padding-top:20px;
  }
  .MobileTemplateBox ul{
    width: 280px;
    margin-bottom: 20px;
    margin-right:30px;
  }
  .MobileTemplateImg{
    height:280px;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #F3F3F3;
  }
  .MobileTemplateImg img{
    display: block;
    width: 100%;
  }
  .MobileTemplateTxt p{
    padding-top:8px;
    color:#585A64;
    line-height: 20px;
  }
  .MobileTemplateBtnArray{
    margin-top:12px;
  }
  .MobileTemplateBtnArrayInactive{
    opacity: .6;
  }
  .TemplateDemoBox{
    position: absolute;
    left:0;right:0;
    top:0;bottom:0;
    background: rgba(0,0,0,0.5);
  }
  .TemplateDemoBox img{
    width: 210px;
    height: 210px;
    margin:35px auto;
  }
</style>
