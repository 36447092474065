<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>{{$t('b.CodePacket.CodeGrouping')}}</li>
      </ul>
      <ul class="TableHeadRight" style="margin-right:20px;">
        <div class="SelectStyle" @click.stop="SelectActive(1,$self)">
          {{$t('c.Common.'+SelectArr[1].datas[SelectArr[1].ix]+'')}}
          <ul class="OptionStyleBox" v-if="SelectArr[1].active">
            <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="$t('c.Common.'+item+'')" @click="SelectData(1,index)"></li>
          </ul>
          <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>
    <div class="TableHint"><i>*</i> {{$t('c.Common.FileHint')}}</div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Numbering')"></th>
            <th v-text="$t('b.CodePacket.CombinationType')"></th>
            <th v-text="$t('c.Common.CodeNumber')"></th>
            <th v-text="$t('b.CodePacket.NumberCombination')"></th>
            <th v-text="$t('b.CodePacket.NumberSuccessfulCombinations')"></th>
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.group_id"></td>
            <td>
              <a v-if="item.group_type == 1">{{$t('c.Common.Scan')}}</a>
              <a v-else-if="item.group_type == 2">{{$t('c.Common.Extent')}}</a>
              <a v-else>{{$t('c.Common.File')}}</a>
            </td>
            <td v-text="item.code_count"></td>
            <td v-text="item.group_count"></td>
            <td v-text="item.group_success_count"></td>
            <td class="TdStatus">
              <a v-if="item.result == 100"><i class="wait"></i>{{$t('c.Common.InProgress')}}</a>
              <a v-else-if="item.result == 200"><i class="reject"></i>{{$t('c.Common.Failure')}}</a>
              <a v-else-if="item.result == 300"><i class="pass"></i>{{$t('c.Common.Completed')}}</a>
              <a v-else-if="item.result == 400"><i class="reject"></i>{{$t('c.Common.Expired')}}</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" v-if="item.result == 300" @click="DownCodeFile(3,item.group_id)" v-text="$t('c.Common.Download')"></a>
                <a class="btnStyleTwo btnRadius ProhibitBtn" v-else v-text="$t('c.Common.Download')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 生码窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{$t('b.CodePacket.CodeGrouping')}}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">{{$t('b.CodePacket.PacketMode')}}（{{$t('b.CodePacket.HintOne')}}）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    {{$t('b.CodePacket.'+SelectArr[0].datas[SelectArr[0].ix]+'')}}
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="$t('b.CodePacket.'+item+'')" @click="SelectData(0,index)"></li>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 0">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.SeniorCode')}}（{{$t('b.CodePacket.HintTwo')}}）</p>
                <p><input :placeholder="$t('b.CodePacket.HintThree')" v-model="HigherCode" v-on:keyup.13="AddCode(1)"/></p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 0">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.InferiorCode')}}（{{$t('b.CodeActive.HintFour')}}）</p>
                <p><input :placeholder="$t('b.CodePacket.HintFour')" v-model="LowCode" v-on:keyup.13="AddCode(2)"/></p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 1">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.SeniorCode')}}</p>
                <p><input :placeholder="$t('b.CodePacket.HintThree')" v-model="code" /></p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 1">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.InferiorFirstCode')}}</p>
                <p><input :placeholder="$t('b.CodePacket.HintFive')" v-model="first_code" /></p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 1">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.InferiorLastCode')}}</p>
                <p><input :placeholder="$t('b.CodePacket.HintSix')" v-model="last_code" /></p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 2 || SelectArr[0].ix == 4">
                <p class="FormStyleTwoTitle"><i>*</i> {{$t('b.CodePacket.GroupNumber')}}</p>
                <p><input :placeholder="$t('b.CodePacket.HintSeven')" v-model="group_number" /></p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 2 || SelectArr[0].ix == 4">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.SeniorFile')}}（{{$t('c.Common.FileSizeHint')}}）</p>
                <p class="btnArray">
                  <a class="btnStyleTwo btnRadius cyanBackColor"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.FileUpload')}}</a>
                  <a class="FormUpFileHint onlyOneRow" v-if="fileTxt" v-text="fileTxt"></a>
                  <input type="file" class="upload" @change="UploadFile(1)" ref="file" />
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 2 || SelectArr[0].ix == 4">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.InferiorFile')}}（{{$t('c.Common.FileSizeHint')}}）</p>
                <p class="btnArray">
                  <a class="btnStyleTwo btnRadius cyanBackColor"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.FileUpload')}}</a>
                  <a class="FormUpFileHint onlyOneRow" v-if="file2Txt" v-text="file2Txt"></a>
                  <input type="file" class="upload" @change="UploadFile(2)" ref="file2" />
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 3">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.File')}}（{{$t('c.Common.FileSizeHint')}}）</p>
                <p class="btnArray">
                  <a class="btnStyleTwo btnRadius cyanBackColor"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.FileUpload')}}</a>
                  <a class="FormUpFileHint onlyOneRow" v-if="file3Txt" v-text="file3Txt"></a>
                  <input type="file" class="upload" @change="UploadFile(3)" ref="file3" />
                </p>
              </li>
            </ul>
            <div class="CodeActiveListBox" v-if="SelectArr[0].ix == 0 && Codes.length > 0">
              <span class="CodeActiveListTitle">{{$t('c.Common.CurrentCodeList')}}<i v-text="Codes.length"></i>{{$t('c.Common.Unit')}}<a class="CodeActiveListRomoveAll feedback NoSelect" @click="Remove('all')">{{$t('c.Common.Clear')}}</a></span>
              <ul class="CodeActiveList CodeActiveListPacket">
                <li v-for="(item,index) in Codes" v-bind:key="index">{{item}}<i class="icon iconfont iconicon-test33 CodeActiveListRomove feedback" @click="Remove(index)"></i></li>
              </ul>
            </div>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'PacketManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      totalPages:0,
      datas:null,
      HigherCode:null,
      LowCode:null,
      ModelShow:false,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["ScanPacket","ExtentPacket","FilePacket","OldFilePacket"]
      },{
        ix:0,
        active:false,
        datas:["Scan","Extent","File","OldFile"]
      }],
      Codes:[],
      code:null,
      gp_type:0,
      first_code:null,
      last_code:null,
      group_number:null,
      fileTxt:null,
      file2Txt:null,
      file3Txt:null
    }
  },
  created () {
    self = this;
    this.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if(ix == 0){
        self.gp_type = six;
      }
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"codeGroupList/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    SubmitForm () {  //提交表单
      let ix = self.SelectArr[0].ix;
      let Datas;
      let URL = "group";
      if(ix == 0){
        Datas = {gp_type:self.gp_type,code:self.Codes,rootid:localStorage.getItem("wwmxt_Rootid")};
        if(self.Codes.length <= 2){
          self.ToastFun(1,"低级码不能少于两个！");
          return;
        }
      }else if(ix == 1){
        let code,first_code,last_code;
        if(self.code){
          code = self.FiltrationCode(self.code);
        }else{
          self.ToastFun(1,"高级码不能为空！");
          return;
        }
        if(self.first_code){
          first_code = self.FiltrationCode(self.first_code);
        }else{
          self.ToastFun(1,"低级首码不能为空！");
          return;
        }
        if(self.last_code){
          last_code = self.FiltrationCode(self.last_code);
        }else{
          self.ToastFun(1,"低级尾码不能为空！");
          return;
        }

        //高级码格式校验
        if(code.length != 18 && code.length != 32){
          console.log(code.length);
          self.ToastFun(1,"高级码格式不正确！");
          return;
        }
        //低级首码格式校验
        if(first_code.length != 18 && first_code.length != 32){
          self.ToastFun(1,"低级首码格式不正确！");
          return;
        }
        //低级尾码格式校验
        if(last_code.length != 18 && last_code.length != 32){
          self.ToastFun(1,"低级尾码格式不正确！");
          return;
        }
        Datas = {gp_type:self.gp_type,codeStr:code,first_code:first_code,last_code:last_code,rootid:localStorage.getItem("wwmxt_Rootid")};
      }else if(ix == 2 || ix == 4){
        if(!self.fileTxt || !self.file2Txt){
          self.ToastFun(1,"文件不能为空！");
          return;
        }
        let fileDOM,fileDOM2;
        fileDOM = this.$refs.file;
        fileDOM2 = this.$refs.file2;

        //外部分组接口
        if(ix == 4){
          URL = "externalFileGroup";
        }

        //FormDatas格式提交
        Datas = new FormData();
        Datas.append("UpCodeFile",fileDOM.files[0]);
        Datas.append("DownCodeFile",fileDOM2.files[0]);
        Datas.append("group_number",self.group_number);
        Datas.append("gp_type",self.gp_type);
        Datas.append("rootid",localStorage.getItem("wwmxt_Rootid"));
      }else if(ix == 3){
        URL = "groupNew";
        if(!self.file3Txt){
          self.ToastFun(1,"文件不能为空！");
          return;
        }
        let fileDOM = this.$refs.file3;

        //FormDatas格式提交
        Datas = new FormData();
        Datas.append("file",fileDOM.files[0]);
      }
      self.loading = true;
      axios.post(self.GetServerUrl(1)+ URL,Datas)
      .then(function (response){
        self.loading = false;
        self.ModelShow = false;
        self.ToastFun(0,response.data.message);
        self.GetList();

        //清空历史数据
        if(ix == 0){
          self.Codes= [];
        }else if(ix == 1){
          self.code = null;
          self.first_code = null;
          self.last_code = null;
        }
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    Remove (ix) {
      if(ix == "all"){
        self.Codes = [];
      }else{
        if(ix == 0){
          self.Codes = [];
        }else{
          self.Codes.splice(ix,1);
        }
      }
    },
    AddCode (te) {
      if(te == 1){
        let code = self.FiltrationCode(self.HigherCode);
        if(code){
          //等级校验
          if(code.substring(0,1) == "A"){
            self.ToastFun(1,"不能键入1级码！");
          }else{
            if(self.Codes.length >= 1){
              self.Codes = [code];
            }else{
              self.Codes.unshift(code);
            }
            self.HigherCode = null;
          }
        }else{
          self.ToastFun(1,"码标不正确！");
        }
      }else{
        let code = self.FiltrationCode(self.LowCode);
        if(code){
          if(self.Codes.length >= 1){
            //等级校验
            if(self.LevelCalibration(code) == self.LevelCalibration(self.Codes[0])){
              self.ToastFun(1,"分组不能为平级码！");
            }else if(self.LevelCalibration(code) > self.LevelCalibration(self.Codes[0])){
              self.ToastFun(1,"低级码等级不能组高级码！");
            }else{
              
              // if((self.LevelCalibration(self.Codes[0]) - self.LevelCalibration(code)) >= 2){
              //   self.ToastFun(1,"不能跨等级分组！");
              // }else{
              //   //重复校验
              //   if(self.RepeatCheck(self.Codes,code) == 1){
              //     self.Codes.push(code);
              //     self.LowCode = null;
              //   }else{
              //     self.ToastFun(1,"不能重复插入码标！");
              //   }
              // }
              
              //重复校验
              if(self.RepeatCheck(self.Codes,code) == 1){
                self.Codes.push(code);
                self.LowCode = null;
              }else{
                self.ToastFun(1,"不能重复插入码标！");
              }
              
            }
          }else{
            self.ToastFun(1,"请先添加高级码！");
          }
        }else{
          self.ToastFun(1,"码标不正确！");
        }
      }
    },
    UploadFile (te) {
      if(te == 1){
        if(this.$refs.file.files[0].type != "text/plain"){
          self.ToastFun(1,"文件格式必须为txt！");
          self.fileTxt = null;
          return;
        }
        let size = (this.$refs.file.files[0].size/(1024 * 1024)).toFixed(2);
        if(size < 0.01){
          size = 0.01;
        }
        if(size > 20){
          self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
          self.fileTxt = null;
          return;
        }
        self.fileTxt = '（'+size+'M）' + this.$refs.file.files[0].name;
      }else if(te == 2){
        if(this.$refs.file2.files[0].type != "text/plain"){
          self.ToastFun(1,"文件格式必须为txt！");
          self.file2Txt = null;
          return;
        }
        let size = (this.$refs.file2.files[0].size/(1024 * 1024)).toFixed(2);
        if(size < 0.01){
          size = 0.01;
        }
        if(size > 20){
          self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
          self.file2Txt = null;
          return;
        }
        self.file2Txt = '（'+size+'M）' + this.$refs.file2.files[0].name;
      }else if(te == 3){
        if(this.$refs.file3.files[0].type != "text/plain"){
          self.ToastFun(1,"文件格式必须为txt！");
          self.file3Txt = null;
          return;
        }
        let size = (this.$refs.file3.files[0].size/(1024 * 1024)).toFixed(2);
        if(size < 0.01){
          size = 0.01;
        }
        if(size > 20){
          self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
          self.file3Txt = null;
          return;
        }
        self.file3Txt = '（'+size+'M）' + this.$refs.file3.files[0].name;
      }
    },
    DownCodeFile (etype,id) {
      if(id){
        window.open("https://syapi.oid.plus/v1/downCodeFile/"+etype+"/"+id);
      }
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
  .SelectStyle{
    width: 120px;
  }
</style>
