<template>
	<div class="MainBox">
    <div class="NotificationsBox">
      <div class="NotificationsBtnArray NoSelect">
        <a v-for="(item,index) in NavList" v-bind:key="index" :class="{NotificationsBtnActive:index == ix,NotificationsBtn:index != ix}" @click="ClickNav(index,item.type)">
          <i v-if="item.number > 0" v-text="item.title+'('+item.number+')'"></i>
          <i v-else v-text="item.title"></i>
        </a>
      </div>

      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="tableTwo" v-else>
        <thead>
          <tr>
            <th style="width:10px"><input type="checkbox" v-model="all" @change="AllSelected" /></th>
            <th>标题内容</th>
            <th>时间</th>
            <th>类型</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index" :class="{read:item.is_read}">
            <td><input type="checkbox" v-model="item.active" @change="SelectedData(index,item)" /></td>
            <td><a v-text="item.title" class="HighlightTitle" @click="GoToPage('NotificationsDetail?id='+item.message_id)"></a></td>
            <td v-text="item.created_at"></td>
            <td>
              <a v-if="item.type == 'system'">系统通知</a>
              <a v-else-if="item.type == 'product'">产品通知</a>
              <a v-else-if="item.type == 'active'">活动通知</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="NotificationsBottom NoSelect">
        <ul class="NotificationsBottomLeft">
          <li class="NotificationsBottomLeftCheck"><input type="checkbox" v-model="all" @change="AllSelected" /></li>
          <li class="NotificationsBottomLeftBtn" :class="{NoClick:selectDatas.length < 1}" @click="RemoveData(1)">删除</li>
          <li class="NotificationsBottomLeftBtn" :class="{NoClick:selectDatas.length < 1}" @click="MessageRead(3)">标记已读</li>
          <li class="NotificationsBottomLeftBtn" @click="MessageRead(4)">全部已读</li>
          <li class="NotificationsBottomLeftBtn" @click="RemoveData(2)">全部删除</li>
        </ul>
        <ul class="NotificationsBottomRight" v-if="totalPages > 1">
          <li class="NotificationsBottomArrows" v-text="'<<'" @click="SkipPage(1)" :class="{prohibitClick:page <= 1}"></li>
          <li class="NotificationsBottomArrows" v-text="'<'" @click="ChangePage(1)" :class="{prohibitClick:page <= 1}"></li>
          <li class="NotificationsBottomPage" :class="{NotificationsBottomActive:index+1 == page}" v-for="(item,index) in totalPages" v-bind:key="index" v-text="item" v-if="page < index+4 && page+2 > index" @click="SkipPage(index+1)"></li>
          <li class="NotificationsBottomArrows" v-text="'>'" @click="ChangePage(2)" :class="{prohibitClick:page >= totalPages}"></li>
          <li class="NotificationsBottomArrows" v-text="'>>'" @click="SkipPage(totalPages)" :class="{prohibitClick:page >= totalPages}"></li>
        </ul>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'Notifications',
  components: {
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      ix:0,
      te:"all",
      page:1,
      totalPages:0,
      all:false,
      ConfirmType:null,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Confirm:{
        state:false,
        txt:null
      },
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      NavList:[{
        title:"全部消息",
        number:0,
        type:"all"
      },{
        title:"产品消息",
        number:0,
        type:"product"
      },{
        title:"活动消息",
        number:0,
        type:"active"
      },{
        title:"系统消息",
        number:0,
        type:"system"
      }],
      datas:null,
      selectDatas:[]
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ClickNav (ix,te) {
      self.ix = ix;
      self.te = te;
      self.GetList();
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"message_list/"+self.te+"/"+self.page)
      .then(function (response){
          let datas = [];
          datas = response.data.meta.data;
          for(let item in datas){
            datas[item].active = false;
          }
          self.isLoding = false;
          self.datas = datas;

          for(let item in self.NavList){
            if(self.NavList[item].type == "system"){
              self.NavList[item].number = response.data.meta.systemCount;
            }else if(self.NavList[item].type == "product"){
              self.NavList[item].number = response.data.meta.productCount;
            }else if(self.NavList[item].type == "active"){
              self.NavList[item].number = response.data.meta.activeCount;
            }
          }
          self.NavList[0].number = response.data.meta.systemCount + response.data.meta.productCount + response.data.meta.activeCount;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    ChangePage (te) {  //上下翻页
      if(te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (ix) {  //跳转分页
      self.page = ix;
      self.GetList();
    },
    SelectedData (ix,data) {
      if(data.active){
        if(self.selectDatas > 1){
          for(let item in self.selectDatas){
            if(self.selectDatas[item].message_id != data.message_id){
              self.selectDatas.push(data);
            }
          }
        }else{
          self.selectDatas.push(data);
        }
      }else{
        for(let item in self.selectDatas){
          if(self.selectDatas[item].message_id == data.message_id){
            self.selectDatas.splice(item,1);
          }
        }
      }
    },
    AllSelected () {
      if(self.all){
        for(let item in self.datas){
          self.datas[item].active = true
        }
        self.selectDatas = self.datas;
      }else{
        for(let item in self.datas){
          self.datas[item].active = false
        }
        self.selectDatas = [];
      }
    },
    RemoveData (te) {
      self.ConfirmType = te;
      self.Confirm.state = true;
      if(te == 1){
        self.Confirm.txt = "是否确定删除选中内容？"
      }else{
        self.Confirm.txt = "是否确定删除全部内容？"
      }
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        let datas=[],type;
        if(self.ConfirmType == 1 || self.ConfirmType == 3){
          for(let item in self.selectDatas){
            datas.push(self.selectDatas[item].message_id);
          }
        }else if(self.ConfirmType == 2 || self.ConfirmType == 4){
          for(let item in self.datas){
            datas.push(self.datas[item].message_id);
          }
        }

        if(self.ConfirmType == 1 || self.ConfirmType == 2){
          type = "delete"
        }else if(self.ConfirmType == 3 || self.ConfirmType == 4){
          type = "patch"
        }

        axios({
          method:type,
          url:self.GetServerUrl(1)+"message",
          data:{message_ids:datas},
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    },
    MessageRead (te) {
      self.ConfirmType = te;
      self.Confirm.state = true;
      if(te == 1){
        self.Confirm.txt = "是否确定标记选中内容为已读？"
      }else{
        self.Confirm.txt = "是否确定标记所有内容为已读？"
      }
    }
  }
}
</script>

<style scoped>
  .NotificationsBox{
    padding:20px;
    font-size:13px;
  }
  .NotificationsBtnArray{
    overflow: auto;
  }
  .NotificationsBtnArray a{
    float: left;
    display: block;
    padding:10px 15px;
    margin-left:10px;
    cursor: pointer;
  }
  .NotificationsBtnArray a:first-child{
    margin-left:0;
  }
  .NotificationsBtn{
    color:#333;
    background-color: #EBEDEF;
  }
  .NotificationsBtn:hover{
    background-color: #E6E8E9;
  }
  .NotificationsBtnActive{
    color:#FFF;
    background-color: #3595FF;
  }
  .NotificationsBtnActive:hover{
    background-color: #278BF5;
  }
  .prohibitClick{
    cursor: not-allowed;
  }
</style>
