<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>添加用户</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>用户名</th>
            <th>最后一次登录时间</th>
            <th>最后一次登录地址</th>
            <th>创建时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.username"></td>
            <td>
              <a v-if="item.last_login_at" v-text="item.last_login_at"></a>
              <a v-else>-</a>
            </td>
            <td>
              <a v-if="item.last_login_ip" v-text="item.last_login_ip"></a>
              <a v-else>-</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="ResetPwd(item.uid)">重置密码</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 添加管理员窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            添加用户
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 登录账号</p>
                <p><input placeholder="请输入登录账号" v-model="FormDatas.username" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 登录密码</p>
                <p><input placeholder="请输入登录密码" type="password" v-model="FormDatas.password"/></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 确认密码（防止密码输入有误）</p>
                <p><input placeholder="请确认密码" type="password" v-model="FormDatas.password_confirmation"/></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>查看用户管理说明</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'UserManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      page:1,
      te:null,
      id:null,  //网点ID
      uid:null,  //用户ID
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      totalPages:0,
      datas:null,
      ModelShow:false,
      column:[],
      FormDatas:{}
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.id = this.$route.query.id;
    if(this.$route.query.type == "process"){
      self.te = "process";
      self.column = [{name:'工序列表（点击返回上一级）',url:'ProcessList'},{name:'用户管理',url:'UserManage?type=process'}];
    }else{
      self.te = "dealer";
      self.column = [{name:'经销商列表（点击返回上一级）',url:'DealerList'},{name:'用户管理',url:'UserManage?type=dealer'}];
    }
    self.GetList();
  },
  mounted () {

  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
      self.FormDatas = {};
    },
    SubmitForm () {  //提交表单
      self.loading = true;
      self.FormDatas.id = self.id;

      if(!self.FormDatas.username){
        self.ToastFun(2,"登录账号不能为空！");  //弹窗提醒
        self.loading = false;
        return;
      }
      if(!self.FormDatas.password || !self.FormDatas.password_confirmation){
        self.ToastFun(2,"登录密码不能为空！");  //弹窗提醒
        self.loading = false;
        return;
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+self.te+"_manager",
        data:self.FormDatas
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    GetList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+self.te+"_manager_list/"+self.id+"/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.datas = null;
      })
    },
    ResetPwd (id) {
      self.uid = id;
      self.Confirm.style = 1;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定重置该用户密码？"
    },
    ResetResult (data) {
      self.loading = true;
      let url;
      if(self.te = "process"){
        url = "p_rest_password";
      }else{
        url = "dm_rest_password";
      }
      axios.patch(self.GetServerUrl(1)+url,{uid:self.uid})
      .then(function (response){
        self.Confirm.state = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));
      })
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.ResetResult();
      }
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
</style>
