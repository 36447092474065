<template>
  <div id="app">
    <div class="LeftNavBox" v-if="!isLoginData">
      <K-LeftNav ref="LeftNav" :isLogin="isLoginData" :simple="simpleData" @changeSimple="UpdateSimple"></K-LeftNav>
    </div>

    <div class="RightNavBox" :class="[{RightNavBoxPOne:!simpleData},{RightNavBoxPTwo:simpleData},{RightNavBoxPThree:isLoginData},{TopHide:isHead}]">
      <K-HeadBar ref="HeadBar" v-if="!isLoginData" :simple="simpleData" @changeSimple="UpdateSimple" @OpenLeftNavPage="OpenLeftNavPage"></K-HeadBar>
      <router-view @ListenBack="ListenBack" @OpenLeftNavPage="OpenLeftNavPage"></router-view>
    </div>
  </div>
</template>

<script>
var self;
import LeftNav from '@/components/common/LeftNav.vue'
import HeadBar from '@/components/common/HeadBar.vue'
export default {
  name: 'App',
  data () {
    return {
      isLoginData:false,  //是否显示左侧导航
      isHead:false,
      simpleData:false  //左侧导航展开状态
    }
  },
  components: {
    'K-LeftNav': LeftNav,
    'K-HeadBar': HeadBar
  },
  watch:{
    $route(to,from){
      self.ChangeRoute();
      if(self.$route.name == "ORGHomePage"){
        self.isHead = false;
      }
    }
  },
  created () {
    self = this;
    self.ChangeRoute();
  },
  methods:{
    ChangeRoute () {
      if(self.$route.name == "Login" || self.$route.name == "Signin" || self.$route.name == "ForgetPassword" || self.$route.name == "ORGKanban" || self.$route.name == "ORGCKKanban"){
        if(self.$route.name == "ORGKanban" || self.$route.name == "ORGCKKanban"){
          self.isHead = true;
        }
        self.isLoginData = true;
      }else{
        self.isLoginData = false;
      }
      console.log(self.isLoginData)
    },
    UpdateSimple (data) {
      self.simpleData = data.simple;
    },
    OpenLeftNavPage (data) {
      this.$refs['LeftNav'].OpenLeftNavPage(data.te,data.url)
    },
    ListenBack () {
      self.$refs.HeadBar.ChangeDatas();
    },
    initWebSocket(){  //初始化weosocket
      const wsuri = "ws://192.168.18.169:8059/websocket/}"  //这个地址由后端童鞋提供
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen(){  //连接成功
      console.log("连接成功！");
    },
    websocketonerror(){  //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e){  //监听数据
      console.log(e);
    },
    websocketsend(Data){  //数据发送
      this.websock.send(Data)
    },
    websocketclose(e){  //关闭
      console.log('断开连接', e)
    }
  }
}
</script>

<style scoped>
  .RightNavBox{
    top:60px;right:0;
    bottom:0;
    z-index: 99;
    position: fixed;
    overflow: auto;
    background-color: #EFF4F8;
  }
  .RightNavBoxPOne{
    left:180px;
  }
  .RightNavBoxPTwo{
    left:60px;
  }
  .RightNavBoxPThree{
    left:0;
  }
  .TopHide{
    top:0;
  }
</style>
