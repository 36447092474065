<template>
	<div class="MainBox PushBox">
    <div class="iconTitle">消息推送</div>
    <div class="PushMain">
      <div class='PushMainLeft'>
        <div class="FormStyleFive">
          <ul class="FormStyleFiveMain">
            <li class="FormStyleFiveLeft FormStyleFiveTitle"><i class="redColor">*</i> 目标平台：</li>
            <li class="FormStyleFiveRight FormStyleFiveIconArr">
              <a v-for="(item,index) in icons" v-bind:key="index" :class="['FormStyleFiveIconActive'+index,{FormStyleFiveIconInactive:!item.active}]" @click="ClickActive(1,index)">
                <span class="icon iconfont iconicon-test57" :class="item.icon"></span>
              </a>
            </li>
          </ul>
          <ul class="FormStyleFiveMain">
            <li class="FormStyleFiveLeft"><i class="redColor">*</i> 推送主题：</li>
            <li class="FormStyleFiveRight ">
              <div class="SelectStyle" @click="SelectActive(0)">
                {{SelectArr[0].datas[SelectArr[0].ix]}}
                <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
            </li>
          </ul>
          <ul class="FormStyleFiveMain">
            <li class="FormStyleFiveLeft"><i class="redColor">*</i> 推送标题：</li>
            <li class="FormStyleFiveRight ">
              <input placeholder="请输入推送的标题内容" />
            </li>
          </ul>
          <ul class="FormStyleFiveMain">
            <li class="FormStyleFiveLeft FormStyleFiveTitle"><i class="redColor">*</i> 推送内容：</li>
            <li class="FormStyleFiveRight ">
              <textarea placeholder="请输入推送内容"></textarea>
            </li>
          </ul>
        </div>
      </div>

      <div class="PushMainRight">
        <div class="FormStyleFive">
          <ul class="FormStyleFiveMain">
            <li class="FormStyleFiveLeft FormStyleFiveTitle"><i class="redColor">*</i> 推送图片：</li>
            <li class="FormStyleFiveRight FormStyleFiveImgsArr NoSelect">
              <a class="backImg"></a>
            </li>
          </ul>
          <ul class="FormStyleFiveMain">
            <li class="FormStyleFiveLeft"><i class="redColor">*</i> 目标用户：</li>
            <li class="FormStyleFiveRight FormStyleFiveCheckArr NoSelect">
              <a v-for="(item,index) in checks" v-bind:key="index" @click="ClickActive(2,index)"><span class="icon iconfont iconicon-test55 feedback" :class="{'iconicon-test56':item.active}"></span>{{item.name}}</a>
            </li>
          </ul>
          <ul class="FormStyleFiveMain">
            <li class="FormStyleFiveLeft FormStyleFiveTitle"><i class="redColor">*</i> 用户编号：</li>
            <li class="FormStyleFiveRight">
              <textarea style="width:450px;height:83px" placeholder="请输入需要推送的用户编号,多个以逗号分隔 例: 5566,7788"></textarea>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <!-- 底部按钮 -->
    <div class="SiginButton">
      <div class="SiginButtonBox">
        <a class="SiginButtonRight">立即推送</a>
      </div>
    </div>

	</div>
</template>

<script>
var self;
export default {
  name: 'Push',
  data () {
    return {
      ix:0,
      icons:[{
        active:true,
        icon:"iconicon-test57"
      },{
        active:false,
        icon:"iconicon-test58"
      },{
        active:false,
        icon:"iconicon-test59"
      },{
        active:false,
        icon:"iconicon-test60"
      }],
      checks:[{
        active:true,
        name:"指定用户"
      },{
        active:false,
        name:"经销代理"
      },{
        active:false,
        name:"高级定制"
      },{
        active:false,
        name:"企业商户"
      },{
        active:false,
        name:"个体商户"
      }],
      SelectArr:[{
        ix:0,
        active:false,
        datas:["产品消息","活动消息","维护消息","审核消息"]
      }]
    }
  },
  created () {
    self = this;
  },
  mounted () {

  },
  methods: {
    SelectActive (ix) {  //隐藏显示下拉内容
      if(self.SelectArr[ix].active){
        self.SelectArr[ix].active = false;
      }else{
        //隐藏所有下拉内容
        for(let item in self.SelectArr){
          self.SelectArr[item].active = false;
        }
        self.SelectArr[ix].active = true;
      }
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
    },
    ClickActive (te,ix) {
      if(te == 1){
        if(self.icons[ix].active){
          self.icons[ix].active = false;
        }else{
          self.icons[ix].active = true;
        }
      }else if(te == 2){
        if(self.checks[ix].active){
          self.checks[ix].active = false;
        }else{
          if(ix == 0){
            for(let item in self.checks){
              self.checks[item].active = false;
            }
          }else{
            self.checks[0].active = false;
          }
          self.checks[ix].active = true;
        }
      }
    }
  }
}
</script>

<style scoped>
  .PushBox{
    padding:20px;
  }
  .PushMain{
    overflow: hidden;
  }
  .PushMainLeft{
    float: left;
  }
  .PushMainRight{
    float: left;
    margin-left:50px;
  }

  .iconTitle{
    padding:0;
    padding-bottom: 20px;
    border-bottom: 1px solid #F3F3F3;
  }
  .FormStyleFiveMain{
    position: relative;
    margin-top:30px;
  }
  .FormStyleFiveLeft{
    position: absolute;
    left:0;
    width:100px;
    top:calc(50% - 10px);
    color:#585A64;
  }
  .FormStyleFiveTitle{
    top:5px;
  }
  .FormStyleFiveRight{
    margin-left:100px;
  }
  .FormStyleFiveRight input,.FormStyleFiveRight textarea{
    border:1px solid #D8E4ED;
    height:35px;
    width:300px;
    padding:0 10px;
    font-size:14px;
    border-radius: 3px;
  }
  .FormStyleFiveRight textarea{
    height:60px;
    padding:10px;
  }
  .FormStyleFiveRight > .SelectStyle{
    width:300px;
  }
  .FormStyleFiveIconArr{
    overflow: hidden;
  }
  .FormStyleFiveIconArr a{
    float: left;
    display: block;
    width:40px;
    height:40px;
    color:#FFF;
    text-align: center;
    border-radius: 40px;
    margin-right:15px;
    cursor: pointer;
  }
  .FormStyleFiveIconActive0{
    background-color: #F86846;
  }
  .FormStyleFiveIconActive1{
    background-color: #1DA1F2;
  }
  .FormStyleFiveIconActive2{
    background-color: #77C440;
  }
  .FormStyleFiveIconActive3{
    background-color: #FFAF18;
  }
  .FormStyleFiveIconInactive{
    background-color: #ccc;
  }
  .FormStyleFiveIconArr a span{
    line-height:40px;
    font-size:16px;
  }
  .FormStyleFiveCheckArr{
    overflow: hidden;
    width: 620px;
  }
  .FormStyleFiveCheckArr a{
    float: left;
    margin-right:20px;
    color:#404A59;
    cursor: pointer;
  }
  .FormStyleFiveCheckArr a span{
    margin-right:6px;
    position: relative;
    top:1px;
    color:#858585;
  }
  .iconicon-test56{
    color: #278BF5 !important;
  }
  .FormStyleFiveImgsArr{
    overflow: hidden;
  }
  .FormStyleFiveImgsArr a{
    display: block;
    float: left;
    height:100px;
    width:100px;
    border-radius: 3px;
    cursor: pointer;
    border:1px solid #D8E4ED;
    background-image: url('/public/static/images/add.png');
  }
  .SiginButton{
    margin-top:25px;
  }
</style>
