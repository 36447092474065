<template>
	<div class="HomePageMainBox">
    <!-- 总数统计 -->
    <ul class="StatsListBox">
      <li v-for="(item,index) in statsListBox" v-bind:key="index">
        <div class="StatsListMainBox">
          <div class="StatsListRow StatsListIcon" :class="item.border"><span class="icon iconfont" :class="[item.icon,item.color]"></span></div>
          <div class="StatsListRow StatsListMain">
            <a class="StatsListName" v-text="$t('a.HomePage.'+item.name+'')"></a>
            <a class="StatsListNumber" v-text="item.number"></a>
          </div>
        </div>
      </li>
    </ul>

    <!-- 激活、扫码统计 -->
    <div class="HomeChartBox">
      <div class="HomeChartLeft">
        <div class="HomePageBox" id="HomeChartLeftMain"></div>
      </div>
      <div class='HomeChartRight'>
        <div class="HomePageBox" id="HomeChartRightMain"></div>
      </div>
    </div>
	</div>
</template>

<script>
var self;
var echarts = require('echarts')
var HomeChartLeftMain,HomeChartRightMain,HomeChartBottomMain;
var statsListBox = [
  {icon:"iconicon-test2",color:"blueColor",border:"blueBorder",name:"TotalIncome",number:682352315},
  {icon:"iconicon-test3",color:"redTwoColor",border:"redTwoBorder",name:"CreationCodeAmount",number:3768219},
  {icon:"iconicon-test2",color:"greenColor",border:"greenBorder",name:"ScanQRAmount",number:1326581},
  {icon:"iconicon-test13",color:"violetColor",border:"violetBorder",name:"OrganizationAmount",number:158},
  {icon:"iconicon-test11",color:"cyanColor",border:"cyanBorder",name:"BusinessAmount",number:1232},
  {icon:"iconicon-test12",color:"pinkColor",border:"pinkBorder",name:"IndividualAmount",number:5115}
]
export default {
  name: 'HomePage',
  data () {
    return {
      language: 'zh',
      statsListBox: statsListBox
    }
  },
  created () {
    self = this;
    self.language = localStorage.getItem("wwmxt_locale");
  },
  updated () {
    if(self.language != localStorage.getItem("wwmxt_locale")){
      self.language = localStorage.getItem("wwmxt_locale");
      self.loadLeftChart();  //激活统计
      self.loadRightChart();  //扫码统计
    }
  },
  mounted () {
    localStorage.setItem("wwmxt_TopPage","HomePage");

    self.loadLeftChart();  //激活统计
    self.loadRightChart();  //扫码统计

    //屏幕宽度改变图表自动适应
    window.addEventListener('resize',function(){
      self.ChangeChartWidth();
    })
  },
  methods: {
    loadLeftChart () {
      HomeChartLeftMain = echarts.init(document.getElementById('HomeChartLeftMain'));
      HomeChartLeftMain.setOption({
          title: {
            text: this.$t('a.HomePage.IncomeStatistics'),
            color: '#162029',
            textStyle: {
              fontSize: '16',
              fontWeight: 'lighter'
            },
            subtext: this.$t('a.HomePage.IncomeStatisticsHint')
          },
          color: ['#3595FF'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '15%',
            left: '3%',
            right: '3%',
            bottom: '8%'
          },
          xAxis: {
            axisLine: {
                lineStyle: {
                    color: ['#999'],
                }
            },
            data: ["2019-09-12","2019-09-13","2019-09-14","2019-09-15","2019-09-16","2019-09-17","2019-09-18"]
          },
          yAxis: {
            axisLine: {
                lineStyle: {
                    color: ['#999'],
                }
            }
          },
          series: [{
              name: this.$t('a.HomePage.RevenueCost'),
              type: 'bar',
              barWidth: '50%',
              data: [14,5, 20, 36, 10, 10, 20]
          }]
      });
    },
    loadRightChart () {
      HomeChartRightMain = echarts.init(document.getElementById('HomeChartRightMain'));
      HomeChartRightMain.setOption({
          title : {
            text: this.$t('b.HomePage.ChinaScanQRStat'),
            subtext: this.$t('b.HomePage.ChinaScanQRStatDescribe'),
            color: '#162029',
            x:'center',
            textStyle: {
              fontSize: '16',
              fontWeight: 'lighter'
            }
          },
          tooltip : {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            top: '15%',
            left: 'center',
            data: [this.$t('b.HomePage.Guangdong'),this.$t('b.HomePage.Zhejiang'),this.$t('b.HomePage.Fujian'),this.$t('b.HomePage.Shanghai'),this.$t('b.HomePage.Hunan'),"其他"]
          },
          series : [{
              name: this.$t('b.HomePage.Referer'),
              type: 'pie',
              radius : '55%',
              center: ['50%', '60%'],
              data:[
                {value:1548, name:this.$t('b.HomePage.Guangdong')},
                {value:850, name:this.$t('b.HomePage.Zhejiang')},
                {value:534, name:this.$t('b.HomePage.Fujian')},
                {value:335, name:this.$t('b.HomePage.Shanghai')},
                {value:150, name:this.$t('b.HomePage.Hunan')},
                {value:150, name:"其他"}
              ],
              itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal:{
                  color:function(params) {
                    var colorList = ['#DD4B39', '#1DA1F2', '#FF5722', '#3B5998', '#967ADC', '#44b549'];
                    return colorList[params.dataIndex]
                  }
                }
              },
              label: {
                normal: {
                  show: false
                }
              }
            }
          ]
      });
    },
    ChangeChartWidth () {
      HomeChartLeftMain.resize();
      HomeChartRightMain.resize();
    }
  }
}
</script>

<style scoped>
  .StatsListBox li{
    width: 16.66%;
  }
  .HomeChartBox{
    overflow: hidden;
    margin-top:10px;
  }
  .HomeChartLeft{
    float: left;
    width:70%;
  }
  .HomePageBox{
    background-color: #FFF;
    border-radius: 5px;
    border:1px solid #E4ECF2;
  }
  #HomeChartLeftMain{
    padding:20px;
    margin-right:20px;
    height:500px;
  }
  #HomeChartRightMain{
    padding:20px;
    height:500px;
  }
  .HomeChartRight{
    float: right;
    width: 30%;
  }
</style>
