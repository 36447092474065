import { render, staticRenderFns } from "./ProcessList.vue?vue&type=template&id=6039bba1&scoped=true"
import script from "./ProcessList.vue?vue&type=script&lang=js"
export * from "./ProcessList.vue?vue&type=script&lang=js"
import style0 from "./ProcessList.vue?vue&type=style&index=0&id=6039bba1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6039bba1",
  null
  
)

export default component.exports