<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true,1)"><span class="icon iconfont iconicon-test20"></span>添加模块</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="docsbox">
      <div class="CustomRefBox" v-if="datas.length > 0">

        <div class="CustomRefRow" v-for="(item,index) in datas" v-bind:key="index">
          <div class="CustomRefHead">
            <a class="CustomRefTitle onlyOneRow">{{item.name}}</a>
            <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
              <li @click="HandleModel(true,1,item,index)">编辑</li>
              <li @click="RemoveModule(index)">删除</li>
            </ul>
          </div>
          <div class="CustomRefMaiBox">

            <div class="CustomRefMain" v-if="item.list.length > 0" v-for="(val,ix) in item.list" v-bind:key="ix">
              <div class="CustomRefHead">
                <a class="CustomRefTitle onlyOneRow" v-if="val.type == 0">文本</a>
                <a class="CustomRefTitle onlyOneRow" v-else>图片</a>
                <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                  <li @click="HandleModel(true,2,val,index,ix)">编辑</li>
                  <li @click="RemoveData(index,ix)">删除</li>
                </ul>
              </div>
            </div>

            <div class="btnStyleTwo greenBackColor btnRadius AddDefine CustomRefBtnAdd" @click="HandleModel(true,2,null,index)"><span class="icon iconfont iconicon-test20"></span>添加内容</div>
          </div>
        </div>

      </div>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-else></K-Transition>

      <!-- 底部按钮 -->
      <div class="btnArray formFootBtnArr">
        <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>保存</a>
        <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>返回</a>
      </div>


    </div>

    <!-- 添加模块 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加模块
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>模块名称</p>
              <p><input placeholder="请输入图标名称" v-model="FormDatas.name" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>视频地址</p>
              <p><input placeholder="请输入模板编号" v-model="FormDatas.video" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>模块介绍</p>
              <p>
                <textarea class="form-control" v-model="FormDatas.introduce" placeholder="请输入模块介绍"></textarea>
              </p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,1)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddModule">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加模块内容 -->
    <div class="ModalBox" v-if="ModelTwoShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加模块内容
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>内容类型</p>
              <p>
                <div class='SelectStyleArr'>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix]}}</div>
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
                        <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                  <div class="clear"></div>
                </div>
              </p>
            </li>
            <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 0">
              <p class="FormStyleTwoTitle"><i>*</i>文本内容</p>
              <p>
                <textarea class="form-control" v-model="FormDatas.data" placeholder="请输入模块介绍"></textarea>
              </p>
            </li>
            <li class="FormStyleTwoRow" v-else>
              <p class="FormStyleTwoTitle"><i>*</i>图片内容</p>
              <p>
                <p class="FormStyleOneImgs">
                  <ul class="FormStyleTwoImgsRow" v-if="FormDatas.imgs.length > 0" v-for="(item,index) in FormDatas.imgs" v-bind:key="index">
                    <li class="FormStyleTwoImgsBox backImg" @click="ImagesListModelFun({type:true},index)">
                      <div class="UploadFinishImg backImg" :style="{backgroundImage:'url('+item+')'}"><span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(index)"></span></div>
                    </li>
                  </ul>
                  <ul class="FormStyleTwoImgsRow">
                    <li class="FormStyleTwoImgsBox backImg" @click="ImagesListModelFun({type:true},-1)">
                      <div class="UploadFinishImg backImg"></div>
                    </li>
                  </ul>
                </p>
              </p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,2)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddModuleData">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

    <!-- 图片列表 -->
    <K-ImagesList :ImgsData="ImgsData" @SonToastFun="SonToastFun" @ImagesListModelFun="ImagesListModelFun"></K-ImagesList>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Column from '@/components/common/Column.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
import ImagesList from '@/components/common/ImagesList.vue'
export default {
  name: 'DocsDatasSetting',
  components: {
    'K-Column': Column,
    'K-Transition': Transition,
    'K-ImagesList': ImagesList,
    'K-Loading': Loading,
    'K-Toast': Toast,
    'K-Confirm': Confirm
  },
  data () {
    return {
      $self:null,
      mte:null,
      id:null,
      ix:null,
      te:null,
      six:null,
      ste:null,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      datas:[],
      ModelShow:false,
      ModelTwoShow:false,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["文字","图片"]
      }],
      FormDatas:{
        name:null,
        video:null,
        introduce:null,
        list:null,
        type:null,
        data:null,
        imgs:[]
      },
      column:[{name:'文档编辑',url:'DocsSetting'}],
      ImgsData:{
        type:false
      },
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.$route.query.id){
      self.id = self.$route.query.id;
      self.column[1] = {name:'功能编辑',url:'DocsDatasSetting?id='+self.$route.query.id};
      self.GetDatas();
    }else{
      javascript:history.back();
    }
  },
  methods: {
    ImagesListModelFun (data,te) {
      self.ImgsData.type = data.type;
      if(te != undefined){
        self.te = te;
        console.log(te)
      }
      if(data.img){
        if(self.te == -1){
          self.FormDatas.imgs.push(data.img);
        }else{
          self.FormDatas.imgs.splice(self.te,1,data.img);
        }
      }
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    SonToastFun (data) {
      self.Toast.state = true;
      self.Toast.type = data.te;
      self.Toast.txt = data.txt;
    },
    HandleModel (status,te,data,ix,six) {
      if(te == 1){
        self.ModelShow = status;
        self.ix = ix;
        if(data){
          self.ste = "edit";
          self.FormDatas.name = data.name;
          self.FormDatas.video = data.video;
          self.FormDatas.introduce = data.introduce;
          self.FormDatas.list = data.list;
        }else{
          self.ste = "add";
          self.FormDatas.name = null;
          self.FormDatas.video = null;
          self.FormDatas.introduce = null;
          self.FormDatas.list = [];
        }
      }else{
        self.ModelTwoShow = status;
        self.ix = ix;
        self.six = six;
        if(data){
          self.ste = "edit";
          self.SelectArr[0].ix = data.type;
          self.FormDatas.data = data.text;
          if(data.imgs){
            self.FormDatas.imgs = data.imgs;
          }
        }else{
          self.ste = "add";
          self.SelectArr[0].ix = 0;
          self.FormDatas.data = [];
          self.FormDatas.imgs = [];
        }
      }
    },
    SelectData (ix,six,id,rank) {
      self.SelectArr[ix].ix = six;
    },
    GetDatas () {
      axios.get(self.GetServerUrl(1)+"doc/"+self.id)
      .then(function (response) {
        self.isLoding = false;
        self.mte = response.data.meta.type;
        if(!response.data.meta.datas){
          self.datas = []
          self.subHint = "内容为空";
          self.isLoding = false;
        }else{
          self.datas = JSON.parse(response.data.meta.datas)
        }
      }).catch(function (error){
        self.datas = [];
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    SubmitForm () {
      let datas = {id:self.id}
      if(self.datas.length > 0){
        datas.datas = JSON.stringify(self.datas);
      }else{
        datas.datas = null;
      }
      self.loading = true;
      axios({
        method:'patch',
        url:self.GetServerUrl(1)+"doc",
        data:datas
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"doc/"+self.id,
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    },
    AddModule () {
      let datas = self.FormDatas;
      if(self.ste == "add"){
        self.datas.push({name:datas.name,video:datas.video,introduce:datas.introduce,list:[]});
      }else{
        self.datas[self.ix] = {name:datas.name,video:datas.video,introduce:datas.introduce,list:datas.list};
      }
      self.ModelShow = false;
    },
    AddModuleData () {
      let datas = self.FormDatas;
      if(self.ste == "add"){
        if(self.SelectArr[0].ix == 0){
          self.datas[self.ix].list.push({type:self.SelectArr[0].ix,text:datas.data,imgs:[]});
        }else{
          self.datas[self.ix].list.push({type:self.SelectArr[0].ix,text:null,imgs:datas.imgs});
        }
      }else{
        if(self.SelectArr[0].ix == 0){
          self.datas[self.ix].list[self.six] = {type:self.SelectArr[0].ix,text:datas.data,imgs:[]};
        }else{
          self.datas[self.ix].list[self.six] = {type:self.SelectArr[0].ix,text:null,imgs:datas.imgs};
        }
      }
      self.ModelTwoShow = false;
    },
    RemoveModule (ix) {
      self.datas.splice(ix,1);
      if(self.datas.length <= 0){
        self.subHint = "内容为空";
      }
    },
    RemoveData (ix,six) {
      self.datas[ix].list.splice(six,1);
    },
    RemoveImg (ix) {
      self.FormDatas.imgs.splice(ix,1);
    }
  }
}
</script>

<style scoped>
  .TableHead{
    padding-bottom: 0;
  }
  .docsbox{
    padding:0 20px;
    margin-bottom: 20px;
  }
  .CustomRefBox{
    margin-top:0;
  }
  .FormStyleTwoImgs{
    overflow: hidden;
  }
  .FormStyleTwoImgsRow{
    float: left;
    overflow: hidden;
    margin-right:20px;
    margin-bottom:10px;
  }
  .FormStyleTwoImgsBox{
    width:100px;
    height:100px;
    border:1px solid #D8E4ED;
    position: relative;
    background-image: url('/public/static/images/add.png');
  }
</style>
