<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('DeviceEdit')"><span class="icon iconfont iconicon-test20"></span>{{$t('b.DeviceManage.AddDevice')}}</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input :placeholder="$t('b.DeviceManage.SearchHint')" v-model="QueryName" v-on:keyup.13="QueryData" />
          <a class="SearchBtn" @click="QueryData" v-text="$t('c.Common.Search')"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Name')"></th>
            <th v-text="$t('c.Common.Image')"></th>
            <th>设备编号</th>
            <th>地址</th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.img + ')'}" v-if="item.img"><img v-if="item.img" class="tableImgShow" :src="item.img" @click="PreviewImgs"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td v-text="item.device_id"></td>
            <td v-text="item.linkaddress"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius greenBackColor" @click="GoToPage('DeviceEdit?id='+item.id)" v-text="$t('c.Common.Edit')"></a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.id)" v-text="$t('c.Common.Delete')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>
<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'ProductManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      spage:1,
      id:null,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      stotalPages:0,
      datas:null,
      ModelShow:false,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      ConfirmType:null,
      isHover:false,
      QueryName:null,
      fileTxt:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.SelectArr[0].isHover = false;
    },
    HandleModel (status,id) {  //隐藏显示生码模态框
      self.id = id;
      self.ModelShow = status;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"device_list/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.datas = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    RemoveData (id) {
      self.id = id;
      self.ConfirmType = 1;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该设备？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        if(self.ConfirmType == 1){  //删除设备
          axios({
            method:"delete",
            url:self.GetServerUrl(1)+"device/"+self.id,
          }).then(function (response) {
              self.loading = false;
              self.ToastFun(0,response.data.message);  //弹窗提醒
              self.GetList();
          }).catch(function (error) {
            self.loading = false;
            self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
          })
        }else{  //导入数据

          let fileDOM;
          fileDOM = this.$refs.file;

          //FormDatas格式提交
          let Datas = new FormData();
          Datas.append("file",fileDOM.files[0]);
          Datas.append("rootid",localStorage.getItem("wwmxt_Rootid"));

          axios.post(self.GetServerUrl(1)+"productImport",Datas)
          .then(function (response){
            self.loading = false;
            self.ToastFun(0,response.data.message);
            self.GetList();
          }).catch(function (error){
            self.loading = false;
            self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
          })
        }
      }
    },
    QueryData () {
      self.datas = null;
      self.isLoding = true;
      axios.post(self.GetServerUrl(1)+"device_list_condition",{name:self.QueryName})
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.datas = null;
      })
    },
    SelectArrLoading () {
      self.SelectArr[0].page++;
      self.loading = true;
      self.GetProductList(true);
    },
    OpenLeftNavPage (te,url) {
      self.$emit('OpenLeftNavPage',{te:te,url:url});
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    },
    UploadFile () {
      let fileTxt;
      if(this.$refs.file.files[0].type != "text/plain"){
        self.ToastFun(1,"文件格式必须为txt！");
        fileTxt = null;
        return;
      }
      let size = (this.$refs.file.files[0].size/(1024 * 1024)).toFixed(2);
      if(size < 0.01){
        size = 0.01;
      }
      if(size > 20){
        self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
        fileTxt = null;
        return;
      }
      fileTxt = '（'+size+'M）' + this.$refs.file.files[0].name;
      self.ConfirmType = 2;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定导入 "+fileTxt+" 文件？"
    }
  }
}
</script>

<style scoped>
  .BrandHint{
    color:#404A59;
  }
  .BrandHint a{
    color:#278BF5;
    cursor: pointer;
    margin-left:5px;
  }
  .BrandHint a:hover{
    text-decoration: underline;
  }
</style>
