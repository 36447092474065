<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 表单内容 -->
    <div class="box FloatBox">
      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <viewer @inited="inited" ref="viewer">
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>{{$t('b.Brand.BrandName')}}</p>
            <p><input :placeholder="$t('b.Brand.HintThree')" v-model="FormDatas.trademark_name" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>{{$t('b.Brand.Certificate')}}（{{$t('b.Brand.HintFour')}}）</p>
            <p>
              <ul class="BasicInfoOneBoxImgs">
                <li>
                  <p class="BasicInfoOneBoxImg backImg" v-if="FormDatas.trademark_cert" :style="{backgroundImage: 'url(' + FormDatas.trademark_cert + ')'}">
                    <img v-if="FormDatas.trademark_cert" class="tableImgShow" :src="FormDatas.trademark_cert" @click="PreviewImgs">
                    <span class="icon iconfont iconicon-test33 RemoveImgIcon" @click="RemoveImg(1)"></span>
                  </p>
                  <p class="BasicInfoOneBoxImg backImg" v-else></p>
                  <p class="BasicInfoOneBoxTxt">{{$t('b.Brand.BrandCertificate')}}</p>
                  <p class="BasicInfoOneBoxBtn" @click="HandleModel(1,true)">{{$t('b.Material.UploadImages')}}</p>
                </li>
                <li>
                  <p class="BasicInfoOneBoxImg backImg" v-if="FormDatas.attribute" :style="{backgroundImage: 'url(' + FormDatas.attribute + ')'}">
                    <img v-if="FormDatas.attribute" class="tableImgShow" :src="FormDatas.attribute" @click="PreviewImgs">
                    <span class="icon iconfont iconicon-test33 RemoveImgIcon" @click="RemoveImg(2)"></span>
                  </p>
                  <p class="BasicInfoOneBoxImg backImg" v-else></p>
                  <p class="BasicInfoOneBoxTxt">{{$t('b.Brand.Authorization')}}</p>
                  <p class="BasicInfoOneBoxBtn" @click="HandleModel(2,true)">{{$t('b.Material.UploadImages')}}</p>
                </li>
              </ul>
            </p>
          </li>
          </viewer>
        </ul>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm()"><span class="icon iconfont iconicon-test17"></span>{{$t('c.Common.Save')}}</a>
      <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>{{$t('c.Common.Back')}}</a>
    </div>

    <!-- 图片空间 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          {{$t('c.LeftNav.ImagesSpace')}}
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>{{$t('b.Material.UploadImages')}}
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click.stop="SelectActive(2,$self)" v-if="SelectArr[2].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[2].datas[SelectArr[2].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(2,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox ModalMainImgBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table">
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">{{$t('c.Common.Cancel')}}</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(false)">{{$t('c.Common.Confirm')}}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Column from '@/components/common/Column.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'AddProcess',
  components: {
    'K-Paging': Paging,
    'K-Column': Column,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      ModelShow:false,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      column:[
        {name:'Brand',url:'Brand'}
      ],
      SelectArr:[{
        ix:0,
        active:false,
        datas:["否","是"]
      },{
        ix:0,
        active:false,
        datas:["否","是"]
      },{
        ix:0,
        active:false,
        datas:[]
      }],
      pid:0, //分组id
      FormDatas:{},
      imgsList:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.$route.query.id){
      self.id = self.$route.query.id;
      self.column[1] = {name:'ChangeBrand',url:'Brand?id='+self.$route.query.id};
      self.GetData();
    }else{
      self.column[1] = {name:'AddBrand',url:'Brand'};
    }
    self.GetPacketList();
    self.GetImgsList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if(ix == 2){  //列表分组选择
        self.page = 1;  //重置页数
        self.totalPages = null;  //清空分页总数
        self.pid = id;  //清楚分组ID
        self.imgsList = null;  //清除图片列表
        self.GetImgsList();  //获取空间图片列表
      }
    },
    HandleModel (te,status) {  //隐藏显示生码模态框
      self.uploadType = te;
      self.ModelShow = status;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        javascript:history.back();
      }
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"trademark/"+self.id)
      .then(function (response){
        self.FormDatas = response.data.meta;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[2].datas = response.data.meta
        self.SelectArr[2].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[2].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    selectImg (img) {
      if(self.uploadType == 1){
        self.FormDatas.trademark_cert = img;
      }else{
        self.FormDatas.attribute = img;
      }
      self.HandleModel(false);
    },
    RemoveImg (te) {
      if(te == 1){
        self.FormDatas.trademark_cert = null;
      }else{
        self.FormDatas.attribute = null;
      }
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SubmitForm () {
      if(!self.FormDatas.trademark_name){
        self.ToastFun(2,"商标名称不能为空！");
        return;
      }
      if(!self.FormDatas.trademark_cert && !self.FormDatas.attribute){
        self.ToastFun(2,"商标证书和授权书必须有一个不能为空！");
        return;
      }

      let type = "POST";
      if(self.id){
        type = "PATCH"
        self.FormDatas.id = self.id;
      }

      self.loading = true;
      axios({
        method:type,
        url:self.GetServerUrl(1)+"trademark",
        data:self.FormDatas
      }).then(function (response) {
        self.loading = false;
        self.Confirm.style = 1;
        self.Confirm.state = true;
        self.Confirm.txt = response.data.message+"，是否确定返回列表？"
      }).catch(function (error) {
        if(self.ModelType == 1){

        }else{
          self.HandleModel(self.ModelType,false); //隐藏弹出框
        }
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    }
  }
}
</script>

<style scoped>

</style>
