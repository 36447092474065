<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(1,true)"><span class="icon iconfont iconicon-test20"></span>{{$t('b.BatchManage.AddBatch')}}</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input :placeholder="$t('b.BatchManage.HintOne')" v-model="QueryName" v-on:keyup.13="QueryData" />
          <a class="SearchBtn" @click="QueryData" v-text="$t('c.Common.Search')"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('b.BatchManage.BatchName')"></th>
            <th v-text="$t('b.BatchManage.QualityStatus')"></th>
            <th v-text="$t('b.BatchManage.LogisticsStatus')"></th>
            <th v-text="$t('c.Common.Remarks')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td class="slidebtnBox">
              <span v-if="item.qc" class="icon iconfont iconicon-test61 whether blueColor"></span>
              <span v-else class="icon iconfont iconicon-test62 whether"></span>
            </td>
            <td class="slidebtnBox">
              <span v-if="item.express" class="icon iconfont iconicon-test61 whether blueColor"></span>
              <span v-else class="icon iconfont iconicon-test62 whether"></span>
            </td>
            <td v-text="item.remark"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="GoToPage('QualityInformation?id='+item.batch_id)" v-text="$t('b.BatchManage.QualityInformation')"></a>
                <a class="btnStyleTwo btnRadius cyanBackColor" @click="HandleModel(2,true,item)" v-text="$t('b.BatchManage.LogisticsInformation')"></a>
                <a class="btnStyleTwo btnRadius redTwoBackColor" @click="GoToPage('DemoProcess?id='+item.batch_id)">演示溯源</a>
                <a class="btnStyleTwo btnRadius greenBackColor" @click="HandleModel(1,true,item)" v-text="$t('c.Common.Edit')"></a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.batch_id)" v-text="$t('c.Common.Delete')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 批次添加 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{$t('b.BatchManage.AddBatch')}}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(1,false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('c.Common.Name')}}</p>
                <p><input :placeholder="$t('b.BatchManage.HintTwo')" v-model="batch.name" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">{{$t('c.Common.Remarks')}}</p>
                <p>
                  <textarea :placeholder="$t('b.BatchManage.HintThree')" class="form-control" v-model="batch.remark" ></textarea>
                </p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(1,false)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(1)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

      <!-- 物流信息 -->
      <div class="ModalBox" v-if="ModelTwoShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{$t('b.BatchManage.LogisticsInformation')}}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(2,false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">{{$t('b.BatchManage.LogisticsType')}}</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    {{$t('b.BatchManage.'+SelectArr[0].datas[SelectArr[0].ix]+'')}}
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="$t('b.BatchManage.'+item+'')" @click="SelectData(0,index)"></li>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.BatchManage.LogisticsOrderID')}}</p>
                <p><input :placeholder="$t('b.BatchManage.HintFour')" v-model="express.express_id" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(2,false)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'BatchManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      totalPages:0,
      datas:null,
      ModelShow:false,
      ModelTwoShow:false,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["SFExpress","STOExpress","YTOExpress","YunDaExpress","EMSExpress"]
      },{
        ix:0,
        active:false,
        datas:[]
      }],
      batch:{},
      express:{},
      QueryName:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HandleModel (te,status,data) {  //隐藏显示生码模态框
      if(te == 1){
        self.ModelShow = status;
        if(data){
          self.batch.batch_id = data.batch_id;
          self.batch.name = data.name;
          self.batch.remark = data.remark;
        }else{
          self.batch.batch_id = null;
          self.batch = {};
        }
      }else if(te == 2){
        self.ModelTwoShow = status;
        if(data){
          self.express.batch_id = data.batch_id;
          if(data.express){
            self.SelectArr[0].ix = data.express;
          }
          self.express.express_id = data.express_id;
        }else{
          self.SelectArr[0].ix = 0;
          self.express_id = null;
        }
      }
    },
    SubmitForm (te) {  //提交表单
      if(te == 1){
        self.BatchSubmit();
      }else if(te == 2){
        self.ExpressSubmit();
      }
    },
    SwicthSlide (te,ix) {
      if(te == 1){
        if(self.datas[ix].isQuality){
          self.datas[ix].isQuality = false;
        }else{
          self.datas[ix].isQuality = true;
        }
      }else{
        if(self.datas[ix].isExperss){
          self.datas[ix].isExperss = false;
        }else{
          self.datas[ix].isExperss = true;
        }
      }
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"batch_list/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    BatchSubmit () {
      self.loading = true;
      let type;
      if(self.batch.batch_id){
        type = "patch"
      }else{
        type = "post"
      }
      axios({
        method:type,
        url:self.GetServerUrl(1)+"batch",
        data:self.batch,
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    ExpressSubmit () {
      self.loading = true;
      self.express.express = self.SelectArr[0].ix;
      axios({
        method:"patch",
        url:self.GetServerUrl(1)+"batch",
        data:self.express,
      }).then(function (response) {
        self.ModelTwoShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    QueryData () {
      self.isLoding = true;
      axios.post(self.GetServerUrl(1)+"batch_list_condition",{name:self.QueryName})
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.datas = null;
      })
    },
    RemoveData (id) {
      self.id = id;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该批次？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"batch/"+self.id,
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }

  .FormStyleTwoCustom{
    position: relative;
    margin-bottom: 20px;
  }
  .FormStyleTwoCustom input{
    width: 320px;
  }
  .FormStyleTwoCustomBtnArr{
    position: absolute;
    right:0;
    bottom:2px;
    overflow: hidden;
  }
  .FormStyleTwoCustomBtnArr a{
    width:80px;
    text-align: center;
    display: block;
    float: left;
    height: 35px;
    line-height:35px;
    cursor: pointer;
  }
  .FormStyleTwoUpImg{
    margin-right:10px;
  }
  .FormStyleTwoCenterBtn{
    display: table;
    margin:0 auto;
  }
  .FormStyleTwoCenterBtn a{
    margin-right:5px;
    position: relative;
    top:1px;
  }
</style>
