<template>
	<div class="MainBox">
    <div id="MapBox"></div>

    <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
    <table class="table StripeTable" v-else>
      <thead>
        <tr>
          <th>地区</th>
          <th>总数</th>
          <!-- <th>占比</th> -->
          <th>操作</th>
        </tr>
      </thead>
      <tbody class="TRHoverStyle">
        <tr v-for="(item,index) in datas" v-bind:key="index">
          <td v-text="item.name"></td>
          <td v-text="item.value"></td>
          <!-- <td v-text="item.percent"></td> -->
          <td class="LastTdWidth">
            <div class="btnArray">
              <a class="btnStyleTwo btnRadius blueBackColor" @click="ScanRecord(item.name)">扫码记录</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
	</div>
</template>

<script>
var self,MapMain;
var echarts = require('echarts');
require('echarts/map/js/china');
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'CodeQuery',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null
    }
  },
  created () {
    self = this;
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ShowMap () {
      MapMain = echarts.init(document.getElementById('MapBox'));
      MapMain.setOption({
        title: {
          text: '扫码统计',
          subtext: '红色区域为有扫码次数的地区',
          top:20,
          left:20,
          textStyle: {
            fontWeight:"normal",
            fontSize:15,
            color:"#fff"
          }
        },
				tooltip: {
					trigger: 'item',
					showDelay: 0,
					transitionDuration: 0.2,
					formatter: function (params) {
						var value = (params.value + '').split('.');
						value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,');
            if(value > 0){
              return params.seriesName + '<br/>' + params.name + ': ' + value;
            }else{
              return params.seriesName + '<br/>' + params.name + ': ' + 0;
            }
					}
				},
        backgroundColor: '#404a59',
        series: [{
          name: '被扫次数',
          type: 'map',
          map: 'china',
          itemStyle: {
          	areaColor:"#999"
          },
          data:self.datas
        }]
			});
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"scanStatic")
      .then(function (response){
        self.isLoding = false;

        let datas = response.data.meta;
        for(let item in datas){
          datas[item].name = datas[item].name.replace('省','');
          datas[item].itemStyle = {areaColor:"#DD4B39"};
        }
        self.datas = datas;
        setTimeout(function(){
          self.ShowMap();  //渲染地图
        },100)
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.ShowMap(); //窜货图表加载
      })
    },
    ScanRecord (name) {
      self.GoToPage(`ScanQRRecords?province=${name}省`)
    }
  }
}
</script>

<style scoped>
  #MapBox{
    width: 100%;
    height:420px;
    background-color: #404a59;
  }
</style>
