<template>
  <div class="MainBox" @click.stop="SelectActive(-1, $self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true, 1)"><span
            class="icon iconfont iconicon-test20"></span>{{ $t('b.CodeActive.CodeActive') }}</li>
      </ul>
      <!-- <ul class="TableHeadRight">
        <div class="SearchBox">
          <input :placeholder="$t('b.BatchManage.HintOne')" />
          <a class="SearchBtn" v-text="$t('c.Common.Search')"></a>
        </div>
      </ul> -->
      <ul class="clear"></ul>
    </div>
    <div class="TableHint"><i>*</i> {{ $t('c.Common.FileHint') }}</div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Numbering')"></th>
            <th>激活方式</th>
            <th v-text="$t('c.Common.Batch')"></th>
            <th v-text="$t('c.Common.ProductName')"></th>
            <th v-text="$t('c.Common.CodeNumber')"></th>
            <th v-text="$t('c.Common.ActivateNumber')"></th>
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item, index) in datas" v-bind:key="index">
            <td v-text="item.activation_id"></td>
            <td>
              <a v-if="item.activation_type == 1">采集</a>
              <a v-else-if="item.activation_type == 2">首尾码</a>
              <a v-else>文件</a>
            </td>
            <td>
              <a v-if="item.batch" v-text="item.batch.name"></a>
              <a v-else>无</a>
            </td>
            <td>
              <div>
                <a v-text="item.nyname || item.production_name" v-if="item.production_name"></a>
                <a v-else></a>
              </div>
            </td>
            <td v-text="item.code_count"></td>
            <td v-text="item.activation_count"></td>
            <td class="TdStatus">
              <a v-if="item.result == 100"><i class="wait"></i>{{ $t('c.Common.InProgress') }}</a>
              <a v-else-if="item.result == 200"><i class="reject"></i>{{ $t('c.Common.Failure') }}</a>
              <a v-else-if="item.result == 300"><i class="pass"></i>{{ $t('c.Common.Completed') }}</a>
              <a v-else-if="item.result == 400"><i class="reject"></i>{{ $t('c.Common.Expired') }}</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" v-if="item.tmp_filename"
                  @click="DownCodeFile(2, item.activation_id)" v-text="$t('c.Common.Download')"></a>
                <a class="btnStyleTwo btnRadius ProhibitBtn" v-else v-text="$t('c.Common.Download')"></a>
                <a class="btnStyleTwo btnRadius cyanBackColor" v-text="$t('b.CodeActive.ReplaceData')"
                  @click="HandleModel(true, 2, item.activation_id)"></a>
                <a class="btnStyleTwo btnRadius greenBackColor" v-text="$t('b.CodeActive.ViewDetails')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 码标激活窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{ $t('b.CodeActive.CodeActive') }}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback"
              @click="HandleModel(false, 1)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">

              <!-- 下来选择5-6为上海红纺定制 -->
              <K-Select 
                v-for="(item,index) in selectList" 
                :key="index" :selectItem="item" 
                :selectIx="index" 
                @selectActive="selectActive"
                v-if="(index < 4 || item.availableIds.includes(rootid) || item.availableIds.includes(orgid)) && !item.excludeIds.includes(orgid)"
              ></K-Select>
             
              <!-- 扫码激活 -->
              <li class="FormStyleTwoRow" v-if="selectList[0].ix == 0">
                <p class="FormStyleTwoTitle"><i>*</i>{{ $t('c.Common.ActiveCode') }}（{{ $t('b.CodeActive.HintFour') }}）</p>
                <p><input :placeholder="$t('b.CodeActive.HintFive')" v-model="code" v-on:keyup.13="AddCode" /></p>
              </li>
              <!-- 扫码激活 -->

              <!-- 首位码激活 -->
              <li class="FormStyleTwoRow" v-if="selectList[0].ix == 1">
                <p class="FormStyleTwoTitle"><i>*</i>{{ $t('c.Common.FirstCode') }}</p>
                <p><input :placeholder="$t('b.CodeActive.HintSix')" v-model="first_code" /></p>
              </li>
              <li class="FormStyleTwoRow" v-if="selectList[0].ix == 1">
                <p class="FormStyleTwoTitle"><i>*</i>{{ $t('c.Common.LastCode') }}</p>
                <p><input :placeholder="$t('b.CodeActive.HintSeven')" v-model="last_code" /></p>
              </li>
              <!-- 首位码激活 -->

              <!-- 文件激活 -->
              <li class="FormStyleTwoRow" v-if="selectList[0].ix == 2">
                <p class="FormStyleTwoTitle"><i>*</i>{{ $t('c.Common.CodeFile') }}（{{ $t('c.Common.FileSizeHint') }}）</p>
                <p class="btnArray">
                  <a class="btnStyleTwo btnRadius cyanBackColor"><span
                      class="icon iconfont iconicon-test28"></span>{{ $t('c.Common.FileUpload') }}</a>
                  <a class="FormUpFileHint onlyOneRow" v-if="fileTxt" v-text="fileTxt"></a>
                  <input type="file" class="upload" @change="UploadFile" ref="file" />
                </p>
              </li>
              <!-- 文件激活 -->

            </ul>
            <div class="CodeActiveListBox" v-if="selectList[0].ix == 0 && Codes.length > 0">
              <span class="CodeActiveListTitle">{{ $t('c.Common.CurrentCodeList') }}<i
                  v-text="Codes.length"></i>{{ $t('c.Common.Unit') }}<a class="CodeActiveListRomoveAll feedback NoSelect"
                  @click="Remove('all')">{{ $t('c.Common.Clear') }}</a></span>
              <ul class="CodeActiveList">
                <li v-for="(item, index) in Codes" v-bind:key="index">{{ item }}<i
                    class="icon iconfont iconicon-test33 CodeActiveListRomove feedback" @click="Remove(index)"></i></li>
              </ul>
            </div>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span
                class="icon iconfont iconicon-test28"></span>{{ $t('c.Common.Document') }}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false, 1)"
                v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(1)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

      <!-- 数据替换窗口 -->
      <div class="ModalBox" v-if="ModelTwoShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{ $t('b.Dealer.CodesShipping') }}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback"
              @click="HandleModel(false, 2)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              
              <!-- 下来选择5-6为上海红纺定制 -->
              <K-Select 
                v-for="(item,index) in selectList" 
                :key="index" :selectItem="item" 
                :selectIx="index" 
                @selectActive="selectActive"
                v-if="index > 0 && (index < 4 || item.availableIds.includes(rootid) || item.availableIds.includes(orgid)) && !item.excludeIds.includes(orgid)" 
              ></K-Select>

            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span
                class="icon iconfont iconicon-test28"></span>{{ $t('c.Common.Document') }}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false, 2)"
                v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

  </div>
</template>

<script>
let self;
import axios from 'axios'
import $axios from '@/hooks/axios-common.js'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import SelectView from '@/components/common/SelectView.vue'
export default {
  name: 'ProductManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast,
    'K-Select': SelectView
  },
  data() {
    return {
      $self: null,
      page: 1,
      subHint: '数据加载中...',
      isLoding: true,
      datas: null,
      rootid: null,
      orgid: null,
      totalPages: 0,
      ModelShow: false,
      ModelTwoShow: false,
      loading: false,
      tradeType: null,
      pix:3, //产品容器索引
      Toast: {
        state: false,
        type: 0,
        txt: null
      },
      selectList: [{
        name:'发货方式',
        ix: 0,
        active: false,
        type: 'static',
        title: 'b.Dealer.ShippingType',
        hint: 'b.Dealer.HintTwo',
        datas: ["b.Dealer.ScanShipping", "b.Dealer.ExtentShipping", "b.Dealer.FileShipping"],
        excludeIds: [],
        availableIds: [],
      }, {
        name:'生产时间',
        ix: 0,
        page: 1,
        type: 'static',
        totalPages: 0,
        active: false,
        title: 'b.Dealer.CreationDate',
        hint: 'b.Dealer.HintNine',
        datas: ["b.Dealer.Nodate", "b.Dealer.CurrentDate", "b.Dealer.CustomDate"],
        excludeIds: ['1002'],
        availableIds: [],
      }, {
        name:'批次',
        ix: 0,
        page: 1,
        totalPages: 0,
        active: false,
        search: true,
        listUrl: 'batch_list',
        searchUrl: 'batch_list_condition',
        title: 'c.Common.Batch',
        hint: 'b.CodeActive.HintTwo',
        datas: [],
        excludeIds: [],
        availableIds: [],
      }, {
        name:'产品',
        ix: 0,
        page: 1,
        totalPages: 0,
        active: false,
        search: true,
        listUrl: 'production_list',
        searchUrl: 'production_list_condition',
        title: 'c.Common.Product',
        hint: 'b.Dealer.HintThree',
        datas: [],
        excludeIds: [],
        availableIds: [],
      }, {
        name:'品牌',
        ix: 0,
        page: 1,
        totalPages: 0,
        active: false,
        search: true,
        listUrl: 'brand_list',
        searchUrl: 'brand_list_condition',
        title: 'b.ShhfLicensorManage.BrandName',
        hint: '',
        datas: [],
        excludeIds: [],
        availableIds: ['100010109', '100010002']
      }, {
        name:'授权商',
        ix: 0,
        page: 1,
        totalPages: 0,
        active: false,
        listUrl: 'licensor_list',
        searchUrl: 'licensor_list_condition',
        title: '',
        hint: '',
        datas: [],
        excludeIds: [],
        availableIds: ['100010109', '100010002', '1002', '1009']
      }],
      Codes: [],
      code: null,
      first_code: null,
      last_code: null,
      fileTxt: null,
      ac_id: null,
      attribute: {
        ship_date: null
      }
    }
  },
  created() {
    self = this;
    this.$self = this;
    self.rootid = localStorage.getItem("wwmxt_Rootid");
    self.orgid = self.rootid.substring(0, 4);
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    const keynameObj = {
      1000: 'LicensorName',
      1001: 'LicensorName',
      1002: 'FieldsName', 
      1009: 'FieldsName',
    }
    this.selectList[5].title = `b.ShhfLicensorManage.${keynameObj[self.orgid] || 'LicensorName'}`
    
    //请求下拉列表数据-产品
    $axios.selectList.call(this,3,"post","production_list_condition",{no_page:true},value=>{
      $axios.getList.call(this,"codeAcList/"); //产品不为空，加载列表
    });
    
    //请求下拉列表数据-批次
    $axios.selectList.call(this,2,"get","batch_list",{},value=>{
      if(value.data.meta.data && value.data.meta.data.length){
        this.selectList[2].datas.unshift({name: '无', batch_id: 0});
      }
    });

    //品牌API
    if(this.selectList[4].availableIds.includes(self.rootid) || this.selectList[4].availableIds.includes(self.orgid)){
      $axios.selectList.call(this,4,"get","brand_list");
    }

    //授权商API
    if(this.selectList[5].availableIds.includes(self.rootid) || this.selectList[5].availableIds.includes(self.orgid)){
      $axios.selectList.call(this,5,"get","licensor_list");
    }

  },
  methods: {
    ToastFun(type, txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast() {
      self.Toast.state = false;
    },
    ChangePage(data) {  //上下翻页
      if (data.te == 1) {
        if (self.page > 1) {
          self.page--
        }
      } else {
        if (self.page < self.totalPages) {
          self.page++
        }
      }
      $axios.getList.call(self,"codeAcList/"); //加载列表
    },
    SkipPage(data) {  //跳转分页
      self.page = data.ix;
      $axios.getList.call(self,"codeAcList/"); //加载列表
    },
    SubmitForm(te) {  //提交表单
      let ix, Datas, BatchID, ProductID, RootID;
      if (te == 1) {
        ix = self.selectList[0].ix;
      } else {
        ix = 3;  //数据替换
      }

      RootID = localStorage.getItem("wwmxt_Rootid");
      this.selectList.forEach(item=>{
        if(item.name == "批次"){
          if (item.datas.length > 0) {
            BatchID = item.datas[item.ix].batch_id;
          } else {
            BatchID = 0;
          }
        }else if(item.name == "产品"){
          ProductID = item.datas[item.ix].production_id;
        }else if(item.name == "品牌"){ //上海红纺定制功能
          if(item.datas.length > 0){
            self.attribute.brand = item.datas[item.ix];
          }
        }else if(item.name == "授权商"){ //上海红纺定制功能
          if(item.datas.length > 0){
            self.attribute.licensor = item.datas[item.ix];
          }
        }
      });

      var _url = 'ac';
      if (ix == 0) {
        Datas = { code: self.Codes, rootid: RootID, production_id: ProductID, batch: BatchID };
        if (self.Codes.length < 1) {
          self.ToastFun(1, "码标不能为空！");
          return;
        }
      } else if (ix == 1) {
        let first_code, last_code;
        if (self.first_code) {
          first_code = self.FiltrationCode(self.first_code);
        } else {
          self.ToastFun(1, "首码不能为空！");
          return;
        }
        if (self.last_code) {
          last_code = self.FiltrationCode(self.last_code);
        } else {
          self.ToastFun(1, "尾码不能为空！");
          return;
        }

        //首码格式校验
        if (first_code.length != 18 && first_code.length != 32) {
          self.ToastFun(1, "首码格式不正确！");
          return;
        }
        //尾码格式校验
        if (last_code.length != 18 && last_code.length != 32) {
          self.ToastFun(1, "尾码格式不正确！");
          return;
        }
        Datas = { first_code: first_code, last_code: last_code, rootid: RootID, production_id: ProductID, batch: BatchID };
      } else if (ix == 2) {
        if (!self.fileTxt) {
          self.ToastFun(1, "文件不能为空！");
          return;
        }
        let fileDOM;
        fileDOM = this.$refs.file;
        Datas = new FormData();
        Datas.append("file", fileDOM.files[0]);
        Datas.append("batch", BatchID);
        Datas.append("production_id", ProductID);
        Datas.append("rootid", RootID);
        Datas.append("ac_type", ix);
      } else {
        _url = "acCover"
        Datas = { production_id: ProductID, batch: BatchID, activation_id: self.ac_id };
      }
      //自动获取当前时间
      if (self.selectList[1].ix == 1) {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        self.attribute.ship_date = year + "-" + month + "-" + day;
      }
      Datas.attribute = JSON.stringify(self.attribute);
      Datas.ac_type = ix;

      //单一自定义属性
      if(["1002", "1009"].includes(self.orgid)){
        Datas.attribute = (this.selectList[5].datas.length && this.selectList[5].datas[this.selectList[5].ix].licensor_id) || '';
      }

      self.loading = true;
      axios.post(self.GetServerUrl(1) + _url, Datas)
        .then(function (response) {
          self.loading = false;

          if (te == 1) {
            self.ModelShow = false;
          } else {
            self.ModelTwoShow = false;
          }
          self.ToastFun(0, response.data.message);
          $axios.getList.call(self,"codeAcList/"); //加载列表

          //清空历史数据
          if (ix == 0) {
            self.Codes = [];
          } else if (ix == 1) {
            self.code = null;
            self.first_code = null;
            self.last_code = null;
          }
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
        })
    },
    Remove(ix) {
      if (ix == "all") {
        self.Codes = [];
      } else {
        self.Codes.splice(ix, 1);
      }
    },
    AddCode() {
      let code = self.FiltrationCode(self.code);
      if (code) {
        //重复校验
        if (self.RepeatCheck(self.Codes, code) == 1) {
          //跨级校验
          if (self.Codes.length > 0) {
            if (code.substring(0, 1) != self.Codes[0].substring(0, 1)) {
              self.ToastFun(1, "请勿跨级激活码标！");
              return;
            } else {
              self.Codes.push(code);
              self.code = null;
            }
          } else {
            self.Codes.push(code);
            self.code = null;
          }
        } else {
          self.ToastFun(1, "不能重复插入码标！");
        }
      } else {
        self.ToastFun(1, "码标不正确！");
      }
    },
    UploadFile() {
      if (this.$refs.file.files[0].type != "text/plain") {
        self.ToastFun(1, "文件格式必须为txt！");
        self.fileTxt = null;
        return;
      }
      let size = (this.$refs.file.files[0].size / (1024 * 1024)).toFixed(2);
      if (size < 0.01) {
        size = 0.01;
      }
      if (size > 20) {
        self.ToastFun(1, "文件大小不能超过20M，当前大小为：" + size + "M！");
        self.fileTxt = null;
        return;
      }
      self.fileTxt = '（' + size + 'M）' + this.$refs.file.files[0].name;
    },
    DownCodeFile(etype, id) {
      if (id) {
        window.open("https://syapi.oid.plus/v1/downCodeFile/" + etype + "/" + id);
      }
    },
    HandleModel(status, te, id='') {  //隐藏显示生码模态框
      self.ac_id = id;
      self.selectList[1].ix = 0;  //生产日期归位
      if (self.selectList[3].datas.length > 0) {
        if (te == 1) {
          self.ModelShow = status;
        } else {
          self.ds_id = id;
          self.ModelTwoShow = status;
        }
      } else {
        self.ToastFun(1, "产品内容不能为空！");
      }
    },
    selectActive(params){
      this.keyword = params.keyword; //搜索框内容
      if(params.type === 'active'){ //点击展开

        //隐藏所有下拉内容
        self.selectList.forEach((value,index)=>{
          if(index !== params.selectIx) value.active = false;
        })
        //展开、隐藏当前下拉框
        self.selectList[params.selectIx].active = !self.selectList[params.selectIx].active;
      }else if(params.type === 'check'){ //选中属性
        self.selectList[params.selectIx].active = false;
        self.selectList[params.selectIx].ix = params.optionIx;

        //生产日期
        if (params.selectIx == 1 && params.optionIx == 2) {
          self.attribute.ship_date = params.ship_date;
        }
      }else if(params.type === 'search'){ //搜索
        $axios.searchSelectData.call(this,params.selectIx,params.url);
      }else if(params.type === 'more'){ //查看更多
        $axios.selectLoadMore.call(this,params.selectIx,params.url);
      }
    }
  }
}
</script>

<style scoped>
.iconicon-test38 {
  font-size: 15px !important;
}
</style>
