<template>
	<div class="MainBox">
    <div class='FinancialBox'>
      <div class="FinancialLeft">
        <div class="NavbarStyleOne">财务管理<a class="NavbarStyleOneMore feedback">{{$t('b.HomePage.BillingDetails')}}<i class="icon iconfont iconicon-test25"></i></a></div>

        <ul class="FinancialMoney">
          <li class="FinancialMoneyBox">
            <p>总剩余（元）</p>
            <p class="redColor" v-text="totalBalance"></p>
          </li>
          <li class="FinancialMoneyBox">
            <p>总收益（元）</p>
            <p class="grayColor" v-text="totalEarnings"></p>
          </li>
          <li class="FinancialMoneyBox">
            <p>总消费（元）</p>
            <p class="grayColor" v-text="totalExpend"></p>
          </li>
        </ul>
        <div class="FinancialMoneyBox HomeExpenseMainTwoBtnArray">
          <a>存入记录</a>
          <a>取出记录</a>
        </div>

        <ul class="FinancialModule">
          <li v-for="(item,index) in datas" v-bind:key="index">
            <p class="FinancialModuleIcon" :style="{backgroundColor:colorList[index]}"><span class="icon iconfont" :class="item.icon"></span></p>
            <p class="FinancialModuleTxt" v-text="item.value+'元'"></p>
            <p class="FinancialModuleTxt" v-text="'占比 '+(item.value/totalEarnings*100).toFixed(2)+'%'"></p>
            <p class="FinancialModuleTxt" v-text="item.name">企业生码套餐</p>
          </li>
        </ul>
      </div>
      <div class="FinancialRight" id="FinancialChat">

      </div>
    </div>

    <div class="FinancialBootBox" id="YearOnYearChat"></div>
	</div>
</template>

<script>
var self,FinancialChat,YearOnYearChat;
var echarts = require('echarts')
export default {
  name: 'Financial',
  data () {
    return {
      totalBalance:4000000,
      totalEarnings:4300000,
      totalExpend:300000,
      colorList:['#6C6FC0', '#F86846', '#77C440', '#FFAF18', '#5FD8A5', '#F9CC33', '#1DA1F2', '#DD4B39'],
      datas:[{
        icon:"iconicon-test39",
        value:1500000.00,
        name:"企业生码套餐"
      },{
        icon:"iconicon-test48",
        value:1000000.00,
        name:"个人生码套餐"
      },{
        icon:"iconicon-test7",
        value:500000.00,
        name:"功能模块开通"
      },{
        icon:"icontubiaozhizuomoban",
        value:200000.00,
        name:"促销红包充值"
      },{
        icon:"iconicon-test53",
        value:100000.00,
        name:"开发者接入"
      },{
        icon:"iconicon-test38",
        value:500000.00,
        name:"防伪商城抽成"
      },{
        icon:"iconicon-test41",
        value:300000.00,
        name:"空间扩容"
      },{
        icon:"iconicon-test54",
        value:200000.00,
        name:"其他收益"
      }]
    }
  },
  created () {
    self = this;
  },
  mounted () {
      self.loadFinancialChat();  //财务收益模块
      self.LoadYearOnYearChat();  //同比增长率
  },
  methods: {
    loadFinancialChat () {
      FinancialChat = echarts.init(document.getElementById('FinancialChat'));
      FinancialChat.setOption({
          title : {
            text: '收益模块金额和占比',
            subtext: '当前金额和占比为总收益的计算',
            color: '#162029',
            x:'center',
            top:"50px",
            textStyle: {
              fontSize: '16',
              fontWeight: 'lighter'
            }
          },
          tooltip : {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          series : [{
              name: "收益来源",
              type: 'pie',
              radius : ['50%', '70%'],
              center: ['50%', '56%'],
              data:self.datas,
              itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal:{
                  color:function(params) {
                    var colorList = self.colorList;
                    return colorList[params.dataIndex]
                  }
                }
              },
              label: {
                normal: {
                  show: false
                }
              }
            }
          ]
      });
    },
    LoadYearOnYearChat () {
      YearOnYearChat = echarts.init(document.getElementById('YearOnYearChat'));
      YearOnYearChat.setOption({
          title : {
            text: "收益同比增长率",
            subtext: "当年图表为年同比增长率",
            color: '#162029',
            textStyle: {
              fontSize: '16',
              fontWeight: 'lighter'
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} %"
          },
          xAxis: {
              show:false,
              type: 'category',
              data: ["2013","2014","2015","2016","2017","2018","2019"]
          },
          yAxis: {
              show:false,
              type: 'value'
          },
          grid: {
            top: '25%',
            left: '-5%',
            right: '-5%',
            bottom: '0%'
          },
          series: [{
              name: "收益同比增长",
              data: [100, 500, 500, 934, 1500, 1330, 1320],
              type: 'line',
              smooth: true,
              symbolSize: 10,
              lineStyle: {
                  normal: {
                      width: 2.5,
                      color:'#FF5722',
                  }
              },
              itemStyle: {
                borderColor: '#FF5722',
                borderWidth:2
              }
          }]
      });
    }
  }
}
</script>

<style scoped>
  .FinancialBox{
    overflow: hidden;
    border-bottom:1px solid #F3F3F3;
  }
  .FinancialLeft{
    float: left;
    width:calc(100% - 451px);
    border-right:1px solid #F3F3F3;
  }
  .FinancialRight{
    height:560px;
    float: right;
    width:450px;
  }
  .FinancialMoney{
    overflow: hidden;
  }
  .FinancialMoney li{
    float: left;
    width:30%;
    padding-top:20px;
  }
  .HomeExpenseMainTwoBtnArray{
    padding-top:20px;
    padding-bottom:25px;
  }
  .FinancialMoneyBox p{
    padding-left:25px;
  }
  .FinancialMoneyBox p:first-child{
    color:#404A59;
  }
  .FinancialMoneyBox p:last-child{
    padding-top:10px;
    font-size:20px;
  }
  .HomeExpenseMainTwoBtnArray{
    min-width: 240px;
  }
  .HomeExpenseMainTwoBtnArray a:first-child{
    margin-left:25px;
  }

  .FinancialModule{
    overflow: hidden;
  }
  .FinancialModule li{
    float: left;
    width: calc(25% - 21px);
    padding:25px 0;
    padding-left:20px;
    text-align: center;
    cursor: pointer;
    border-top:1px solid #F3F3F3;
    border-right: 1px solid #F3F3F3;
  }
  .FinancialModule li:hover{
    background-color: #FCFCFC;
  }
  .FinancialModule li:nth-child(4n){
    border-right: 0;
  }
  .FinancialModuleIcon{
    height:60px;
    width:60px;
    border-radius: 60px;
    margin:0 auto;
    margin-bottom:10px;
  }
  .FinancialModuleIcon span{
    color:#FFF;
    font-size:30px;
    line-height:60px;
  }
  .FinancialModuleTxt{
    margin-top:5px;
    color:#585A64;
  }

  .FinancialBootBox{
    height:300px;
    margin:20px;
  }
</style>
