<template>
	<div class="MainBox">
		<div class="BasicInfoHead">
      <p class="BasicInfoHeadLogo backImg" v-if="datas.logo" :style="{backgroundImage:'url('+datas.logo+')'}"></p>
      <p class="BasicInfoHeadLogo backImg" v-else></p>
      <p class="BasicInfoHeadName" v-if="datas.id_code_base">
        {{datas.name}}
        <i class="authenticated BlueState" v-if="datas.id_code_base.status_id == 100">已认证</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == 0">待审核</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -1">审核失败</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -2">禁用</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -100">待完善资料</i>
      </p>
      <p class="BasicInfoHeadName" v-else>{{datas.name}}</p>
      <p class="BasicInfoHeadDate" v-if="datas.created_at">注册时间：{{datas.created_at}}</p>
      <p class="BasicInfoHeadExpire" v-if="datas.company_OID">OID：{{datas.company_OID}}</p>
      <p class="BasicInfoHeadExpire" v-else>商户编号 {{datas.rootid}}</p>
      <!-- <a class="BasicInfoHeadUpLogo NoSelect">保存设置</a> -->
      <a class="BasicInfoHeadUpLogo NoSelect" @click="HandleModel(true,1)">{{$t('b.BasicInfo.UploadLogo')}}</a>
    </div>

    <div class="BasicInfoNav">
      <template v-for="(item,index) in NavList">
        <a class="feedback" v-bind:key="index" v-if="item.isShow != false" v-text="item.name" :class="{NavActive:index == ix}" @click="ClickNav(index)"></a>
      </template>
    </div>

    <!-- 商户信息 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-if="ix == 0">
      <div class="BasicInfoOneBoxLeft">
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">商户余额</li>
          <li>
            <p><input v-model="datas.money" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">商户名称</li>
          <li>
            <p><input v-model="datas.name" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">商户类型</li>
          <li>
            <p><input disabled="true" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne" v-if="datas.trade">
          <li class="FormStyleOneTitle">所属行业</li>
          <li>
            <p><input v-model="datas.trade.trade_name" class="disabledInput" disabled="true" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">所属地区</li>
          <li>
            <p><input v-model="datas.address" disabled="true" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne" v-if="datas.btype == 1">
          <li class="FormStyleOneTitle">企业地址</li>
          <li>
            <p><input v-model="datas.baddress" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne" v-if="datas.btype == 1">
          <li class="FormStyleOneTitle">信用代码</li>
          <li>
            <p><input v-model="datas.organization_code" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne" v-if="datas.btype == 2">
          <li class="FormStyleOneTitle">身份证号</li>
          <li>
            <p><input v-model="datas.idcard" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">经纬度</li>
          <li>
            <p><input v-model="datas.lon_lat" placeholder="请点击获取经销商经纬度" @focus="ClickMap(true)" @click="ClickMap(true)"  /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">联系人</li>
          <li>
            <p><input v-model="datas.linkman" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">联系电话</li>
          <li>
            <p><input v-model="datas.linkphone" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">关键词</li>
          <li>
            <p><input placeholder="多个关键词请用英文逗号分割" v-model="datas.keyword" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">外部链接</li>
          <li>
            <p><input placeholder="可输入外部链接" v-model="datas.link" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">商户简介</li>
          <li>
            <p>
              <textarea class="form-control"  v-model="datas.intro" ></textarea>
            </p>
          </li>
        </ul>
        <div><a class="FormStyleOneBottomBtn btnRadius blueBackColor NoSelect" @click="ChangeBusinessInfo">{{$t('c.Common.Save')}}</a></div>
      </div>

      <div class="BasicInfoOneBoxRight" v-if="datas.btype == 1">
        <ul class="BasicInfoOneBoxImgs">
          <li>
            <p class="BasicInfoOneBoxImg backImg" v-if="datas.blicense" :style="{backgroundImage:'url('+datas.blicense+')'}"><a class="PreviewsImgBox" :href="datas.blicense" target="view_window"></a></p>
            <p class="BasicInfoOneBoxImg backImg" v-else></p>
            <p class="BasicInfoOneBoxTxt">营业执照</p>
          </li>
        </ul>

        <div style="margin-top: 10px;">
          <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item,index) in datas.customize_data" v-bind:key="index">
            <li class="FormStyleTwo" style="padding:2px 0;">
              <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key"/>
              <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value"/>
              <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,2,index)">{{$t('c.Common.Image')}}</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{$t('c.Common.Delete')}}</a>
              </p>
            </li>
          </ul>
          <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="addAttributeRow"><span class="icon iconfont iconicon-test20"></span>{{$t('b.ProductManage.AddLine')}}</div>
        </div>
      </div>
      <div class="BasicInfoOneBoxRight" v-if="datas.btype == 2">
        <ul class="BasicInfoOneBoxImgs">
          <li>
            <p class="BasicInfoOneBoxImg backImg" v-if="datas.idcard_img" :style="{backgroundImage:'url('+datas.idcard_img+')'}"><a class="PreviewsImgBox" :href="datas.idcard_img" target="view_window"></a></p>
            <p class="BasicInfoOneBoxImg backImg" v-else></p>
            <p class="BasicInfoOneBoxTxt">手持身份证照</p>
          </li>
        </ul>
      </div>

    </div>

    <!-- 企业权限 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-else-if="ix == 1">
      <div class="BasicInfoOneBoxLeft">
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">OID编码</li>
          <li>
            <p><input v-model="datas.company_idcode" class="disabledInput" :placeholder="'1.2.156.40000.'+datas.rootid" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">生码域名</li>
          <li>
            <p><input v-model="datas.code_url" class="disabledInput" placeholder="https://lj.oid.plus" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">授权时间</li>
          <li>
            <p>
              <input type="text" lay-verify="required" style="box-sizing:inherit" class="layui-input" id="publish_date" v-model="datas.user_package.due_date" placeholder="生码到期时间" autocomplete="off">
            </p>
          </li>
        </ul>
        <!-- <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">促销时间</li>
          <li>
            <p><input v-model="datas.expire_date" class="disabledInput" placeholder="促销到期时间" /></p>
          </li>
        </ul> -->
        <div><a class="FormStyleOneBottomBtn btnRadius blueBackColor NoSelect" @click="ChangeBusinessInfo">{{$t('c.Common.Save')}}</a></div>
      </div>

    </div>

    <!-- 功能列表 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-else-if="ix == 2">
      <table class="tableTwo">
        <thead>
          <tr>
            <th>功能</th>
            <th>描述</th>
            <th>收费</th>
            <th>到期时间</th>
            <th>状态</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="ListDatas">
          <tr v-for="(item,index) in ListDatas" v-bind:key="index">
            <td><a v-text="item.name"></a></td>
            <td v-text="item.intro"></td>
            <td v-text="item.fee"></td>
            <td>
              <a v-if="item.due_date" v-text="item.due_date"></a>
              <a v-else>-</a>
            </td>
            <td>
              <a v-if="item.status == 0" class="grayColor">未开通</a>
              <a v-else-if="item.status == 1" class="grayColor">已过期</a>
              <a v-else-if="item.status == 2">已开通</a>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!ListDatas"></K-Transition>
    </div>

    <!-- 商品列表 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-else>
      <viewer @inited="inited" ref="viewer">
      <table class="tableTwo">
        <thead>
          <tr>
            <th>名称</th>
            <th>图片</th>
            <th>规格</th>
            <th>库存</th>
            <th>创建时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="ListDatas">
          <tr v-for="(item,index) in ListDatas" v-bind:key="index">
            <td><a v-text="item.name"></a></td>
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.img + ')'}" v-if="item.img"><img v-if="item.img" class="tableImgShow" :src="item.img" @click="PreviewImgs"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td v-text="item.stock_unit"></td>
            <td v-text="item.stock"></td>
            <td v-text="item.created_at"></td>
            <td>
              <a class="btnStyleTwo btnRadius greenBackColor" @click="GoToPage('ProductEdit?id='+item.production_id)" v-text="$t('c.Common.Edit')"></a>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!ListDatas"></K-Transition>
    </div>

    <!-- 图片空间 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          图片空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>上传图片
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click="SelectActive(0)" v-if="SelectArr[0].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(0,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table ModalMainImgBox" >
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url,item.name)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <div class="mapbox" v-if="mapShow">
      <div class="mapShade" @click="ClickMap(false)"></div>
      <baidu-map :center="{lng:120.548984,lat:27.574843}" :zoom="14" @ready="handler" :scroll-wheel-zoom="true" class="MapMain" @click="getClickInfo">
        <bm-marker :position="{lng:120.548984, lat:27.574843}" :dragging="true">
          <bm-label content="温州链接科技有限公司" :labelStyle="{color:'red',fontSize:'13px'}" :offset="{width:-58,height:30}"/>
        </bm-marker>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
      </baidu-map>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self,form,layer,laydate;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'BasicInfo',
  components: {
    'K-Paging': Paging,
    'K-Loading': Loading,
    'K-Transition': Transition,
    'K-Toast': Toast
  },
  data () {
    return {
      ix:0,
      cix:0,
      page:1,
      isHide: false,
      totalPages:0,
      loading:false,
      subHint:'数据加载中...',
      isLoding:true,
      mapShow:false,
      ModelShow:false,
      pid:0, //分组id
      UploadType:null,
      imgsList:null,
      Confirm:{
        state:false,
        txt:null
      },
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      SelectArr:[{
        ix:0,
        active:false,
        datas:[]
      }],
      NavList:[{
        name:"商户信息",
      },{
        name:"管理设置",
      },{
        name:"功能列表",
      },{
        name:"商品列表"
      }],
      datas:{
        logo:null,
        name:null,
        linkman:null,
        linkphone:null,
        keyword: null,
        link: null,
        intro:null,
        organization_code:null,
        id_code_base:null
      },
      attribute:null,
      ListDatas:null
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    this.isHide = ['1011'].includes(localStorage.getItem("wwmxt_Rootid")) || false;
    this.NavList[1].isShow = !this.isHide;
  },
  mounted () {
    layui.use(['form', 'layer', 'laydate'], function() {
      form = layui.form;
      layer = layui.layer;
      laydate = layui.laydate;

      if(self.$route.query.id){
        self.id = self.$route.query.id;
        self.GetData();
        self.GetPacketList();
        self.GetImgsList();
      }else{
        javascript:history.back();
      }

    });
  },
  methods: {
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.page = 1;  //重置页数
      self.totalPages = null;  //清空分页总数
      self.pid = id;
      self.GetImgsList();  //获取空间图片列表
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      if(self.UploadType == 1){  //logo
        FormDatas.append("belong","logo");
      }else{
        FormDatas.append("belong","blicense");
      }
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
      })
    },
    addAttributeRow () {
      if(!self.datas.customize_data){
        self.datas.customize_data = [];
      }
      self.datas.customize_data.push({key:"",value:""});
      self.$forceUpdate();
    },
    SelectActive (ix) {  //隐藏显示下拉内容
      if(self.SelectArr[ix].active){
        self.SelectArr[ix].active = false;
      }else{
        //隐藏所有下拉内容
        for(let item in self.SelectArr){
          self.SelectArr[item].active = false;
        }
        self.SelectArr[ix].active = true;
      }
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    selectImg (img,name) {
      if(self.UploadType == 1){
        self.datas.logo = img;
      }else{
        self.datas.customize_data[self.cix].key = name;
        self.datas.customize_data[self.cix].value = img;
      }
      self.HandleModel(false);
    },
    ClickNav (ix) {
      self.ix = ix;
      if(ix == 1){
        self.$nextTick(() => {
          laydate.render({
            elem: '#publish_date',
            type: 'datetime',
            trigger: 'click',
            done: function(value, date, endDate) {
              self.datas.user_package.due_date = value;
            }
          });
        });
      }else if(ix == 2){
        self.GetFunctionList();
      }else if(ix == 3){
        self.GetProductList();
      }
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"business/"+self.id)
      .then(function (response) {
          let datas = response.data.meta;
          datas.address = "";
          for(let item in datas.area){
            datas.address += datas.area[item].address_name+" "
          }
          if(!datas.user_package){
            datas.user_package = {due_date:null};
          }
          self.datas = datas;
          self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    GetOrderList () {
      self.ListDatas = null;
      self.isLoding = true;
      axios.post(self.GetServerUrl(1)+"order_list",{id:self.id,page:self.page})
      .then(function (response){
          self.ListDatas = response.data.meta.data;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[0].datas = response.data.meta
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = "数据为空";
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    GetFunctionList () {
      self.ListDatas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"module/"+self.id,)
      .then(function (response){
          self.ListDatas = response.data.meta;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    GetProductList () {
      self.ListDatas = null;
      self.isLoding = true;
      let uid = localStorage.getItem("wwmxt_uid");
      axios.get(self.GetServerUrl(1)+"production_list/"+self.page+"/"+self.id,)
      .then(function (response){
          self.ListDatas = response.data.meta.data;
          self.isLoding = false;
          console.log(self.ListDatas)
          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    RemoveRow (ix) {
      self.attribute.splice(ix,1);
    },
    ChangeBusinessInfo () {
      self.loading = true;

      //FormDatas格式提交
      let datas = {
        business_id:self.datas.rootid,
        name:self.datas.name,
        baddress: self.datas.baddress,
        logo:self.datas.logo,
        money: self.datas.money,
        linkphone:self.datas.linkphone,
        linkman:self.datas.linkman,
        keyword: self.datas.keyword,
        link: self.datas.link,
        intro:self.datas.intro,
        company_idcode:self.datas.company_idcode,
        code_url:self.datas.code_url,
        lon_lat:self.datas.lon_lat,
        due_date:self.datas.user_package.due_date,
        customize_data:JSON.stringify(self.datas.customize_data)
      };
      if(self.datas.id_code_base.status_id == -1 || self.datas.id_code_base.status_id == -100){
        datas.blicense = self.datas.blicense;
        datas.organization_code = self.datas.organization_code;
      }
      console.log(datas)

      axios.patch(self.GetServerUrl(1)+"business",datas)
      .then(function (response){
        self.GetData();
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error){
        let msg;
        if (error.response) {
          if(error.response.data.message){
            msg = error.response.data.message;
          }else{
            msg = error.response.statusText;
          }
        }else{
          msg = "请检查网络连接！";
        }
        self.loading = false;
        self.ToastFun(2,msg);  //弹窗提醒
      })
    },
    HandleModel (status,te,ix) {  //隐藏显示生码模态框
      self.ModelShow = status;
      self.UploadType = te;
      self.cix = ix;
    },
    RemoveRow (ix) {
      self.datas.customize_data.splice(ix,1);
      self.$forceUpdate();
    },
    ClickMap (status) {
      self.mapShow = status;
    },
    handler ({BMap, map}) {

    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    },
    getClickInfo (e) {
      var point = new BMap.Point(e.point.lng, e.point.lat);
      var gc = new BMap.Geocoder();
      self.datas.lon_lat = e.point.lng+","+e.point.lat;
      self.mapShow = false;
    }
  }
}
</script>

<style scoped>
  .FormStyleOne input,.SelectStyle{
    width: 300px;
  }
  .FormStyleOneBottomBtn{
    padding:10px 18px;
    display: table;
    margin-top:25px;
    margin-left:90px;
    cursor: pointer;
  }
  .disabledInput{
    border:1px solid #DDD;
  }
  .tableTwo{
    margin-top:0;
  }
  .tableTwo > tbody > tr > td{
    color:#585A64;
  }
  .pass{
    top:4px;
  }
</style>
