<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
      </ul>
      <ul class="TableHeadRight">
        <el-date-picker
          v-model="value2"
          type="monthrange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          :picker-options="pickerOptions">
        </el-date-picker>
      </ul>
      <ul class="TableHeadRight" style="margin-right:20px;">
        <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
          {{SelectArr[0].datas[SelectArr[0].ix]}}
          <ul class="OptionStyleBox" v-if="SelectArr[0].active">
            <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
          </ul>
          <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>交易编号</th>
            <th>产品类型</th>
            <th>收支类型</th>
            <th>金额</th>
            <th>余额</th>
            <th>方式</th>
            <th>时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.id"></td>
            <td v-text="item.name"></td>
            <td>
              <a class="" v-if="item.type == 1">收入</a>
              <a class="" v-else>支出</a>
            </td>
            <td>
              <a class="blueColor" v-if="item.type == 1" v-text="'+'+item.money"></a>
              <a class="redTwoColor" v-else v-text="'-'+item.money"></a>
            </td>
            <td v-text="item.balance"></td>
            <td v-text="item.way"></td>
            <td v-text="item.date"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="GoToPage('StockDetails?type='+te+'&name='+name+'&pid='+item.id+'&pname='+item.name)">详情记录</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
export default {
  name: 'IncomeDisbursement',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column
  },
  data () {
    return {
      $self:null,
      te:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      column:[],
      SelectArr:[{
        ix:0,
        active:false,
        datas:["全部","收入","支出"]
      }],
      value2:null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  created () {
    self = this;
    this.$self = this;
    self.te = self.$route.query.type;
    if(self.te == "home"){
      self.column = [{name:'首页展示',url:'BusinessHomePage'},{name:'账单明细',url:'IncomeDisbursement?type='+self.te}];
    }else{
      self.column = [{name:'费用中心',url:'BillingManagement'},{name:'账单明细',url:'IncomeDisbursement'}];
    }
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        id:6521561859641,
        type:1,
        name:"余额充值",
        money:300,
        balance:900,
        way:"手动购买",
        date:"2019-11-12 16:51"
      },{
        id:8541561859234,
        type:2,
        name:"开发者开通",
        money:300,
        balance:600,
        way:"手动购买",
        date:"2019-11-12 16:12"
      },{
        id:7621561859555,
        type:2,
        name:"基础版套餐",
        money:300,
        balance:900,
        way:"手动购买",
        date:"2019-11-11 15:55"
      }];
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width:90px;
  }
</style>
