<template>
  <div class="FullScreen">
    <div class="loginBack backImg"></div>
    <div class="LanguageBox NoSelect">
      <li class="SiginHeadRightRow" title="语言（Language）" @click="SwicthLanguage">
        <span class="icon iconfont iconicon-test8"></span>
        <span class="SiginHeadRightTxt SiginHeadRightLanguage feedback" v-text="$t('c.Language.name')"></span>
        <span class="icon iconfont iconicon-test25"></span>
      </li>
    </div>
    <ul class="loginBox">

      <!-- 左容器 -->
      <li class="loginBoxLeft" :class="{'img-bg': oid == '1012'}">
        <template v-if="oid != '1012'">
          <span v-text="$t('c.Login.ItemUniqueCode')"></span>
          <p v-text="$t('c.Login.CodeDescribe')"></p>
        </template>
      </li>

      <!-- 有容器 -->
      <li class="loginBoxRight">
        <div class="loginForm">
          <div><input ref="username" type="text" :placeholder="$t('c.Login.UserName')" v-model="username" @input="ChangeInput" v-on:keyup.13="login"/></div>
          <div class="loginFormPwd"><input type="password" :placeholder="$t('c.Login.Password')" v-model="password" @input="ChangeInput" v-on:keyup.13="login"/></div>
          <div class="loginHint" v-text="hint"></div>
          <button class="loginFormBtn gradient" @click="login" v-text="$t('c.Login.'+SignUpTxt)" :style="{opacity: buttonOpacity}"></button>
          <div class="floatStyleOneBox">
            <div class="floatStyleOneLeft feedback NoSelect" @click="Rememb">
              <a class="icon iconfont" :class="remembIcon" style="font-size:11px"></a>
              <a v-text="$t('c.Login.Rememb')"></a>
            </div>
            <div class='floatStyleOneRight'>
              <a class="feedback" v-text="$t('c.Login.SignIn')" @click="Signin"></a>
               <!-- /<a class="feedback" v-text="$t('c.Login.ForgetPassword')" @click="ForgetPwd"></a> -->
            </div>
          </div>
          <div class="clientDow">{{$t('c.Login.Hint')}}<a href="#" v-text="$t('c.Login.CustomerServiceDownload')"></a></div>
        </div>
      </li>

    </ul>
  </div>
</template>

<script>
var self;
import axios from 'axios'
export default {
  name: 'Login',
  data () {
    return {
      oid: null,
      username:null,
      password:null,
      isSelect:false,
      hint:null,
      remembIcon: 'iconicon-test31',
      SignUpTxt: 'SignUp',
      buttonOpacity:1
    }
  },
  mounted () {
    self = this;

    const params = this.GetParams();
    this.oid = params.id;

    //获取焦点
    this.$nextTick(function(){
      this.$refs.username.focus()
    })
  },
  methods:{
    login () {
      //避免重复提交
      if(self.buttonOpacity != 1){
        return;
      }

      self.hint = "";
      if(!self.username){
        self.hint = "邮箱/用户名不能为空"
        return;
      }
      if(!self.password){
        self.hint = "密码不能为空"
        return;
      }

      //提示语和按钮样式
      self.SignUpTxt = 'Logging'
      self.buttonOpacity = .6

      //数据请求
      axios.post(self.GetServerUrl(1)+"login",{username: self.username,password: self.password})
      .then(function (response) {
          self.SignUpTxt = 'SignUp'
          self.buttonOpacity = 1
          localStorage.setItem("wwmxt_HistoryURL","HomePage");  //左侧导航焦点样式
          localStorage.setItem("wwmxt_Token",response.data.meta.token);  //token
          localStorage.setItem("wwmxt_Role",response.data.meta.user.role);  //角色类型
          localStorage.setItem("wwmxt_Logo",response.data.meta.user.logo);  //LOGO
          localStorage.setItem("wwmxt_Name",response.data.meta.detail.name);  //名称
          localStorage.setItem("wwmxt_OID",response.data.meta.detail.company_OID);  //OIDID
          localStorage.setItem("wwmxt_BType",response.data.meta.detail.btype);  //商户类型
          localStorage.setItem("wwmxt_Rootid",response.data.meta.detail.rootid);  //用户ID

          if(response.data.meta.detail.trade_info){
            localStorage.setItem("wwmxt_TradeType",response.data.meta.detail.trade_info.trade_type);  //行业ID
          }

          //角色判断
          if(response.data.meta.user.role == 10){  //管理员
            self.$router.push({path:'/AadminHomePage'});
          }else if(response.data.meta.user.role == 20){  //组织
            self.$router.push({path:'/ORGBusiness'});
          }else if(response.data.meta.user.role == 30){  //商户
            self.$router.push({path:'/BusinessHomePage'});
          }else if(response.data.meta.user.role == 40){  //工序
            localStorage.setItem("wwmxt_Rootid",response.data.meta.detail.search_rootid);  //用户ID
            self.$router.push({path:'/BrancStockManage'});
          }else if(response.data.meta.user.role == 50){  //经销商
            localStorage.setItem("wwmxt_Rootid",response.data.meta.detail.search_rootid);  //用户ID
            self.$router.push({path:'/BrancStockManage'});
          }
      }).catch(function (error) {
        let msg;
        if (error.response) {
          if(error.response.data.message){
            msg = error.response.data.message;
          }else{
            msg = error.response.statusText;
          }
        }else{
          msg = "请检查网络连接！";
        }
        self.SignUpTxt = 'SignUp'
        self.buttonOpacity = 1
        self.hint = msg;
      })

    },
    Rememb () {
      if(!self.isSelect){
        self.isSelect = true;
        self.remembIcon = 'iconicon-test32'
      }else{
        self.isSelect = false;
        self.remembIcon = 'iconicon-test31'
      }
    },
    Signin () {
      this.$router.push({path:'/Signin', query: {id: this.oid}}) // 路由跳转
    },
    ForgetPwd () {
      this.$router.push({path:'/ForgetPassword'}) // 路由跳转
    },
    ChangeInput () {
      self.hint = null;
    },
    SwicthLanguage () {
      if(self.$i18n.locale == "zh"){
        localStorage.setItem("wwmxt_locale","en");
        self.$i18n.locale = "en"
      }else{
        localStorage.setItem("wwmxt_locale","zh");
        self.$i18n.locale = "zh"
      }
    }
  }
}
</script>

<style scoped>
  .loginBack{
    position: fixed;
    height:100%;
    width:100%;
    left:0;top:0;
    background-image: url('/public/static/images/login.png');
    z-index: 98;
  }
  .loginBox{
    position: fixed;
    width:900px;
    height:570px;
    overflow: hidden;
    left:calc(50% - 450px);
    top:calc(50% - 285px);
    z-index: 99;
  }
  .loginBox li{
    float: left;
    width:400px;
    height:520px;
    padding:25px;
  }
  .loginBoxLeft{
    color:#FFF;
    letter-spacing: 2px;
    background: rgba(53,149,255,0.8);
    border-radius: 5px 0 0 5px;
  }
  .loginBoxLeft span{
    font-size:28px;
    display: block;
    margin-top:210px;
    font-weight: 700;
  }
  .loginBoxLeft p{
    font-size:15px;
    margin-top:20px;
    line-height:25px;
  }
  .loginBoxRight{
    position: relative;
    background-color: #FFF;
    border-radius: 0 5px 5px 0;
  }
  .loginForm{
    margin-top:145px;
    overflow: hidden;
  }
  .loginFormPwd{
    margin-top:20px;
  }
  .loginForm input{
    border:0;
    height:40px;
    width:400px;
    font-size:14px;
    border-bottom:1px solid #D8E4ED;
  }
  .loginFormBtn{
    height: 40px;
    width:400px;
    margin-top:15px;
    color:#FFF;
  }
  .loginHint{
    margin-top:10px;
    color:#FF272D;
    height:20px;
  }

  .floatStyleOneBox{
    overflow: hidden;
    margin-top:15px;
    font-size:13px;
    color:#8B8B8C;
    padding:0 1px;
  }
  .floatStyleOneBox a{
    cursor: pointer;
  }
  .floatStyleOneLeft{
    float: left;
  }
  .iconicon-test32{
    color:#3595FF;
  }
  .floatStyleOneRight{
    float:right;
  }
  .clientDow{
    text-align: center;
    position: absolute;
    bottom:20px;
    left:0;right:0;
    margin:0 auto;
    font-size:13px;
    color:#8B8B8C;
  }
  .clientDow a{
    color:#3595FF;
  }

  .LanguageBox{
    position: fixed;
    top:20px;
    left:20px;
    z-index: 99;
    opacity: .8;
  }
  .SiginHeadRightRow{
    color:#FFF;
  }
  .SiginHeadRightTxt{
    font-size:14px;
    cursor: pointer;
  }
  .SiginHeadRightLanguage{
    position: relative;
    top:-2px;
  }
  .iconicon-test8{
    font-size:18px;
  }
  .iconicon-test25{
    font-size:8px;
    position: relative;
    top:-3px;
    right:0;
  }

  .img-bg{
    background-image: url('/public/static/images/cxzx.jpg');
    background-size: 100% 100%; /* 背景图片覆盖整个元素区域 */
    background-repeat: no-repeat; /* 不重复背景图片 */
  }
</style>
