<template>
	<div>
    <div class="headmain NoSelect" :class="[{headmainPOne:!simple},{headmainPTwo:simple}]">
      <ul class="headmainLeft">
        <li @click="SimpleNav" title="导航样式（Navigation Style）"><span class="icon iconfont iconicon-test6"></span></li>
        <!-- <li @click="ClickFunction" title="功能列表（Function List）"><span class="icon iconfont iconicon-test7" v-if="role==30"></span></li> -->
        <li title="语言（Language）" @click="SwicthLanguage">
          <span class="icon iconfont iconicon-test8"></span>
          <span class="headmainLeftTxt headmainLeftLanguage" v-text="$t('c.Language.name')"></span>
          <span class="icon iconfont iconicon-test25"></span>
        </li>
      </ul>
      <ul class="headmainRight">
        <li class="headmainRightRow headmainRightUserImgBox" @mouseenter="DropdownMenus(1,true)" @mouseleave="DropdownMenus(1,false)">
          <div class="headmainRightUserImg backImg" v-if="logo" :style="{backgroundImage:'url('+logo+')'}" @click="OpenLeftNavPage(true,'BasicInfo')"></div>
          <div class="headmainRightUserImg backImg" v-else @click="OpenLeftNavPage(true,'BasicInfo')"></div>
          <ul class="DropdownMenus" style="right:5px" v-if="isShowUserMenu">
            <li class="DropdownMenusEntName" v-text="name" @click="OpenLeftNavPage(true,'BasicInfo')"></li>
            <li v-if="role==20||role==30" class='DropdownMenusRow' @click="OpenLeftNavPage(true,'BasicInfo')"><span class="icon iconfont iconicon-test14"></span>{{$t('c.HeadBar.BasicInfo')}}</li>
            <li v-if="role==10||role==20||role==30" class="DropdownMenusRow"  @click="OpenLeftNavPage(false,'ChangePassword')"><span class="icon iconfont iconicon-test15"></span>{{$t('c.HeadBar.ChangePassword')}}</li>
            <li v-if="role==10" class="DropdownMenusRow"  @click="OpenLeftNavPage(true,'CreateCodeAudit')"><span class="icon iconfont iconicon-test16"></span>{{$t('c.LeftNav.CreateCodeAudit')}}</li>
            <li v-if="role==20||role==30" class="DropdownMenusRow" @click="OpenLeftNavPage(true,'SecuritySettings')"><span class="icon iconfont iconicon-test16"></span>{{$t('c.HeadBar.SecuritySettings')}}</li>
            <li class="DropdownMenusBtn DropdownMenusLogout feedback" @click="Logout">{{$t('c.HeadBar.Logout')}}</li>
          </ul>
        </li>
        <li v-if="!['20'].includes(role)" class="headmainRightRow" title="疑问（question）" @click="OpenLeftNavPage(false,'Question')"><span class="icon iconfont iconicon-test10"></span></li>
        <li v-if="!['20'].includes(role)" class="headmainRightRow headmainLeftInfo" @mouseenter="DropdownMenus(2,true)" @mouseleave="DropdownMenus(2,false)" @click.stop="OpenLeftNavPage(false,'Notifications')">
          <span class="icon iconfont iconicon-test9"></span>
          <i></i>
          <ul class="DropdownInfoMenus" style="right:38px" v-if="isShowInfoMenu && infoList">
            <li class="DropdownInfoMenusMain">
              <p class="DropdownInfoMenusRow" v-if="index < 3" v-for="(item,index) in infoList" v-bind:key="index" @click.stop="OpenLeftNavPage(false,'NotificationsDetail?id='+item.message_id)">
                <a class="DropdownInfoMenusTitle onlyOneRow" v-text="item.title"></a>
                <a class="DropdownInfoMenusDate" v-text="item.created_at"></a>
              </p>
            </li>
            <li class="DropdownMenusBtn feedback" @click="OpenLeftNavPage(false,'Notifications')">{{$t('c.HeadBar.ViewMore')}}</li>
          </ul>
        </li>
        <!-- <li class="headmainRightRow headmainLeftTxt"><a v-text="$t('c.HeadBar.Documents')" href="http://oid.plus/#/" target="_blank"></a></li> -->
        <li v-if="!['20'].includes(role)" class="headmainRightRow headmainLeftTxt" v-text="$t('c.HeadBar.Service')" @click="ChatOn"></li>
        <!-- <li v-if="role==30" class="headmainRightRow headmainLeftTxt" v-text="$t('c.HeadBar.BillingManagement')" @click="OpenLeftNavPage(true,'BillingManagement')"></li> -->
        <!-- <li v-if="role==30" class="headmainRightRow headmainLeftTxt" v-text="$t('c.HeadBar.FunctionPackage')" @click="OpenLeftNavPage(true,'FunctionPackage')"></li> -->
      </ul>

      <i class="headState" @click="OpenLeftNavPage(true,BasicInfo)" v-if="state == 0">{{$t('c.Common.InReview')}}</i>
      <i class="headState" @click="OpenLeftNavPage(true,BasicInfo)" v-else-if="state == -1">{{$t('c.Common.Refer')}}</i>
      <i class="headState" @click="OpenLeftNavPage(true,BasicInfo)" v-else-if="state == -2">{{$t('c.Common.Disabled')}}</i>
      <i class="headState" @click="OpenLeftNavPage(true,BasicInfo)" v-else-if="state == -100">{{$t('c.Common.Corvidae')}}</i>

      <div class="FunctionListBox" v-if="isShowFunctionList" :class="[{FunctionListBoxOne:!simple},{FunctionListBoxTwo:simple}]">
        <div class="FunctionListMain">
          <div class='FunctionListHeadBox'>
            <ul class='FunctionListHead'>
              <li class="FunctionListHeadRow" v-for="(item,index) in functionListDatas" v-bind:key="index" v-text="$t('b.FunctionList.'+item.title+'')" :class="{FunctionListHeadActive:index==NavIndex}" @click="SwicthHeadNav(index)"></li>
              <span class="FunctionListHeadClose icon iconfont iconicon-test33" @click="ClickFunction"></span>
            </ul>
          </div>
          <ul class="FunctionListContent">
            <li class="FunctionListContentBox" v-for="(item,index) in functionListDatas" v-bind:key="index" v-if="index == NavIndex">
              <div class="FunctionListContentRow" v-for="(value,six) in item.datas" v-bind:key="six">
                <p class="FunctionListContentTitle" v-text="$t('b.FunctionList.'+value.title+'')"></p>
                <p class="FunctionListContentMsg" v-text="$t('b.FunctionList.'+value.content+'')"></p>
                <a class="slidebtn slidebtnRight" :class="[{slidebtnOpen:value.active},{slidebtnClose:!value.active}]" @click="SwicthSlide(index,six)"><i class="transition"></i></a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
var self;
import axios from 'axios'
export default {
  name: 'HeadBar',
  props:["simple","datas"],
  data () {
    return {
      name:null, //名称
      logo:null, //logo
      role:null, //角色
      state:null, //企业状态
      NavIndex:0, //更多功能当前索引
      isShowUserMenu:false, //是否显示下拉用户菜单
      isShowInfoMenu:false, //是否显示下拉消息菜单
      isShowFunctionList:false, //是否显示更多功能窗体
      BasicInfo:"BasicInfo", //基本信息变量
      infoList:null,
      version:["Order"],
      functionListDatas:[{ //更多功能内容
        title:"ProcessManage",
        datas:[{
          active:true,
          title:"ProcessList",
          content:"ProcessListDescribe"
        },{
          active:false,
          title:"ScanQRDistribution",
          content:"ScanQRDistributionDescribe"
        },{
          active:false,
          title:"ExtentQRDistribution",
          content:"ExtentQRDistributionDescribe"
        },{
          active:false,
          title:"FileQRDistribution",
          content:"FileQRDistributionDescribe"
        },{
          active:false,
          title:"DistributionList",
          content:"DistributionListDescribe"
        }]
      },{
        title:"DealerManage",
        datas:[{
          active:false,
          title:"DealerList",
          content:"DealerListDescribe"
        },{
          active:false,
          title:"ScanQRShipments",
          content:"ScanQRShipmentsDescribe"
        },{
          active:false,
          title:"ExtentQRShipments",
          content:"ExtentQRShipmentsDescribe"
        },{
          active:false,
          title:"FileQRShipments",
          content:"FileQRShipmentsDescribe"
        },{
          active:false,
          title:"ShipmentsList",
          content:"ShipmentsListDescribe"
        }]
      }]
    }
  },
  created () {
    self = this;
    self.name = localStorage.getItem("wwmxt_Name");
    self.logo = localStorage.getItem("wwmxt_Logo");
    self.state = localStorage.getItem("wwmxt_OIDState");
    self.role = localStorage.getItem("wwmxt_Role");

    //等于组织需要跳转其他页面
    console.log(self.role)
    if(self.role == 20){
      self.BasicInfo = "ORGBasicInfo";
    }

    //判断是否登录
    let token = localStorage.getItem("wwmxt_Token");
    if(!token){
      self.$router.push({path:'/'})
    }
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  watch:{
      $route:{
        handler(nv,ov){
          if(!self.isInArray(self.version,self.$route.name)){
            self.VersionCheck(); //版本检测
          }
        }
      },
      deep:true
  },
  mounted () {
    self.GetList();
  },
  methods: {
    SimpleNav () {
      if(!self.simple){
        self.$emit('changeSimple',{simple:true});
      }else{
        self.$emit('changeSimple',{simple:false});
      }
    },
    SwicthLanguage () {
      if(self.$i18n.locale == "zh"){
        localStorage.setItem("wwmxt_locale","en");
        self.$i18n.locale = "en"
      }else{
        localStorage.setItem("wwmxt_locale","zh");
        self.$i18n.locale = "zh"
      }
    },
    DropdownMenus (te,status) {
      if(te == 2){
        self.isShowInfoMenu = status;
      }else{
        self.isShowUserMenu = status;
      }
    },
    Logout () {
      // localStorage.clear(); //清除缓存
      localStorage.removeItem("wwmxt_HistoryURL");
      localStorage.removeItem("wwmxt_Token");
      localStorage.removeItem("wwmxt_TopPage");
      localStorage.removeItem("wwmxt_Rootid");
      self.$router.push({path:'/'})
    },
    ClickFunction () {
      if(self.isShowFunctionList){
        self.isShowFunctionList = false;
      }else{
        self.isShowFunctionList = true;
      }
    },
    SwicthSlide (ix,six) {
      if(self.functionListDatas[ix].datas[six].active){
        self.functionListDatas[ix].datas[six].active = false;
      }else{
        self.functionListDatas[ix].datas[six].active = true;
      }
    },
    SwicthHeadNav (ix) {
      self.NavIndex = ix;
    },
    OpenLeftNavPage (te,url) {
      if(url == "BasicInfo" && (self.role == 10||self.role == 40||self.role == 50)){
        return;
      } else if(self.role == 20){
        url = "ORGBasicInfo"
      }
      self.$emit('OpenLeftNavPage',{te:te,url:url});
    },
    ChatOn () {
      window.open('tencent://message/?uin=124469081');
    },
    ChangeDatas () {
      self.name = localStorage.getItem("wwmxt_Name");
      self.logo = localStorage.getItem("wwmxt_Logo");
      self.state = localStorage.getItem("wwmxt_OIDState");
    },
    GetList () {
      axios.get(self.GetServerUrl(1)+"message_list/all/1")
      .then(function (response){
        self.infoList = response.data.meta.data;
      }).catch(function (error){
        self.infoList = null;
      })
    },
    VersionCheck () {
      axios({
        method: 'get',
        url: self.GetServerUrl(1) +"version",
      }).then(function(res) {
        var json = res.data;
        if(json.code == 200){
          var datas = JSON.parse(json.meta);
          if(datas.version != localStorage.getItem("wwmxt_Version")){
            localStorage.setItem("wwmxt_Version",datas.version);
            window.location.reload(true);
          }
        }
      }).catch(function(error) {
        console.log(error);
      })
    }
  }
}
</script>

<style scoped>
  .headmain{
    height: 60px;
    background-color: #FFF;
    box-shadow:0 5px 10px rgba(0,0,0,0.1);
    overflow: hidden;
    position: fixed;
    right:0;
    top:0;
    z-index: 99;
    min-width:800px;
  }
  .headmainPOne{
    left:180px;
  }
  .headmainPTwo{
    left:60px;
  }
  .headmainLeft{
    overflow: hidden;
    float: left;
    line-height: 60px;
  }
  .headmainLeft li{
    float: left;
    cursor: pointer;
    margin-left:30px;
  }
  .iconicon-test7{
    font-size:21px;
  }
  .iconicon-test8{
    font-size:18px;
  }
  .iconicon-test25{
    font-size:8px;
    position: relative;
    top:-3px;
    right:0;
  }

  .headmainRight{
    float: right;
    color:#162029;
    overflow: hidden;
    margin-right:15px;
    line-height: 60px;
  }
  .headmainRightRow{
    float: right;
    cursor: pointer;
    padding:0 15px;
  }
  .headmainRightUserImgBox{
    height:60px;
    width:40px;
    position: relative;
  }
  .headmainRightUserImg{
    height:40px;
    width:40px;
    margin-top:10px;
    border-radius: 40px;
    overflow: hidden;
    background-color: #EFF4F8;
    background-image: url('/public/static/images/nullimg.png');
  }
  .iconicon-test9{
    font-size:19px;
  }
  .iconicon-test10{
    font-size:17px;
  }
  .headmainLeftTxt{
    font-size:14px;
  }
  .headmainLeftTxt a{
    color:#162029;
  }
  .headmainLeftTxt:hover{
    opacity: .7;
  }
  .headmainLeftInfo{
    position: relative;
  }
  .headmainLeftInfo i{
    position: absolute;
    width:5px;
    height:5px;
    display: block;
    border-radius: 5px;
    background-color: #FF272D;
    top:18px;
    right: 12px;
  }
  .headmainLeftLanguage{
    position: relative;
    top:-2px;
  }

  /* 企业设置下拉菜单 */
  .DropdownMenus,.DropdownInfoMenus{
    position: fixed;
    top:56px;
    z-index: 100;
    font-size:12px;
    line-height: 18px;
    border-radius: 5px;
    background-color: #FFF;
    box-shadow:0 0 5px rgba(0,0,0,0.2);
  }
  .DropdownMenusEntName{
    padding:15px 20px 10px 20px;
    border-bottom:1px solid #F9F9F9;
  }
  .DropdownMenusRow{
    padding:10px 20px;
    color:#585A64;
  }
  .DropdownMenusRow:hover{
    background-color: #F9F9F9;
  }
  .DropdownMenusRow span{
    padding-right:5px;
    position: relative;
    top:1px;
  }
  .DropdownMenusBtn{
    border-top:1px solid #F9F9F9;
    padding:12px 0;
    text-align: center;
  }
  .DropdownMenusLogout{
    color:#FF272D;
  }

  /* 消息下拉菜单 */
  .DropdownInfoMenusRow{
    width: 200px;
    padding:13px 20px;
    border-bottom:1px solid #F3F3F3;
  }
  .DropdownInfoMenusRow:hover{
    background-color: #F9F9F9;
  }
  .DropdownInfoMenusRow a{
    display: block;
  }
  .DropdownInfoMenusTitle{
    height:20px;
    overflow: hidden;
  }
  .DropdownInfoMenusDate{
    color:#8B8B8C;
  }
  .headState{
    color:#FFF;
    position: absolute;
    right:8px;top:5px;
    font-size:11px;
    line-height:17px;
    padding:0 3px;
    border-radius:3px;
    opacity: 0.95;
    cursor: pointer;
    background-color: #FF5722;
  }
  .headState:hover{
    opacity: .9;
  }
</style>
