import { render, staticRenderFns } from "./JuniorBusiness.vue?vue&type=template&id=90d1b208&scoped=true"
import script from "./JuniorBusiness.vue?vue&type=script&lang=js"
export * from "./JuniorBusiness.vue?vue&type=script&lang=js"
import style0 from "./JuniorBusiness.vue?vue&type=style&index=0&id=90d1b208&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90d1b208",
  null
  
)

export default component.exports