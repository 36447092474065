<template>
  <div class="Kanban">
    <div class="KanbanHead">
      <a class="KanbanHeadDate" v-text="CurrentDate"></a>
      国家OID武夷茶安全追溯平台
      <a class="KanbanHeadBtn" @click="goback"><i>退出看板模式</i></a>
    </div>
    <canvas id="BackCanvas"></canvas>
    <div class="KanbanMain">

      <div class="KanbanMainBox">

        <!-- 左侧容器 -->
        <div class="KanbanLeft">

          <!-- 看板导航 -->
          <div class="KanbanNav">
            <div class="KanbanNavMain" v-for="item in navlist">
              <img class="KanbanNavIcon" :src="'static/images/'+item.icon+'.png'">
              <p class="KanbanNavNumber hide" :style="{'color':item.color}" v-text="item.number"></p>
              <p class="KanbanNavName hide" v-text="item.name"></p>
            </div>
          </div>

          <!-- 已确权区 -->
          <div class="KanbanBox">
            <div class="KanbanTitle">
              <i class="KanbanTitleIcon"></i>
              已确权区
              <a class="more-list">全部<span class="icon iconfont iconicon-test25"></span></a>
            </div>
            <div class="box-one">
              <ul class="tableHead row-60">
                <li>OID编号</li>
                <li>户名</li>
                <li>面积</li>
                <li>海拔</li>
                <li>茶树</li>
                <li>年龄</li>
              </ul>
              <div class="swiper-container box-four-table">
                <div class="swiper-wrapper">
                  <div class="refresh">释放刷新</div>
                  <div class="swiper-slide tableBox row-60" v-for="item in datas.MerchantInfo">
                    <li v-text="item.rootid"></li>
                    <li v-text="item.name"></li>
                    <li v-for="val in item.info" v-text="val.value"></li>
                  </div>
                  <!-- <div class="loadmore">加载更多</div> -->
                </div>
                <div class="swiper-scrollbar"></div>
              </div>
            </div>
          </div>

          <!-- 追溯交易图对比 -->
          <div class="KanbanBox">
            <div class="KanbanTitle">
              <i class="KanbanTitleIcon"></i>
              追溯交易图对比
              <a class="more-list">全部<span class="icon iconfont iconicon-test25"></span></a>
            </div>
            <div class="box-two" id="ChartOne"></div>
          </div>

        </div>

        <!-- 中间容器 -->
        <div class="KanbanCenter">

          <!-- 地图 -->
          <div class="KanbanBox NoTop map-box">
            <div class="KanbanOneHead" v-if="datas.OrgInfo">
              <ul>
                <li v-for="item in datas.OrgInfo.customize_data">
                  <p class="KanbanOneHeadName" v-text="item.key"></p>
                  <p class="KanbanOneHeadNumber" v-text="item.value"></p>
                </li>
              </ul>
            </div>
            <div class="box-three" id="map"></div>
          </div>

          <!-- 销售明星 -->
          <div class="KanbanBox">
            <div class="KanbanTitle">
              <i class="KanbanTitleIcon"></i>
              销售明星
              <a class="more-list">全部<span class="icon iconfont iconicon-test25"></span></a>
            </div>
            <div class="box-four">
              <ul class="tableHead row-40">
                <li>商户</li>
                <li>品名</li>
                <li>销量</li>
                <li>状态</li>
              </ul>
              <div class="swiper-container box-one-table">
                <div class="swiper-wrapper">
                  <div class="refresh">释放刷新</div>
                  <div class="swiper-slide tableBox row-40" v-for="item in datas.MerchantInfo">
                    <li v-text="item.name"></li>
                    <li v-text="item.product.name"></li>
                    <li v-text="item.product.sales"></li>
                    <li>
                      <i v-if="item.name">已检测</i>
                      <i v-else>未检测</i>
                    </li>
                  </div>
                  <!-- <div class="loadmore">加载更多</div> -->
                </div>
                <div class="swiper-scrollbar"></div>
              </div>
            </div>
          </div>

        </div>

        <!-- 右侧容器 -->
        <div class="KanbanRight">

          <!-- 实时访问数据 -->
          <div class="KanbanBox NoTop">
            <div class="KanbanTitle">
              <i class="KanbanTitleIcon"></i>
              实时访问数据
              <a class="more-list">全部<span class="icon iconfont iconicon-test25"></span></a>
            </div>
            <div class="box-five">
              <ul class="tableHead row-40">
                <li>商户</li>
                <li>品名</li>
                <li>查询时间</li>
                <li>是否重复</li>
              </ul>
              <div class="swiper-container box-five-table">
                <div class="swiper-wrapper">
                  <div class="refresh">释放刷新</div>
                  <div class="swiper-slide tableBox row-40" v-for="item in datas.MerchantInfo">
                    <li v-text="item.name"></li>
                    <li v-text="item.product.name"></li>
                    <li v-text="nowTime"></li>
                    <li>
                      <i v-if="item.repeat">是</i>
                      <i v-else>否</i>
                    </li>
                  </div>
                  <!-- <div class="loadmore">加载更多</div> -->
                </div>
                <div class="swiper-scrollbar"></div>
              </div>
            </div>
          </div>

          <!-- 武夷农家茶实时库存详情 -->
          <div class="KanbanBox">
            <div class="KanbanTitle">
              <i class="KanbanTitleIcon"></i>
              武夷农家茶实时库存详情
              <a class="more-list">全部<span class="icon iconfont iconicon-test25"></span></a>
            </div>
            <div class="box-six">
              <ul class="tableHead row-30">
                <li>商户</li>
                <li>品名</li>
                <li>库存（斤）</li>
              </ul>
              <div class="swiper-container box-six-table">
                <div class="swiper-wrapper">
                  <div class="refresh">释放刷新</div>
                  <div class="swiper-slide tableBox row-30" v-for="item in stockList">
                    <li v-text="item.business.name"></li>
                    <li v-text="item.name"></li>
                    <li v-text="item.stock"></li>
                  </div>
                  <!-- <div class="loadmore">加载更多</div> -->
                </div>
                <div class="swiper-scrollbar"></div>
              </div>
            </div>
          </div>

          <!-- 价格对比图 -->
          <div class="KanbanBox">
            <div class="KanbanTitle">
              <i class="KanbanTitleIcon"></i>
              商品价格趋势图
              <a class="more-list">全部<span class="icon iconfont iconicon-test25"></span></a>
            </div>
            <div class="box-seven" id="ChartTwo"></div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
  var self, canvas, ChartOne, ChartTwo, map, swiper, refreshEnd, refreshText;
  var echarts = require('echarts')
  import axios from 'axios'
  export default {
    name: 'HomePage',
    data() {
      return {
        language: 'zh',
        CurrentDate: null,
        nowTime:"2022-01-11- 11:11:11",
        navlist: [{
          icon: "kanban-01",
          name: "追溯品种",
          number: 512,
          color: "#F79506"
        }, {
          icon: "kanban-02",
          name: "追溯查询",
          number: 233,
          color: "#4AA7E4"
        }, {
          icon: "kanban-03",
          name: "农药检测",
          number: 134,
          color: "#7FC082"
        }, {
          icon: "kanban-04",
          name: "追溯企业",
          number: 322,
          color: "#CDB33A"
        }],
        stockList:null,
        datas: {
          acreage: "8",
          number: 1000,
          productCount: 6000,
          OrgInfo: null,
          MerchantInfo: null,
        }
      }
    },
    created() {
      self = this;
      self.language = localStorage.getItem("wwmxt_locale");
      axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
      setInterval(self.getTime, 3000); //时间
    },
    updated() {
      self.boxSize(); //容器高度设置
    },
    mounted() {

      self.backStyle(); //背景
      self.ChartOne(); //交易图对比
      self.GetData(); //组织信息
      self.GetBusinessData(); //商户信息
      self.GetProductList(); //产品信息
      self.GetStockList(1); //库存列表

      //屏幕宽度改变图表自动适应
      window.addEventListener('resize', function() {
        if (self.$route.name == "ORGKanban") {
          location.reload();
        }
      })
      self.dateFun();
      self.CreateMap(); //地图
    },
    methods: {
      dateFun() {
        setTimeout(function() {
          self.dateFun();
        }, 1000)
        self.CurrentDate = self.CurrentDateFun();
      },
      goback() {
        localStorage.setItem("wwmxt_HistoryURL", "ORGHomePage");
        self.GoToPage("ORGHomePage"); // 路由跳转
      },
      boxSize() {
        var wheight = $(window).height();
        var mapheight = $('.map-box').height();
        $(".box-one").height(wheight - 63 - 70 - 40 - 250 - 39 * 2); //窗体高度-顶部导航-菜单导航-容器边距-标题导航-交易图对比高度
        $(".box-four").height(wheight - 63 - mapheight - 30 - 39); //窗体高度-顶部导航-地图高度-容器边距-标题导航
        $(".box-five,.box-six").height((wheight - 63 - 40 - 39 * 3 - 250) / 2);
      },
      CreateMap() {
        map = new BMap.Map("map"); // 创建Map实例
        var point = new BMap.Point(117.962063, 27.663616);
        var marker = new BMap.Marker(point);
        var label = new BMap.Label("武夷山市农家茶联合会", {
          offset: new BMap.Size(-68, 26)
        });
        label.setStyle({
          color: "black",
          border: '0px',
          fontSize: "12px",
          height: "20px",
          padding: "0 10px",
          lineHeight: "20px",
          fontFamily: "微软雅黑"
        });
        marker.setLabel(label);
        map.addOverlay(marker);
        map.centerAndZoom(point, 13); // 初始化地图,设置中心点坐标和地图级别
        map.addControl(new BMap.MapTypeControl()); //添加地图类型控件
        map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      },
      tableSwiper(id,delay) {
        refreshEnd = false;
        var times = 0; //加载次数
        var oriSpeed = 300
        swiper = new Swiper('.' + id, {
          speed: oriSpeed,
          autoplay: {
            delay: delay,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          slidesPerView: 'auto',
          freeMode: true,
          direction: 'vertical',
          setWrapperSize: true,
          scrollbar: {
            el: '.swiper-scrollbar',
          },
          on: {
            //下拉释放刷新
            touchEnd: function() {
              swiper = this
              refreshText = swiper.$el.find('.refresh')
              if (this.translate > 100) {
                swiper.setTransition(this.params.speed);
                swiper.setTranslate(100);
                swiper.touchEventsData.isTouched = false; //跳过touchEnd事件后面的跳转(4.0.5)
                refreshText.html('刷新中')

                swiper.allowTouchMove = false;

                self.GetBusinessData(true);

              }
            },
            touchStart: function() {
              if (refreshEnd == true) {
                this.$el.find('.refresh').html('释放刷新');
                refreshEnd = false;
              }
            }
            //加载更多
            // momentumBounce: function() { //非正式反弹回调函数，上拉释放加载更多可参考上例
            //   swiper = this
            //   if (swiper.translate < -100) {

            //     swiper.allowTouchMove = false; //禁止触摸
            //     swiper.params.virtualTranslate = true; //定住不给回弹
            //     setTimeout(function() { //模仿AJAX
            //       for (var m = 0; m < 4; m++) {
            //         swiper.appendSlide('<div class="swiper-slide tableBox row-60"><li>100100003</li><li>武夷山一茶一道茶业有限公司</li><li>245</li><li>245</li><li>245</li><li>245</li></div>');
            //       }
            //       swiper.params.virtualTranslate = false;
            //       swiper.allowTouchMove = true;
            //       times++
            //     }, 1000)
            //   }
            // },

          }
        });
      },
      ChartOne() {
        ChartOne = echarts.init(document.getElementById('ChartOne'));
        ChartOne.setOption({
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c}"
          },
          xAxis: {
            type: 'category',
            data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
            axisLine: {
              lineStyle: {
                color: ['rgba(76,207,254,0.6)']
              }
            }
          },
          yAxis: {
            show: true,
            type: 'value',
            splitLine: {
              show: false, //去除网格线
            },
            axisLine: {
              lineStyle: {
                color: ['rgba(76,207,254,0.6)']
              }
            },
          },
          grid: {
            top: '20px',
            left: '10%',
            right: '10px',
            bottom: '10%'
          },
          series: [{
            name: "数量",
            data: [100, 932, 500, 934, 1500, 1330, 332, 532, 1123, 421, 553, 665],
            type: 'line',
            symbolSize: 8,
            lineStyle: {
              normal: {
                width: 3,
                color: '#4ccffe',
              }
            },
            itemStyle: {
              borderColor: '#4ccffe',
              borderWidth: 2
            }
          }]
        });
      },
      ChartTwo(list) {
        ChartTwo = echarts.init(document.getElementById('ChartTwo'));
        var listName = [],
          datas = [];
        for (let i in list) {
          listName.push(list[i].name);
          if (list[i].attribute) {
            let main = JSON.parse(list[i].attribute)
            let mainArr = [];
            for (let y in main) {
              mainArr.push(main[y]);
            }
            datas.push({
              name: list[i].name,
              type: 'line',
              data: mainArr
            })
          }
        }

        ChartTwo.setOption({
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: '10px',
            right: '10px',
            textStyle: {
              color: "#4ccffe"
            },
            data: listName
          },
          xAxis: {
            type: 'category',
            data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
            axisLine: {
              lineStyle: {
                color: ['rgba(76,207,254,0.6)']
              }
            }
          },
          yAxis: {
            show: true,
            type: 'value',
            splitLine: {
              show: false, //去除网格线
            },
            axisLine: {
              lineStyle: {
                color: ['rgba(76,207,254,0.6)']
              }
            },
          },
          grid: {
            top: '20px',
            left: '10%',
            right: '10px',
            bottom: '10%',
          },
          series: datas
        });
      },
      backStyle() {
        canvas = document.getElementById('BackCanvas');
        var ctx = canvas.getContext('2d')
        canvas.width = $(window).width() - 30;
        canvas.height = $(window).height() - 66;
        ctx.lineWidth = .3;
        ctx.strokeStyle = (new Color(150)).style;

        var mousePosition = {
          x: 30 * canvas.width / 100,
          y: 30 * canvas.height / 100
        };

        var dots = {
          nb: 200,
          distance: 70,
          d_radius: 100,
          array: []
        };

        function colorValue(min) {
          return Math.floor(Math.random() * 255 + min);
        }

        function createColorStyle(r, g, b) {
          return 'rgba(' + r + ',' + g + ',' + b + ', 0.8)';
        }

        function mixComponents(comp1, weight1, comp2, weight2) {
          return (comp1 * weight1 + comp2 * weight2) / (weight1 + weight2);
        }

        function averageColorStyles(dot1, dot2) {
          var color1 = dot1.color,
            color2 = dot2.color;

          var r = mixComponents(color1.r, dot1.radius, color2.r, dot2.radius),
            g = mixComponents(color1.g, dot1.radius, color2.g, dot2.radius),
            b = mixComponents(color1.b, dot1.radius, color2.b, dot2.radius);
          return createColorStyle(Math.floor(r), Math.floor(g), Math.floor(b));
        }

        function Color(min) {
          min = min || 0;
          this.r = colorValue(min);
          this.g = colorValue(min);
          this.b = colorValue(min);
          this.style = createColorStyle(this.r, this.g, this.b);
        }

        function Dot() {
          this.x = Math.random() * canvas.width;
          this.y = Math.random() * canvas.height;

          this.vx = -.5 + Math.random();
          this.vy = -.5 + Math.random();

          this.radius = Math.random() * 2;

          this.color = new Color();
        }

        Dot.prototype = {
          draw: function() {
            ctx.beginPath();
            ctx.fillStyle = this.color.style;
            ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
            ctx.fill();
          }
        };

        function createDots() {
          for (var i = 0; i < dots.nb; i++) {
            dots.array.push(new Dot());
          }
        }

        function moveDots() {
          for (var i = 0; i < dots.nb; i++) {

            var dot = dots.array[i];

            if (dot.y < 0 || dot.y > canvas.height) {
              dot.vx = dot.vx;
              dot.vy = -dot.vy;
            } else if (dot.x < 0 || dot.x > canvas.width) {
              dot.vx = -dot.vx;
              dot.vy = dot.vy;
            }
            dot.x += dot.vx;
            dot.y += dot.vy;
          }
        }

        function connectDots() {
          for (var i = 0; i < dots.nb; i++) {
            for (var j = 0; j < dots.nb; j++) {
              var i_dot = dots.array[i];
              var j_dot = dots.array[j];

              if ((i_dot.x - j_dot.x) < dots.distance && (i_dot.y - j_dot.y) < dots.distance && (i_dot.x - j_dot.x) > -
                dots.distance && (i_dot.y - j_dot.y) > -dots.distance) {
                if ((i_dot.x - mousePosition.x) < dots.d_radius && (i_dot.y - mousePosition.y) < dots.d_radius && (i_dot
                    .x - mousePosition.x) > -dots.d_radius && (i_dot.y - mousePosition.y) > -dots.d_radius) {
                  ctx.beginPath();
                  ctx.strokeStyle = averageColorStyles(i_dot, j_dot);
                  ctx.moveTo(i_dot.x, i_dot.y);
                  ctx.lineTo(j_dot.x, j_dot.y);
                  ctx.stroke();
                  ctx.closePath();
                }
              }
            }
          }
        }

        function drawDots() {
          for (var i = 0; i < dots.nb; i++) {
            var dot = dots.array[i];
            dot.draw();
          }
        }

        function animateDots() {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          moveDots();
          connectDots();
          drawDots();

          requestAnimationFrame(animateDots);
        }

        $('canvas').on('mousemove', function(e) {
          mousePosition.x = e.pageX;
          mousePosition.y = e.pageY;
        });

        $('canvas').on('mouseleave', function(e) {
          mousePosition.x = canvas.width / 2;
          mousePosition.y = canvas.height / 2;
        });

        createDots();
        requestAnimationFrame(animateDots);
      },
      GetData() {
        self.loading = true;
        axios.get(self.GetServerUrl(1) + "org")
          .then(function(response) {
            self.datas.OrgInfo = response.data.meta;
            //更新头部导航LOGO和状态
            localStorage.setItem("wwmxt_Logo", response.data.meta.logo);
            self.loading = false;
          }).catch(function(error) {
            let msg;
            if (error.response) {
              if (error.response.data.message) {
                msg = error.response.data.message;
              } else {
                msg = error.response.statusText;
              }
            } else {
              msg = "请检查网络连接！";
            }
            self.loading = false;
            self.ToastFun(2, msg); //弹窗提醒
          })
      },
      GetStockList(page) {
        axios.post(self.GetServerUrl(1) + "production_list/" + page,{org_rootid:1001})
          .then(function(response) {
            let datas = response.data.meta.data
            let res = response.data.meta;
            if(res.last_page > 1 && res.current_page < res.last_page ){
              setTimeout(function(){
                self.GetStockList(res.current_page+1);
              },1000)
            }
            if(page == 1){
              self.stockList = datas;
            }else{
              for(let i in datas){
                self.stockList.push(datas[i]);
              };
            }
            self.$nextTick(() => {
              self.tableSwiper("box-six-table",3200); //库存详情
            });
          }).catch(function(error) {
            console.log(error);
          })
      },
      GetBusinessData(etype) {

        //下拉刷新
        if(etype){
          swiper.removeAllSlides();
          self.datas.MerchantInfo = [];
        }

        axios.post(self.GetServerUrl(1) + "business_list", )
          .then(function(response) {
            var datas = response.data.meta.data;
            var product = ["小种","正山小种","名丛","肉桂","奇种","水仙","奇红(金骏眉)","大红袍"]
            for (let i in datas) {
              datas[i].rootid = "" + datas[i].rootid;
              if (datas[i].customize_data) {
                datas[i].customize_data = JSON.parse(datas[i].customize_data);
                datas[i].info = [];
                for (let y in datas[i].customize_data) {
                  datas[i].info.push(datas[i].customize_data[y])
                }
              }

              let number = Math.ceil(Math.random()*8) - 1;
              datas[i].product = {
                name:product[number],
                stock:(datas[i].trade_id/(parseInt(i)+1)).toFixed(0),
                sales:(datas[i].trade_id*(datas.length-parseInt(i)+1)).toFixed(0),
              }

              //初始化
              if(!datas[i].info || datas[i].info.length < 4){
                datas[i].info = [{key:"面积",value:"-"},{key:"海拔",value:"-"},{key:"茶树",value:"-"},{key:"年龄",value:"-"}];
              }

              if (datas[i].lon_lat) {
                var ll = datas[i].lon_lat.split(',');
                var point = new BMap.Point(ll[0], ll[1]);
                var marker = new BMap.Marker(point);
                let left = datas[i].name.length * 6;

                var label = new BMap.Label(datas[i].name, {
                  offset: new BMap.Size('-' + left, 26)
                });
                label.setStyle({
                  color: "black",
                  border: '0px',
                  fontSize: "12px",
                  height: "20px",
                  padding: "0 10px",
                  lineHeight: "20px",
                  fontFamily: "微软雅黑"
                });
                marker.setLabel(label);
                map.addOverlay(marker);
              }
            }
            console.log(datas)
            self.datas.MerchantInfo = datas;

            self.$nextTick(() => {
              self.tableSwiper("box-four-table",2600); //已确权区
              self.tableSwiper("box-one-table",3000); //销售明星
              self.tableSwiper("box-five-table",2800); //实时访问数据
              if(etype){
                refreshText.html('刷新完成');
                refreshEnd = true;
                swiper.allowTouchMove = true;
              }
            });
          }).catch(function(error) {
            let msg;
            if (error.response) {
              if (error.response.data.message) {
                msg = error.response.data.message;
              } else {
                msg = error.response.statusText;
              }
            } else {
              msg = "请检查网络连接！";
            }
            console.log(msg);
          })
      },
      GetProductList() {
        axios.get(self.GetServerUrl(1) + "production_list/" + 1)
          .then(function(response) {
            let datas = response.data.meta.data
            self.ChartTwo(datas); //价格对比

          }).catch(function(error) {
            console.log(error);
          })
      },
      getTime() {
          var myDate = new Date();
          var myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
          var myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
          var myToday = myDate.getDate(); //获取当前日(1-31)
          var myDay = myDate.getDay(); //获取当前星期X(0-6,0代表星期天)
          var myHour = myDate.getHours(); //获取当前小时数(0-23)
          var myMinute = myDate.getMinutes(); //获取当前分钟数(0-59)
          var mySecond = myDate.getSeconds(); //获取当前秒数(0-59)

          self.nowTime = myYear + '-' + self.fillZero(myMonth) + '-' + self.fillZero(myToday) + '-' + ' ' + self.fillZero(myHour) + ':' +
              self.fillZero(myMinute) + ':' + self.fillZero(mySecond);
      },
      fillZero(str) {
          var realNum;
          if (str < 10) {
              realNum = '0' + str;
          } else {
              realNum = str;
          }
          return realNum;
      }
    }
  }
</script>

<style scoped>
  .hide {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Kanban {
    background-color: red;
    /* 浏览器不支持时显示 */
    background-image: linear-gradient(to right bottom, #1584b4, #511b96);
  }

  .KanbanHead {
    text-align: center;
    padding: 15px 0;
    color: #FFF;
    font-size: 25px;
    font-weight: bold;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
  }

  .KanbanHeadDate {
    position: absolute;
    left: 22px;
    top: 0;
    line-height: 63px;
    font-size: 16px;
    color: #47edff;
  }

  .KanbanHeadBtn {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    font-size: 12px;
    padding: 3px 4px;
    border-radius: 20px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.8) inset;
    background-image: linear-gradient(to right, #2082c0, #1fb59d);
  }

  .KanbanHeadBtn i {
    padding: 5px 10px;
    border-radius: 20px;
    display: block;
    font-weight: 100;
    background-color: #3b549d;
  }

  .KanbanHeadBtn i:hover {
    opacity: 0.9;
  }

  .KanbanMain {
    position: fixed;
    top: 63px;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    right: 0;
    z-index: 999;
  }

  .KanbanMainBox {
    overflow: hidden;
    padding: 10px 20px;
  }

  .KanbanLeft,
  .KanbanCenter,
  .KanbanRight {
    float: left;
    width: calc(33.33% - 6.66px);
    /* background: rgba(255,255,255,0.1); */
  }

  .KanbanCenter {
    margin: 0 10px;
  }

  .KanbanNav {
    overflow: hidden;
  }

  .KanbanNavMain {
    float: left;
    width: calc(25% - 87.5px);
    background: rgba(255, 255, 255, 0.1);
    margin-right: 10px;
    color: #FFF;
    padding: 15px 0;
    padding-left: 70px;
    padding-right: 10px;
    border-radius: 5px;
    position: relative;
  }

  .KanbanNavMain:last-child {
    margin-right: 0;
  }

  .KanbanNavIcon {
    position: absolute;
    width: 45px;
    top: 13px;
    left: 15px;
  }

  .KanbanNavNumber {
    font-size: 20px;
  }

  .KanbanNavName {
    font-size: 13px;
  }

  .KanbanBox {
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.1);
  }

  .KanbanTitle {
    color: #FFF;
    padding: 10px 0;
    padding-left: 35px;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
  }

  .KanbanTitleIcon {
    position: absolute;
    left: 20px;
    width: 6px;
    height: 20px;
    background-color: #69bf11;
  }

  .KanbanOne {
    height: 400px;
  }

  .NoTop {
    margin-top: 0;
  }

  .KanbanOneHead {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .KanbanOneHead ul {
    overflow: hidden;
  }

  .KanbanOneHead ul li {
    float: left;
    color: #FFF;
    padding: 20px 0;
    text-align: center;
    width: calc(33.33%);
  }

  .KanbanOneHeadName {
    opacity: 0.9;
  }

  .KanbanOneHeadNumber {
    margin-top: 7px;
    font-size: 20px;
  }

  #map {
    height: 500px;
    opacity: 0.7;
  }

  #ChartOne,
  #ChartTwo {
    height: 250px;
  }

  .iconicon-test25 {
    font-size: 11px;
    margin-left: 2px;
  }

  .more-list {
    position: absolute;
    right: 15px;
    font-size: 14px;
    cursor: pointer;
  }

  .more-list:hover {
    opacity: 0.8;
  }


  .swiper-container {
    width: 100%;
    height: calc(100% - 66px);
  }
  .swiper-slide {
    height: 45px;
    line-height: 45px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .refresh {
    color: #FFF;
    position: absolute;
    line-height: 100px;
    bottom: 100%;
    text-align: center;
    width: 100%;
  }
  .loadmore {
    position: absolute;
    line-height: 25px;
    top: 100%;
    text-align: center;
    width: 100%;
    color: #FFF;
  }
</style>
