import { render, staticRenderFns } from "./BusinessDetail.vue?vue&type=template&id=ca591b58&scoped=true"
import script from "./BusinessDetail.vue?vue&type=script&lang=js"
export * from "./BusinessDetail.vue?vue&type=script&lang=js"
import style0 from "./BusinessDetail.vue?vue&type=style&index=0&id=ca591b58&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca591b58",
  null
  
)

export default component.exports