<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('BrandEdit')"><span class="icon iconfont iconicon-test20"></span>{{$t('b.Brand.AddBatch')}}</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('b.Brand.BrandName')"></th>
            <th v-text="$t('b.Brand.BrandCertificate')"></th>
            <th v-text="$t('b.Brand.Authorization')"></th>
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.trademark_name"></td>
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.trademark_cert + ')'}" v-if="item.trademark_cert"><img v-if="item.trademark_cert" class="tableImgShow" :src="item.trademark_cert" @click="PreviewImgs" /></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td>
              <div class="tableImg backImg" v-if="item.attribute" :style="{backgroundImage: 'url('+item.attribute+')'}"><img v-if="item.attribute" class="tableImgShow" :src="item.attribute" @click="PreviewImgs" /></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td class="TdStatus">
              <a v-if="item.status == 1"><i class="wait"></i>{{$t('c.Common.InReview')}}</a>
              <a v-else-if="item.status == 2"><i class="reject"></i>{{$t('c.Common.Refer')}}</a>
              <a v-else-if="item.status == 3"><i class="pass"></i>{{$t('c.Common.Passed')}}</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius" :class="{blueBackColor:item.status == 3,ProhibitBtn:item.status != 3}" @click="HandleModel(true,item.trademark_id,item.status)" v-text="$t('b.Brand.BindingProduct')"></a>
                <a class="btnStyleTwo btnRadius greenBackColor" v-if="item.status != 3" @click="GoToPage('BrandEdit?id='+item.trademark_id)" v-text="$t('c.Common.Change')"></a>
                <a class="btnStyleTwo btnRadius ProhibitBtn" v-else v-text="$t('c.Common.Change')"></a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.trademark_id)" v-text="$t('c.Common.Delete')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 产品绑定 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{$t('b.Brand.BindingProduct')}}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="ListBtnArray" v-if="products">
                <p class="FormStyleTwoTitle">已绑定产品（一个商标模板可绑定多个产品）</p>
                <p>
                  <a class="feedback NoSelect" v-for="(item,index) in products" v-bind:key="index" v-text="item.name" :class="{ListBtnActive:item.active}" @click="ClickNav(index)"></a>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">{{$t('b.CodeManage.ProductOption')}}（{{$t('b.Brand.HintOne')}}）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    {{SelectArr[0].datas[SelectArr[0].ix].name}}
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <li class='OptionStyleQuery' v-if="SelectArr[0].totalPages > 1" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery">查询</a></li>
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[0].ix == index && !SelectArr[0].isHover}" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.name" @click="SelectData(0,index)" @mouseover="SelectHover($self,0,true)" @mouseout="SelectHover($self,0,false)"></li>
                        <li class="OptionStyleLoading" @click.stop="SelectArrLoading(0)" v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page < SelectArr[0].totalPages">加载更多</li>
                        <li class="OptionStyleLoading" @click.stop v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page >= SelectArr[0].totalPages">无更多内容</li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'Brand',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      SQueryName:null,
      datas:null,
      ModelShow:false,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      isHover:false,
      SelectArr:[{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      }],
      products:null
    }
  },
  created () {
    self = this;
    this.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetList();
    self.GetProductList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HandleModel (status,id,state) {  //隐藏显示生码模态框
      if(status && state != 3){
        return;
      }
      self.ModelShow = status;
      if(status){
        self.SelectArr[0].ix = 0;
        self.GetBindList(id);
      }
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.SelectArr[0].isHover = false;
    },
    ClickNav (ix) {
      if(self.trade[ix].active){
        self.trade[ix].active = false;
      }else{
        self.trade[ix].active = true;
      }
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"trademark_list/"+self.page)
      .then(function (response){
          let datas = [];
          self.datas = response.data.meta.data;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    GetBindList (id) {
      self.id = id;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"trademark_product/"+id)
      .then(function (response){
          self.products = response.data.meta;
          self.isLoding = false;
      }).catch(function (error){
        self.isLoding = false;
        self.products = null;
      })
    },
    SubmitForm () {  //提交表单
      self.loading = true;
      axios({
        method:"PATCH",
        url:self.GetServerUrl(1)+"bind_trademark_product",
        data:{production_id:self.SelectArr[0].datas[self.SelectArr[0].ix].production_id,trademark_id:self.id}
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveData (id) {
      self.id = id;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该商标？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"trademark/"+self.id,
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    },
    GetProductList (te) {
      axios.get(self.GetServerUrl(1)+"production_list/"+self.SelectArr[0].page)
      .then(function (response){
        if(!te){
          self.SelectArr[0].datas = response.data.meta.data;
          self.SelectArr[0].totalPages = response.data.meta.last_page;
        }else{
          self.loading = false;
          let datas = response.data.meta.data;
          for(let item in datas){
            self.SelectArr[0].datas.push(datas[item]);
          }
        }
      }).catch(function (error){
        if(!te){
          self.loading = false;
        }
      })
    },
    SelectArrQuery () {
      self.loading = true;
      axios.post(self.GetServerUrl(1)+"production_list_condition",{name:self.SQueryName})
      .then(function (response){
        self.SelectArr[0].ix = 0;
        self.SelectArr[0].datas = response.data.meta.data;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SelectArrLoading (ix) {
      self.SelectArr[ix].page++;
      self.loading = true;
      self.GetProductList(true);
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    }
  }
}
</script>

<style scoped>
  .ListBtnArray{
    overflow: hidden;
  }
  .ListBtnArray a{
    float: left;
    padding:10px 16px;
    border:1px solid #DDD;
    border-radius: 5px;
    margin-right:17px;
    color:#585A64;
    cursor: pointer;
    margin-top:15px;
  }
  .ListBtnArray a:nth-child(7n){
    margin-right:0;
  }
  .ListBtnActive{
    color:#278BF5 !important;
    border:1px solid #278BF5 !important;
  }
  .ListBtnArray a{
    margin-top:10px;
  }
</style>
