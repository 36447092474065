<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="CodeQueryBox">
      <div class="FloatBox">
        <div class="CodeQueryLeft FormStyleThree">
          <div class="CodeQueryProduct">
            <p class="CodeQueryProductTitle">
              <span class="icon iconfont iconicon-test28"></span>发票信息
              <a class="CodeQueryProductTitleBtn blueColor" v-if="!isEdit" v-on:click="ChangeInvoice(1)">修改发票信息</a>
              <a class="CodeQueryProductTitleBtn blueColor" v-else v-on:click="ChangeInvoice(2)">保存</a>
            </p>
            <ul class="FormStyleFour" v-if="datas.open_type == 1">
              <li>
                <a class="FormStyleFourKey">开具类型：</a>
                <a class="FormStyleFourValue" v-if="!isEdit">
                  <i v-if="datas.open_type == 1">企业</i>
                  <i v-else>个人</i>
                </a>
                <a v-else class="FormStyleFourValue EditStyle">
                  <select class='FormStyleFourSelect' v-model="datas.open_type">
                    <option value="1">企业</option>
                    <option value="2">个人</option>
                  </select>
                </a>
              </li>
              <li>
                <a class="FormStyleFourKey">发票抬头：</a>
                <a class="FormStyleFourValue" v-text="datas.invoice_title" v-if="!isEdit"></a>
                <a class="FormStyleFourValue EditStyle" v-else>
                  <input class="FormStyleFourInput" type="text" v-model="datas.invoice_title"/>
                </a>
              </li>
              <li>
                <a class="FormStyleFourKey">发票类型：</a>
                <a class="FormStyleFourValue" v-if="!isEdit">
                  <i v-if="datas.invoice_user_type == 1">增值税普通发票</i>
                  <i v-else-if="datas.invoice_user_type == 2">增值税专用</i>
                  <i v-else-if="datas.invoice_user_type == 3">组织（非企业）增值税普通</i>
                </a>
                <a class="FormStyleFourValue EditStyle" v-else>
                  <select class='FormStyleFourSelect' v-model="datas.invoice_user_type">
                    <option value="1">增值税普通发票</option>
                    <option value="2">增值税专用</option>
                    <option value="3">组织（非企业）增值税普通</option>
                  </select>
                </a>
              </li>
              <li>
                <a class="FormStyleFourKey">税务登记证号：</a>
                <a class="FormStyleFourValue" v-text="datas.tax_number" v-if="!isEdit"></a>
                <a class="FormStyleFourValue EditStyle" v-else>
                  <input class="FormStyleFourInput" type="text" v-model="datas.tax_number"/>
                </a>
              </li>
              <li>
                <a class="FormStyleFourKey">开户银行名称：</a>
                <a class="FormStyleFourValue" v-text="datas.open_bank_name" v-if="!isEdit"></a>
                <a class="FormStyleFourValue EditStyle" v-else>
                  <input class="FormStyleFourInput" type="text" v-model="datas.open_bank_name"/>
                </a>
              </li>
              <li>
                <a class="FormStyleFourKey">基本开户账号：</a>
                <a class="FormStyleFourValue" v-text="datas.bank_account" v-if="!isEdit"></a>
                <a class="FormStyleFourValue EditStyle" v-else>
                  <input class="FormStyleFourInput" type="text" v-model="datas.bank_account"/>
                </a>
              </li>
              <li>
                <a class="FormStyleFourKey">注册场所地址：</a>
                <a class="FormStyleFourValue" v-text="datas.reg_address" v-if="!isEdit"></a>
                <a class="FormStyleFourValue EditStyle" v-else>
                  <input class="FormStyleFourInput" type="text" v-model="datas.reg_address"/>
                </a>
              </li>
              <li>
                <a class="FormStyleFourKey">注册固定电话：</a>
                <a class="FormStyleFourValue" v-text="datas.reg_tel" v-if="!isEdit"></a>
                <a class="FormStyleFourValue EditStyle" v-else>
                  <input class="FormStyleFourInput" type="text" v-model="datas.reg_tel"/>
                </a>
              </li>
            </ul>
            <ul class="FormStyleFour" v-else>
              <li>
                <a class="FormStyleFourKey">开具类型：</a>
                <a class="FormStyleFourValue" v-if="!isEdit">
                  <i v-if="datas.open_type == 1">企业</i>
                  <i v-else>个人</i>
                </a>
                <a v-else class="FormStyleFourValue EditStyle">
                  <select class='FormStyleFourSelect' v-model="datas.open_type">
                    <option value="1">企业</option>
                    <option value="2">个人</option>
                  </select>
                </a>
              </li>
              <li>
                <a class="FormStyleFourKey">发票类型：</a>
                <a class="FormStyleFourValue">增值税普通发票</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Column from '@/components/common/Column.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'CodeQuery',
  components: {
    'K-Column': Column,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      te:null,
      column:[],
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      datas:{
        open_type:null
      },
      isEdit:false,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["企业","个人"]
      }]
    }
  },
  created () {
    self = this;
    self.te = self.$route.query.type;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.te == "home"){
      self.column = [{name:'首页展示',url:'BusinessHomePage'},{name:'发票地址管理',url:'InvoiceAddress?type='+self.te}];
    }else{
      self.column = [{name:'费用中心',url:'BillingManagement'},{name:'发票地址管理',url:'InvoiceAddress'}];
    }
  },
  mounted () {
    self.GetData();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"invoiceUser")
      .then(function (response){
        let datas = response.data.meta;
        if(datas.is_default_invoice == 1){
          self.datas.invoice_title = datas.business.name;
          self.datas.open_type = datas.business.btype
          self.datas.invoice_user_type = 1;
          self.datas.tax_number
        }else{
          self.datas = datas;
        }
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));
      })
    },
    ChangeInvoice (te) {
      if(te == 1){
        self.isEdit = true;
      }else{
        self.loading = true;
        axios.post(self.GetServerUrl(1)+"invoiceUser",self.datas)
        .then(function (response){
          self.ToastFun(0,response.data.message);
          self.loading = false;
          self.isEdit = false;
          self.GetData();
        }).catch(function (error){
          self.ToastFun(2,self.ErrorHint(error));
          self.loading = false;
        })
      }
    }
  }
}
</script>

<style scoped>
  .CodeQueryBox{
    padding-top:0;
  }
  .CodeQueryLeft{
    width: 405px;
  }
  .FormStyleFourSelect{
    border:0;
    height:39px;
    line-height:39px;
    width: 282px;
    padding:0 12px;
    background: #FFF;
    border-radius: 0;
  }
  .EditStyle{
    padding:0;
    width:282px;
  }
  .FormStyleFourInput{
    width: 252px !important;
    height:39px !important;
    border:0;
    padding:0 15px;
  }
</style>
