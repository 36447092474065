<template>
	<div class="MainBox">
		<div class="FunctionPackageNav">{{$t('c.HeadBar.FunctionPackage')}}</div>
    <div class="FunctionPackageBox">
      <div class="FunctionPackageRow FunctionPackageLeft">
        <ul class="FunctionPackageLeftHead">
          <li class='FunctionPackageLogo backImg' :style="{backgroundImage:'url('+logo+')'}"></li>
          <li class='FunctionPackageEntName' v-text="name"></li>
          <div v-if="datas">
            <li class="FunctionPackageVer"><a v-text="datas.package.name"></a></li>
            <li class="FunctionPackageExpire">{{$t('c.Common.ExpirationDate')}} {{datas.due_date}}</li>
          </div>
        </ul>
        <ul class="FunctionPackageLeftFoot">
          <div v-if="datas">
            <li>{{$t('c.Common.ResidueCodes')}} <i v-if="datas.code_limit == -1">{{$t('c.Common.Infinite')}}</i><i v-else>{{datas.CodeSurplus}}</i></li>
            <li>{{$t('b.Material.SurplusStorage')}} {{datas.SpaceSurplus}}G</li>
            <li>{{$t('c.Common.HistoryBuy')}} {{datas.buy_date}}</li>
          </div>
          <div v-else>
            <p>{{$t('c.Common.NotPackage')}}</p>
            <li class="FunctionPackageVer FunctionPackageTry" style="margin-top:12px;" @click="iSBuy(0)"><a>{{$t('c.Common.FreeTrial')}}</a></li>
          </div>
        </ul>
      </div>
      <div class="FunctionPackageRow FunctionPackageContent" :class="{BorderRight:showPay}" v-if="SetMeal">
        <div class="SiginMainFourBox" :class="{SetMealActive:MealIndex == index}" v-for="(item,index) in SetMeal" v-bind:key="index" v-if="index > 0" @click="iSBuy(index)">
          <ul>
            <li class="SiginMainFourHead">
              <p class="icon iconfont SiginMainFourHeadIcon" :class="item.icon"></p>
              <p class="SiginMainFourHeadTitle" v-text="item.name"></p>
              <p class='SiginMainFourHeadHint' v-text="item.instructions"></p>
            </li>
            <li class='SiginMainFourFoot'>
              <p class="SiginMainFourHeadText">￥{{item.intro}}</p>
              <p class="SiginMainFourHeadTxt">{{$t('a.HomePage.CreationCodeAmount')}} <a v-if="item.code_limit == -1">{{$t('c.Common.Infinite')}}</a><a v-else>{{item.code_limit}}</a></p>
              <p class="SiginMainFourHeadTxt">{{$t('b.Material.StorageSpace')}} {{(item.space_limit/1024).toFixed(2)}}G</p>
              <p class="SiginMainFourHeadBtn">{{$t('c.Common.Buy')}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="FunctionPackageRow FunctionPackageRight" v-if="showPay">
        <div class="FunctionPackageRightTitle"><span class="icon iconfont iconweixin"></span>{{$t('c.Common.WeChatPay')}}</div>
        <div class="FunctionPackageRightDate" v-if="countdown > 0"><a>{{$t('c.Common.PayTime')}}</a><a v-text="countdown" class="countdown"></a><a>{{$t('c.Common.S')}}</a></div>
        <div class="FunctionPackageRightDate" v-if="countdown == 'timeOver'"><a class="redTwoColor">{{$t('b.FuncitonPackage.HintOne')}}</a></div>
        <div class="FunctionPackageRightQr"><img :src="payQR"></div>
        <div class="FunctionPackageRightMoney">{{$t('b.FuncitonPackage.WeChatScanPay')}}<a v-text="payDatas.order_amount"></a>{{$t('c.Common.Yuan')}}</div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>
	</div>
</template>

<script>
var self,Interval;
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
import QRCode from 'qrcode'
export default {
  name: 'FunctionPackage',
  components: {
    'K-Loading': Loading,
    'K-Confirm': Confirm,
    'K-Toast': Toast
  },
  data () {
    return {
      logo:null,
      name:null,
      payQR:null,
      payDatas:{
        code_url:null,
        order_amount:null
      },
      showPay:false,
      MealIndex:null,
      datas:null,
      SetMeal: null,
      money:0,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      countdown:null
    }
  },
  created () {
    self = this;
    self.name = localStorage.getItem("wwmxt_Name");
    self.logo = localStorage.getItem("wwmxt_Logo");
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetData();
    self.GetPackage();
  },
  beforeDestroy () {
    clearInterval(Interval);
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    GetData () {
      axios.get(self.GetServerUrl(1)+"user_package")
      .then(function (response) {
        let datas = response.data.meta;

        let num1 = datas.code_limit - datas.created_code_limited;
        let num2 = (datas.space_limit - datas.spaced)/1024;
        datas.CodeSurplus = num1.toFixed(0).replace(/(\d)(?=(?:\d{3})+$)/g,'$1,');
        datas.SpaceSurplus = Math.floor(num2*100)/100;

        //续费时间转换
        let y1 = datas.buy_date.substring(0,datas.buy_date.indexOf("-"));
        let m1 = datas.buy_date.substring(datas.buy_date.indexOf("-")+1,datas.buy_date.lastIndexOf("-"));
        let d1 = datas.buy_date.substring(datas.buy_date.lastIndexOf("-")+1,datas.buy_date.lastIndexOf(" "));
        datas.buy_date = y1+"年"+m1+"月"+d1+"日";

        //到期时间转换
        let y2 = datas.due_date.substring(0,datas.due_date.indexOf("-"));
        let m2 = datas.due_date.substring(datas.due_date.indexOf("-")+1,datas.due_date.lastIndexOf("-"));
        let d2 = datas.due_date.substring(datas.due_date.lastIndexOf("-")+1,datas.due_date.lastIndexOf(" "));
        datas.due_date = y2+"年"+m2+"月"+d2+"日";

        self.datas = datas;
      }).catch(function (error){
        console.log(self.ErrorHint(error));
      })
    },
    GetPackage () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"package")
      .then(function (response) {
        self.loading = false;
        self.SetMeal = response.data.meta
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    iSBuy (ix) {
      self.MealIndex = ix;
      if(self.datas&&self.datas.package.rule > 0){
        self.Confirm.state = true;
        self.Confirm.txt = "购买此套餐后将会覆盖原有套餐，是否确定操作？"
      }else{
        self.BuyPackage();
      }
    },
    BuyPackage () {
      let rule = self.SetMeal[self.MealIndex].rule;
      let id = self.SetMeal[self.MealIndex].package_id;

       //清除检查定时器
      if(Interval){
        clearInterval(Interval);
      }
      self.loading = true;
      axios.post(self.GetServerUrl(1)+"user_package",{package_id:id})
      .then(function (response) {
        self.loading = false;

        if(rule < 1){  //免费试用
          self.GetData();
          self.ToastFun(0,response.data.message);  //弹窗提醒
        }else{  //正常套餐购买
          self.payDatas = response.data.meta;

          //开始倒计时
          if(response.data.meta.pay_end_time){
            let oid = response.data.meta.orderid;
            let time = response.data.meta.pay_end_time;
            Interval = setInterval(function(){
              let dateArr = self.GetRTime(time*1000);
              if(dateArr != "timeOver" && dateArr.length >= 4){
                self.countdown = dateArr[2]*60 + dateArr[3];

                //三秒检测一次订单状态
                if(dateArr[3] % 3 == 0){
                  self.CheckPayState(oid);
                }

              }else{
                self.countdown = "timeOver";
                clearInterval(Interval);
              }
            },1000)
          }
          self.CreatePayQR(response.data.meta);
        }
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    CreatePayQR (datas) {
      QRCode.toDataURL(datas.code_url,{errorCorrectionLevel:"H",version: 5},
      function(err,url){
        if(!err){
          self.payQR = url;
          self.showPay = true;
        }
      })
    },
    CheckPayState (oid) {
      axios.get(self.GetServerUrl(1)+"order/"+oid)
      .then(function (response) {

        if(response.data.meta.pay_status == 1){
          clearInterval(Interval);  //删除定时器
          self.GetData(); //更新当前套餐
          self.showPay = false;
          self.MealIndex = null;
          self.ToastFun(0,"支付成功！");  //弹窗提醒

          //清除订单历史
          for(let item in self.payDatas){
            self.payDatas[item] = null;
          }
        }else{
          console.log("支付未成功！");
        }

      }).catch(function (error){
        console.log("支付未成功！");
      })
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.BuyPackage();
      }else{
        self.MealIndex = null;
      }
    }
  }
}
</script>

<style scoped>
  .FunctionPackageNav{
    padding:15px 20px;
    border-bottom: 1px solid #F3F3F3;
  }
  .FunctionPackageBox{
    overflow: hidden;
  }
  .FunctionPackageRow{
    float: left;
  }
  .FunctionPackageLeft{
    width: 230px;
    text-align: center;
    border: 1px solid #E9E9E9;
    margin:20px;
    margin-right:0;
  }
  .FunctionPackageLeftHead{
    padding:10px 0 5px 0;
  }
  .FunctionPackageLogo{
    height:80px;
    width:80px;
    border-radius: 80px;
    background-color: #F9F9F9;
    margin:15px auto;
    background-image: url('/public/static/images/login.png');
  }
  .FunctionPackageEntName{
    font-size:15px;
  }
  .FunctionPackageVer{
    margin:0 auto;
    margin-top:15px;
    border:1px solid #ED5840;
    color:#ED5840;
    padding:7px 17px;
    display: table;
    border-radius: 5px;
    font-size:13px;
  }
  .FunctionPackageTry{
    cursor: pointer;
  }
  .FunctionPackageTry:hover{
    background-color: #ED5840;
    color:#FFF;
  }
  .FunctionPackageExpire{
    margin-top:16px;
    color:#585A64;
  }
  .FunctionPackageLeftFoot{
    color:#585A64;
    padding:18px 20px;
    margin-top:13px;
    border-top: 1px solid #E9E9E9;
  }
  .FunctionPackageLeftFoot li{
    margin-top:8px;
  }
  .FunctionPackageLeftFoot li:first-child{
    margin-top:0;
  }

  .FunctionPackageContent{
    min-width: 700px;
    width: 55%;
    padding:20px;
  }
  .BorderRight{
    border-right: 1px solid #F3F3F3;
  }
  .SiginMainFourBox{
    float: left;
    min-width: 233px;
    width: 33.33%;
    text-align: center;
  }
  .SiginMainFourBox ul{
    margin:0 10px;
    cursor: pointer;
    border:1px solid #E9E9E9;
  }
  .SiginMainFourHead{
    padding:25px;
    background-color: #EEE;
  }
  .SiginMainFourHeadIcon{
    color:#646B71;
    font-size:35px;
    margin-bottom:25px;
  }
  .SiginMainFourHeadTitle{
    font-size:15px;
    color:#5F5D5D;
    margin-bottom:10px;
  }
  .SiginMainFourHeadHint{
    color:#8B8B8C;
    font-size:13px;
  }
  .SiginMainFourHeadText{
    font-size:17px;
    padding:20px 0;
    color:#5F5D5D;
  }
  .SiginMainFourHeadText i{
    font-size:13px;
  }
  .SiginMainFourHeadTxt{
    line-height:25px;
    color:#8B8B8C;
  }
  .SiginMainFourHeadBtn{
    padding:8px 12px;
    display: table;
    margin:15px auto;
    border:1px solid #D8E4ED;
    border-radius: 5px;
    color:#8B8B8C;
    cursor: pointer;
  }
  .SiginMainFourHeadBtn:hover{
    background-color: #F0F0F0;
  }

  .SiginMainFourFoot{
    padding-bottom:10px;
  }
  .SetMealActive ul{
    border:1px solid #3595FF;
    box-shadow:0 0 10px rgba(53,149,255,0.5);
  }
  .SetMealActive > ul > .SiginMainFourHead{
    background-color: #3595FF;
  }
  .SetMealActive > ul > .SiginMainFourHead p{
    color:#FFF;
  }
  .SetMealActive > ul > .SiginMainFourFoot > .SiginMainFourHeadText{
    color:#3595FF;
  }
  .SetMealActive > ul > .SiginMainFourFoot > .SiginMainFourHeadBtn{
    color:#3595FF;
    border:1px solid #3595FF;
  }
  .SetMealActive > ul > .SiginMainFourFoot > .SiginMainFourHeadTxt{
    color:#5F5D5D;
  }
</style>
