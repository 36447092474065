<template>
	<div class="MainBox">
    <div class="iconTitle"><span class="icon iconfont iconicon-test16"></span>用户协议设置</div>
		<div class="FunctionListMain">
      <div id="wangeditor">
        <div ref="editorElem"></div>
      </div>
		</div>

    <!-- 顶部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitDatas" :class="{loadingBtn:loading}"><span class="icon iconfont iconicon-test17"></span>保存</a>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import E from "wangeditor";
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'UserAgreement',
  props: ['catchData'], // 接收父组件的方法
  data () {
    return {
      editor: null,
      editorContent: '',
      loading: false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      }
    }
  },
  components: {
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.createRichText();
  },
  methods: {
    ToastFun (state,type,txt) {
      self.Toast.state = state;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    createRichText () {
      self.editor = new E(self.$refs.editorElem);
      self.editor.customConfig.zIndex = 90
      self.editor.create(); // 创建富文本实例

      //获取列表
      self.GetData();
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"config/agreement")
        .then(response => {
        self.loading = false;
        self.editor.txt.html(response.data.meta.config_value); //渲染内容
      })
      .catch(error => {
        self.loading = false;
      })
    },
    SubmitDatas () {
      self.loading = true;
      let datas = self.editor.txt.html();

      //数据请求
      axios.post(self.GetServerUrl(1)+"admin_config",{config_name:'agreement',config_value: datas})
        .then(response => {
        self.loading = false;
        self.ToastFun(true,0,response.data.message);  //弹窗提醒
      })
      .catch(error => {
        self.loading = false;
        self.ToastFun(true,2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  #wangeditor{
    border-top:1px solid #F3F3F3;
    padding-top:20px;
  }
  .FunctionListMain{
    padding:0;
  }
  .FunctionListContentBox{
    border-top:1px solid #F3F3F3;
  }
  .FunctionListContentRow{
    border-bottom: 1px solid #F3F3F3;
  }
</style>
