<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>类型</th>
            <th>发货方式</th>
            <th>数量</th>
            <th>发货网点</th>
            <th>收货网点</th>
            <th>时间</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td>
              <a v-if="item.to_rootid == uid">入库</a>
              <a v-else>出库</a>
            </td>
            <td>
              <a v-if="item.dealer_shipping && item.dealer_shipping.shipping_type == 1">采集发货</a>
              <a v-else-if="item.dealer_shipping && item.dealer_shipping.shipping_type == 2">">首尾码发货</a>
              <a v-else-if="item.dealer_shipping && item.dealer_shipping.shipping_type == 3">文件发货</a>
              <a v-else>未知发货</a>
            </td>
            <td>
              <a class="redColor" v-if="item.to_rootid == uid" v-text="'+'+item.number"></a>
              <a class="blueColor" v-else v-text="'-'+item.number"></a>
            </td>
            <td v-text="item.from_name"></td>
            <td v-text="item.to_name"></td>
            <td v-text="item.created_at"></td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
export default {
  name: 'StockDetails',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column
  },
  data () {
    return {
      te:null,
      name:null,
      pid:null,
      uid:null,
      leve:null,
      pname:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      column:[],
    }
  },
  created () {
    self = this;

  },
  mounted () {
    if(localStorage.getItem("wwmxt_stockDetail")){
      let stock = JSON.parse(localStorage.getItem("wwmxt_stockDetail"))
      console.log(stock);
      self.te = stock.type;
      self.name = stock.name;
      self.pid = stock.pid;
      self.uid = stock.uid;
      self.leve = stock.leve;
      self.pname = stock.pname;
      self.level = stock.level;
      self.column = [{name:'库存列表',url:'StockManage'},{name:self.pname+' - 库存详情',url:'StockDetails?type='+self.te+'&name='+self.name+'&pid='+self.pid+'&pname='+self.pname}];
      self.GetList();
    }else{
      javascript:history.back();
    }
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      self.subHint = "数据加载中...";

      let datas = {page:self.page,rootid:self.uid,level:self.level,production_id:self.pid};
      axios({
        method:"post",
        url:self.GetServerUrl(1)+"stockList",
        data:datas
      }).then(function (response) {
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error) {
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
  tbody > tr > td{
    padding:22px 20px;
  }
</style>
