<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 表单内容 -->
    <div class="box FloatBox">
      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <li class="FormStyleTwoImgBox FormStyleTwoRow">
            <div class='FormStyleTwoImg backImg' v-if="FormDatas.logo" :style="{backgroundImage: 'url(' + FormDatas.logo + ')'}"></div>
            <div class='FormStyleTwoImg backImg' v-else></div>
            <div class="FormStyleTwoImgMore">
              <p class="hint" style="width:230px">上传组织图片不能大于2M，格式为 JPG或PNG</p>
              <p class="btnStyleTwo btnRadius blueBackColor" style="display:table;"  @click="HandleModel(1,true)">上传图片</p>
            </div>
          </li>

          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>组织类型</p>
            <p>
              <div class="SelectStyle" @click.stop="SelectActive(1,$self)" v-if="SelectArr[1].datas">
                <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix]}}</div>
                <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                  <ul class="OptionStyleMain">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item" @click="SelectData(1,index)"></li>
                  </ul>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
            </p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>提成百分比</p>
            <p><input placeholder="默认为30%" v-model="FormDatas.deduct" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>组织名称</p>
            <p><input placeholder="请输入组织名称" v-model="FormDatas.name" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>账号</p>
            <p><input placeholder="请输入登录账号" v-model="FormDatas.username" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>密码</p>
            <p><input placeholder="请输入登录密码" type="password" v-model="FormDatas.password" /></p>
          </li>
        </ul>
      </div>
      <div class="FormStyleTwoRight FloatBoxRow">
        <div class="FormStyleTwo">
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>所属地区</p>
            <p>
              <div class='SelectStyleArr'>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(2,$self)" v-if="SelectArr[2].datas">
                  <div class="SelectStyleValue">{{SelectArr[2].datas[SelectArr[2].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[2].datas.length > 6}">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(2,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(3,$self)" v-if="SelectArr[3].datas">
                  <div class="SelectStyleValue">{{SelectArr[3].datas[SelectArr[3].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[3].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[3].datas.length > 6}" v-if="SelectArr[3].datas">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[3].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(3,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(4,$self)" v-if="SelectArr[4].datas">
                  <div class="SelectStyleValue">{{SelectArr[4].datas[SelectArr[4].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[4].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[4].datas.length > 6}" v-if="SelectArr[4].datas">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[4].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(4,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="clear"></div>
              </div>
            </p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>邮箱（用于重要推送消息）</p>
            <p><input placeholder="请输入邮箱" v-model="FormDatas.email" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>联系人</p>
            <p><input placeholder="请输入联系人名称" v-model="FormDatas.linkman" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>电话（用于重要推送消息）</p>
            <p><input placeholder="请输入联系人电话" v-model="FormDatas.linkphone" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">地址</p>
            <p><input placeholder="可输入联系人地址" v-model="FormDatas.linkaddress" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">描述</p>
            <p>
              <textarea class="form-control" placeholder="可输入有关组织的描述介绍" v-model="FormDatas.intro" ></textarea>
            </p>
          </li>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>保存</a>
      <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>返回</a>
    </div>

    <!-- 图片空间窗口 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          图片空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>上传图片
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click.stop="SelectActive(0,$self)" v-if="SelectArr[0].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(0,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox ModalMainImgBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table">
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(false)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'OrganizationEdit',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      id:null,
      page:1,
      loading:false,
      subHint:'数据加载中...',
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      column:[
        {name:'组织列表',url:'Organization'}
      ],
      SelectArr:[{
        ix:0,
        active:false,
        datas:[{id:0,group_name:"默认分组"}]
      },{
        ix:0,
        active:false,
        datas:["经销代理","高级定制"]
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      }],
      FormDatas:{
        logo:null,
        name:null,
        deduct:null,
        username:null,
        password:null,
        linkman:null,
        linkphone:null,
        linkaddress:null,
        intro:null,
      },
      ModelShow:false,
      pid:0, //分组id
      imgsList:null
    }
  },
  created () {
    self = this;
    this.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.$route.query.id){
      self.id = self.$route.query.id;
      self.column[1] = {name:'修改组织',url:'OrganizationEdit?id='+self.$route.query.id};
      self.GetData();
    }else{
      self.column[1] = {name:'添加组织',url:'OrganizationEdit'};
    }
  },
  mounted () {
    self.GetImgsList();
    self.GetAddress(); //获取地区
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    SelectData (ix,six,id,rank) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if(ix == 2){  //选中省清除市和区的内容并重置索引
        self.FormDatas.province_id = id  //省id
        self.FormDatas.city_id = null
        self.FormDatas.area_id = null
        self.SelectArr[3].ix = 0;
        self.SelectArr[3].datas = null;
        self.SelectArr[4].ix = 0;
        self.SelectArr[4].datas = null;
      }else if(ix == 3){  //选中市清除区的内容并重置索引
        self.FormDatas.city_id = id;  //市id
        self.FormDatas.area_id = null
        self.SelectArr[4].ix = 0;
        self.SelectArr[4].datas = null;
      }else if(ix == 4){
        self.FormDatas.area_id = id  //县区id
      }

      //地址选择
      if(ix > 1 && ix < 4 && rank != 3 && id != 710000 && id != 810000 && id != 820000){
        self.GetAddress(rank,id);
      }
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
    },
    selectImg (img) {
      self.FormDatas.logo = img;
      self.HandleModel(false);
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    GetAddress (rank,id) {
      var aid = 0;
      var arank = 0;
      if(id){
        aid = id;
      }
      if(rank){
        arank = rank;
      }
      axios.get(self.GetServerUrl(1)+"address/"+aid)
      .then(function (response) {
          if(arank == 0){
            self.FormDatas.province_id = response.data.meta[0].address_id  //省id
            self.SelectArr[2].datas = response.data.meta
            self.GetAddress(response.data.meta[0].address_level,response.data.meta[0].address_id);  //获取市
          }else if(arank == 1){
            //如果不是直辖市获取县、区
            if(response.data.meta[0].address_level != 3){
              self.GetAddress(response.data.meta[0].address_level,response.data.meta[0].address_id);  //获取市
              self.FormDatas.city_id = response.data.meta[0].address_id;  //市id
              self.SelectArr[3].datas = response.data.meta
            }else{
              self.FormDatas.area_id = response.data.meta[0].address_id  //县区id
              self.SelectArr[4].datas = response.data.meta
            }
          }else if(arank == 2){
            self.FormDatas.area_id = response.data.meta[0].address_id  //县区id
            self.SelectArr[4].datas = response.data.meta
          }
      }).catch(function (response) {
        console.log("获取地址失败");
      })
    },
    SubmitForm () {

      if(!self.id && !self.FormDatas.username && !self.FormDatas.password && !self.FormDatas.name && !self.FormDatas.lon_lat){
        self.ToastFun(2,"*号必填项不能为空！");
        return;
      }

      //地址id特殊处理
      if(!self.FormDatas.city_id){
        self.FormDatas.city_id = self.FormDatas.province_id
      }
      if(!self.FormDatas.area_id){
        self.FormDatas.area_id = self.FormDatas.city_id
      }

      let type;
      if(self.id){
        type = "patch";
        self.FormDatas.id = self.FormDatas.id;
      }else{
        type = "post";
      }
      self.FormDatas.org_type = self.SelectArr[1].ix + 1;

      self.loading = true;
      axios({
        method:type,
        url:self.GetServerUrl(1)+"org",
        data:self.FormDatas
      }).then(function (response) {
        self.loading = false;
        javascript:history.back();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width: 300px;
  }
  .SelectStyle3{
    float: left;
    width:100px;
    margin-right:18px;
  }
  .SelectStyle3:last-child{
    margin-right: 0;
  }
  .FormStyleTwoRight {
    min-width: 450px;
  }
  .FormStyleTwoRight > .FormStyleTwo input,.FormStyleTwoRight > .FormStyleTwo textarea{
    width: 380px;
  }
  .FormStyleTwo textarea{
    height:39px;
  }
</style>
