<template>
	<div class="FullScreen" @click.stop="SelectActive(-1,$self)">

    <K-HeadBarTwo></K-HeadBarTwo>

    <section class="SiginBox">
      <div class="SiginBoxHead">
        <ul class="SiginBoxHeadMain NoSelect">
          <li class="SiginBoxHeadTxt" :class="{ActiveNav:ix == 0}">1 同意协议</li>
          <li class="SiginBoxHeadLine"></li>
          <li class="SiginBoxHeadTxt" :class="{ActiveNav:ix == 1}">2 账号注册</li>
        </ul>
      </div>

      <div class="SiginMain">

        <!-- 协议容器 -->
        <div class="SiginMainOne SiginMainOneCenter agreementBox" v-if="ix == 0">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!agreement"></K-Transition>
          <div v-else v-html="agreement"></div>
        </div>

        <!-- 注册容器 -->
        <div class="SiginMainTwo SiginMainOneCenter" v-if="ix == 1">
          <ul class="FormStyleOne">
            <li class="FormStyleOneTitle"><i class="notice">*</i>商户类型</li>
            <li>
              <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix]}}</div>
                <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                  <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                  </ul>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
              <p class="FormStyleOneHint">不同的商户类型，功能也会有所差别</p>
            </li>
          </ul>
          <ul class="FormStyleOne" style="padding-right:100px;">
            <li class="FormStyleOneTitle"><i class="notice">*</i>用户名</li>
            <li>
              <p><input v-model="datas.username" /></p>
              <p class="FormStyleOneHint">平台的账号(最长48位)</p>
            </li>
          </ul>
          <ul class="FormStyleOne">
            <li class="FormStyleOneTitle"><i class="notice">*</i>登录密码</li>
            <li>
              <p><input type="password" v-model="datas.password" /></p>
              <p class="FormStyleOneHint">字母、数字或者英文符号，最短6位，区分大小写</p>
            </li>
          </ul>
          <ul class="FormStyleOne">
            <li class="FormStyleOneTitle"><i class="notice">*</i>确认密码</li>
            <li>
              <p><input type="password" v-model="datas.password_confirmation" /></p>
              <p class="FormStyleOneHint">字母、数字或者英文符号，最短6位，区分大小写</p>
            </li>
          </ul>
          <ul class="FormStyleOne" style="padding-right:100px;">
            <li class="FormStyleOneTitle"><i class="notice">*</i>邮箱</li>
            <li>
              <p><input v-model="datas.email" type="email" /></p>
              <p class="FormStyleOneHint">可用于找回密码或接收系统推送信息</p>
            </li>
          </ul>

          <!-- 企业 -->
          <div class="boxTop" v-if="SelectArr[0].ix == 0">
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle"><i class="notice">*</i>商户名称</li>
              <li>
                <p><input v-model="datas.name" /></p>
                <p class="FormStyleOneHint">商户名称必须与营业执照上一致</p>
              </li>
            </ul>
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle"><i class="notice">*</i>单位住所</li>
              <li>
                <p><input v-model="datas.baddress" /></p>
                <p class="FormStyleOneHint">单位住所必须与营业执照上一致</p>
              </li>
            </ul>
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle"><i class="notice">*</i>信用代码</li>
              <li>
                <p><input v-model="datas.organization_code" /></p>
                <p class="FormStyleOneHint">请填写营业执照上的信用代码</p>
              </li>
            </ul>
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle"><i class="notice">*</i>营业执照</li>
              <li>
                <p class="FormStyleOneImgs">
                  <a class="UploadBtn">
                    <div class="UploadFinishImg backImg" v-if="blicenseImg" :style="{backgroundImage:'url('+blicenseImg+')'}"></div>
                    <input type="file" class="upload" @change="AddImg(1)" ref="blicense" accept="image/png,image/jpeg,image/gif,image/jpg"/>
                  </a>
                  <i class="UploadHint redColor" v-if="blicenseError">上传非图片格式</i>
                </p>
                <p class="FormStyleOneHint">请上传小于 2M，格式为：jpg、png的营业执照图片</p>
              </li>
            </ul>
            <!-- <ul class="FormStyleOne">
              <li class="FormStyleOneTitle">法人身份证正反面</li>
              <li>
                <p class="FormStyleOneImgs">
                  <a></a>
                  <a></a>
                </p>
                <p class="FormStyleOneHint">请上传小于 2M，格式为：jpg、png的身份证正反面图片</p>
              </li>
            </ul> -->
          </div>

          <!-- 个人 -->
          <div class="boxTop" v-else>
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle"><i class="notice">*</i>商户名称</li>
              <li>
                <p><input v-model="datas.name" /></p>
                <p class="FormStyleOneHint">商户名称是唯一不可重复的，如侵犯他人商标名称可能会被强制更改</p>
              </li>
            </ul>
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle"><i class="notice">*</i>身份证号</li>
              <li>
                <p><input v-model="datas.idcard" /></p>
                <p class="FormStyleOneHint">请填写负责人的身份证号码</p>
              </li>
            </ul>
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle"><i class="notice">*</i>手持身份证</li>
              <li>
                <p class="FormStyleOneImgs">
                  <a class="UploadBtn">
                    <div class="UploadFinishImg backImg" v-if="idcardImg" :style="{backgroundImage:'url('+idcardImg+')'}"></div>
                    <input type="file" class="upload" @change="AddImg(2)" ref="idcardImg" accept="image/png,image/jpeg,image/gif,image/jpg"/>
                  </a>
                  <i class="UploadHint redColor" v-if="idcardError">上传非图片格式</i>
                </p>
                <p class="FormStyleOneHint">请上传小于 2M，格式为：jpg、png的手持身份证照片</p>
              </li>
            </ul>
          </div>

          <ul class="FormStyleOne">
            <li class="FormStyleOneTitle">LOGO</li>
            <li>
              <p class="FormStyleOneImgs">
                <a class="UploadBtn">
                  <div class="UploadFinishImg backImg" v-if="logoImg" :style="{backgroundImage:'url('+logoImg+')'}"></div>
                  <input type="file" class="upload" @change="AddImg(3)" ref="logo" accept="image/png,image/jpeg,image/gif,image/jpg"/>
                </a>
                <i class="UploadHint redColor" v-if="logoError">上传非图片格式</i>
              </p>
              <p class="FormStyleOneHint">可上传小于 2M，格式为：jpg、png的商户LOGO图片</p>
            </li>
          </ul>

          <!-- 企业 -->
          <div class="boxTop" v-if="SelectArr[0].ix == 0">
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle"><i class="notice">*</i>单位性质</li>
              <li>
                <div class="SelectStyle" @click.stop="SelectActive(1,$self)" v-if="SelectArr[1].datas">
                  <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix].unit_type_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[1].datas.length > 6}">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.unit_type_name" @click="SelectData(1,index,item.code)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <p class="FormStyleOneHint">不同的行业类型所需要填写的资质不一样，生码规则也不一样请选择跟营业执照相关的行业类型</p>
              </li>
            </ul>
          </div>

          <ul class="FormStyleOne">
            <li class="FormStyleOneTitle"><i class="notice">*</i>所属行业</li>
            <li>
              <div class='SelectStyleArr'>
                <div class="SelectStyle SelectStyle2" v-if="!SelectArr[2].datas">
                  选择行业
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle2" @click.stop="SelectActive(2,$self)" v-if="SelectArr[2].datas">
                  <div class="SelectStyleValue">{{SelectArr[2].datas[SelectArr[2].ix].trade_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[2].datas.length > 6}">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.trade_name" @click="SelectData(2,index,item.trade_id,item.trade_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle2" @click.stop="SelectActive(3,$self)" v-if="SelectArr[3].datas">
                  <div class="SelectStyleValue">{{SelectArr[3].datas[SelectArr[3].ix].trade_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[3].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[3].datas.length > 6}" v-if="SelectArr[3].datas.length">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[3].datas" v-bind:key="index" v-text="item.trade_name" @click="SelectData(3,index,item.trade_id,item.trade_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="clear"></div>
              </div>
              <p class="FormStyleOneHint">请选择正确的行业，不同的行业功能会有所差异</p>
            </li>
          </ul>
          <ul class="FormStyleOne">
            <li class="FormStyleOneTitle"><i class="notice">*</i>所在地区</li>
            <li>
              <div class='SelectStyleArr'>
                <div class="SelectStyle SelectStyle3" v-if="!SelectArr[4].datas">
                  选择地区
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(4,$self)" v-if="SelectArr[4].datas">
                  <div class="SelectStyleValue">{{SelectArr[4].datas[SelectArr[4].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[4].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[4].datas.length > 6}">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[4].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(4,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(5,$self)" v-if="SelectArr[5].datas">
                  <div class="SelectStyleValue">{{SelectArr[5].datas[SelectArr[5].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[5].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[5].datas.length > 6}" v-if="SelectArr[5].datas.length">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[5].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(5,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(6,$self)" v-if="SelectArr[6].datas">
                  <div class="SelectStyleValue">{{SelectArr[6].datas[SelectArr[6].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[6].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[6].datas.length > 6}" v-if="SelectArr[6].datas">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[6].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(6,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="clear"></div>
              </div>
              <p class="FormStyleOneHint">请选择商户所属地区</p>
            </li>
          </ul>
          <ul class="FormStyleOne">
            <li class="FormStyleOneTitle"><i class="notice">*</i>联系人名称</li>
            <li>
              <p><input v-model="datas.linkman" /></p>
              <p class="FormStyleOneHint">2-50位（数字、字母、字）</p>
            </li>
          </ul>
          <ul class="FormStyleOne" style="padding-right:100px;">
            <li class="FormStyleOneTitle"><i class="notice">*</i>联系手机号</li>
            <li>
              <p><input v-model="datas.linkphone" /></p>
              <p class="FormStyleOneHint">填写完手机号后点击后面的短信验证按钮，获取短信验证码</p>
            </li>
            <li class="FormStyleOneBtn btnStyleOne" @click="GetCode" v-if="CodeTime <= 1">短信验证</li>
            <li class="FormStyleOneBtn btnStyleOne CodeTime" v-text="CodeTime+'s'" v-else></li>
          </ul>
          <ul class="FormStyleOne">
            <li class="FormStyleOneTitle"><i class="notice">*</i>短信验证码</li>
            <li>
              <p><input v-model="datas.verification_code" /></p>
              <p class="FormStyleOneHint">请回填短信中的6位验证码</p>
            </li>
          </ul>
          <!-- <ul class="FormStyleOne">
            <li class="FormStyleOneTitle">验证码</li>
            <li>
              <p><input /></p>
              <p class="FormStyleOneHint">点击验证码图片可换一张</p>
            </li>
            <li class="FormStyleOneCode"><img src="static/images/code.png"></li>
          </ul> -->
        </div>

        <!-- 套餐容器 -->
        <div class="SiginMainFour" v-if="ix == 2">
          <div class="SiginMainFourBox" :class="{SetMealActive:MealIndex == index}" v-for="(item,index) in SetMeal" v-bind:key="index" @click="SetMealStyle(index)">
            <ul>
              <li class="SiginMainFourHead">
                <p class="icon iconfont SiginMainFourHeadIcon" :class="item.icon"></p>
                <p class="SiginMainFourHeadTitle" v-text="item.name"></p>
                <p class='SiginMainFourHeadHint' v-text="item.instructions"></p>
              </li>
              <li class='SiginMainFourFoot'>
                <p class="SiginMainFourHeadText"><i v-if="index < 3">￥</i>{{item.intro}}</p>
                <p class="SiginMainFourHeadTxt">生码量 <a v-if="item.code_limit == -1">无限</a><a v-else>{{item.code_limit}}</a> 枚</p>
                <p class="SiginMainFourHeadTxt">素材空间 {{item.space_limit}}</p>
                <p class="SiginMainFourHeadBtn">{{$t('c.Common.Buy')}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 套餐支付 -->
       <div class="SiginMainFive" v-if="ix == 3">
          <div class="FunctionPackageRow FunctionPackageRight">
            <div class="FunctionPackageRightTitle"><span class="icon iconfont iconweixin"></span>微信支付</div>
            <div class="FunctionPackageRightDate" v-if="countdown > 0"><a>订单到期时间倒计时</a><a v-text="countdown" class="countdown"></a><a>秒</a></div>
            <div class="FunctionPackageRightDate" v-if="countdown == 'timeOver'"><a class="redTwoColor">注意：该订单二维码已失效</a></div>
            <div class="FunctionPackageRightQr"><img :src="payQR"></div>
            <div class="FunctionPackageRightMoney">微信扫码支付<a v-text="payDatas.order_amount"></a>元</div>
          </div>
       </div>

      <!-- 底部按钮 -->
      <div class="SiginButton" v-if="agreement">
        <div class="SiginButtonBox">
          <a v-if="ix != 2" class="SiginButtonLeft btnStyleOne" @click="ClickBtn(false,ix)">上一步</a>
          <a class="SiginButtonRight" v-for="(item,index) in btnArr" v-bind:key="index" v-text="item" v-if="index == ix" @click="ClickBtn(true,index)"></a>
        </div>
      </div>

      <!-- loading -->
      <K-Loading v-if="loading"></K-Loading>

      <!-- toast -->
      <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

      <!-- 确认框 -->
      <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

    </section>
	</div>
</template>

<script>
var self,Interval;
import axios from 'axios'
import HeadBarTwo from '@/components/common/HeadBarTwo.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Transition from '@/components/common/Transition.vue'
import Confirm from '@/components/common/Confirm.vue'
import QRCode from 'qrcode'
export default {
  name: 'VideosSpace',
  components: {
    'K-HeadBarTwo': HeadBarTwo,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Confirm': Confirm,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      ix: 0,
      isLoding:true,
      subHint:'数据加载中...',
      agreement:null,
      btnArr: ["同意并下一步","注册","确定"],
      money:0,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      CodeTime:1,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["企业","个人"]
      },{
        ix:0,
        active:false,
        datas:["请选择行业类型","农药","医疗","其他"]
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      }],
      MealIndex:0,
      SetMeal: null,
      blicenseImg:null,
      blicenseError:false,
      idcardImg:null,
      idcardError:false,
      logoImg:null,
      logoError:false,
      payQR:null,
      payDatas:{
        code_url:null,
        order_amount:null
      },
      datas:{
        btype:1,
        username:null,
        password:null,
        password_confirmation:null,
        trade_id:null,
        email:null,
        name:null,
        code:1,
        province_id:null,
        city_id:null,
        area_id:null,
        linkman:null,
        linkphone:null,
        logo:null,
        idcard:null,
        idcard_img:null,
        verification_code:null,
        blicense:null,
        baddress:null,
        organization_code:null
      },
      countdown:null
    }
  },
  created () {
    self = this;
    self.$self = this;
  },
  mounted () {
    self.GetData(); //获取协议内容
    self.GetUnittypes(); //获取单位性质
    self.GetAddress(); //获取地区
    self.GetTrade(); //获取行业
    self.GetPackage(); //获取套餐
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    GetData () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"config/agreement")
      .then(function (response) {
        self.isLoding = false;
        self.agreement = response.data.meta.config_value; //渲染内容
      }).catch(function (error) {
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    GetUnittypes () {
      axios.get(self.GetServerUrl(1)+"unittypes/")
      .then(function (response) {
        self.SelectArr[1].datas = response.data.meta

        //填充默认单位性质ID
        self.datas.code = response.data.meta[0].code;
      }).catch(function (error) {
        console.log("获取单位性质失败");
      })
    },
    GetTrade (rank,id) {
      var tid = 0;
      var trank = 0;
      if(id){
        tid = id;
      }
      if(rank){
        trank = rank;
      }
      axios.get(self.GetServerUrl(1)+"trade/"+tid+"/"+self.datas.btype)
      .then(function (response) {
          if(trank == 0){
            self.SelectArr[2].datas = response.data.meta
            self.GetTrade(response.data.meta[0].trade_level,response.data.meta[0].trade_id);
          }else{
            self.SelectArr[3].datas = response.data.meta
          }

          //填充默认行业ID
          self.datas.trade_id = response.data.meta[0].trade_id;
      }).catch(function (error) {
        console.log("获取行业失败");
      })
    },
    GetAddress (rank,id) {
      var aid = 0;
      var arank = 0;
      if(id){
        aid = id;
      }
      if(rank){
        arank = rank;
      }
      axios.get(self.GetServerUrl(1)+"address/"+aid)
      .then(function (response) {
          if(arank == 0){
            self.datas.province_id = response.data.meta[0].address_id  //省id
            self.SelectArr[4].datas = response.data.meta
            self.GetAddress(response.data.meta[0].address_level,response.data.meta[0].address_id);  //获取市
          }else if(arank == 1){
            //如果不是直辖市获取县、区
            if(response.data.meta[0].address_level != 3){
              self.GetAddress(response.data.meta[0].address_level,response.data.meta[0].address_id);  //获取市
              self.datas.city_id = response.data.meta[0].address_id;  //市id
              self.SelectArr[5].datas = response.data.meta
            }else{
              self.datas.area_id = response.data.meta[0].address_id  //县区id
              self.SelectArr[6].datas = response.data.meta
            }
          }else if(arank == 2){
            self.datas.area_id = response.data.meta[0].address_id  //县区id
            self.SelectArr[6].datas = response.data.meta
          }
      }).catch(function (response) {
        console.log("获取地址失败");
      })
    },
    GetPackage () {
      axios.get(self.GetServerUrl(1)+"package")
      .then(function (response) {
        self.SetMeal = response.data.meta
      }).catch(function (error) {
        console.log("获取套餐失败");
      })
    },
    ClickBtn (te,ix) {
      if(te){
        if(ix == 0){
          self.ix = ++ix;
        }else if(ix == 1){
          self.loading = true;

          //地址id特殊处理
          if(!self.datas.city_id){
            self.datas.city_id = self.datas.province_id
          }
          if(!self.datas.area_id){
            self.datas.area_id = self.datas.city_id
          }

          //FormDatas格式提交
          let FormDatas = new FormData();
          FormDatas.append("from_type","pc");
          
          
          for(var key in self.datas){
            if(self.datas[key]){
              FormDatas.append(key,self.datas[key]);
            }
          }

          //上级代码
          var url = window.location.href; /* 获取完整URL */
          var fix = url.indexOf("//")+2;
          var lix = url.indexOf(".");
          if(url.substring(fix,lix) == "sys"){
            FormDatas.append("org_id",1001);
          }else if(url.substring(fix,lix) == "syn"){
            FormDatas.append("org_id",1004);
          }
          const params = this.GetParams();
          if(params.id){
            FormDatas.append("org_id",params.id);
          }

          let URL;
          if(self.datas.btype == 1){
            URL = "business_register"
          }else{
            URL = "personal_register"
          }

          axios({
            method:"post",
            url:self.GetServerUrl(1)+URL,
            headers: {
              "Content-Type": "multipart/form-data"
            },
            data:FormDatas
          }).then(function (response) {
              console.log(response);
              self.loading = false;
              self.ToastFun(0,response.data.message);  //弹窗提醒
              axios.defaults.headers.common['token'] = response.data.meta.token;

              localStorage.setItem("wwmxt_Token",response.data.meta.token);  //token
              localStorage.setItem("wwmxt_Role",response.data.meta.user.role);  //角色类型
              localStorage.setItem("wwmxt_Logo",response.data.meta.user.logo);  //LOGO
              localStorage.setItem("wwmxt_Name",response.data.meta.detail.name);  //名称
              localStorage.setItem("wwmxt_OID",response.data.meta.detail.company_OID);  //OIDID
              localStorage.setItem("wwmxt_TradeID",response.data.meta.detail.trade_id);  //行业ID
              localStorage.setItem("wwmxt_Rootid",response.data.meta.detail.rootid);  //用户ID
              
              if(response.data.meta.detail.trade_info){
                localStorage.setItem("wwmxt_TradeType",response.data.meta.detail.trade_info.trade_type);  //行业ID
              }

              //直接进入
              localStorage.setItem("wwmxt_HistoryURL","HomePage");  //左侧导航焦点样式
              self.$router.push({path:'/BusinessHomePage'}); // 路由跳转
          }).catch(function (error) {
              self.loading = false;
              self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
          })

        }else{
          self.BuyPackage();
        }
      }else{
        if(ix != 0){
          //关闭检测定时器
          if(Interval){
            clearInterval(Interval);
          }
          self.ix = --ix;
        }else{
          javascript:history.back();
        }
      }
    },
    SelectData (ix,six,id,rank) {
      self.SelectArr[ix].ix = six;
      if(ix == 0){
        self.datas.btype = ++six;

        //重置行业ID
        self.SelectArr[2].ix = 0;
        self.SelectArr[3].ix = 0;

        self.GetTrade();
      }else if(ix == 1){
        self.datas.code = id;
      }else if(ix == 2){  //选中行业
        self.datas.trade_id = id  //行业id
        self.SelectArr[3].ix = 0;
        self.SelectArr[3].datas = null;
        self.GetTrade(rank,id);
      }if(ix == 3){  //选中行业
        self.datas.trade_id = id  //行业id
      }else if(ix == 4){  //选中省清除市和区的内容并重置索引
        self.datas.province_id = id  //省id
        self.datas.city_id = null
        self.datas.area_id = null
        self.SelectArr[5].ix = 0;
        self.SelectArr[5].datas = null;
        self.SelectArr[6].ix = 0;
        self.SelectArr[6].datas = null;
      }else if(ix == 5){  //选中市清除区的内容并重置索引
        self.datas.city_id = id;  //市id
        self.datas.area_id = null
        self.SelectArr[6].ix = 0;
        self.SelectArr[6].datas = null;
      }else if(ix == 6){
        self.datas.area_id = id  //县区id
      }

      //地址选择
      if(ix > 3 && ix < 6 && rank != 3 && id != 710000 && id != 810000 && id != 820000){
        self.GetAddress(rank,id);
      }
    },
    AddImg (te) {
      let inputDOM;
      if(te == 1){
        inputDOM = this.$refs.blicense;
      }else if(te == 2){
        inputDOM = this.$refs.idcardImg;
      }else{
        inputDOM = this.$refs.logo;
      }
      let file = inputDOM.files[0];
      var type = file.type.split('/')[0];
      let size = Math.floor(file.size / 1024);

      if ( type === 'image' ){
        //将图片img转化为base64
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          if(te == 1){  //营业执照
            self.blicenseError = false;
            self.datas.blicense = file;
            self.blicenseImg = reader.result;
          }else if(te == 2){  //手持身份证照
            self.idcardError = false;
            self.datas.idcard_img = file;
            self.idcardImg = reader.result;
          }else{  //商户logo
            self.logoError = false;
            self.datas.logo = file;
            self.logoImg = reader.result;
          }
        }
      }else{
        if(te == 1){
          self.blicenseError = true;
        }else{
          self.idcardError = true;
        }
      }
    },
    GetCode () {
      if(!self.datas.linkphone){
        self.ToastFun(2,"手机号码不能为空！");  //弹窗提醒
        return;
      }
      self.CodeTime = 60;
      let timer = setInterval(function(){
        self.CodeTime--;
        if(self.CodeTime <= 1){
          clearInterval(timer);
        }
      },1000)
      axios.post(self.GetServerUrl(1)+"verif/",{btype:self.datas.btype,mobile:self.datas.linkphone})
      .then(function (response) {
          self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error) {
        console.log("获取地址失败");
      })
    },
    SetMealStyle (ix) {
      self.MealIndex = ix;
    },
    BuyPackage () {
      let rule = self.SetMeal[self.MealIndex].rule;
      let id = self.SetMeal[self.MealIndex].package_id;

       //清除检查定时器
      if(Interval){
        clearInterval(Interval);
      }
      self.loading = true;
      axios.post(self.GetServerUrl(1)+"user_package",{package_id:id})
      .then(function (response) {
        self.loading = false;

        if(rule < 1){  //免费试用
          localStorage.setItem("wwmxt_HistoryURL","HomePage");  //左侧导航焦点样式
          self.$router.push({path:'/BusinessHomePage'}); // 路由跳转
        }else{  //正常套餐购买
          self.ix++
          self.payDatas = response.data.meta;

          //开始倒计时
          if(response.data.meta.pay_end_time){
            let oid = response.data.meta.orderid;
            let time = response.data.meta.pay_end_time;
            Interval = setInterval(function(){
              let dateArr = self.GetRTime(time*1000);
              if(dateArr != "timeOver" && dateArr.length >= 4){
                self.countdown = dateArr[2]*60 + dateArr[3];

                //三秒检测一次订单状态
                if(dateArr[3] % 3 == 0){
                  self.CheckPayState(oid);
                }

              }else{
                self.countdown = "timeOver";
                clearInterval(Interval);
              }
            },1000)
          }

          self.CreatePayQR(response.data.meta);
        }
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    CreatePayQR (datas) {
      QRCode.toDataURL(datas.code_url,{errorCorrectionLevel:"H",version: 5},
      function(err,url){
        if(!err){
          self.payQR = url;
        }
      })
    },
    GetRTime (endTime) {
      var EndTime = new Date(endTime);
      var NowTime = new Date();
      var t = EndTime.getTime() - NowTime.getTime();
      var d = 0;
      var h = 0;
      var m = 0;
      var s = 0;
      if(t >= 0) {
        d = Math.floor(t / 1000 / 60 / 60 / 24);
        h = Math.floor(t / 1000 / 60 / 60 % 24);
        m = Math.floor(t / 1000 / 60 % 60);
        s = Math.floor(t / 1000 % 60);
      }else{
        return 'timeOver'
      }
      return [d,h,m,s]
    },
    CheckPayState (oid) {
      axios.get(self.GetServerUrl(1)+"order/"+oid)
      .then(function (response) {

        if(response.data.meta.pay_status == 1){
          clearInterval(Interval);  //删除定时器

          self.Confirm.style = 1;
          self.Confirm.state = true;
          self.Confirm.txt = "套餐购买成功，是否立即前往体验？"

          //清除订单历史
          for(let item in self.payDatas){
            self.payDatas[item] = null;
          }
        }else{
          console.log("支付未成功！");
        }

      }).catch(function (error){
        console.log("支付未成功！");
      })
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        localStorage.setItem("wwmxt_HistoryURL","HomePage");  //左侧导航焦点样式
        self.$router.push({path:'/BusinessHomePage'}); // 路由跳转
      }else{
        javascript:history.back();
      }
    }
  }
}
</script>

<style scoped>
  .FullScreen{
    margin:-60px 0 0 0;
  }
  .boxTop{
    margin-top:30px;
  }
  .FormStyleOne input,.SelectStyle{
    width: 380px;
  }
  .SelectStyle2{
    float: left;
    width:170px;
    margin-right:18px;
  }
  .SelectStyle2:last-child{
    margin-right: 0;
  }
  .SelectStyle3{
    float: left;
    width:100px;
    margin-right:18px;
  }
  .SelectStyle3:last-child{
    margin-right: 0;
  }

  .SiginBox{
    background-color: #FFF;
    margin:85px 25px 25px 25px;
    border-radius: 5px;
    border: 1px solid #E4ECF2;
    box-shadow:0 0 4px rgba(0,0,0,0.1);
  }
  .SiginMain{
    max-width: 1200px;
    margin:0 auto;
  }

  .SiginMainOneCenter{
    display: table;
    margin:0 auto;
    padding:50px 20px;
  }
  .SiginMainFour{
    margin:50px 0;
    overflow: hidden;
  }
  .SiginMainFourBox{
    float: left;
    width:25%;
    text-align: center;
  }
  .SiginMainFourBox ul{
    margin:0 10px;
    cursor: pointer;
    border:1px solid #E9E9E9;
  }
  .SiginMainFourHead{
    padding:25px;
    background-color: #EEE;
  }
  .SiginMainFourHeadIcon{
    color:#646B71;
    font-size:40px;
    margin-bottom:15px;
  }
  .SiginMainFourHeadTitle{
    font-size:16px;
    color:#5F5D5D;
    margin-bottom:10px;
  }
  .SiginMainFourHeadHint{
    color:#8B8B8C;
  }
  .SiginMainFourHeadText{
    font-size:18px;
    padding:25px 0;
    color:#5F5D5D;
  }
  .SiginMainFourHeadText i{
    font-size:13px;
  }
  .SiginMainFourHeadTxt{
    line-height:25px;
    color:#8B8B8C;
  }
  .SiginMainFourHeadBtn{
    padding:10px 15px;
    display: table;
    margin:20px auto;
    border:1px solid #D8E4ED;
    border-radius: 5px;
    color:#8B8B8C;
    cursor: pointer;
  }

  .SiginMainFourFoot{
    padding-bottom:10px;
  }
  .SetMealActive ul{
    border:1px solid #3595FF;
    box-shadow:0 0 10px rgba(53,149,255,0.5);
  }
  .SetMealActive > ul > .SiginMainFourHead{
    background-color: #3595FF;
  }
  .SetMealActive > ul > .SiginMainFourHead p{
    color:#FFF;
  }
  .SetMealActive > ul > .SiginMainFourFoot > .SiginMainFourHeadText{
    color:#3595FF;
  }
  .SetMealActive > ul > .SiginMainFourFoot > .SiginMainFourHeadBtn{
    color:#3595FF;
    border:1px solid #3595FF;
  }
  .SetMealActive > ul > .SiginMainFourFoot > .SiginMainFourHeadTxt{
    color:#5F5D5D;
  }
  .FormStyleOneCode{
    position: absolute;
    right:0;
    top:1px;
    width:100px;
  }
  .FormStyleOneCode img{
    display: block;
    width:100%;
  }
  .FunctionPackageRow{
    display: table;
    margin:0 auto;
  }
  .CodeTime{
    width:24px;
  }
</style>
