import axios from 'axios';

//获取tablelist
function getList(_url) {
    const self = this;
    this.isLoding = true;
    axios.get(this.GetServerUrl(1) + _url + this.page)
    .then(function (response) {
        self.isLoding = false;
        self.datas = response.data.meta.data;
        self.totalPages = response.data.meta.last_page;  //总页数
        
        //农药行业-产品别名
        if (self.tradeType == 1) {
            for (let i in self.datas) {
                if (self.datas[i].nyname) {
                    continue;
                }
                for (let x in self.selectList[self.pix].datas) {
                    if (self.datas[i].production_id == self.selectList[self.pix].datas[x].production_id) {
                        self.datas[i].nyname = self.selectList[self.pix].datas[x].nyname;
                    }
                }
            }
        }
        
    }).catch(function (error) {
        self.isLoding = false;
        self.subHint = self.ErrorHint(error);
    })
}

//下拉框列表
function selectList(ix, _method, _url, _data, resolve, reject) {
    const self = this;
    //如果是get加上页码
    if (_method == 'get') {
        _url = _url + "/" + this.selectList[ix].page
    }
    axios({
        method: _method,
        url: this.GetServerUrl(1) + _url,
        data: _data
    }).then(function (response) {

        //处理POST和GET返回层级不同
        let json = response.data.meta;
        if (_method == 'get') {
            json = response.data.meta.data;
            self.selectList[ix].totalPages = response.data.meta.last_page;
        }
        self.selectList[ix].datas = json;

        //商品名称别名
        if (_url === "production_list_condition") {
            for (let i in self.selectList[ix].datas) {
                let datas = self.selectList[ix].datas[i];
                if (self.tradeType == 1) {
                    datas.nyname = datas.name + '(' + datas.product_code + '、' + datas.unit + '、' + datas.reg_code + '、' + datas.alias_name + ')'
                } else {
                    datas.nyname = datas.name
                }
            }
        }

        if (resolve) resolve(response); //成功回调
    }).catch(function (error) {
        console.log(error)
        if (reject) reject(error); //失败回调
    })
}

//下拉框搜索内容
function searchSelectData(ix, _url, resolve, reject) {
    const self = this;
    this.selectList[ix].page = 1; //页数重置
    this.loading = true;
    axios({
        method: "post",
        url: this.GetServerUrl(1) + _url,
        data: { name: this.keyword } //搜索内容
    }).then(function (response) {
        self.loading = false;
        self.selectList[ix].ix = 0; //重置选中索引
        self.selectList[ix].totalPages = response.data.meta.last_page;
        self.selectList[ix].datas = response.data.meta.data;

        //商品名称别名
        if (_url === "production_list_condition") {
            for (let i in self.selectList[ix].datas) {
                let datas = self.selectList[ix].datas[i];
                if (self.tradeType == 1) {
                    datas.nyname = datas.name + '(' + datas.product_code + '、' + datas.unit + '、' + datas.reg_code + '、' + datas.alias_name + ')'
                } else {
                    datas.nyname = datas.name
                }
            }
        }

        if (resolve) resolve(response); //成功回调
    }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
        if (reject) reject(error); //失败回调
    })
}

//下拉框加载更多
function selectLoadMore(ix, _url, resolve, reject) {
    const self = this;
    this.selectList[ix].page++; //页数++
    let _data = {};
    if (this.keyword) {
        _data.name = this.keyword;
    }
    this.loading = true;
    axios({
        method: "post",
        url: this.GetServerUrl(1) + _url + "/" + this.selectList[ix].page,
        data: _data
    }).then(function (response) {
        self.loading = false;

        let json = response.data.meta.data;
        for (let item in json) {
            self.selectList[ix].datas.push(json[item]);
        }

        if (resolve) resolve(response); //成功回调
    }).catch(function (error) {
        self.loading = false;
        if (reject) reject(error); //失败回调
    })
}

export default {
    getList,
    selectList,
    searchSelectData,
    selectLoadMore
}