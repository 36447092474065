<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>添加{{ keyname }}</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>{{ keyname }}名称</th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,item)" v-text="$t('c.Common.Change')"></a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.licensor_id)" v-text="$t('c.Common.Delete')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 品牌添加修改 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{ keyname }}编辑
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">{{keyname}}名称</p>
                <p><input :placeholder="`请输入${keyname}名称`" v-model="licensorName" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'Brand',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      keyname: '授权',
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      licensorId:null,
      LicensorName:null,
      datas:null,
      ModelShow:false,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      isHover:false,
      SelectArr:[{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      }]
    }
  },
  created () {
    self = this;
    this.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    const orgid = localStorage.getItem("wwmxt_Rootid").substring(0, 4);
    const keynameObj = {
      1000: '授权',
      1001: '授权',
      1002: '产地位置',
      1009: '产地位置',
    }
    this.keyname = keynameObj[orgid] || '授权';
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HandleModel (status,item) {  //隐藏显示模态框
      self.ModelShow = status;
      if(item){
        self.licensorId = item.licensor_id;
        self.licensorName = item.name;
      }else{
        self.licensorId = null;
        self.licensorName = null;
      }
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"licensor_list/"+self.page)
      .then(function (response){
          self.datas = response.data.meta.data;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    RemoveData (id) {
      self.licensorId = id;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该品牌？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"licensor/"+self.licensorId,
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    },
    SubmitForm() {  //提交表单self.FormDatas.id
      let datas = {};
      let _url = self.GetServerUrl(1)+"licensor/";
      let _method = "post";
      if(self.licensorId){
        _method = "patch";
        _url = self.GetServerUrl(1)+"licensor/";
        datas["licensor_id"] = self.licensorId;
      }
      datas["name"] = self.licensorName;
      self.loading = true;

      axios({
        method:_method,
        url:_url,
        data:datas
      }).then(function (response) {
          self.ModelShow = false;
          self.loading = false;
          self.ToastFun(0,response.data.message);  //弹窗提醒
          self.GetList();
      }).catch(function (error) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })

    }
  }
}
</script>

<style scoped>
  .ListBtnArray{
    overflow: hidden;
  }
  .ListBtnArray a{
    float: left;
    padding:10px 16px;
    border:1px solid #DDD;
    border-radius: 5px;
    margin-right:17px;
    color:#585A64;
    cursor: pointer;
    margin-top:15px;
  }
  .ListBtnArray a:nth-child(7n){
    margin-right:0;
  }
  .ListBtnActive{
    color:#278BF5 !important;
    border:1px solid #278BF5 !important;
  }
  .ListBtnArray a{
    margin-top:10px;
  }
</style>
