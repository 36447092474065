<template>
	<div class="MainBox">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('DealerEdit?type=add')"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input placeholder="输入商户名称查询" />
          <a class="SearchBtn">搜索</a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable">
        <thead>
          <tr>
            <th>接口请求次数</th>
            <th>类型</th>
            <th>商户名称</th>
            <th>接口地址</th>
            <th>生码地址</th>
            <th>拉黑原因</th>
            <th>申请时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.number"></td>
            <td v-text="item.te"></td>
            <td v-text="item.name"></td>
            <td v-text="item.portURL"></td>
            <td v-text="item.CreateCodeURL"></td>
            <td v-text="item.reason" class="redColor"></td>
            <td v-text="item.date"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor">启用</a>
                <a class="btnStyleTwo btnRadius cyanBackColor">详情</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'DeveloperList',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null
    }
  },
  created () {
    self = this;
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        number:1024,
        te:"企业",
        name:"黑拉吧唧",
        portURL:"https://api.heilabaji.con",
        CreateCodeURL:"https://heilabaji.con",
        reason:"依赖注入",
        date:'2019-09-08 13:22'
      },{
        number:654596,
        te:"个人",
        name:"虎拉吧唧",
        portURL:"https://api.hulabaji.con",
        CreateCodeURL:"https://hulabaji.con",
        reason:"恶意请求",
        date:'2019-09-08 13:22'
      }],
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    }
  }
}
</script>

<style scoped>

</style>
