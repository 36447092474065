<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 表单内容 -->
    <div class="box FloatBox">

      <!-- 左容器 -->
      <div class="MTELeft">
        <div class="Mobile">
          <div class="MobileTop">
            <a class="Camera"></a>
            <a class="Sensor"></a>
            <a class="Speaker"></a>
          </div>
          <div class="MobileBar" style="top:45px;"></div>
          <div class="on-off"></div>
          <div class="sleep"></div>
          <div class="up"></div>
          <div class="down"></div>
          <div class="MobileScreen">
            <div class="MobileHead">
              <div class="MobileStatusBar"><img src="static/images/statusBar.png"></div>
              <div class="MobileTitle">
                <p class="MobileTitleBack"><span class="icon iconfont iconicon-test24" style="font-size:10px;"></span>{{$t('c.Common.Back')}}</p>
                链接OID
                <span class="icon iconfont iconicon-test26 MobileTitleMore"></span>
              </div>
              <div class="MobileMain" v-if="iframeShow">
                <iframe id="iframeBox" :src="'https://lj.oid.plus/#/?tid='+templateID+'&tname='+templateName+'&pid='+productID+'&rid='+rootid" frameborder="0" width="100%" height="100%"></iframe>
              </div>
            </div>
          </div>
          <div class="MobileBar" style="bottom:50px;"></div>
          <div class="MobileBottom"><a><i></i></a></div>
        </div>
        <div class="ViewDemoQr" @mouseenter="DemoQrShow(true)" @mouseleave="DemoQrShow(false)">
          <a class="feedback">{{$t('b.ProductManage.SeeEffect')}}</a>
          <img v-if="DemoQRShow" class="DemoQrImg" :src="DemoQR">
        </div>
      </div>

      <!-- 右容器 -->
      <div class="MTERight">
        <div class="MTERightHeadBox">
          <div class="MTERightHeadLeft" @click="ClickNav('prev')"><span class="icon iconfont iconicon-test24"></span></div>
          <div class='MTERightHeadMain NoSelect swiper-container' id="NavList">
            <ul class="swiper-wrapper">
              <li class="swiper-slide" v-for="(item,index) in NavLists" v-bind:key="index" v-text="item.name" :class="{ActiveNav:currentIndex == index,feedback:currentIndex != index}" @click="ClickNav('to',index,item.te)"></li>
            </ul>
          </div>
          <div class="MTERightHeadRight" @click="ClickNav('next')"><span class="icon iconfont iconicon-test25"></span></div>
        </div>

        <div class="MTERightBox">
          <div class="MTERightOn-Off">
            <a>{{$t('b.ProductManage.IsShowModule')}}：</a>
            <a class="slidebtn slidebtnRight" :class="[{slidebtnClose:FormDatas[NavType] == 0},{slidebtnOpen:FormDatas[NavType] == 1}]" @click="SwicthSlide(NavType)"><i class="transition"></i></a>
          </div>

          <div class="MTERightMain">
            <div class="MTESaveBtn btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>{{$t('c.Common.Save')}}</div>

            <!-- 基本信息默认值 -->
            <div class="box FloatBox" v-if="NavType == 'basic_is_show'">
              <div class="FormStyleTwoLeft FloatBoxRow">
                <ul class="FormStyleTwo">
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>用户扫码定位方式（ip定位部分地区会有偏差）</p>
                    <p>
                      <div class="SelectStyle" style="width:278px" @click.stop="SelectActive(0,$self)">
                        <div>{{SelectArr[0].datas[SelectArr[0].ix]}}</div>
                        <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                        </ul>
                        <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
                      </div>
                    </p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>扫码次数（根据码标实际访问次数）</p>
                    <p><input style="width:278px" placeholder="当前为演示扫码次数" v-model="FormDatas.scan_count" /></p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>首次扫码时间（根据码标首次访问时间）</p>
                    <p><input style="width:278px" placeholder="当前不支持修改" disabled="disabled" /></p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>首次查询地点（根据码标首次访问地址）</p>
                    <p><input style="width:278px" placeholder="当前不支持修改" disabled="disabled" /></p>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 焦点图 -->
            <div class="box FloatBox" v-if="NavType == 'focus_pics_is_show'">
              <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="HandleModel(true,1)"><span class="icon iconfont iconicon-test20"></span>添加焦点图</div>

              <div class="CustomBannerBox" v-if="FormDatas.focus && FormDatas.focus.length > 0">
                <div class="CustomBannerRow" v-for="(item,index) in FormDatas.focus" v-bind:key="index">
                  <div class="backImg CustomBannerImg" v-if="item.img" :style="{backgroundImage: 'url(' + item.img + ')'}"></div>
                  <div class='backImg CustomBannerImg' v-else></div>

                  <div class="CustomBannerMain">
                    <p class="onlyOneRow" v-text="item.name"></p>
                    <p class="onlyOneRow" v-text="item.url"></p>
                  </div>

                  <ul class="CustomMenuBtnArr">
                    <li @click="HandleModel(true,1,item,index)">编辑</li>
                    <li @click="RemoveData(index,1)">删除</li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- 产品信息默认值 -->
            <div class="box FloatBox" v-if="NavType == 'prod_is_show'">
              <div class="FormStyleTwoLeft FloatBoxRow">
                <ul class="FormStyleTwo">
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle">产品名称</p>
                    <p class="FormStyleTwoRelative">
                      <input placeholder="根据码标实际激活产品" :value="FormDatas.product.name" disabled="disabled" />
                      <a class="FormStyleTwoRightBtn NoSelect" :class="{cyanBackColor:FormDatas.prod_name_is_show == 1,blueBackColor:FormDatas.prod_name_is_show == 0}" @click="SwicthSlide('prod_name_is_show')">
                        <i v-if="FormDatas.prod_name_is_show == 1">隐藏</i>
                        <i v-else>显示</i>
                      </a>
                    </p>
                  </li>
                  <li class="FormStyleTwoRow" v-if="FormDatas.product.period">
                    <p class="FormStyleTwoTitle">保质期</p>
                    <p class="FormStyleTwoRelative">
                      <input placeholder="根据码标实际激活产品" :value="FormDatas.product.period" disabled="disabled" />
                      <a class="FormStyleTwoRightBtn NoSelect" :class="{cyanBackColor:FormDatas.prod_period_is_show == 1,blueBackColor:FormDatas.prod_period_is_show == 0}" @click="SwicthSlide('prod_period_is_show')">
                        <i v-if="FormDatas.prod_period_is_show == 1">隐藏</i>
                        <i v-else>显示</i>
                      </a>
                    </p>
                  </li>
                  <li class="FormStyleTwoRow" v-if="FormDatas.product.unit">
                    <p class="FormStyleTwoTitle">规格</p>
                    <p class="FormStyleTwoRelative">
                      <input :value="FormDatas.product.unit" disabled="disabled" />
                      <a class="FormStyleTwoRightBtn NoSelect" :class="{cyanBackColor:FormDatas.prod_unit_is_show == 1,blueBackColor:FormDatas.prod_unit_is_show == 0}" @click="SwicthSlide('prod_unit_is_show')">
                        <i v-if="FormDatas.prod_unit_is_show == 1">隐藏</i>
                        <i v-else>显示</i>
                      </a>
                    </p>
                  </li>
                  <li class="FormStyleTwoRow" v-if="FormDatas.product.introduce">
                    <p class="FormStyleTwoTitle">描述</p>
                    <p class="FormStyleTwoRelative">
                      <textarea class="form-control" :value="FormDatas.product.introduce" disabled="disabled"></textarea>
                      <a class="FormStyleTwoRightBtn NoSelect" :class="{cyanBackColor:FormDatas.prod_desc_is_show == 1,blueBackColor:FormDatas.prod_desc_is_show == 0}" @click="SwicthSlide('prod_desc_is_show')">
                        <i v-if="FormDatas.prod_desc_is_show == 1">隐藏</i>
                        <i v-else>显示</i>
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 防伪信息 -->
            <div class="box FloatBox" v-if="NavType == 'antifake_is_show'">
              <div class="FormStyleTwoLeft FloatBoxRow">
                <ul class="FormStyleTwo">
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>是否显示明码</p>
                    <p>
                      <div class="SelectStyle" style="width:278px" @click.stop="SelectActive(5,$self)">
                        <div>{{SelectArr[5].datas[SelectArr[5].ix]}}</div>
                        <ul class="OptionStyleBox" v-if="SelectArr[5].active">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[5].datas" v-bind:key="index" v-text="item" @click="SelectData(5,index)"></li>
                        </ul>
                        <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
                      </div>
                    </p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>是否语音播报</p>
                    <p>
                      <div class="SelectStyle" style="width:278px" @click.stop="SelectActive(6,$self)">
                        <div>{{SelectArr[6].datas[SelectArr[6].ix]}}</div>
                        <ul class="OptionStyleBox" v-if="SelectArr[6].active">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[6].datas" v-bind:key="index" v-text="item" @click="SelectData(6,index)"></li>
                        </ul>
                        <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
                      </div>
                    </p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>防伪码</p>
                    <p><input placeholder="根据码标实际绑定为准" disabled="disabled" /></p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>首次查询语句</p>
                    <p>
                      <textarea class="form-control" v-model="FormDatas.first_antifake_msg" :placeholder="'默认：您查询的是 '+bname+' 的正牌产品，请放心使用!'"></textarea>
                    </p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>多次查询语句</p>
                    <p>
                      <textarea class="form-control" v-model="FormDatas.many_antifake_msg" placeholder="默认：您所查询的码标已被多次扫描，谨防假冒!"></textarea>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 批次信息 -->
            <div class="box FloatBox" v-if="NavType == 'batch_is_show'" style="height: 480px;">
              <div class="FormStyleTwoLeft FloatBoxRow">
                <ul class="FormStyleTwo">
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>批次（当前为演示选择，实际批次根据发货为准）</p>
                    <div v-if="SelectArr[4].datas && SelectArr[4].datas.length > 0">
                      <div class="SelectStyle" @click.stop="SelectActive(4,$self)">
                        {{SelectArr[4].datas[SelectArr[4].ix].name}}
                        <ul class="OptionStyleBox" v-if="SelectArr[4].active">
                          <li class='OptionStyleQuery' v-if="SelectArr[4].totalPages > 4" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery(1)">查询</a></li>
                          <ul class="OptionStyleMain">
                            <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[4].ix == index && !SelectArr[4].isHover}" v-for="(item,index) in SelectArr[4].datas" v-bind:key="index" v-text="item.name" @click="SelectData(4,index)" @mouseover="SelectHover($self,1,true)" @mouseout="SelectHover($self,1,false)"></li>
                            <li class="OptionStyleLoading" @click.stop="SelectArrLoading(1)" v-if="SelectArr[4].totalPages > 1 && SelectArr[4].page < SelectArr[4].totalPages">加载更多</li>
                            <li class="OptionStyleLoading" @click.stop v-if="SelectArr[4].totalPages > 1 && SelectArr[4].page >= SelectArr[4].totalPages">无更多内容</li>
                          </ul>
                        </ul>
                        <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 溯源信息 -->
            <div class="box FloatBox" v-if="NavType == 'trace_is_show'" style="padding-top: 0;">
              <div class="CustomRefBox" style="margin-top:0;">
                <div class="CustomRefRow">
                  <div class="CustomRefHead" style="border-bottom:0;">
                    <a class="CustomRefTitle onlyOneRow">演示工序流程</a>
                    <ul class="CustomRefBtnArr">
                      <li class="cyanBackColor" v-if="FormDatas.process_demo_is_show == 1" @click="SwicthSlide('process_demo_is_show')">隐藏</li>
                      <li class="greenBackColor" v-else @click="SwicthSlide('process_demo_is_show')">显示</li>
                    </ul>
                  </div>
                </div>
                <div class="CustomRefRow">
                  <div class="CustomRefHead" style="border-bottom:0;">
                    <a class="CustomRefTitle onlyOneRow">工序流程</a>
                    <ul class="CustomRefBtnArr">
                      <li class="cyanBackColor" v-if="FormDatas.process_is_show == 1" @click="SwicthSlide('process_is_show')">隐藏</li>
                      <li class="greenBackColor" v-else @click="SwicthSlide('process_is_show')">显示</li>
                    </ul>
                  </div>
                </div>
                <div class="CustomRefRow">
                  <div class="CustomRefHead" style="border-bottom:0;">
                    <a class="CustomRefTitle onlyOneRow">经销商流程</a>
                    <ul class="CustomRefBtnArr">
                      <li class="cyanBackColor" v-if="FormDatas.dealer_is_show == 1" @click="SwicthSlide('dealer_is_show')">隐藏</li>
                      <li class="greenBackColor" v-else @click="SwicthSlide('dealer_is_show')">显示</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- 促销信息 -->
            <div class="box FloatBox" v-if="NavType == 'activity_is_show'">
              <div class="FormStyleTwoLeft FloatBoxRow">
                <ul class="FormStyleTwo">
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>活动介绍（编写完成请点击右边保存按钮）</p>
                    <p style="margin-top:20px;">
                      <div id="wangeditor">
                        <div ref="editorElem"></div>
                      </div>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 背景音乐 -->
            <div class="box FloatBox" v-if="NavType == 'music_is_show'">
              <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="HandleModel(true,2)"><span class="icon iconfont iconicon-test20"></span>添加音乐</div>
              <div v-if="FormDatas.music">
                <ul class="MusicsList">
                  <span class="icon iconfont MusicsListIcon" :class="FormDatas.music.icon" @click="Playing()"></span>
                  <li v-text="FormDatas.music.name"></li>
                  <li class="MusicsListTxt"><a v-text="(FormDatas.music.size/(1024 * 1024)).toFixed(2)+'M'"></a><a v-text="FormDatas.music.create_at"></a></li>
                  <span class="icon iconfont iconicon-test33 MusicsListSelect feedback" @click.stop="RemoveImg(4)"></span>
                </ul>
                <audio id="PlayerBox" :src="FormDatas.music.url"></audio>
              </div>
            </div>

            <!-- 宣传视频 -->
            <div class="box FloatBox" v-if="NavType == 'video_is_show'">
              <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="HandleModel(true,3)"><span class="icon iconfont iconicon-test20"></span>添加视频</div>
              <div v-if="FormDatas.video">
                <ul class="ImagesList">
                  <li class="ImagesListImg backImg" :style="{backgroundImage: 'url(' + FormDatas.video.url + '?vframe/jpg/offset/7/w/400/)'}">
                    <img class="ImagesListImgPlay" src="/static/images/play.png">
                    <a class="ImagesListImgPlay" :href="FormDatas.video.url" target="view_window"></a>
                    <ul class="ImagesListImgFoot">
                      <li class='ImagesListImgSize' v-text="(FormDatas.video.size/(1024 * 1024)).toFixed(2)+'M'"></li>
                      <li class='ImagesListImgDate' v-text="FormDatas.video.date"></li>
                    </ul>
                    <span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(2)"></span>
                  </li>
                  <li class="ImagesListTitle onlyOneRow" v-text="FormDatas.video.name"></li>
                </ul>
              </div>
            </div>

            <!-- 商户信息 -->
            <div class="box FloatBox" v-if="FormDatas.business && NavType == 'business_is_show'">
              <div class="FormStyleTwoLeft FloatBoxRow">
                <ul class="FormStyleTwo">
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>商户名称（不可修改）</p>
                    <p class="FormStyleTwoRelative"><input placeholder="商户名称" :value="FormDatas.business.name" disabled="disabled" /></p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>联系人（如需修改请移步 基本信息 ）</p>
                    <p class="FormStyleTwoRelative"><input placeholder="商户联系人" :value="FormDatas.business.linkman" disabled="disabled" /></p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>联系电话（如需修改请移步 基本信息 ）</p>
                    <p class="FormStyleTwoRelative"><input placeholder="商户联系电话" :value="FormDatas.business.linkphone" disabled="disabled" /></p>
                  </li>
                  <li class="FormStyleTwoRow" v-if="FormDatas.business.baddress">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>联系地址（如需修改请移步 基本信息 ）</p>
                    <p class="FormStyleTwoRelative"><input placeholder="商户联系地址" :value="FormDatas.business.baddress" disabled="disabled" /></p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle">跳转链接（URL或小程序页面地址）</p>
                    <p class="FormStyleTwoRelative"><input placeholder="请输入跳转链接" v-model="FormDatas.jump_link" /></p>
                  </li>
                  <li class="FormStyleTwoRow">
                    <p class="FormStyleTwoTitle"><i class="redColor">*</i>商户简介（如需修改请移步 基本信息 ）</p>
                    <p class="FormStyleTwoRelative">
                      <textarea class="form-control" :value="FormDatas.business.intro" disabled="disabled" placeholder="商户简介信息"></textarea>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 自定义栏目 -->
            <div class="box FloatBox" v-if="NavType == 'ref_is_show'">
              <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="HandleModel(true,4)"><span class="icon iconfont iconicon-test20"></span>添加栏目</div>

              <div class="CustomRefBox" style="margin-top:0;">
                <div class="CustomRefRow" v-if="FormDatas.ref" v-for="(item,index) in FormDatas.ref" v-bind:key="index">
                  <div class="CustomRefHead">
                    <a v-text="item.name" class="CustomRefTitle onlyOneRow"></a>
                    <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                      <li @click="HandleModel(true,4,item,index)">编辑</li>
                      <li @click="RemoveData(index,2)">删除</li>
                    </ul>
                  </div>
                  <div class="CustomRefMaiBox">

                    <div class="CustomRefMain" v-for="(val,ix) in item.datas" v-bind:key="ix">
                      <div class="CustomRefHead">
                        <a class="CustomRefTitle onlyOneRow"><i v-if="val.type == 0">（文字）</i><i v-else>（图片）</i>{{val.data}}</a>
                        <ul class="CustomRefBtnArr CustomRefBtnArrStyle">
                          <li @click="HandleModel(true,5,val,index,ix)">编辑</li>
                          <li @click="RemoveData(index,3,ix)">删除</li>
                        </ul>
                      </div>
                    </div>

                    <div class="btnStyleTwo cyanBackColor btnRadius AddDefine CustomRefBtnAdd" @click="HandleModel(true,5,null,index)"><span class="icon iconfont iconicon-test20"></span>添加栏目内容</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 自定义底部菜单 -->
            <div class="box FloatBox" v-if="NavType == 'menu_is_show'">
              <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="HandleModel(true,6)"><span class="icon iconfont iconicon-test20"></span>添加底部菜单</div>

              <div class="CustomBannerBox" v-if="FormDatas.menu && FormDatas.menu.length > 0">
                <div class="CustomBannerRow" v-for="(item,index) in FormDatas.menu" v-bind:key="index">
                  <div class="backImg CustomBannerImg" v-if="item.icon" :style="{backgroundImage: 'url(' + item.icon + ')'}"></div>
                  <div class='backImg CustomBannerImg' v-else></div>

                  <div class="CustomBannerMain">
                    <p class="onlyOneRow" v-text="item.name"></p>
                    <p class="onlyOneRow" v-text="item.describe"></p>
                  </div>

                  <ul class="CustomMenuBtnArr">
                    <li @click="HandleModel(true,6,item,index)">编辑</li>
                    <li @click="RemoveData(index,4)">删除</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <!-- 焦点图 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加焦点图
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,ModelType)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>图片信息</p>
              <p>
                <p class="FormStyleOneImgs">
                  <ul class="FormStyleTwoImgsRow">
                    <li class="FormStyleTwoImgsBox backImg" @click="ImagesListModelFun({type:true},1)">
                      <div class="UploadFinishImg backImg" v-if="focus.img" :style="{backgroundImage:'url('+focus.img+')'}"><span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(1)"></span></div>
                    </li>
                  </ul>
                </p>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 名称</p>
              <p><input placeholder="请输入焦点图名称" v-model="focus.name" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">跳转链接（填写后点击图片可跳转）</p>
              <p><input placeholder="链接前需要加：http://或https://" v-model="focus.url" /></p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,ModelType)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddFocus">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 音频、视频空间 -->
    <div class="ModalBox" v-if="ModelTwoShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          素材空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,ModelType)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span><i v-if="MaterialType == 'audio'">上传音频</i><i v-else>上传视频</i>
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click.stop="SelectActive(1,$self)" v-if="SelectArr[1].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(1,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox ModalMainImgBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!MaterialList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:MaterialList.length > 5}">
            <table class="table">
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in MaterialList" v-bind:key="index" @click="SelectMaterial(item)">
                  <td class="ModalMaiImgMainBox" v-if="MaterialType != 'audio'"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + '?vframe/jpg/offset/7/w/400/)'}"></div></td>
                  <td>
                    <p class="MusicsListTitle" v-text="item.name"></p>
                    <p class="MusicsListTxt"><a v-text="(item.size/(1024 * 1024)).toFixed(2)+'M'"></a><a v-text="item.create_at"></a></p>
                  </td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,ModelType)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(false,ModelType)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 自定义栏目 -->
    <div class="ModalBox" v-if="ModelThreeShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加栏目
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,ModelType)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 栏目名称</p>
              <p><input placeholder="请输入栏目名称" v-model="ref.name" /></p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,ModelType)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddRef">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 自定义栏目内容 -->
    <div class="ModalBox" v-if="ModelFourShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加栏目内容
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,ModelType)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>内容类型</p>
              <p>
                <div class="SelectStyle" @click.stop="SelectActive(2,$self)">
                  <div>{{SelectArr[2].datas[SelectArr[2].ix]}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item" @click="SelectData(2,index)"></li>
                  </ul>
                  <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
                </div>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 栏目内容</p>
              <p class="FormStyleTwoRelative"><input placeholder="请输入栏目名称" v-model="refDatas" /><a @click="ImagesListModelFun({type:true},4)" v-if="SelectArr[2].ix == 1" class="FormStyleTwoUpImg cyanBackColor">上传图片</a></p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,ModelType)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddRefData">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- 自定义底部菜单 -->
    <div class="ModalBox" v-if="ModelFiveShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          添加底部菜单
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,ModelType)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">图标（不上传则为默认图标）</p>
              <p>
                <p class="FormStyleOneImgs">
                  <ul class="FormStyleTwoImgsRow" style="float:left;margin-right:20px;">
                    <li class="FormStyleTwoImgsBox backImg" @click="ImagesListModelFun({type:true},2)">
                      <div class="UploadFinishImg backImg" v-if="menu.icon" :style="{backgroundImage:'url('+menu.icon+')'}"><span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(3,1)"></span></div>
                    </li>
                    <li class='FormStyleTwoImgTitle'>（菜单图标）</li>
                  </ul>
                  <ul class="FormStyleTwoImgsRow" style="float:left;" v-if="SelectArr[3].ix == 2" @click="ImagesListModelFun({type:true},3)">
                    <li class="FormStyleTwoImgsBox backImg">
                      <div class="UploadFinishImg backImg" v-if="menu.qr" :style="{backgroundImage:'url('+menu.qr+')'}"><span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg(3,2)"></span></div>
                    </li>
                    <li class='FormStyleTwoImgTitle'>（二维码图片）</li>
                  </ul>
                </p>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i class="redColor">*</i>类型</p>
              <p>
                <div class="SelectStyle" @click.stop="SelectActive(3,$self)">
                  <div>{{SelectArr[3].datas[SelectArr[3].ix]}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[3].active">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[3].datas" v-bind:key="index" v-text="item" @click="SelectData(3,index)"></li>
                  </ul>
                  <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
                </div>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 名称</p>
              <p><input placeholder="请输入底部菜单名称" v-model="menu.name" /></p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">描述</p>
              <p><input placeholder="可输入菜单描述" v-model="menu.describe" /></p>
            </li>
            <li class="FormStyleTwoRow" v-if="SelectArr[3].ix != 2">
              <p class="FormStyleTwoTitle">链接</p>
              <p><input placeholder="可输入跳转链接" v-model="menu.url" /></p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,ModelType)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="AddMenu">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 图片列表 -->
    <K-ImagesList :ImgsData="ImgsData" @SonToastFun="SonToastFun" @ImagesListModelFun="ImagesListModelFun"></K-ImagesList>

	</div>
</template>

<script>
var self,category;
import axios from 'axios'
import E from "wangeditor";
import Paging from '@/components/common/Paging.vue'
import Column from '@/components/common/Column.vue'
import Swiper from 'swiper'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import ImagesList from '@/components/common/ImagesList.vue'
import QRCode from 'qrcode'
export default {
  name: 'MobileTemplate',
  props: ['catchData'], // 接收父组件的方法
  components: {
    'K-Column': Column,
    'K-Paging': Paging,
    'K-ImagesList': ImagesList,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      selectType:1, //1单个商品、2多个商品选择模版
      pids:[], //批量产品ID
      rootid:null,  //用户ID
      bname:null,  //企业名称
      productID:null,  //产品ID
      templateID:null,  //模板ID
      templateName:null,  //模板名称
      NavType:null,  //导航类型
      $self:null,
      tradeType:null,
      currentIndex:0,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      column:[{
        name:'ProductManage',url:'ProductManage'
      }],
      NavLists:[{te:"basic_is_open",name:"基本信息"}],
      FormDatas:{
        activity_is_show:null,
        activity_type:null,
        activity_rule:null,
        basic_is_show:null,
        focus_pics_is_show:null,
        prod_is_show:null,
        prod_name_is_show:null,
        prod_period_is_show:null,
        prod_desc_is_show:null,
        prod_unit_is_show:null,
        antifake_is_show:null,
        music_is_show:null,
        video_is_show:null,
        business_is_show:null,
        ref_is_show:null,
        menu_is_show:null,
        focus:[],
        first_antifake_msg:null,
        many_antifake_msg:null,
        jump_link:null,
        music:null,
        video:null,
        menu:[],
        ref:[]
      },
      SelectArr:[{
        ix:0,
        active:false,
        datas:["ip定位","经纬度定位"]
      },{
        ix:0,
        active:false,
        datas:[]
      },{
        ix:0,
        active:false,
        datas:["文字","图片"]
      },{
        ix:0,
        active:false,
        datas:["官网地址","商城跳转","微信公众号"]
      },{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      },{
        ix:0,
        active:false,
        datas:["否","是"]
      },{
        ix:0,
        active:false,
        datas:["否","是"]
      }],
      EditIndex:null,
      EditType:null,
      ModelShow:false,
      ModelTwoShow:false,
      ModelThreeShow:false,
      ModelFourShow:false,
      ModelFiveShow:false,
      ModelType:null,
      focus:null,
      menu:null,
      ImgsData:{
        type:false
      },
      page:1,
      MaterialList:null,
      pid:0,
      MaterialType:null,
      PlayInterval:null,
      PlayData:null,
      isPlay:false,
      ref:null,
      refDatas:null,
      refIndex:null,
      editor:null,
      iframeShow:true,
      DemoQR:null,
      DemoQRShow:false
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    self.bname = localStorage.getItem("wwmxt_Name");  //企业名称
    self.rootid = localStorage.getItem("wwmxt_Rootid");  //用户ID
    if(this.$route.query.tid){
      if(self.$route.query.pid && self.$route.query.pid != 'null'){
        self.selectType = 1;
        self.productID = self.$route.query.pid;
      }else{
        self.selectType = 2;
        self.pids = localStorage.getItem('wwmxt_tids').split(',');
        console.log(self.pids[0]);
        self.productID = self.pids[0];
      }
      self.templateID = self.$route.query.tid;
      self.column.push({name:'TemplateSettings',url:'MobileTemplate?id='+self.productID},{name:'TemplateEdit',url:'MobileTemplateEdit?pid='+self.productID+'&tid='+self.templateID});
    }
  },
  mounted () {
    category = new Swiper('#NavList', {
      slidesPerView : "auto",
      slidesPerGroup : 1,
      observer:true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents:true,//修改swiper的父元素时，自动初始化swiper
    })
    self.GetNavList();
  },
  updated () {
    if(self.NavType == "activity_is_show" && !self.editor){
      self.createRichText();
    }else if(self.NavType != "activity_is_show"){
      self.editor = null;
    }
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    ImagesListModelFun (data,te) {
      self.ImgsData.type = data.type;
      if(te){
        self.te = te;
      }
      if(data.img){
        if(self.te == 1){
          self.focus.img = data.img;
          self.focus.name = data.name;
        }else if(self.te == 2){
          self.menu.icon = data.img;
        }else if(self.te == 3){
          self.menu.qr = data.img;
        }else if(self.te == 4){
          self.refDatas = data.img;
        }
      }
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    SonToastFun (data) {
      self.Toast.state = true;
      self.Toast.type = data.te;
      self.Toast.txt = data.txt;
    },
    SelectData (ix,six,id) {
      self.SelectArr[ix].ix = six;
      if(ix == 1){
        self.pid = id;
        self.GetMaterialList();
      }
    },
    HandleModel (status,te,data,ix,six) {  //隐藏显示生码模态框

      if(!status){
        self.ImgsData.type = false;
      }

      self.ModelType = te;
      if(te == 1){
        self.ModelShow = status;
        if(data){
          self.EditType = "change";
          self.EditIndex = ix;
          self.focus = data;
        }else{
          self.EditType = "add";
          self.focus = {id:null,img:null,name:null,url:null};
        }
      }else if(te == 2){
        self.MaterialType = "audio";
        self.ModelTwoShow = status;
        if(status){
          self.GetPacketList();
          self.GetMaterialList();
        }
      }else if(te == 3){
        self.MaterialType = "video";
        self.ModelTwoShow = status;
        if(status){
          self.GetPacketList();
          self.GetMaterialList();
        }
      }else if(te == 4){
        self.ModelThreeShow = status;
        if(data){
          self.EditType = "change";
          self.EditIndex = ix;
          self.ref = data;
        }else{
          self.EditType = "add";
          self.ref = {name:null,data:null}
        }
      }else if(te == 5){
        self.ModelFourShow = status;
        if(status){
          self.refIndex = ix;
        }
        if(data){
          self.EditType = "change";
          self.EditIndex = six;
          self.refDatas = self.FormDatas.ref[ix].datas[six].data;
          self.SelectArr[2].ix = self.FormDatas.ref[ix].datas[six].type;
        }else{
          self.EditType = "add";
          self.refDatas = null;
          self.SelectArr[2].ix = 0;
        }
      }else if(te == 6){
        self.ModelFiveShow = status;
        if(data){
          self.EditType = "change";
          self.EditIndex = ix;
          self.menu = data;
          self.SelectArr[3].ix = data.type;
        }else{
          self.EditType = "add";
          self.menu = {icon:null,name:null,describe:null,url:null,qr:null},
          self.SelectArr[3].ix = 0;
        }
      }
    },
    ClickNav (te,ix,nte) {
      //暂存富文本编辑器内容
      if(self.editor){
        self.FormDatas.activity_rule = self.editor.txt.html(); //渲染内容
      }
      if(te == "prev"){
        if(self.currentIndex > 0){
          self.currentIndex--;
          self.NavType = self.NavLists[self.currentIndex].te;
        }
      }else if(te == "to"){
        self.NavType = nte;
        self.currentIndex = ix;
      }else{
        if(self.NavLists.length-1 > self.currentIndex){
          self.currentIndex++;
          self.NavType = self.NavLists[self.currentIndex].te;
        }
      }
      category.slideTo(self.currentIndex, 500, false);
    },
    SwicthSlide (key) {
      if(key != "basic_is_show" && key != "activity_is_show"){
        if(self.FormDatas[key]){
          self.FormDatas[key] = 0;
        }else{
          self.FormDatas[key] = 1;
        }
      }else{
        self.ToastFun(2,"当前模块暂不支持隐藏");
      }
    },
    GetNavList () {
      axios.get(self.GetServerUrl(1)+"template/"+self.templateID)
      .then(function (response){
        self.templateName = response.data.meta.id;  //模板名称
        self.CreatePayQR('https://wmv.xin/#/?tid='+self.templateID+'&tname='+self.templateName+'&pid='+self.productID+'&rid='+self.rootid);  //创建演示二维码
        let datas = response.data.meta;
        let NavList = [];
        if(datas.basic_is_open == 1){
          NavList.push({te:"basic_is_show",name:"基本信息"});
          self.FormDatas.basic_is_show = 1;
        }
        if(datas.focus_is_open == 1){
          NavList.push({te:"focus_pics_is_show",name:"焦点图"});
          self.FormDatas.focus_pics_is_show = 1;
        }
        if(datas.prod_is_open == 1){
          NavList.push({te:"prod_is_show",name:"产品信息"});
          self.FormDatas.prod_is_show = 1;
          self.FormDatas.prod_name_is_show = 1;
          self.FormDatas.prod_period_is_show = 1;
          self.FormDatas.prod_desc_is_show = 1;
          self.FormDatas.prod_unit_is_show = 1;
        }
        if(datas.antifake_is_open == 1){
          NavList.push({te:"antifake_is_show",name:"防伪信息"});
          self.FormDatas.antifake_is_show = 1;
        }
        if(datas.batch_is_open == 1){
          NavList.push({te:"batch_is_show",name:"批次信息"});
          self.FormDatas.batch_is_show = 1;
          self.GetBatchList();
        }
        if(datas.trace_is_open == 1){
          NavList.push({te:"trace_is_show",name:"溯源信息"});
          self.FormDatas.trace_is_show = 1;
        }
        if(datas.activity_is_open == 1){
          NavList.push({te:"activity_is_show",name:"促销信息"});
          self.FormDatas.activity_is_show = 1;
        }
        if(datas.music_is_open == 1){
          NavList.push({te:"music_is_show",name:"背景音乐"});
          self.FormDatas.music_is_show = 1;
        }
        if(datas.video_is_open == 1){
          NavList.push({te:"video_is_show",name:"宣传视频"});
          self.FormDatas.video_is_show = 1;
        }
        if(datas.business_is_open == 1){
          NavList.push({te:"business_is_show",name:"商户信息"});
          self.FormDatas.business_is_show = 1;
        }
        if(datas.ref_is_open == 1){
          NavList.push({te:"ref_is_show",name:"自定义栏目"});
          self.FormDatas.ref_is_show = 1;
        }
        if(datas.menu_is_open == 1){
          NavList.push({te:"menu_is_show",name:"底部菜单"});
          self.FormDatas.menu_is_show = 1;
        }
        if(datas.module_index_is_open == 1){
          NavList.push({te:"module_index_is_show",name:"模块索引"});
          self.FormDatas.module_index_is_show = 1;
        }
        console.log(self.FormDatas)
        self.NavLists = NavList;
        setTimeout(function(){
          self.ClickNav("to",0,NavList[0].te);
        },100)

        self.GetTemplateDetail();  //获取模板详情
      }).catch(function (error){
        self.GetTemplateDetail();  //获取模板详情
      })
    },
    GetTemplateDetail () {
      axios.get(self.GetServerUrl(1)+"template_product/"+self.productID+"/"+self.rootid)
      .then(function (response){
        let datas = response.data.meta;
        if(datas.focus){
          datas.focus = JSON.parse(datas.focus);
        }else{
          datas.focus = [];
        }
        if(datas.ref){
          datas.ref = JSON.parse(datas.ref);
        }else{
          datas.ref = [];
        }
        if(datas.menu){
          datas.menu = JSON.parse(datas.menu);
        }else{
          datas.menu = [];
        }
        if(datas.music){
          datas.music = JSON.parse(datas.music);
        }
        if(datas.video){
          datas.video = JSON.parse(datas.video);
        }
        self.SelectArr[5].ix = datas.antifake_code_show; //是否明码显示防伪码
        self.SelectArr[6].ix = datas.antifake_auto; //是否明码显示防伪码
        self.SelectArr[0].ix = datas.location_type; //定位方式
        datas.activity_is_show = 1;
        self.FormDatas = datas;
      }).catch(function (error){
        console.log("模板信息为空！");
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/"+self.MaterialType)
      .then(function (response){
        self.SelectArr[1].datas = response.data.meta
        self.SelectArr[1].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[1].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetMaterialList () {
      self.MaterialList = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/"+self.MaterialType+"/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
            datas[item].icon = "iconicon-test36";
            let fix = datas[item].create_at.indexOf("-")+1;
            let lix = datas[item].create_at.lastIndexOf(":");
            datas[item].create_at = datas[item].create_at.substring(fix,lix);
          }
          self.MaterialList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    GetBatchList (te) {
      self.datas = null;
      axios.get(self.GetServerUrl(1)+"batch_list/"+self.SelectArr[4].page)
      .then(function (response){
        if(!te){
          self.SelectArr[4].datas = response.data.meta.data;
          self.SelectArr[4].totalPages = response.data.meta.last_page;
        }else{
          self.loading = false;
          let datas = response.data.meta.data;
          for(let item in datas){
            self.SelectArr[4].datas.push(datas[item]);
          }
        }
      }).catch(function (error){
        if(!te){
          self.loading = false;
        }
      })
    },
    createRichText () {
      self.editor = new E(self.$refs.editorElem);
      self.editor.customConfig.zIndex = 90
      self.editor.create(); // 创建富文本实例
      self.editor.txt.html(self.FormDatas.activity_rule); //渲染内容
    },
    SelectMaterial (data) {
      self.ModelTwoShow = false;
      if(self.MaterialType == "audio"){
        self.FormDatas.music = data;
      }else{
        self.FormDatas.video = data;
      }
    },
    Playing (ix,url) {
      let x = 1;

      if(!self.isPlay){
        self.isPlay = true;
        self.PlayData = document.getElementById("PlayerBox");
        self.PlayData.currentTime = 0;  //重新播放
        setTimeout(function(){
          self.PlayData.play();
          self.PlayInterval = setInterval(function(){
            if(x == 1){
              self.FormDatas.music.icon = "iconicon-test34"
            }else if(x == 2){
              self.FormDatas.music.icon = "iconicon-test35"
            }else if(x == 3){
              self.FormDatas.music.icon = "iconicon-test36"
              x = 0;
            }
            x++
          },500)

          //音频播放完毕
          self.PlayData.addEventListener('ended', function () {
            clearInterval(self.PlayInterval)
            self.isPlay = false;
            self.PlayInterval = null;
            self.FormDatas.music.icon = "iconicon-test36"
          }, false);

        },300)
      }else{
        self.isPlay = false;
        clearInterval(self.PlayInterval)
        self.PlayInterval = null;
        self.FormDatas.music.icon = "iconicon-test36"
        self.PlayData.pause();
      }
    },
    AddFocus () {
      if(!self.focus.img){
        self.ToastFun(1,"图片不能为空！");
        return;
      }
      if(!self.focus.name){
        self.ToastFun(1,"名称不能为空！");
        return;
      }
      if(self.EditType == "add"){
        self.FormDatas.focus.push({img:self.focus.img,name:self.focus.name,url:self.focus.url});
      }else{
        self.FormDatas.focus[self.EditIndex] = {img:self.focus.img,name:self.focus.name,url:self.focus.url};
      }
      self.ModelShow = false;
    },
    AddRef () {
      if(!self.ref.name){
        self.ToastFun(1,"名称不能为空！");
        return;
      }
      if(self.EditType == "add"){
        self.FormDatas.ref.push({name:self.ref.name,datas:[]});
      }else{
        self.FormDatas.ref[self.EditIndex].name = self.ref.name;
      }
      self.ModelThreeShow = false;
    },
    AddRefData () {
      if(!self.refDatas){
        self.ToastFun(1,"栏目内容不能为空！");
        return;
      }
      if(self.EditType == "add"){
        self.FormDatas.ref[self.refIndex].datas.push({type:self.SelectArr[2].ix,data:self.refDatas});
      }else{
        self.FormDatas.ref[self.refIndex].datas[self.EditIndex] = {type:self.SelectArr[2].ix,data:self.refDatas};
      }
      self.ModelFourShow = false;
    },
    AddMenu () {
      if(!self.menu.name){
        self.ToastFun(1,"底部菜单名称不能为空！");
        return;
      }
      if(self.EditType == "add"){
        self.FormDatas.menu.push({icon:self.menu.icon,qr:self.menu.qr,type:self.SelectArr[3].ix,name:self.menu.name,describe:self.menu.describe,url:self.menu.url});
      }else{
        self.FormDatas.menu[self.EditIndex] = {icon:self.menu.icon,qr:self.menu.qr,type:self.SelectArr[3].ix,name:self.menu.name,describe:self.menu.describe,url:self.menu.url};
      }
      self.ModelFiveShow = false;
    },
    RemoveData (ix,te,six) {
      if(te == 1){
        self.FormDatas.focus.splice(ix,1);
      }else if(te == 2){
        self.FormDatas.ref.splice(ix,1);
      }else if(te == 3){
        self.FormDatas.ref[ix].datas.splice(six,1);
      }else if(te == 4){
        self.FormDatas.menu.splice(ix,1);
      }
    },
    SubmitForm () {
      self.loading = true;
      self.FormDatas.production_id = self.productID;  //产品ID
      self.FormDatas.template_id = self.templateID;  //模板ID
      self.FormDatas.location_type = self.SelectArr[0].ix;  //定位方式
      self.FormDatas.antifake_code_show = self.SelectArr[5].ix;  //防伪码展示方式
      self.FormDatas.antifake_auto = self.SelectArr[6].ix;  //防伪码展示方式
      self.FormDatas.batch = self.SelectArr[4].datas[self.SelectArr[4].ix];  //批次信息
      if(self.editor){
        self.FormDatas.activity_rule = self.editor.txt.html();
      }

      //多个产品同时设置模版
      let _url = "template_product"
      if(self.selectType === 2){
        _url = "template_products"
        self.FormDatas.production_ids = localStorage.getItem('wwmxt_tids').split(',');  //多个产品ID
      }
      axios.patch(self.GetServerUrl(1)+_url,self.FormDatas)
      .then(function (response){
        self.iframeShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetTemplateDetail();

        //重新加载iframe容器
        setTimeout(function(){
          self.iframeShow = true;
        },200)
      }).catch(function (error){
        console.log(error);
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SelectArrQuery (te) {
      self.loading = true;
      let url;
      if(te == 1){
        url = "batch_list";
      }else{
        url = "production_list_condition";
      }
      axios.post(self.GetServerUrl(1)+url,{name:self.SQueryName})
      .then(function (response){
        if(te == 1){
          self.SelectArr[1].ix = 0;
          self.SelectArr[1].datas = response.data.meta.data;
        }else{
          self.SelectArr[2].ix = 0;
          self.SelectArr[2].datas = response.data.meta.data;
        }
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SelectArrLoading (ix) {
      self.SelectArr[ix].page++;
      self.loading = true;
      if(ix == 1){
        self.GetBatchList(true);
      }else{
        self.GetProductList(true);
      }
    },
    CreatePayQR (data) {
      QRCode.toDataURL(data,{errorCorrectionLevel:"H",version: 7},
      function(err,url){
        console.log(err);
        if(!err){
          self.DemoQR = url;
        }
      })
    },
    DemoQrShow (status) {
      self.DemoQRShow = status;
    },
    RemoveImg(te,ste){
      if(te == 1){
        self.focus.img = null;
      }else if(te == 2){
        self.FormDatas.video = null;
      }else if(te == 3){  //底部菜单
        if(ste == 1){
          self.menu.icon = null;
        }else{
          self.menu.qr = null;
        }
      }else if(te == 4){
        self.FormDatas.music = null;
      }
    }
  }
}
</script>

<style scoped>
  .MTERightMain{
    position: relative;
  }
  .formFootBtnArr{
    display: block;
    margin:0 25px;
    margin-bottom:25px;
  }
  .SelectStyle{
    width:300px;
  }
  .MTELeft{
    float: left;
  }
  .Mobile{
    border: 4px solid #D9DBDC;
    background: #F8F8F8;
    padding: 12px;
    border-radius: 40px;
    height: 600px;
    width: 300px;
    position: relative;
  }
  .MobileTop{
    padding: 4px 88px 32px;
  }
  .Camera{
    display: block;
    margin: 0 auto;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    margin-bottom: 10px;
    background: #333;
  }
  .Sensor{
    display: block;
    width: 12px;
    height: 12px;
    float: left;
    background: #333;
    margin-top: -4px;
    border-radius: 12px;
  }
  .Speaker{
    display: block;
    width: 56px;
    height: 4.8px;
    margin: 0 auto;
    border-radius: 4.8px;
    background: #292728;
  }
  .MobileBar{
    display: block;
    height: 12px;
    border-left: 4px solid #BBB;
    border-right: 4px solid #BBB;
    position: absolute;
    left: -4px;
    right:-4px;
  }
  .on-off,.sleep,.up,.down{
    position: absolute;
    height:30px;
    width:4px;
    background-color: #CCC;
    border-radius: 3px 0 0 3px;
  }
  .on-off{
    top:75px;
    left:-8px;
  }
  .sleep{
    top:125px;
    height:40px;
    right:-8px;
    border-radius: 0  3px 3px 0;
  }
  .up,.down{
    height:40px;
    left:-8px;
  }
  .up{
    top:125px;
  }
  .down{
    top:175px;
  }
  .MobileScreen{
    height: 470px;
    margin: 0 auto;
    border: 2px solid #D9D9D9;
    overflow: hidden;
  }
  .MobileBottom{
    padding: 8px 0 0;
  }
  .MobileBottom a{
    display: block;
    margin: 0 auto;
    width: 55px;
    height: 55px;
    background: #CCC;
    border-radius: 55px;
    background: linear-gradient(135deg, #303233 0%, #b5b7b9 50%, #f0f2f2 69%, #303233 100%);
    position: relative;
  }
  .MobileBottom a i{
    display: block;
    width: 48px;
    height: 48px;
    background: #FFF;
    border-radius: 48px;
    position: absolute;
    left: 3.5px;
    top: 3.5px;
  }
  .MobileStatusBar img{
    display: block;
    width: 100%;
  }
  .MobileTitle{
    color:#FFF;
    line-height: 40px;
    position: relative;
    background-color: #1E1C1F;
    text-align: center;
    font-size:12px;
  }
  .MobileTitleBack{
    position: absolute;
    left:8px;
    top:1px;
    font-size:11px;
    cursor: pointer;
  }
  .MusicsListSelect{
    position: absolute;
    right:15px;
    top:27px;
    color:#888;
    cursor: pointer;
  }
  .iconicon-test24{
    padding-right:2px;
  }
  .MobileTitleMore{
    position: absolute;
    right:8px;
    top:1px;
    font-size:4px;
    cursor: pointer;
  }
  .MobileMain{
    width:313px;
    height:415px;
    position: relative;
  }
  .ScrollBar{
    width:4px;
    height:100px;
    position: absolute;
    right:0;top:10px;
    border-radius: 4px;
    background: rgba(0,0,0,0.4);
  }
  .slidebtn{
    right:20px;
  }
  .MTERightHeadMain{
    margin:0 50px;
    overflow: hidden;
  }
  .AddDefine{
    margin-top:0;
  }
  .FormStyleTwoImgsBox{
    width:100px;
    height:100px;
    border:1px solid #D8E4ED;
    position: relative;
    background-image: url('/public/static/images/add.png');
  }
  .FormStyleTwoImgTitle{
    padding-top:11px;
    text-align: center;
    color:#585A64;
  }


  .MusicsListTitle{
    border:0;
  }
  .MusicsList{
    margin-left:0;
  }
  .ImagesList{
    margin-left:0;
  }

  .MTESaveBtn{
    position: absolute;
    top:25px;right:25px;
  }
  .FormStyleTwoUpImg{
    position: absolute;
    right:0;top:0;
    padding:9px 15px;
  }
  .ViewDemoQr{
    color:#555;
    text-align: center;
    margin-top:10px;
    cursor: pointer;
    position: relative;
  }
  .DemoQrImg{
    top:-155px;left:0;right:0;
    margin:0 auto;
    position: absolute;
    width:150px;
    height:150px;
    box-shadow: 0 0 10px rgba(100,100,100,0.5);
  }
  .CustomBannerMain p:last-child{
    color:#666;
  }
  .ActiveNav{
    color:#FFF !important;
    background-color: #278BF5;
    border-right: 1px solid #278BF5;
    box-shadow:0 0 10px rgba(53,149,255,0.5);
  }
</style>
