<template>
	<div class="LoadingBox NoSelect">
	  <div class="LoadingMain" :style="{marginTop:boxTop+'px'}">
	    <div class="LoadingIcon"><img src="static/images/loading3.gif"></div>
	  </div>
	</div>
</template>

<script>
export default {
  name: 'Loading',
  data () {
    return {
      boxTop:100
    }
  },
  created () {
    this.boxTop = document.documentElement.clientHeight/2 - 97;
  }
}
</script>

<style scoped>
  .LoadingBox{
    position: fixed;
    top:0;bottom:0;
    right:0;
    left:0;
    z-index: 1001;
    background: rgba(0,0,0,0.2);
  }
  .LoadingMain{
    display: table;
    margin:0 auto;
    padding:10px;
    background: rgba(255,255,255,1);
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
  .LoadingIcon img{
    display: block;
    width:100px;
  }
  .LoadingTxt{
    margin-top:10px;
  }
</style>
