<template>
	<div class="MainBox">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('DealerEdit?type=add')"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input placeholder="输入用户名称查询" />
          <a class="SearchBtn">搜索</a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>用户名称</th>
            <th>头像</th>
            <th>手机</th>
            <th>中奖总额</th>
            <th>中奖次数</th>
            <th>是否关注</th>
            <th>参与时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td><div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.img + ')'}"><img v-if="item.img" class="tableImgShow" :src="item.img" @click="PreviewImgs" /></div></td>
            <td v-text="item.phone"></td>
            <td v-text="item.WinningTotalMoney"></td>
            <td v-text="item.WinningNumber"></td>
            <td v-text="item.isConcern"></td>
            <td v-text="item.date"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor">详情</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'UserStats',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null
    }
  },
  created () {
    self = this;
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        name:'啊创',
        img:'/public/static/images/login.png',
        phone:15816869108,
        WinningTotalMoney:650,
        WinningNumber:52,
        isConcern:'是',
        date:'2019-09-08 13:22'
      },{
        name:'啊戳',
        img:'/public/static/images/login.png',
        phone:15816869108,
        WinningTotalMoney:598,
        WinningNumber:41,
        isConcern:'是',
        date:'2019-09-08 13:22'
      }],
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    }
  }
}
</script>

<style scoped>

</style>
