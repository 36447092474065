<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
          <span class="icon iconfont iconicon-test20"></span>{{$t('b.Material.UploadVideo')}}
          <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple />
        </li>
        <li class="TableHeadLeftRow btnStyleTwo cyanBackColor btnRadius" @click="GoToPage('StaticPacket?type=video')"><span class="icon iconfont iconicon-test19"></span>{{$t('b.Material.GroupingManage')}}</li>
        <li class="TableHeadLeftRow TableHeadHint">{{$t('b.Material.VideoSizeHint')}} <a class="FocusA">{{$t('c.Common.ClickView')}}</a></li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SelectStyle" @click.stop="SelectActive(0,$self)" v-if="SelectArr[0].datas.length > 0">
          <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].group_name}}</div>
          <ul class="OptionStyleBox" v-if="SelectArr[0].active">
            <ul class="OptionStyleMain">
              <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(0,index,item.space_group_id)"></li>
            </ul>
          </ul>

          <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>
    <div class="ImagesSpaceHint NoSelect">
        <div v-if="!PackageDatas">{{$t('b.Material.Loading')}}</div>
        <div v-else>{{$t('b.Material.StorageSpace')}}：{{PackageDatas.space_limit}}M，{{$t('b.Material.UsedStorage')}}：{{PackageDatas.spaced}}M，{{$t('b.Material.SurplusStorage')}}：{{PackageDatas.SpaceSurplus}}M</div>
        <div class="IconArray">
          <a class="IconArrayRow feedback" @click="AllSelected(true)" v-if="isAllSelected">{{$t('c.Common.Cancel')}}</a>
          <a class="IconArrayRow feedback" @click="AllSelected(false)" v-else>{{$t('c.Common.SelectAll')}}</a>
          <a class="IconArrayRow feedback" @click="RemoveData()">{{$t('c.Common.DeleteSelection')}}</a>
          <a class="IconArrayRow feedback" @click="HandleModel(true,1)">{{$t('c.Common.MovePacket')}}</a>
        </div>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <div v-else class="FloatBox">
        <ul class="ImagesList" v-for="(item,index) in datas" v-bind:key="index">
          <li class="ImagesListImg backImg" :style="{backgroundImage: 'url(' + item.url + '?vframe/jpg/offset/1/w/400/)'}" @mouseenter="HoverImgBox(true,index)" @mouseleave="HoverImgBox(false,index)">
            <div class="ImagesListImgShade" v-if="HoverIndex == index || item.active"></div>
            <div class="ImagesListImgHover" v-if="HoverIndex == index || item.active">
              <span class="icon iconfont ImagesListSelect" :class="{'iconicon-test31':!item.active,'iconicon-test32':item.active}" @click="SelectImgs(index)"></span>
              <span class="ImagesListEdit" v-if="HoverIndex == index" @click="HandleModel(true,2,item.id,item.name,index)">{{$t('c.Common.Rename')}}</span>
            </div>
            <img class="ImagesListImgPlay" src="static/images/play.png">
            <a class="ImagesListImgPlay" :href="item.url" target="view_window"></a>
            <ul class="ImagesListImgFoot">
              <li class='ImagesListImgSize' v-text="(item.size/(1024 * 1024)).toFixed(2)+'M'"></li>
              <li class='ImagesListImgDate' v-text="item.date"></li>
            </ul>
          </li>
          <li class="ImagesListTitle onlyOneRow" v-text="item.name"></li>
        </ul>
      </div>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 添加生码套餐窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            图片编辑
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow" v-if="ModelType == 1">
                <p class="FormStyleTwoTitle">图片分组</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(1,$self)">
                    <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix].group_name}}</div>
                    <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(1,index,item.space_group_id)"></li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="ModelType == 2">
                <p class="FormStyleTwoTitle"><i>*</i> 图片名称</p>
                <p><input placeholder="请输入图片名称" v-model="Img.ImgName" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>
	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'VideosSpace',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      subHint:'数据加载中...',
      ModelShow:false,
      ModelType:null,
      isLoding:true,
      totalPages:0,
      datas:null,
      isAllSelected:false,
      Img:{
        ImgIndex:null,
        ImgID:null,
        ImgName:null,
      },
      pid:0, //分组id
      fileLength:null,
      loading:false,
      Confirm:{
        state:false,
        txt:null
      },
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      SelectArr:[{
        ix:0,
        active:false,
        datas:[]
      },{
        ix:0,
        active:false,
        datas:[]
      }],
      HoverIndex:null,
      PackageDatas:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetPacketList();
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HoverImgBox (te,ix) {  //图片悬浮
      if(te){
        self.HoverIndex = ix;
      }else{
        self.HoverIndex = null;
      }
    },
    SelectImgs (ix) {  //选中图片
      if(self.datas[ix].active){
        self.datas[ix].active = false;
      }else{
        self.datas[ix].active = true;
      }
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if(ix == 0){  //列表分组选择
        self.pid = id;
        self.GetList();
      }
    },
    HandleModel (status,te,id,name,ix) {  //隐藏显示生码模态框
      //判断是否有选中
      if(status&&self.iSSelected(te)){
        return;
      }
      self.ModelShow = status;
      self.ModelType = te
      if(!status){  //清空套餐ID并清除填充内容
        self.Img.ImgIndex = null;
        self.Img.ImgName = null;
        self.Img.ImgID = null;
      }else{
        self.Img.ImgIndex = ix;
        self.Img.ImgName = name;
        self.Img.ImgID = id;
      }
    },
    GetUserData () {
      axios.get(self.GetServerUrl(1)+"user_package")
      .then(function (response) {
        let datas = response.data.meta;
        datas.spaced = datas.spaced.toFixed(2);
        let num = datas.space_limit - datas.spaced;
        datas.SpaceSurplus = Math.floor(num*100)/100;
        self.PackageDatas = datas;
      }).catch(function (error){
        console.log(self.ErrorHint(error));
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/video")
      .then(function (response){
        self.SelectArr[0].datas = response.data.meta;
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
        self.SelectArr[1].datas = self.SelectArr[0].datas;
      }).catch(function (error){
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
        self.SelectArr[1].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      self.AllSelected(true); //取消全选或取消
      axios.get(self.GetServerUrl(1)+"resource/video/"+self.pid+"/"+self.page)
      .then(function (response){
        let datas = [];
        for(let item in response.data.meta.data){
          datas.push(response.data.meta.data[item])
          datas[item].active = false;
        }
        self.datas = datas;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
        self.GetUserData();  //获取用户存储
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.GetUserData();  //获取用户存储
      })
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","video");
      FormDatas.append("space_group_id",self.pid);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,response.data.message);  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveData () {
      //判断是否有选中
      if(self.iSSelected(1)){
        return;
      }
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除选中图片？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        let datas = [];
        for(let item in self.datas){
          if(self.datas[item].active){
            datas.push(self.datas[item].id);
          }
        }
        self.Confirm.state = false;
        self.loading = true;

        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"resource",
          data:{resource_ids:datas}
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    },
    SubmitForm () {  //提交表单
      self.loading = true;
      let datas;
      if(self.ModelType == 1){
        let ids = [];
        for(let item in self.datas){
          if(self.datas[item].active){
            ids.push(self.datas[item].id);
          }
        }
        datas = {id:ids,space_group_id:self.SelectArr[1].datas[self.SelectArr[1].ix].space_group_id};
      }else{
        datas = {id:self.Img.ImgID,name:self.Img.ImgName};
      }

      axios.patch(self.GetServerUrl(1)+"resource",datas)
      .then(function (response){
          if(self.ModelType == 1){
            self.GetList();
          }else{
            self.datas[self.Img.ImgIndex].name = self.Img.ImgName;
          }

          self.HandleModel(false); //隐藏弹出框
          self.loading = false;
          self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error){
        self.HandleModel(false); //隐藏弹出框
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    AllSelected (te) {
      for(let item in self.datas){
        if(te){
          self.datas[item].active = false;
          self.isAllSelected = false;
        }else{
          self.datas[item].active = true;
          self.isAllSelected = true;
        }
      }
    },
    iSSelected (te) {  //判断是否有选中
      let isNull;
      if(te == 1){
        for(let item in self.datas){
          if(self.datas[item].active){
            isNull = true;
          }
        }
        if(!isNull){
          self.ToastFun(2,"请先选中图片");  //弹窗提醒
          return true;
        }
      }
    }
  }
}
</script>

<style scoped>
  .TableHeadLeft > .hint{
    margin-top:10px;
    padding-left:10px;
  }
  .hint{
    color:#646B71;
    padding:0 20px;
  }
  .FloatBox{
    padding-bottom:25px;
  }
  .iconicon-test32{
    color:#278BF5 !important;
  }
</style>
