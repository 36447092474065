<template>
	<div class="MainBox">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true,1)"><span class="icon iconfont iconicon-test20"></span>添加套餐</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input placeholder="输入套餐名称查询" />
          <a class="SearchBtn">搜索</a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable">
        <thead>
          <tr>
            <th>套餐名称</th>
            <th>费用</th>
            <th>时长</th>
            <th>生码量</th>
            <th>存储空间</th>
            <th>时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td>
              <a v-if="item.money == 0">免费</a>
              <a v-else v-text="item.money+'元'"></a>
            </td>
            <td v-text="item.expiry_date+'天'"></td>
            <td>
              <a v-if="item.code_limit == -1">无限</a>
              <a v-else v-text="item.code_limit"></a>
            </td>
            <td v-text="item.space_limit/1024+'G'"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,2,item.package_id)">编辑</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.package_id)">删除</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 添加生码套餐窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            添加生码套餐
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 套餐等级</p>
                <p><input placeholder="套餐等级" v-model="FormDatas.rule" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 套餐图标</p>
                <p><input placeholder="套餐图标" v-model="FormDatas.icon" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 套餐名称</p>
                <p><input placeholder="套餐名称" v-model="FormDatas.name" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 费用说明</p>
                <p><input placeholder="套餐费用说明" v-model="FormDatas.intro" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 费用（整数 单位为 “ 元 ”）</p>
                <p><input placeholder="套餐支付费用" v-model="FormDatas.money" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 时长（整数 单位为 “ 天 ”）</p>
                <p><input placeholder="套餐使用时长" v-model="FormDatas.expiry_date" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 生码量（整数 单位为 “ 个 ”）</p>
                <p><input placeholder="套餐生码量" v-model="FormDatas.code_limit" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 素材空间（整数 单位为 “ KB ”）</p>
                <p><input placeholder="素材空间容量" v-model="FormDatas.space_limit" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 套餐说明</p>
                <p><input placeholder="说明介绍" v-model="FormDatas.instructions" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>
	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Confirm from '@/components/common/Confirm.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'FunctionSetting',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      ModelShow:false,
      loading: false,
      Confirm:{
        state:false,
        txt:null
      },
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      datas:null,
      FormDatas:{
        rule:null,
        package_id:null,
        name:null,
        intro:null,
        money:null,
        expiry_date:null,
        code_limit:null,
        space_limit:null,
        instructions:null
      }
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();  //获取套餐
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    HandleModel (status,te,id) {  //隐藏显示生码模态框
      self.ModelShow = status;
      if(!status){  //清空套餐ID并清除填充内容
        self.FormDatas.package_id = null;

        for(let item in self.FormDatas){
          self.FormDatas[item] = null;
        }
      }
      if(te == 2){  //编辑套餐
        self.GetList(id);
      }
    },
    GetList (id) {
      var URL;
      if(id){
        URL = "package/"+id;
        self.loading = true;
      }else{
        URL = "package";
        self.datas = null;
        self.isLoding = true;
      }
      axios.get(self.GetServerUrl(1)+URL)
      .then(function (response) {
          if(id){
            self.FormDatas = response.data.meta
            self.loading = false;
          }else{
            self.datas = response.data.meta
            self.isLoding = false;
          }
      }).catch(function (error){
        if(id){
          self.loading = false;
        }else{
          self.subHint = "数据为空";
          self.isLoding = false;
        }
      })
    },
    SubmitForm () {  //提交表单
      self.loading = true;
      axios.post(self.GetServerUrl(1)+"admin_package",self.FormDatas)
      .then(function (response) {
          self.HandleModel(false); //隐藏弹出框
          self.GetList();

          self.loading = false;
          self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error) {
        self.HandleModel(false); //隐藏弹出框
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveData (id) {
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该套餐？"
      self.FormDatas.package_id = id;
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;
        axios.delete(self.GetServerUrl(1)+"admin_package/"+self.FormDatas.package_id)
        .then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
