<template>
	<div class="MainBox" v-if="datas">
		<div class="BasicInfoHead">
      <p class="BasicInfoHeadLogo backImg" v-if="datas.logo" :style="{backgroundImage:'url('+datas.logo+')'}"></p>
      <p class="BasicInfoHeadLogo backImg" v-else></p>
      <p class="BasicInfoHeadName" v-if="datas.id_code_base">
        {{datas.name}}
        <i class="authenticated BlueState" v-if="datas.id_code_base.status_id == 100">已认证</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == 0">待审核</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -1">审核失败</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -2">禁用</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -100">待完善资料</i>
      </p>
      <p class="BasicInfoHeadName" v-else>{{datas.name}}</p>
      <p class="BasicInfoHeadDate" v-if="datas.created_at">注册时间：{{datas.created_at}}</p>
      <p class="BasicInfoHeadExpire" v-if="datas.rootid">组织编号 {{datas.rootid}}</p>
      <a class="BasicInfoHeadUpLogo NoSelect" @click="HandleModel(true,1)">上传LOGO</a>
    </div>

    <div class="BasicInfoNav">
      <a class="feedback" v-for="(item,index) in NavList" v-bind:key="index" v-text="item" :class="{NavActive:index == ix}" @click="ClickNav(index)"></a>
    </div>

    <!-- 组织信息 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-if="ix == 0">
      <div class="BasicInfoOneBoxLeft">
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">组织类型</li>
          <li>
            <p>
              <input v-if="datas.org_type == 1" value="经销代理" disabled="true" class="disabledInput" />
              <input v-else value="高级定制" disabled="true" class="disabledInput" />
            </p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">组织名称</li>
          <li>
            <p><input v-model="datas.name" disabled="true" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne" v-if="datas.trade">
          <li class="FormStyleOneTitle">所属行业</li>
          <li>
            <p><input v-model="datas.trade.trade_name" disabled="true" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">联系人</li>
          <li>
            <p><input value="小梁" v-model="datas.linkman" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">联系电话</li>
          <li>
            <p><input value="15816869108" v-model="datas.linkphone"/></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">联系地址</li>
          <li>
            <p><input value="15816869108" v-model="datas.linkaddress"/></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">组织简介</li>
          <li>
            <p>
              <textarea class="form-control"  v-model="datas.intro" placeholder="可输入有关组织的描述介绍"></textarea>
            </p>
          </li>
        </ul>
        <div><a class="FormStyleOneBottomBtn btnRadius blueBackColor" @click="ChangeBusinessInfo">保存</a></div>
      </div>
     <div class="BasicInfoOneBoxRight">
        <div class="hint">组织自定义属性</div>
        <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item,index) in datas.customize_data" v-bind:key="index">
          <li class="FormStyleTwo" style="padding:2px 0;">
            <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key"/>
            <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value"/>
            <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
              <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,2,index)">{{$t('c.Common.Image')}}</a>
              <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{$t('c.Common.Delete')}}</a>
            </p>
          </li>
        </ul>
        <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="addAttributeRow"><span class="icon iconfont iconicon-test20"></span>{{$t('b.ProductManage.AddLine')}}</div>
      </div>

    </div>

    <!-- 模板信息 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-else-if="ix == 1">
      <div class="BasicInfoOneBoxLeft" v-if="datas && datas.template">
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">初始模板</li>
          <li class="FormStyleOneSelect">
            <select v-model="datas.template.state">
              <option v-for="s in states" :value="s.id">{{s.name}}</option>
            </select>
          </li>
        </ul>
         <ul class="FormStyleOne">
           <li class="FormStyleOneTitle">模板链接</li>
           <li>
             <p>
               <input v-model="datas.template.url" class="disabledInput" />
              </p>
           </li>
         </ul>
         <ul class="FormStyleOne">
           <li class="FormStyleOneTitle">模板标题</li>
           <li>
             <p>
               <input v-model="datas.template.title" class="disabledInput" />
              </p>
           </li>
         </ul>
         <ul class="FormStyleOne">
           <li class="FormStyleOneTitle">模板署名</li>
           <li>
             <p>
               <input v-model="datas.template.sign" class="disabledInput" />
              </p>
           </li>
         </ul>
         <div><a class="FormStyleOneBottomBtn btnRadius blueBackColor" @click="ChangeBusinessInfo">保存</a></div>
       </div>

      <div class="BasicInfoOneBoxRight">
         <div class="hint">模板信息</div>
         <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item,index) in datas.template.datas" v-bind:key="index">
           <li class="FormStyleTwo" style="padding:2px 0;">
             <input class="FormStyleTwoInputLeft" placeholder="标题" v-model="item.key"/>
             <input class="FormStyleTwoInputRight" placeholder="内容" v-model="item.value"/>
             <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
               <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{$t('c.Common.Delete')}}</a>
             </p>
           </li>
         </ul>
         <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="addAttributeRow"><span class="icon iconfont iconicon-test20"></span>{{$t('b.ProductManage.AddLine')}}</div>
       </div>
    </div>

    <!-- 开发者中心 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-else>
      <div class="DeveloperExplain" v-if="!isDeveloper">
        <div class="ListStyleOneTitle">你还没有成为开发者</div>
        <p class="DeveloperExplainTxt">成为微信公众平台开发者，你将可以使用公众平台的开发接口，在你自身服务器上接收用户的微信消息，并可按需回复。此外，我们还提供了更多更高级的接口来完善公众号的功能：</p>
        <p class="DeveloperExplainRow">1. 会话界面的自定义菜单</p>
        <p class="DeveloperExplainRow">2. 多客服接口，提供贴心快捷的客服服务</p>
        <p class="DeveloperExplainRow">3. 获取用户地址位置，精确提供服务</p>
        <p class="DeveloperExplainRow">4. 高级群发接口，实现更灵活的群发能力</p>
        <div class="DeveloperAgreement">
          <a><input type="checkbox" /></a>
          <a>同意</a>
          <a class="DeveloperAgreementData">《链接OID开发者协议》</a>
        </div>
        <div class="BecomeDeveloper btnRadius blueBackColor" @click="BecomeDeveloper">成为开发者</div>
      </div>

      <div v-else>
        <div class="ListStyleOneTitle">链接OID开发信息</div>
        <ul class="ListStyleOne DeveloperBox">
          <li>
            <div class="ListStyleOneLeft">开发者ID(AppID)</div>
            <div class="ListStyleOneRight">
              <p>wx05754b8136538a08</p>
              <p class="ListStyleOneRTxt">开发者ID是公众号开发识别码，配合开发者密码可调用公众号的接口能力。</p>
            </div>
          </li>
          <li>
            <div class="ListStyleOneLeft">开发者密钥(AppSecret)</div>
            <div class="ListStyleOneRight">
              <p class="blueColor ListStyleOneBtn">查看密钥</p>
              <p class="ListStyleOneRTxt">开发者密码是校验公众号开发者身份的密码，具有极高的安全性。切记勿把密码直接交给第三方开发者或直接存储在代码中。如需第三方代开发公众号，请使用授权方式接入。</p>
            </div>
          </li>
        </ul>

        <div class="ListStyleOneTitle ListStyleOneTitleTop">服务器配置(未启用)
          <div class="DeveloperBtnArray">
            <a class="DeveloperBtnEdit">修改配置</a>
            <a class="DeveloperBtnStar">启动</a>
          </div>
        </div>
        <ul class="ListStyleOne DeveloperBox">
          <li>
            <div class="ListStyleOneLeft">接口地址(URL)</div>
            <div class="ListStyleOneRight">
              <p>未填写</p>
            </div>
          </li>
          <li>
            <div class="ListStyleOneLeft">生码地址(URL)</div>
            <div class="ListStyleOneRight">
              <p>未填写</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 图片空间 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          图片空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>上传图片
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click="SelectActive(0)" v-if="SelectArr[0].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(0,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table ModalMainImgBox" >
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url,item.name)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'BasicInfo',
  components: {
    'K-Paging': Paging,
    'K-Loading': Loading,
    'K-Transition': Transition,
    'K-Toast': Toast
  },
  data () {
    return {
      ix:0,
      page:1,
      totalPages:0,
      isDeveloper:false,
      loading:false,
      subHint:'数据加载中...',
      isLoding:true,
      ModelShow:false,
      pid:0, //分组id
      imgsList:null,
      UploadType:null,
      NavList:["商户信息","模板信息"],
      states:[{id:0,name:"关闭"},{id:1,name:"开启"}],
      Confirm:{
        state:false,
        txt:null
      },
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      SelectArr:[{
        ix:0,
        active:false,
        datas:[]
      }],
      datas:{
        name:null,
        logo:null,
        origin:{template:{state:0,title:"",sign:"国家OID城市服务平台提供",url:"",datas:[]}}
      },
      customizeIx:null
    }
  },
  created () {
    self = this;
    self.datas.name = localStorage.getItem("wwmxt_Name");
    self.datas.logo = localStorage.getItem("wwmxt_Logo");
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetData();
    self.GetPacketList();
    self.GetImgsList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    HandleModel (status,te,etype) {  //隐藏显示生码模态框
      self.ModelShow = status;
      self.UploadType = te;
      self.customizeIx = etype;
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.page = 1;  //重置页数
      self.totalPages = null;  //清空分页总数
      self.pid = id;
      self.GetImgsList();  //获取空间图片列表
    },
    SelectActive (ix) {  //隐藏显示下拉内容
      if(self.SelectArr[ix].active){
        self.SelectArr[ix].active = false;
      }else{
        //隐藏所有下拉内容
        for(let item in self.SelectArr){
          self.SelectArr[item].active = false;
        }
        self.SelectArr[ix].active = true;
      }
    },
    selectImg (img,name) {
      console.log(self.UploadType)
      if(self.UploadType == 1){
        self.datas.logo = img;
      }else{
        self.datas.customize_data[self.customizeIx].key = "图片";
        self.datas.customize_data[self.customizeIx].value = img;
      }
      self.HandleModel(false);
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ClickNav (ix) {
      self.ix = ix;
    },
    BecomeDeveloper () {
      self.isDeveloper = true;
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"org")
      .then(function (response) {
        var datas = response.data.meta;
        if(datas.origin){
          var origin = JSON.parse(datas.origin);
          if(origin.template){
            datas.template = origin.template;
          }
        }else{
          datas.template = {state:0,title:"",sign:"国家OID城市服务平台提供",url:"",datas:[]};
        }

        self.datas = datas;

        //更新头部导航LOGO和状态
        localStorage.setItem("wwmxt_Logo",response.data.meta.logo);
        self.$emit('ListenBack');
        self.loading = false;
      }).catch(function (error){
        let msg;
        if (error.response) {
          if(error.response.data.message){
            msg = error.response.data.message;
          }else{
            msg = error.response.statusText;
          }
        }else{
          msg = "请检查网络连接！";
        }
        self.loading = false;
        self.ToastFun(2,msg);  //弹窗提醒
      })
    },
    ChangeBusinessInfo () {
      var origin = {template:self.datas.template};

      //FormDatas格式提交
      let datas = {
        logo:self.datas.logo,
        linkphone:self.datas.linkphone,
        linkman:self.datas.linkman,
        linkaddress:self.datas.linkaddress,
        intro:self.datas.intro,
        origin:JSON.stringify(origin),
        customize_data:JSON.stringify(self.datas.customize_data)
      };
      self.loading = true;
      axios.patch(self.GetServerUrl(1)+"org",datas)
      .then(function (response){
        self.GetData();
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[0].datas = response.data.meta
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = "数据为空";
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      if(self.UploadType == 1){  //logo
        FormDatas.append("belong","logo");
      }else{
        FormDatas.append("belong","blicense");
      }
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    addAttributeRow () {
      if(self.ix == 0){
        if(!self.datas.customize_data){
          self.datas.customize_data = [];
        }
        self.datas.customize_data.push({key:"",value:""});
      }else{
        if(!self.datas.template.datas){
          self.datas.template.datas = [];
        }
        self.datas.template.datas.push({key:"",value:""});
      }
    },
    RemoveRow (ix) {
      self.datas.customize_data.splice(ix,1);
    }
  }
}
</script>

<style scoped>
  .FormStyleOne input,.SelectStyle{
    width: 300px;
  }
  .FormStyleOneBottomBtn{
    padding:10px 18px;
    display: table;
    margin-top:25px;
    margin-left:90px;
    cursor: pointer;
  }
  .disabledInput{
    border:1px solid #DDD;
  }
  .FormStyleOneSelect select{
    height: 40px;
  }
</style>
