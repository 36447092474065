<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true,1)"><span class="icon iconfont iconicon-test20"></span>{{$t('c.LeftNav.CodeAllot')}}</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input v-model="name" placeholder="商户名搜索" />
          <a class="SearchBtn" v-text="$t('c.Common.Search')"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>
    <div class="TableHint"><i>*</i> {{$t('c.Common.FileHint')}}</div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Numbering')"></th>
            <th>商户</th>
            <th>数量</th>
            <th>首码</th>
            <th>尾码</th>
            <th>时间</th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.code_batch_id"></td>
            <td v-text="item.business.name"></td>
            <td v-text="item.code_count"></td>
            <td v-text="item.start_code"></td>
            <td v-text="item.end_code"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius cyanBackColor" v-text="$t('b.CodeActive.ReplaceData')" @click="HandleModel(true,2,item)"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 万能码分配窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            万能码分配
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,1)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">商户（选择需要分配的商户）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(2,$self)">
                    {{SelectArr[2].datas[SelectArr[2].ix].name}}
                    <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                      <li class='OptionStyleQuery' v-if="SelectArr[2].totalPages > 1" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery(2)">查询</a></li>
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[2].ix == index && !SelectArr[2].isHover}" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.name" @click="SelectData(2,index)" @mouseover="SelectHover($self,2,true)" @mouseout="SelectHover($self,2,false)"></li>
                        <li class="OptionStyleLoading" @click.stop="SelectArrLoading(2)" v-if="SelectArr[2].totalPages > 1 && SelectArr[2].page < SelectArr[2].totalPages">加载更多</li>
                        <li class="OptionStyleLoading" @click.stop v-if="SelectArr[2].totalPages > 1 && SelectArr[2].page >= SelectArr[2].totalPages">无更多内容</li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="uid == '1004'">
                <p class="FormStyleTwoTitle">{{$t('c.Common.Product')}}（{{$t('b.CodeActive.HintThree')}}）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    {{SelectArr[0].datas[SelectArr[0].ix].name}}
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <li class='OptionStyleQuery' v-if="SelectArr[0].totalPages > 1" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery(0)">查询</a></li>
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[0].ix == index && !SelectArr[0].isHover}" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.name" @click="SelectData(0,index)" @mouseover="SelectHover($self,0,true)" @mouseout="SelectHover($self,0,false)"></li>
                        <li class="OptionStyleLoading" @click.stop="SelectArrLoading(0)" v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page < SelectArr[0].totalPages">加载更多</li>
                        <li class="OptionStyleLoading" @click.stop v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page >= SelectArr[0].totalPages">无更多内容</li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="uid == '1004'">
                <p class="FormStyleTwoTitle">{{$t('c.Common.Batch')}}（{{$t('b.CodeActive.HintTwo')}}）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(1,$self)">
                    {{SelectArr[1].datas[SelectArr[1].ix].name}}
                    <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                      <li class='OptionStyleQuery' v-if="SelectArr[1].totalPages > 1" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery(1)">查询</a></li>
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[1].ix == index && !SelectArr[1].isHover}" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.name" @click="SelectData(1,index)" @mouseover="SelectHover($self,1,true)" @mouseout="SelectHover($self,1,false)"></li>
                        <li class="OptionStyleLoading" @click.stop="SelectArrLoading(1)" v-if="SelectArr[1].totalPages > 1 && SelectArr[1].page < SelectArr[1].totalPages">加载更多</li>
                        <li class="OptionStyleLoading" @click.stop v-if="SelectArr[1].totalPages > 1 && SelectArr[1].page >= SelectArr[1].totalPages">无更多内容</li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('c.Common.FirstCode')}}</p>
                <p><input :placeholder="$t('b.CodeActive.HintSix')" v-model="first_code" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('c.Common.LastCode')}}</p>
                <p><input :placeholder="$t('b.CodeActive.HintSeven')" v-model="last_code" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,1)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(1)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

      <!-- 数据替换窗口 -->
      <div class="ModalBox" v-if="ModelTwoShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            万能码分配
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false,2)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">商户（选择需要分配的商户）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(2,$self)">
                    {{SelectArr[2].datas[SelectArr[2].ix].name}}
                    <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                      <li class='OptionStyleQuery' v-if="SelectArr[2].totalPages > 1" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery(2)">查询</a></li>
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[2].ix == index && !SelectArr[2].isHover}" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.name" @click="SelectData(2,index)" @mouseover="SelectHover($self,2,true)" @mouseout="SelectHover($self,2,false)"></li>
                        <li class="OptionStyleLoading" @click.stop="SelectArrLoading(2)" v-if="SelectArr[2].totalPages > 1 && SelectArr[2].page < SelectArr[2].totalPages">加载更多</li>
                        <li class="OptionStyleLoading" @click.stop v-if="SelectArr[2].totalPages > 1 && SelectArr[2].page >= SelectArr[2].totalPages">无更多内容</li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="uid == '1004'">
                <p class="FormStyleTwoTitle">{{$t('c.Common.Product')}}（{{$t('b.CodeActive.HintThree')}}）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    {{SelectArr[0].datas[SelectArr[0].ix].name}}
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <li class='OptionStyleQuery' v-if="SelectArr[0].totalPages > 1" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery(0)">查询</a></li>
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[0].ix == index && !SelectArr[0].isHover}" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.name" @click="SelectData(0,index)" @mouseover="SelectHover($self,0,true)" @mouseout="SelectHover($self,0,false)"></li>
                        <li class="OptionStyleLoading" @click.stop="SelectArrLoading(0)" v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page < SelectArr[0].totalPages">加载更多</li>
                        <li class="OptionStyleLoading" @click.stop v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page >= SelectArr[0].totalPages">无更多内容</li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="uid == '1004'">
                <p class="FormStyleTwoTitle">{{$t('c.Common.Batch')}}（{{$t('b.CodeActive.HintTwo')}}）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(1,$self)">
                    {{SelectArr[1].datas[SelectArr[1].ix].name}}
                    <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                      <li class='OptionStyleQuery' v-if="SelectArr[1].totalPages > 1" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery(1)">查询</a></li>
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[1].ix == index && !SelectArr[1].isHover}" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.name" @click="SelectData(1,index)" @mouseover="SelectHover($self,1,true)" @mouseout="SelectHover($self,1,false)"></li>
                        <li class="OptionStyleLoading" @click.stop="SelectArrLoading(1)" v-if="SelectArr[1].totalPages > 1 && SelectArr[1].page < SelectArr[1].totalPages">加载更多</li>
                        <li class="OptionStyleLoading" @click.stop v-if="SelectArr[1].totalPages > 1 && SelectArr[1].page >= SelectArr[1].totalPages">无更多内容</li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('c.Common.FirstCode')}}</p>
                <p><input :placeholder="$t('b.CodeActive.HintSix')" v-model="first_code" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('c.Common.LastCode')}}</p>
                <p><input :placeholder="$t('b.CodeActive.HintSeven')" v-model="last_code" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false,2)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'ProductManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      uid:null,
      page:1,
      name:null,
      subHint:'数据加载中...',
      isLoding:true,
      datas:null,
      totalPages:0,
      ModelShow:false,
      ModelTwoShow:false,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      SelectArr:[{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      },{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      },{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      }],
      SQueryName:null,
      first_code:null,
      last_code:null,
      fileTxt:null,
      ac_id:null
    }
  },
  created () {
    self = this;
    this.$self = this;
    self.uid = localStorage.getItem("wwmxt_Rootid");
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
    self.GetBusinessList();
    if(self.uid == "1004"){
      self.GetBatchList();
      self.GetProductList();
    }
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HandleModel (status,te,datas) {  //隐藏显示生码模态框
      if(self.SelectArr[2].datas.length > 0){
        if(te == 1){
          self.ModelShow = status;
          self.first_code = null;
          self.last_code = null;
        }else{
          self.ModelTwoShow = status;
          if(datas){
            self.first_code = datas.start_code;
            self.last_code = datas.end_code;
          }
        }
      }else{
        self.ToastFun(1,"请先添加企业！");
      }
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.SelectArr[ix].isHover = false;
    },
    SubmitForm (te) {  //提交表单
      let ix,Datas,RootID,BatchID,ProductID;
      if(te == 1){
        ix = 1;
      }else{
        ix = 3;  //数据替换
      }

      if(self.SelectArr[2].datas && self.SelectArr[2].datas.length > 0){
        RootID = self.SelectArr[2].datas[self.SelectArr[2].ix].rootid;
      }else{
        self.ToastFun(1,"企业不能为空！");
        return;
      }

      if(self.uid == "1004"){
        if(self.SelectArr[1].datas.length > 0){
          BatchID = self.SelectArr[1].datas[self.SelectArr[1].ix].batch_id;
        }else{
          BatchID = 0;
        }
        ProductID = self.SelectArr[0].datas[self.SelectArr[0].ix].production_id
      }

      let first_code,last_code;
      if(self.first_code){
        first_code = self.FiltrationCode(self.first_code);
      }else{
        self.ToastFun(1,"首码不能为空！");
        return;
      }
      if(self.last_code){
        last_code = self.FiltrationCode(self.last_code);
      }else{
        self.ToastFun(1,"尾码不能为空！");
        return;
      }

      //首码格式校验
      if(first_code.length != 18 && first_code.length != 32){
        self.ToastFun(1,"首码格式不正确！");
        return;
      }
      //尾码格式校验
      if(last_code.length != 18 && last_code.length != 32){
        self.ToastFun(1,"尾码格式不正确！");
        return;
      }
      if(self.uid == "1004"){
        Datas = {start_code:first_code,end_code:last_code,rootid:RootID,production:ProductID,batch:BatchID};
      }else{
        Datas = {start_code:first_code,end_code:last_code,rootid:RootID};
      }

      self.loading = true;
      axios.post(self.GetServerUrl(1)+"distribution",Datas)
      .then(function (response){
        self.loading = false;

        if(te == 1){
          self.ModelShow = false;
        }else{
          self.ModelTwoShow = false;
        }

        self.ToastFun(0,response.data.message);
        self.GetList();

        //清空历史数据
        self.first_code = null;
        self.last_code = null;

      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    Remove (ix) {
      if(ix == "all"){
        self.Codes = [];
      }else{
        self.Codes.splice(ix,1);
      }
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"distribution_list/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    GetBusinessList (te) {
      axios.post(self.GetServerUrl(1)+"business_list",{page:self.page})
      .then(function (response){
        if(!te){
          self.SelectArr[2].datas = response.data.meta.data;
          self.SelectArr[2].totalPages = response.data.meta.last_page;
        }else{
          self.loading = false;
          let datas = response.data.meta.data;
          for(let item in datas){
            self.SelectArr[2].datas.push(datas[item]);
          }
        }
      }).catch(function (error){
        if(!te){
          self.loading = false;
        }
        console.log("产品信息获取失败！");
      })
    },
    GetBatchList (te) {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"batch_list/"+self.SelectArr[1].page)
      .then(function (response){
        if(!te){
          var list = response.data.meta.data;
          list.unshift({batch_id:0,name:"无"});
          self.SelectArr[1].datas = list;
          self.SelectArr[1].totalPages = response.data.meta.last_page;
        }else{
          self.loading = false;
          let datas = response.data.meta.data;
          for(let item in datas){
            self.SelectArr[1].datas.push(datas[item]);
          }
        }
      }).catch(function (error){
        if(!te){
          self.loading = false;
        }
      })
    },
    GetProductList (te) {
      axios.get(self.GetServerUrl(1)+"production_list/"+self.SelectArr[0].page)
      .then(function (response){
        if(!te){
          self.SelectArr[0].datas = response.data.meta.data;
          self.SelectArr[0].totalPages = response.data.meta.last_page;
        }else{
          self.loading = false;
          let datas = response.data.meta.data;
          for(let item in datas){
            self.SelectArr[0].datas.push(datas[item]);
          }
        }
      }).catch(function (error){
        if(!te){
          self.loading = false;
        }
        console.log("产品信息获取失败！");
      })
    },
    SelectArrQuery (te) {
      self.loading = true;
      let url;
      if(te == 0){
        url = "production_list";
      }else if(te == 1){
        url = "batch_list";
      }else {
        url = "business_list";
      }
      axios.post(self.GetServerUrl(1)+url,{name:self.SQueryName})
      .then(function (response){
        self.SelectArr[te].ix = 0;
        self.SelectArr[te].datas = response.data.meta.data;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SelectArrLoading (ix) {
      self.SelectArr[ix].page++;
      self.loading = true;
      if(ix == 0){
        self.GetProductList(true);
      }else if(ix == 1){
        self.GetBatchList(true);
      }else{
        self.GetBusinessList(true);
      }
    },
    AddCode () {
      let code = self.FiltrationCode(self.code);
      if(code){
        //重复校验
        if(self.RepeatCheck(self.Codes,code) == 1){
          //跨级校验
          if(self.Codes.length > 0){
            if(code.substring(0,1) != self.Codes[0].substring(0,1)){
              self.ToastFun(1,"请勿跨级激活码标！");
              return;
            }else{
              self.Codes.push(code);
              self.code = null;
            }
          }else{
            self.Codes.push(code);
            self.code = null;
          }
        }else{
          self.ToastFun(1,"不能重复插入码标！");
        }
      }else{
        self.ToastFun(1,"码标不正确！");
      }
    },
    UploadFile () {
      if(this.$refs.file.files[0].type != "text/plain"){
        self.ToastFun(1,"文件格式必须为txt！");
        self.fileTxt = null;
        return;
      }
      let size = (this.$refs.file.files[0].size/(1024 * 1024)).toFixed(2);
      if(size < 0.01){
        size = 0.01;
      }
      if(size > 20){
        self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
        self.fileTxt = null;
        return;
      }
      self.fileTxt = '（'+size+'M）' + this.$refs.file.files[0].name;
    },
    DownCodeFile (etype,id) {
      if(id){
        window.open("https://syapi.oid.plus/v1/downCodeFile/"+etype+"/"+id);
      }
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
</style>
