<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true,1)"><span class="icon iconfont iconicon-test20"></span>添加地址</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>收件人名称</th>
            <th>电话号码</th>
            <th>地址</th>
            <th>邮编</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td v-text="item.phone"></td>
            <td v-text="item.address"></td>
            <td v-text="item.zipCode"></td>
            <td class="LastTdWidth">
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor">编辑</a>
                <a class="btnStyleTwo btnRadius redBackColor">删除</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 添加发票收货地址 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            添加发票收货地址
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 收件人名称</p>
                <p><input placeholder="请填写收件人名称" v-model="FormDatas.name" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 电话号码</p>
                <p><input placeholder="请填写联系人电话号码" v-model="FormDatas.phone" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 详细地址</p>
                <p><input placeholder="请填写收件人详细地址" v-model="FormDatas.address" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 邮编</p>
                <p><input placeholder="请填写邮编号码" v-model="FormDatas.zipCode" /></p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'InvoiceAddress',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      te:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      ModelShow:false,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      datas:null,
      FormDatas:{
        name:null,
        phone:null,
        address:null,
        zipCode:null
      },
      column:[]
    }
  },
  created () {
    self = this;
    self.te = self.$route.query.type;
    if(self.te == "home"){
      self.column = [{name:'首页展示',url:'BusinessHomePage'},{name:'发票地址管理',url:'InvoiceAddress?type='+self.te}];
    }else{
      self.column = [{name:'费用中心',url:'BillingManagement'},{name:'发票地址管理',url:'InvoiceAddress'}];
    }
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        id:1,
        name:"梁宇鹏",
        phone:"15816869108",
        address:"	广东省深圳市龙华区龙华街道玉翠社区高坳新村三区71-72号602",
        zipCode:"518000"
      }];
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
    },
    SubmitForm () {  //提交表单
      self.loading = true;

      setTimeout(function(){
        self.loading = false;
        self.ModelShow = false;
        self.ToastFun(0,"添加成功！");  //弹窗提醒
      },1000)

      return;
      //有套餐ID等于编辑
      self.FormDatas.type = self.te;
      axios.post(self.GetServerUrl(1)+"space_group",self.FormDatas)
        .then(response => {
          self.HandleModel(false); //隐藏弹出框
          self.GetList();

          self.loading = false;
          self.ToastFun(0,response.data.message);  //弹窗提醒
      })
      .catch(error => {
        self.HandleModel(false); //隐藏弹出框

        let msg;
        if (error.response) {
          msg = error.response.data.message;
        } else {
          msg = error.message;
        }
        self.loading = false;
        self.ToastFun(2,msg);  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
</style>
