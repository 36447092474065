<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('DealerEdit?type=add')"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input placeholder="输入用户名称查询" />
          <a class="SearchBtn">搜索</a>
        </div>
      </ul>
      <ul class="TableHeadRight" style="margin-right:20px;">
        <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
          {{SelectArr[0].datas[SelectArr[0].ix]}}
          <ul class="OptionStyleBox" v-if="SelectArr[0].active">
            <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
          </ul>
          <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>中奖类型</th>
            <th>等级</th>
            <th>奖品</th>
            <th>用户</th>
            <th>头像</th>
            <th>手机</th>
            <th>中奖时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.te"></td>
            <td v-text="item.grade"></td>
            <td v-text="item.prize"></td>
            <td v-text="item.name"></td>
            <td><div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.img + ')'}"><img v-if="item.img" class="tableImgShow" :src="item.img" @click="PreviewImgs" /></div></td>
            <td v-text="item.phone"></td>
            <td v-text="item.date"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor">详情</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'WinningRecord',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      $self:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["全部","独立红包","摇一摇抽奖"]
      }]
    }
  },
  created () {
    self = this;
    this.$self = this;
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        te:'独立红包',
        grade:'-',
        prize:"20元红包",
        name:'啊创',
        img:'/public/static/images/login.png',
        phone:15816869108,
        WinningTotalMoney:650,
        WinningNumber:52,
        isConcern:'是',
        date:'2019-09-08 13:22'
      },{
        te:'摇一摇抽奖',
        grade:'三等奖',
        prize:"iphone7 plus",
        name:'啊戳',
        img:'/public/static/images/login.png',
        phone:15816869108,
        WinningTotalMoney:650,
        WinningNumber:52,
        isConcern:'是',
        date:'2019-09-08 13:22'
      }],
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width:120px;
  }
</style>
