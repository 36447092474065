<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>{{$t('b.CodePacket.CodeGrouping')}}</li>
      </ul>
      <ul class="clear"></ul>
    </div>
    <div class="TableHint"><i>*</i> {{$t('c.Common.FileHint')}}</div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Numbering')"></th>
            <th v-text="$t('b.CodePacket.CombinationType')"></th>
            <th v-text="$t('c.Common.CodeNumber')"></th>
            <th v-text="$t('b.CodePacket.NumberCombination')"></th>
            <th v-text="$t('b.CodePacket.NumberSuccessfulCombinations')"></th>
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.group_id"></td>
            <td>
              <a v-if="item.group_type == 1">{{$t('c.Common.Scan')}}</a>
              <a v-else-if="item.group_type == 2">{{$t('c.Common.Extent')}}</a>
              <a v-else>{{$t('c.Common.File')}}</a>
            </td>
            <td v-text="item.code_count"></td>
            <td v-text="item.group_count"></td>
            <td v-text="item.group_success_count"></td>
            <td class="TdStatus">
              <a v-if="item.result == 100"><i class="wait"></i>{{$t('c.Common.InProgress')}}</a>
              <a v-else-if="item.result == 200"><i class="reject"></i>{{$t('c.Common.Failure')}}</a>
              <a v-else-if="item.result == 300"><i class="pass"></i>{{$t('c.Common.Completed')}}</a>
              <a v-else-if="item.result == 400"><i class="reject"></i>{{$t('c.Common.Expired')}}</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" v-if="item.result == 300" @click="DownCodeFile(item.group_id)" v-text="$t('c.Common.Download')"></a>
                <a class="btnStyleTwo btnRadius ProhibitBtn" v-else v-text="$t('c.Common.Download')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 生码窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{$t('b.CodePacket.CodeGrouping')}}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">{{$t('b.CodePacket.PacketMode')}}（{{$t('b.CodePacket.HintOne')}}）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    {{$t('b.CodePacket.'+SelectArr[0].datas[SelectArr[0].ix]+'')}}
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="$t('b.CodePacket.'+item+'')" @click="SelectData(0,index)"></li>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> {{$t('b.CodePacket.GroupNumber')}}</p>
                <p><input :placeholder="$t('b.CodePacket.HintSeven')" v-model="group_number" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.SeniorFile')}}（{{$t('c.Common.FileSizeHint')}}）</p>
                <p class="btnArray">
                  <a class="btnStyleTwo btnRadius cyanBackColor"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.FileUpload')}}</a>
                  <a class="FormUpFileHint onlyOneRow" v-if="fileTxt" v-text="fileTxt"></a>
                  <input type="file" class="upload" @change="UploadFile(1)" ref="file" />
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodePacket.InferiorFile')}}（{{$t('c.Common.FileSizeHint')}}）</p>
                <p class="btnArray">
                  <a class="btnStyleTwo btnRadius cyanBackColor"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.FileUpload')}}</a>
                  <a class="FormUpFileHint onlyOneRow" v-if="file2Txt" v-text="file2Txt"></a>
                  <input type="file" class="upload" @change="UploadFile(2)" ref="file2" />
                </p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'PacketManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      totalPages:0,
      datas:null,
      HigherCode:null,
      LowCode:null,
      ModelShow:false,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["externalFilePacket"]
      }],
      Codes:[],
      code:null,
      gp_type:0,
      first_code:null,
      last_code:null,
      group_number:null,
      fileTxt:null,
      file2Txt:null,
      file3Txt:null
    }
  },
  created () {
    self = this;
    this.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if(ix == 0){
        self.gp_type = six;
      }
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"codeGroupList/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    SubmitForm () {  //提交表单
      let Datas;
      if(!self.fileTxt || !self.file2Txt){
        self.ToastFun(1,"文件不能为空！");
        return;
      }
      let fileDOM,fileDOM2;
      fileDOM = this.$refs.file;
      fileDOM2 = this.$refs.file2;

      //FormDatas格式提交
      Datas = new FormData();
      Datas.append("UpCodeFile",fileDOM.files[0]);
      Datas.append("DownCodeFile",fileDOM2.files[0]);
      Datas.append("group_number",self.group_number);
      Datas.append("gp_type",self.gp_type);
      Datas.append("rootid",localStorage.getItem("wwmxt_Rootid"));
      self.loading = true;
      axios.post(self.GetServerUrl(1)+ "externalFileGroup",Datas)
      .then(function (response){
        self.loading = false;
        self.ModelShow = false;
        self.ToastFun(0,response.data.message);
        self.GetList();
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    UploadFile (te) {
      if(te == 1){
        if(this.$refs.file.files[0].type != "text/plain"){
          self.ToastFun(1,"文件格式必须为txt！");
          self.fileTxt = null;
          return;
        }
        let size = (this.$refs.file.files[0].size/(1024 * 1024)).toFixed(2);
        if(size < 0.01){
          size = 0.01;
        }
        if(size > 20){
          self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
          self.fileTxt = null;
          return;
        }
        self.fileTxt = '（'+size+'M）' + this.$refs.file.files[0].name;
      }else if(te == 2){
        if(this.$refs.file2.files[0].type != "text/plain"){
          self.ToastFun(1,"文件格式必须为txt！");
          self.file2Txt = null;
          return;
        }
        let size = (this.$refs.file2.files[0].size/(1024 * 1024)).toFixed(2);
        if(size < 0.01){
          size = 0.01;
        }
        if(size > 20){
          self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
          self.file2Txt = null;
          return;
        }
        self.file2Txt = '（'+size+'M）' + this.$refs.file2.files[0].name;
      }else if(te == 3){
        if(this.$refs.file3.files[0].type != "text/plain"){
          self.ToastFun(1,"文件格式必须为txt！");
          self.file3Txt = null;
          return;
        }
        let size = (this.$refs.file3.files[0].size/(1024 * 1024)).toFixed(2);
        if(size < 0.01){
          size = 0.01;
        }
        if(size > 20){
          self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
          self.file3Txt = null;
          return;
        }
        self.file3Txt = '（'+size+'M）' + this.$refs.file3.files[0].name;
      }
    },
    DownCodeFile (id) {
      if(id){
        window.open("https://syapi.oid.plus/v1/downCodeFile/6/"+id);
      }
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
  .SelectStyle{
    width: 120px;
  }
</style>
