<template>
	<div class="MainBox">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input placeholder="输入商标名称查询" />
          <a class="SearchBtn">搜索</a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable">
        <thead>
          <tr>
            <th>商户名称</th>
            <th>商标名称</th>
            <th>商标证书</th>
            <th>授权书</th>
            <th>申请时间</th>
            <th>状态</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.business.name"></td>
            <td v-text="item.trademark_name"></td>
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.trademark_cert + ')'}" v-if="item.trademark_cert"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td>
              <div class="tableImg backImg" v-if="item.attribute" :style="{backgroundImage: 'url('+item.attribute+')'}"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td class="TdStatus">
              <a v-if="item.status == 1"><i class="wait"></i>待审核</a>
              <a v-else-if="item.status == 2"><i class="reject"></i>已驳回</a>
              <a v-else><i class="pass"></i>已通过</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius" :class="{blueBackColor:item.status != 3,ProhibitBtn:item.status == 3}" @click="BrandCheck(3,item.trademark_id,item.status)">通过</a>
                <a class="btnStyleTwo btnRadius" :class="{redBackColor:item.status != 2,ProhibitBtn:item.status == 2}" @click="BrandCheck(2,item.trademark_id,item.status)">驳回</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>

    <!-- 商标驳回理由 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          商标驳回
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 驳回理由（请输入商标驳回的理由）</p>
              <p>
                <textarea placeholder="在此输入驳回内容" v-model="msg"></textarea>
              </p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'TrademarkAudit',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      page:1,
      id:null,
      status:null,
      msg:null,
      ModelShow:false,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HandleModel (status) {
      self.ModelShow = status;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"trademark_list/"+self.page)
      .then(function (response){
          let datas = [];
          self.datas = response.data.meta.data;
          self.isLoding = false;

          // //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    BrandCheck (status,id,state) {
      self.id = id;
      self.status = status;
      if(status == 2){
        if(state != 2){
          self.status = 2;
          self.HandleModel(true);
        }
      }else{
        if(state != 3){
          self.Confirm.state = true;
          self.Confirm.txt = "是否确认审核通过？"
        }
      }
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.CheckResult();
      }
    },
    CheckResult () {
      self.Confirm.state = false;
      let datas = {trademark_id:self.id,status:self.status};
      if(self.status == 2){
        datas.msg = self.msg;
      }
      self.loading = true;
      axios.patch(self.GetServerUrl(1)+"admin_trademark",datas)
      .then(function (response){
        self.loading = false;
        if(self.status == 2){
          self.HandleModel(false);
          self.msg = null;
        }
        self.GetList();
      }).catch(function (error){
        self.loading = false;
      })
    },
    SubmitForm () {  //提交表单
      self.CheckResult();
    }
  }
}
</script>

<style scoped>

</style>
