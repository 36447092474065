<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(1,true)"><span class="icon iconfont iconicon-test20"></span>添加流程</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Image')"></th>
            <th>名称</th>
            <th>排序</th>
            <th>时间</th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.process_img + ')'}" v-if="item.process_img"><img v-if="item.process_img" class="tableImgShow" :src="item.process_img" @click="PreviewImgs"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td v-text="item.process_name"></td>
            <td v-text="item.by_sort"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(1,true,item)" v-text="$t('c.Common.Edit')"></a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.id)" v-text="$t('c.Common.Delete')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 批次添加 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            添加流程
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(1,false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>{{$t('c.Common.Name')}}</p>
                <p><input class="layui-input" placeholder="请输入流程名称" v-model="process.name" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>时间</p>
                <p>
                  <input type="text" lay-verify="required" class="layui-input" id="publish_date" placeholder="发布开始时间" autocomplete="off">
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">排序</p>
                <p><input class="layui-input" v-model="process.sort" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">图片</p>
                <p><input class="layui-input" placeholder="图片小于2M，格式为 JPG、PNG或GIF" v-model="process.img" /></p>
                <p class="btnStyleTwo btnRadius blueBackColor btn-right" style="display:table;" @click="HandleModel(2,true)">选择图片</p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(1,false)" v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(1)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

      <!-- 图片空间 -->
      <div class="ModalBox" v-if="ModelTwoShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            图片空间
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="TableHead ModalMainImgHead">
            <ul class="TableHeadLeft">
              <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
                <span class="icon iconfont iconicon-test20"></span>上传图片
                <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
              </li>
            </ul>
            <ul class="TableHeadRight">
              <div class="SelectStyle ModalSelect" @click.stop="SelectActive(0,$self)" v-if="SelectArr[0].datas.length > 0">
                <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].group_name}}</div>
                <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                  <ul class="OptionStyleMain">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(0,index,item.space_group_id)"></li>
                  </ul>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
            </ul>
            <ul class="clear"></ul>
          </div>
          <div class="ModalMainBox ModalMainImgBox">
            <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
            <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
              <table class="table" >
                <tbody class="TRHoverStyle">
                  <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url)">
                    <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                    <td v-text="item.name"></td>
                    <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                  </tr>
                </tbody>
              </table>
              <!-- 分页器 -->
              <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
            </div>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)">确定</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self,layer,form,laydate;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Column from '@/components/common/Column.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'BatchManage',
  components: {
    'K-Paging': Paging,
    'K-Column': Column,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      pid:null,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      column:[
        {name:'BatchManage',url:'BatchManage'},
        {name:'DemoProcess',url:'DemoProcess'},
      ],
      pid:0, //分组id
      imgsList:null,
      SelectArr:[{
        ix:0,
        active:false,
        datas:[]
      }],
      totalPages:0,
      datas:null,
      ModelShow:false,
      ModelTwoShow:false,
      process:{
        id:null,
        name:null,
        sort:null,
        img:null
      }
    }
  },
  created () {
    self = this;
    self.$self = this;
    if(this.$route.query.id){
      self.id = self.$route.query.id;
    }
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetList();
    self.GetPacketList();
    self.GetImgsList();
  },
  mounted () {
    layui.use(['form', 'layer', 'laydate'], function() {
      form = layui.form;
      layer = layui.layer;
      laydate = layui.laydate;
    });
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    HandleModel (te,status,data) {  //隐藏显示生码模态框
      if(te == 1){
        var date;
        if(data){
          self.process.id = data.id;
          self.process.name = data.process_name;
          self.process.img = data.process_img;
          self.process.sort = data.by_sort;
          date = data.created_at;
        }else{
          self.process.id = null,
          self.process.name = null,
          self.process.sort = null,
          self.process.img = null
          self.process.date = null;
        }

        self.ModelShow = status;
        self.$nextTick(() => {
          laydate.render({
            elem: '#publish_date',
            type: 'datetime',
            trigger: 'click',
            value: date,
            done: function(value, date, endDate) {
              self.process.date = value;
            }
          });
        });
      }else if(te == 2){
        self.ModelTwoShow = status;
      }
    },
    SubmitForm (te) {  //提交表单
      if(te == 1){
        self.ProcessSubmit();
      }else if(te == 2){
        self.ExpressSubmit();
      }
    },
    SwicthSlide (te,ix) {
      if(te == 1){
        if(self.datas[ix].isQuality){
          self.datas[ix].isQuality = false;
        }else{
          self.datas[ix].isQuality = true;
        }
      }else{
        if(self.datas[ix].isExperss){
          self.datas[ix].isExperss = false;
        }else{
          self.datas[ix].isExperss = true;
        }
      }
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.page = 1;  //重置页数
      self.totalPages = null;  //清空分页总数
      self.pid = id;  //清楚分组ID
      self.imgsList = null;  //清除图片列表
      self.GetImgsList();  //获取空间图片列表
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"production_process_demo_list/"+self.id)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[0].datas = response.data.meta
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    selectImg (img) {
      self.process.img = img;
      self.HandleModel(2,false);
    },
    ProcessSubmit () {
      self.loading = true;
      let type;
      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("batch_id",self.id);
      FormDatas.append("process_name",self.process.name);
      FormDatas.append("process_img",self.process.img);
      FormDatas.append("created_at",self.process.date);
      FormDatas.append("by_sort",self.process.sort);
      var _url = "production_process_demo";
      if(self.process.id){
        _url = "production_process_demo_update";
        FormDatas.append("id",self.process.id);
      }
      axios({
        method:"post",
        url:self.GetServerUrl(1)+_url,
        data:FormDatas,
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveData (id) {
      self.pid = id;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该流程？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;
        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"production_process_demo_list/"+self.pid,
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }

  .FormStyleTwoCustom{
    position: relative;
    margin-bottom: 20px;
  }
  .FormStyleTwoCustom input{
    width: 320px;
  }
  .FormStyleTwoCustomBtnArr{
    position: absolute;
    right:0;
    bottom:2px;
    overflow: hidden;
  }
  .FormStyleTwoCustomBtnArr a{
    width:80px;
    text-align: center;
    display: block;
    float: left;
    height: 35px;
    line-height:35px;
    cursor: pointer;
  }
  .FormStyleTwoUpImg{
    margin-right:10px;
  }
  .FormStyleTwoCenterBtn{
    display: table;
    margin:0 auto;
  }
  .FormStyleTwoCenterBtn a{
    margin-right:5px;
    position: relative;
    top:1px;
  }
  .btn-right{
    position: absolute;
    right:20px;bottom:0;
    border-radius: 0;
    padding:0 12px;
    height: 35px;
    line-height: 35px;
  }
</style>
