<template>
	<div class="MainBox">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('DealerEdit?type=add')"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable" v-if="oid != 1004">
        <thead>
          <tr>
            <th>类型</th>
            <th>名称</th>
            <th>留言内容</th>
            <th>回复内容</th>
            <th>时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td>
              <a v-if="item.role == 10">管理员</a>
              <a v-else-if="item.role == 20">组织</a>
              <a v-else-if="item.role == 30">商户</a>
            </td>
            <td v-text="item.name"></td>
            <td v-text="item.content"></td>
            <td class="redColor" v-text="item.reply_content"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,item.feelback_id)">回复</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>所购产品</th>
            <th>购买数量</th>
            <th>购买日期</th>
            <th>购买地址</th>
            <th>购买人</th>
            <th>联系电话</th>
            <th>质量描述</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td style="padding:25px 20px" v-for="(val,ix) in item" v-bind:key="ix">
              <a v-if="val.value" v-text="val.value"></a>
              <a v-else>-</a>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>

    <!-- 留言回复 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          留言回复
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i> 留言回复（请输入意见反馈回复内容）</p>
              <p>
                <textarea placeholder="在此输入回复内容" v-model="msg"></textarea>
              </p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'Message',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      oid:null,
      uid:null,
      page:1,
      msg:null,
      id:null,
      ModelShow:false,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null
    }
  },
  created () {
    self = this;
    self.uid = localStorage.getItem("wwmxt_Rootid");
    if(localStorage.getItem("wwmxt_Rootid")){
      self.oid = localStorage.getItem("wwmxt_Rootid").substring(0,4);
    }
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    HandleModel (status,id) {
      self.id = id;
      self.ModelShow = status;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"feelback_list/"+self.page)
      .then(function (response){
        var datas = response.data.meta.data;
        if(self.oid == 1004){
          for(let i in datas){
            datas[i] = JSON.parse(datas[i].content);
          }
        }
        console.log(datas)
        self.datas = datas;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    SubmitForm () {
      if(!self.msg){
        self.ToastFun(2,"回复的内容不能为空！");
        return;
      }
      self.loading = true;
      axios.post(self.GetServerUrl(1)+"feelback_admin",{id:self.id,body:self.msg})
      .then(function (response){
        self.loading = false;
        self.ModelShow = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));
      })
    }
  }
}
</script>

<style scoped>

</style>
