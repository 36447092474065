<template>
    <li class="FormStyleTwoRow">
        <div class="FormStyleTwoTitle">
            <span>{{ $t(selectItem.title) }}</span>
            <span v-if="selectItem.hint">（{{ $t(selectItem.hint) }}）</span>
        </div>
        <div>
            <div class="SelectStyle" v-if="selectItem.datas.length > 0" @click="handlerSelect({type:'active',selectIx:selectIx})">
                
                <!-- 数组格式标题 -->
                <span v-if="selectItem.type === 'static'">{{ $t(selectItem.datas[selectItem.ix]) }}</span>

                <!-- Json格式标题 -->
                <span v-else>{{selectItem.datas[selectItem.ix].nyname || selectItem.datas[selectItem.ix].name }}</span>

                <ul class="OptionStyleBox" v-if="selectItem.active">

                    <!-- 搜索框 -->
                    <li class='OptionStyleQuery' v-if="selectItem.search" @click.stop>
                        <input placeholder="可在此查询内容" 
                            @keyup.enter="handlerSelect({type:'search',selectIx:selectIx,url:selectItem.searchUrl})"
                            v-model="keyword"
                        />
                        <a class="OptionStyleQueryBtn" @click.stop="handlerSelect({type:'search',selectIx:selectIx,url:selectItem.searchUrl})" >查询</a>
                    </li>
                    <!-- 搜索框 -->
                    
                    <!-- 下拉框内容 -->
                    <ul class="OptionStyleMain">
                        
                        <!-- option -->
                        <li class="OptionStyleRow"
                            v-for="(item, index) in selectItem.datas"
                            v-bind:key="index"
                            :class="{ OptionStyleRowActive: selectItem.ix == index && !selectItem.isHover }"
                            @click.stop="handlerSelect({type:'check',selectIx:selectIx,optionIx:index})"
                        >
                        {{selectItem.type === 'static'?$t(item):item.nyname||item.name}}
                        </li>
                        <!-- option -->

                        <li class="OptionStyleLoading"
                            @click.stop="handlerSelect({type:'more',selectIx:selectIx,url:selectItem.listUrl})"
                            v-if="selectItem.totalPages > 1 && selectItem.page < selectItem.totalPages"
                        >加载更多</li>
                        <li class="OptionStyleLoading"
                            v-if="selectItem.totalPages > 1 && selectItem.page >= selectItem.totalPages"
                        >无更多内容</li>
                    </ul>
                    <!-- 下拉框内容 -->

                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
            <div v-else class="SelectStyle">无</div>
        </div>
        <div v-if="selectItem.name === '生产时间'"><input 
            v-if="selectItem.ix == 2"
            id="ship_date"
            style="width: 100%;"
            :placeholder="$t('b.Dealer.HintEight')"
            type="text"
            class="layui-input"
            placeholder=" - "
            autocomplete="off"
        /></div>
    </li>
</template>

<script>
var form, laydate;
export default {
    name: "SelectView",
    props: ["selectItem", "selectIx"],
    data (){
        return {
            keyword: ""
        }
    },
    methods: {
        handlerSelect (params){
            params.keyword = this.keyword; //搜索框内容
            this.$emit('selectActive',params); //返回数据给父组件

             //生产日期
            if (params.selectIx == 1 && params.optionIx == 2) {
                const self = this;
                this.$nextTick(()=>{
                    laydate.render({
                        elem: '#ship_date',
                        trigger: 'click',
                        done: function (value, date, endDate) {
                            params.ship_date = value; //搜索框内容
                            self.$emit('selectActive',params); //返回数据给父组件
                        }
                    });
                })
            }
        }
    },
    mounted () {
        layui.use(['form', 'laydate'], function () {
            form = layui.form;
            laydate = layui.laydate;
            form.render();
        });
    }
}
</script>

<style scoped>

</style>