<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>当前库存</th>
            <th>类型</th>
            <th>数量</th>
            <th>备注</th>
            <th>时间</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.cStock"></td>
            <td>
              <a v-if="item.te == 1">收货</a>
              <a v-if="item.te == 2">发货</a>
              <a v-if="item.te == 3">报废</a>
              <a v-else>新增</a>
            </td>
            <td>
              <a class="redColor" v-if="item.te == 1" v-text="'+'+item.number"></a>
              <a class="blueColor" v-if="item.te == 2" v-text="'-'+item.number"></a>
              <a class="blueColor" v-if="item.te == 3" v-text="'-'+item.number"></a>
              <a class="redColor" v-else v-text="'+'+item.number"></a>
            </td>
            <td v-text="item.remarks"></td>
            <td v-text="item.date"></td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
export default {
  name: 'ManualStockRecord',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column
  },
  data () {
    return {
      te:null,
      name:null,
      pid:null,
      pname:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      column:[],
    }
  },
  created () {
    self = this;
    self.te = self.$route.query.type;
    self.name = self.$route.query.name;
    self.pid = self.$route.query.pid;
    self.pname = self.$route.query.pname;
    if(self.te == "process"){
      self.column = [{name:'工序列表',url:'ProcessList'},{name:self.name+' - 库存列表',url:'StockManage?type='+self.te+'&name='+self.name},{name:self.pname+' - 手动记录',url:'StockDetails?type='+self.te+'&name='+self.name+'&pid='+self.pid+'&pname='+self.pname}];
    }else{
      self.column = [{name:'经销商列表',url:'DealerList'},{name:self.name+' - 库存列表',url:'StockManage?type='+self.te+'&name='+self.name},{name:self.pname+' - 手动记录',url:'StockDetails?type='+self.te+'&name='+self.name+'&pid='+self.pid+'&pname='+self.pname}];
    }
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        cStock:1320,
        te:4,
        number:100,
        date:"2019-10-08 15:22",
        remarks:"报废后重新修好了"
      },{
        cStock:1220,
        te:3,
        number:100,
        date:"2019-10-07 12:44",
        remarks:"加工的时候报废了"
      }];
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
  tbody > tr > td{
    padding:22px 20px;
  }
</style>
