<template>
  <div class="ConfirmBox" v-if="Confirm.state">
    <div class="ConfirmMain" :style="{marginTop:boxTop+'px'}">
      <div class="ConfirTitle">
        <span class="icon iconfont iconicon-test65 blueColor ConfirIcon" v-if="Confirm.style == 1"></span>
        <span class="icon iconfont iconicon-test44 orangeColor ConfirIcon" v-else-if="Confirm.style == 2"></span>
        <span class="icon iconfont iconicon-test44 orangeColor ConfirIcon" v-else></span>
        链接OID提示
        <span class="icon iconfont iconicon-test29 ConfirClose" @click="ConfirmBackFun(false)"></span>
      </div>
      <div class="ConfirText" v-text="Confirm.txt"></div>
      <div class="ConfirBtnArr NoSelect">
        <a class="ConfirBtnConfirm blueBackColor" @click="ConfirmBackFun(true)">确定</a>
        <a class="ConfirBtnCancel grayBackTwoColor" @click="ConfirmBackFun(false)">取消</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  props:["Confirm"],
  data () {
    return {
      boxTop: 200
    }
  },
  created () {
    this.boxTop = document.documentElement.clientHeight/2 - 135;
  },
  methods:{
    ConfirmBackFun (te) {
      this.$emit('ConfirmBackFun',{state:te});
    }
  }
}
</script>

<style scoped>
  .ConfirmBox{
    position: fixed;
    top:0;bottom:0;
    right:0;
    left:0;
    z-index: 1000;
    background: rgba(0,0,0,0.3);
  }
  .ConfirmMain{
    display: table;
    margin:0 auto;
    padding:20px;
    width:360px;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }
  .ConfirTitle{
    font-size:16px;
    position: relative;
  }
  .ConfirIcon{
    margin-right:5px;
    font-size:17px;
    position: relative;
    top:1px;
  }
  .ConfirClose{
    position: absolute;
    right:0px;
    top:3px;
    color:#999;
    font-size:14px;
    cursor: pointer;
  }
  .ConfirClose:hover{
    color:#777;
  }
  .ConfirText{
    line-height:23px;
    margin:20px 0;
    font-size:15px;
    text-align: center;
  }
  .ConfirBtnArr{
    overflow: hidden;
  }
  .ConfirBtnArr a{
    display: block;
    float: right;
    width:60px;
    height:30px;
    line-height:30px;
    text-align: center;
    cursor: pointer;
  }
  .ConfirBtnConfirm{
    margin-left:12px;
  }
  .ConfirBtnCancel{
    border:1px solid #E9E9E9;
  }
</style>
