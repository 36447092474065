// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import VueI18n from 'vue-i18n'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'
import BaiduMap from 'vue-baidu-map'

Vue.use(VueI18n) // 通过插件的形式挂载
Vue.use(Viewer) //图片放大预览
Vue.use(ElementUI, {locale})
Vue.use(BaiduMap,{
  ak: '9zPRVXvEWGFaKfltWv9AF8tZWZmwngtF'
})

const i18n = new VueI18n({
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    locale: localStorage.getItem('wwmxt_locale') || 'zh',    // 语言标识
    messages: {
      'zh': require('./assets/js/lang/zh'),   // 中文语言包
      'en': require('./assets/js/lang/en')    // 英文语言包
    }
})

// 这里是插件的默认设置
Viewer.setDefaults({
　zIndexInline: 9999,
  navbar:false
})

/* URL */
Vue.prototype.GetServerUrl = function (v) {
    // return 'http://120.79.56.121:9091/v'+v+'/' // 测试环境
    return 'https://syapi.oid.plus/v'+v+'/' // 生产环境
}

/* URL */
Vue.prototype.GetServerFileUrl = function (v,id) {
    return 'https://syapi.oid.plus/v1/downCodeFile/'+v+'/'+id
}

/* URL */
Vue.prototype.GetViewURL = function (v) {
    return 'https://lj.oid.plus/'
}

/* 跳转页面 */
Vue.prototype.GoToPage = function (url) {
  this.$router.push({path:url}) // 路由跳转
}

/* 打开新页面 */
Vue.prototype.OpenNewPage = function (url,value) {
  let routeUrl = this.$router.resolve({
    path: "/"+url,
    query: {id:value}
  });
  window.open(routeUrl.href, '_blank');
}

/* 码标校验 */
Vue.prototype.FiltrationCode = function (str) {
  let firstIndex = str.indexOf("?")+1;
  let lastIndex = str.length;
  let code = str.substring(firstIndex,lastIndex);
  if(code.length == 18 || code.length == 32){
    return code;
  }else{
    return false;
  }
}

/* 码标数组重复校验 */
Vue.prototype.RepeatCheck = function (codeArr,code) {
  if(codeArr.length >= 1){
    for(let item in codeArr){
      if(codeArr[item] == code){
        return 2;
      }
      if(item == codeArr.length-1){
        return 1;
      }
    }
  }else{
    return 1;
  }
}

/* 获取码标等级 */
Vue.prototype.LevelCalibration =  function (code) {
  let te,level;
  if(code.length == 18){
    te = 1;  //普通行业
    if(code.substring(0,1) == 'A'){
      level = 1;
    }else if(code.substring(0,1) == 'B'){
      level = 2;
    }else if(code.substring(0,1) == 'C'){
      level = 3;
    }else if(code.substring(0,1) == 'D'){
      level = 4;
    }else{
      level = 5;
    }
  }else if(code.length == 32){
    te = 2;  //农药行业
    level = code.substring(11,12);
  }
  return level;
}

/* 点击其他地方隐藏下拉框 */
Vue.prototype.SelectActive = function (ix,self) {
  self.keyword = '';
  if(ix != -1 && self.SelectArr[ix].active){
    self.SelectArr[ix].active = false;
  }else{
    //隐藏所有下拉内容
    for(let item in self.SelectArr){
      self.SelectArr[item].active = false;
    }
    if(ix != -1){
      self.SelectArr[ix].active = true;
    }
  }
}


/* 下拉框焦点样式 */
Vue.prototype.SelectHover = function (self,ix,status) {
  self.SelectArr[ix].isHover = status;
}

/* 错误提示 */
Vue.prototype.ErrorHint = function (error) {
  let msg;
  if (error.response) {
    if(error.response.data.message){
      msg = error.response.data.message;
    }else{
      msg = error.response.statusText;
    }
  }else{
    msg = "请检查网络连接！";
  }
  return msg;
}

/* 倒计时 */
Vue.prototype.GetRTime = function (endTime) {
  let EndTime = new Date(endTime);
  let NowTime = new Date();
  let t = EndTime.getTime() - NowTime.getTime();
  let d = 0;
  let h = 0;
  let m = 0;
  let s = 0;
  if(t >= 0) {
    d = Math.floor(t / 1000 / 60 / 60 / 24);
    h = Math.floor(t / 1000 / 60 / 60 % 24);
    m = Math.floor(t / 1000 / 60 % 60);
    s = Math.floor(t / 1000 % 60);
  }else{
    return 'timeOver'
  }
  return [d,h,m,s]
}

/* 获取经销商ID */
Vue.prototype.GoDealerID = function (idOne,idTwo,idThree,idFour) {
  let did;
  if(idOne && idOne > 0){
    did = idOne;
  }
  if(idTwo && idOne > 0){
    did = did +""+ idTwo;
  }
  if(idThree && idOne > 0){
    did = did +""+ idThree;
  }
  if(idFour && idOne > 0){
    did = did +""+ idFour;
  }
  return did;
}

/* 时间 */
function filling(m) {
  return m < 10 ? '0' + m : m
}
Vue.prototype.CurrentDateFun = function(isNew) {
  var time = new Date();
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  if(isNew){
    const weekdays = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    const today = weekdays[new Date().getDay()];
    return y + '年' + filling(m) + '月' + filling(d) + '日 ' + filling(h) + ':' + filling(mm) + ':' + filling(s) + ' '+ today;
  }
  return y + '-' + filling(m) + '-' + filling(d) + ' ' + filling(h) + ':' + filling(mm) + ':' + filling(s);
}

//数组判断
Vue.prototype.isInArray = function(arr, value) {
  for (var i = 0; i < arr.length; i++) {
    if (value === arr[i]) {
      return true;
    }
  }
  return false;
}

//设备判断
Vue.prototype.isMobileDevice = function() {  
  // 这些字符串是常见的移动设备的userAgent的一部分  
  var mobileUserAgents = [  
      "Android", "iPhone", "iPad", "iPod", "BlackBerry", "Windows Phone", "Opera Mini",  
      "IEMobile", "WPDesktop", "Opera Mobi", "webOS", "Nokia", "Symbian", "Samsung",  
      "Mobile", "Opera Mobi", "Mobile Safari", "Mobile Mozilla", "Mobile Firefox",  
      "Windows CE", "UCWEB", "UC Browser", "Mobile Browser", "Dolfin", "Dolphin",  
      "Skyfire", "Zune", "Nintendo Wii", "PlayStation", "Kindle", "Silk", "BlackBerry Tablet OS",  
      "MEIZU", "MQQBrowser", "XiaoMi", "HiBrowser", "VivoBrowser", "LBBROWSER", "MicroMessenger" // 微信内置浏览器  
  ];  

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;  

  // 检查userAgent是否包含上述移动设备的字符串  
  for (var i = 0; i < mobileUserAgents.length; i++) {  
      if (userAgent.indexOf(mobileUserAgents[i]) > -1) {  
          return true; // 是移动设备  
      }  
  }  

  return false; // 不是移动设备，可能是PC  
}

//传入天数获取日期数组
Vue.prototype.getPreviousDaysDates = function (days) {  
  // 确保传入的days是整数，并且是正数  
  if (typeof days !== 'number' || !Number.isInteger(days) || days <= 0) {  
      throw new Error('Days must be a positive integer');  
  }  

  // 创建一个数组来存储日期  
  const dates = [];  

  // 获取当前日期  
  let currentDate = new Date();  

  // 遍历天数  
  for (let i = 0; i < days; i++) {  
      // 设置日期为前一天  
      currentDate.setDate(currentDate.getDate() - 1);  

      // 提取年月日并格式化为字符串  
      const year = currentDate.getFullYear();  
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // getMonth() 返回的是0-11，所以需要+1  
      const day = String(currentDate.getDate()).padStart(2, '0');  

      // 将格式化的日期添加到数组中  
      dates.unshift(`${year}-${month}-${day}`);  
  }  

  // 返回日期数组  
  return dates;  
}

//开发日志打印
Vue.prototype.log = function (...args) {
  if(process.env.NODE_ENV === 'development'){
    console.log(...args);
  }
}

/* 获取URL参数 */
Vue.prototype.GetParams = function() {
  const url = window.location.href;
  const paramsRegex = /[?&]+([^=&]+)=([^&]*)/gi;
  const params = {};
  let match;
  while (match = paramsRegex.exec(url)) {
    params[match[1]] = match[2];
  }
  return params;
}

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')