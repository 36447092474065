<template>
	<div class="MainBox">
    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 头部 -->
    <!-- <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
        <li class="TableHeadLeftRow TableHeadHint">扫码总数：3</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SelectStyle" @click="SelectActive(0)">
          {{SelectArr[0].datas[SelectArr[0].ix]}}
          <ul class="OptionStyleBox" v-if="SelectArr[0].active">
            <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
          </ul>
          <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div> -->

    <!-- 表格内容 -->
    <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
    <table class="table StripeTable" v-else>
      <thead>
        <tr>
          <th>码标</th>
          <th>产品</th>
          <th>扫码地区</th>
          <th>扫码时间</th>
          <!-- <th>操作</th> -->
        </tr>
      </thead>
      <tbody class="TRHoverStyle">
        <tr v-for="(item,index) in datas" v-bind:key="index">
          <td v-text="item.code"></td>
          <td v-text="item.product_name"></td>
          <td v-text="item.address || (item.province?(item.province+' '+item.city):'') || item.ip"></td>
          <td v-text="item.created_at"></td>
          <!-- <td style="width:245px">
            <div class="btnArray">
              <a class="btnStyleTwo btnRadius blueBackColor">码标查询</a>
            </div>
          </td> -->
        </tr>
      </tbody>
    </table>

    <!-- 分页器 -->
    <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
	</div>
</template>

<script>
var self;
import axios from 'axios'
import Column from '@/components/common/Column.vue'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'ScanQRRecords',
  components: {
    'K-Column': Column,
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      column:[
        {name:'扫码统计',url:'ScanQRStats'},
        {name:'扫码记录',url:'ScanQRRecords'}
      ],
      SelectArr:[{
        ix:0,
        active:false,
        datas:["全部产品","红牛"]
      }],
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null
    }
  },
  created () {
    self = this;
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    SelectActive (ix) {  //隐藏显示下拉内容
      if(self.SelectArr[ix].active){
        self.SelectArr[ix].active = false;
      }else{
        //隐藏所有下拉内容
        for(let item in self.SelectArr){
          self.SelectArr[item].active = false;
        }
        self.SelectArr[ix].active = true;
      }
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      var datas = {
        page: self.page,datas,
        province: self.$route.query.province || ''
      }
      axios.post(self.GetServerUrl(1)+"code_query_list",datas)
      .then(function (response){
        self.isLoding = false;

        let datas = response.data.meta.data;
        self.datas = datas;
         self.totalPages = response.data.meta.last_page;  //总页数
        console.log(datas)
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    }
  }
}
</script>

<style scoped>
th{
  padding:20px !important;
}
td{
  padding:20px !important;
}
</style>
