<template>
	<div class="MainBox CodeQueryBox">

    <div class="FormStyleThree">
      <div class="FormStyleThreeQuery">
        <input placeholder="请键入查询的码标" v-model="code" v-on:keyup.13="QueryData" />
        <a class="FormStyleThreeBtn blueBackColor NoSelect" v-on:click="QueryData">查询</a>
      </div>
    </div>

    <div class="FloatBox">
      <div class="CodeQueryLeft FormStyleThree">
        <div class="CodeQueryProduct">
          <p class="CodeQueryProductTitle"><span class="icon iconfont iconicon-test28"></span>分组信息</p>
          <ul class="FormStyleFour">
            <li v-for="(item, index) in list" v-text="item"></li>
          </ul>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'CodeQuery',
  components: {
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      code:null,
      list:[],
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      }
    }
  },
  created () {
    self = this;
  },
  mounted () {

  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    QueryData () {
      let code = self.FiltrationCode(self.code);
      this.list = [];
      this.loading = true;
      axios.get(self.GetServerUrl(1)+"group_query/"+code)
      .then(function (response){
        self.list = response.data.meta;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  .CodeQueryLeft{
    width: 402px;
  }
  .FormStyleThree input{
    width: 380px;
  }
  .FormStyleFour li{
    padding: 10px;
    margin-top: 10px;
  }
</style>
