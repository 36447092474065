<template>
	<div class="transition-box">
    <div class="transition" :style="{margin: middle?'auto':'50px auto', ...customStyle}">
      <div class="transitionImg" :style="{width: imgWidht}"><img :src="isLoding?loadingImg:errorImg"></div>
      <div class="transitionHint" v-text="subHint"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transition',
  props: ['isLoding','subHint'],
  props: {
    isLoding: {
      type: Boolean,
      default: false
    },
    imgWidht: {
      type: String,
      default: '200px'
    },
    customStyle: {
      type: Object,
      default: ()=>{}
    },
    middle:{
      type: Boolean,
      default: false
    },
    loadingImg: {
      type: String,
      default: '/static/images/loading.gif'
    },
    errorImg: {
      type: String,
      default: '/static/images/error1.png'
    },
    subHint: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
  .transition-box{
    width: 100%;
    height: 100%;
    display: flex;
  }
  .transition{
    text-align: center;
    color: #585A64;
  }
  .transitionImg{
    display: table;
    margin:0 auto;
  }
  .transitionImg img{
    width: 100%;
    display: block;
  }
  .transitionHint{
    text-align: center;
  }
</style>
