<template>
	<div class="MainBox">
		<div class="FunctionPackageNav">修改密码</div>
    <div class="FormStyleTwoBox">
      <div class="FormStyleTwo">
        <li class="FormStyleTwoRow">
          <p class="FormStyleTwoTitle"><i class="redColor">*</i>旧密码</p>
          <p><input placeholder="请输入旧密码" type="password" v-model="FormDatas.oldpassword" /></p>
        </li>
        <li class="FormStyleTwoRow">
          <p class="FormStyleTwoTitle"><i class="redColor">*</i>新密码</p>
          <p><input placeholder="请输入新密码" type="password" v-model="FormDatas.password" /></p>
        </li>
        <li class="FormStyleTwoRow">
          <p class="FormStyleTwoTitle"><i class="redColor">*</i>确认密码</p>
          <p><input placeholder="请确认新密码" type="password" v-model="FormDatas.password_confirmation" /></p>
        </li>
      </div>

      <!-- 底部按钮 -->
      <div class="btnArray formFootBtnArr">
        <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>保存</a>
        <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>返回</a>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'SafetyProblem',
  components: {
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      FormDatas:{
        oldpassword:null,
        password:null,
        password_confirmation:null
      }
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {

  },
  methods: {
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        javascript:history.back();
      }
    },
    SubmitForm () {
      if(self.FormDatas.oldpassword == self.FormDatas.password){
        self.ToastFun(2,"新密码不能与旧密码一致！");
        return;
      }
      if(self.FormDatas.password != self.FormDatas.password_confirmation){
        self.ToastFun(2,"新密码两次输入不一致！");
        return;
      }

      self.loading = true;
      axios({
        method:"patch",
        url:self.GetServerUrl(1)+"rest_password/",
        data:self.FormDatas
      }).then(function (response) {
          self.loading = false;
          self.Confirm.style = 1;
          self.Confirm.state = true;
          self.Confirm.txt = response.data.message+"是否确定返回上一级？"
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  .FunctionPackageNav{
    padding:15px 20px;
    border-bottom: 1px solid #F3F3F3;
  }
  .FormStyleTwoBox{
    padding:20px;
  }
  .formFootBtnArr{
    display: block;
  }
</style>
