<template>
	<div class="MainBox">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('DealerEdit?type=add')"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input placeholder="输入用户名称查询" />
          <a class="SearchBtn">搜索</a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>兑换人</th>
            <th>奖品名称</th>
            <th>奖品图片</th>
            <th>收货人</th>
            <th>收货电话</th>
            <th>收货地址</th>
            <th>兑换状态</th>
            <th>中奖时间</th>
            <th>领取时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td v-text="item.prize"></td>
            <td><div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.prizeImg + ')'}"><img v-if="item.prizeImg" class="tableImgShow" :src="item.prizeImg" @click="PreviewImgs" /></div></td>
            <td v-text="item.consignee"></td>
            <td v-text="item.phone"></td>
            <td v-text="item.address"></td>
            <td class="TdStatus">
              <a v-if="item.status == 1"><i class="wait"></i>代发货</a>
              <a v-else-if="item.status == 2"><i class="reject"></i>发货中</a>
              <a v-else><i class="pass"></i>已完成</a>
            </td>
            <td v-text="item.WinningDate"></td>
            <td v-text="item.ExchangeDate"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor">查看详情</a>
                <a class="btnStyleTwo btnRadius" :class="{cyanBackColor:item.status == 1,ProhibitBtn:item.status != 1}">发货</a>
                <a class="btnStyleTwo btnRadius" :class="{redBackColor:item.status == 1,ProhibitBtn:item.status != 1}">驳回</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'PrizeExchange',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null
    }
  },
  created () {
    self = this;
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        name:"啊创",
        prize:"iphone7 Plus",
        prizeImg:"/public/static/images/login.png",
        consignee:"啊戳",
        phone:15816869108,
        address:"湖南省-湘西土家族苗族自治州-保靖县-红梅花",
        status:1,
        WinningDate:"2019-10-10 11:42",
        ExchangeDate:"2019-10-11 15:55"
      },{
        name:"啊戳",
        prize:"iphone7 Plus",
        prizeImg:"/public/static/images/login.png",
        consignee:"面包",
        phone:15816869108,
        address:"湖南省-湘西土家族苗族自治州-保靖县-红梅花",
        status:3,
        WinningDate:"2019-10-10 11:42",
        ExchangeDate:"2019-10-11 15:55"
      }],
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    }
  }
}
</script>

<style scoped>

</style>
