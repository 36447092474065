<template>
  <div class="MainBox" @click.stop="SelectActive(-1, $self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true, 1)"><span
            class="icon iconfont iconicon-test20"></span>{{ $t('b.Process.ProcessAllot') }}</li>
      </ul>
      <!-- <ul class="TableHeadRight">
        <div class="SearchBox">
          <input :placeholder="$t('b.ProductManage.SearchHint')" />
          <a class="SearchBtn" v-text="$t('c.Common.Search')"></a>
        </div>
      </ul> -->
      <ul class="clear"></ul>
    </div>
    <div class="TableHint"><i>*</i> {{ $t('c.Common.FileHint') }}</div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Numbering')"></th>
            <th>分配方式</th>
            <th v-text="$t('c.Common.Product')"></th>
            <th v-text="$t('c.Common.CodeNumber')"></th>
            <th v-text="$t('c.Common.SuccessfullyQuantity')"></th>
            <th v-text="$t('b.Process.ReceivingProcess')"></th>
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item, index) in datas" v-bind:key="index">
            <td v-text="item.id"></td>
            <td>
              <a v-if="item.shipping_type == 1">采集</a>
              <a v-else-if="item.shipping_type == 2">首尾码</a>
              <a v-else>文件</a>
            </td>
            <td>
              <div>
                <a v-text="item.nyname || item.production_name" v-if="item.production_name"></a>
                <a v-else></a>
              </div>
            </td>
            <td v-text="item.code_count"></td>
            <td v-text="item.shipping_code_count"></td>
            <td v-text="item.name"></td>
            <td class="TdStatus">
              <a v-if="item.result == 100"><i class="wait"></i>{{ $t('c.Common.InProgress') }}</a>
              <a v-else-if="item.result == 200"><i class="reject"></i>{{ $t('c.Common.Failure') }}</a>
              <a v-else-if="item.result == 300"><i class="pass"></i>{{ $t('c.Common.Completed') }}</a>
              <a v-else-if="item.result == 400"><i class="reject"></i>{{ $t('c.Common.Expired') }}</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" v-if="item.result == 300 && item.filename"
                  @click="DownCodeFile(4, item.id)" v-text="$t('c.Common.Download')"></a>
                <a class="btnStyleTwo btnRadius ProhibitBtn" v-else v-text="$t('c.Common.Download')"></a>
                <!-- <a class="btnStyleTwo btnRadius cyanBackColor" v-text="$t('b.CodeActive.ReplaceData')" @click="HandleModel(true,2,item.id)"></a> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 生码窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{ $t('b.Process.ProcessAllot') }}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback"
              @click="HandleModel(false, 1)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">

              <!-- 发货方式 -->
              <K-Select 
                :selectItem="selectList[0]" 
                :selectIx="0" 
                @selectActive="selectActive"
              ></K-Select>
              <!-- 发货方式 -->
              
              <!-- 产品 -->
              <K-Select 
                v-if="role == 30"
                :selectItem="selectList[1]" 
                :selectIx="1" 
                @selectActive="selectActive"
              ></K-Select>
              <!-- 产品 -->

              <!-- 工序 -->
              <K-Select
                v-if="role == 30 || role == 40"
                :selectItem="selectList[2]" 
                :selectIx="2" 
                @selectActive="selectActive"
              ></K-Select>
              <!-- 工序 -->

              <li class="FormStyleTwoRow" v-if="selectList[0].ix == 0">
                <p class="FormStyleTwoTitle"><i>*</i>{{ $t('b.Process.AllotCodes') }}（{{ $t('b.CodeActive.HintFour') }}）</p>
                <p><input :placeholder="$t('b.Process.HintFive')" v-model="code" v-on:keyup.13="AddCode" /></p>
              </li>
              <li class="FormStyleTwoRow" v-if="selectList[0].ix == 1">
                <p class="FormStyleTwoTitle"><i>*</i>{{ $t('c.Common.FirstCode') }}</p>
                <p><input :placeholder="$t('b.Process.HintSix')" v-model="first_code" /></p>
              </li>
              <li class="FormStyleTwoRow" v-if="selectList[0].ix == 1">
                <p class="FormStyleTwoTitle"><i>*</i>{{ $t('c.Common.LastCode') }}</p>
                <p><input :placeholder="$t('b.Process.HintSeven')" v-model="last_code" /></p>
              </li>
              <li class="FormStyleTwoRow btnArray" v-if="selectList[0].ix == 2">
                <a class="btnStyleTwo btnRadius cyanBackColor"><span
                    class="icon iconfont iconicon-test28"></span>{{ $t('c.Common.FileUpload') }}</a>
                <a class="FormUpFileHint onlyOneRow" v-if="fileTxt" v-text="fileTxt"></a>
                <input type="file" class="upload" @change="UploadFile" ref="file" />
              </li>
            </ul>

            <div class="CodeActiveListBox">
              <ul class="CodeActiveList" v-if="selectList[0].ix == 0 && Codes.length > 0">
                <span class="CodeActiveListTitle">当前采集码标<i v-text="Codes.length"></i>个<a
                    class="CodeActiveListRomoveAll feedback NoSelect" @click="Remove('all')">清空</a></span>
                <li v-for="(item, index) in Codes" v-bind:key="index">{{ item }}<i
                    class="icon iconfont iconicon-test33 CodeActiveListRomove feedback" @click="Remove(index)"></i></li>
              </ul>
            </div>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span
                class="icon iconfont iconicon-test28"></span>{{ $t('c.Common.Document') }}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false, 1)"
                v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(1)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

      <!-- 数据替换窗口 -->
      <div class="ModalBox" v-if="ModelTwoShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{ $t('b.Dealer.CodesShipping') }}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback"
              @click="HandleModel(false, 2)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">{{ $t('c.Common.Product') }}（{{ $t('b.Dealer.HintThree') }}）</p>
                <p>
                <div class="SelectStyle" @click.stop="SelectActive(1, $self)" v-if="selectList[1].datas.length > 0">
                  {{ selectList[1].datas[selectList[1].ix].nyname }}
                  <ul class="OptionStyleBox" v-if="selectList[1].active">
                    <li class='OptionStyleQuery' @click.stop><input
                        placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn"
                        @click.stop="selectListQuery(1,'production_list_condition')">查询</a></li>
                    <ul class="OptionStyleMain">
                      <li class="OptionStyleRow"
                        :class="{ OptionStyleRowActive: selectList[1].ix == index && !selectList[1].isHover }"
                        v-for="(item, index) in selectList[1].datas" v-bind:key="index" v-text="item.nyname"
                        @click="SelectData(1, index)" @mouseover="SelectHover($self, 1, true)"
                        @mouseout="SelectHover($self, 1, false)"></li>
                      <li class="OptionStyleLoading" @click.stop="selectListLoading(1)"
                        v-if="selectList[1].totalPages > 1 && selectList[1].page < selectList[1].totalPages">加载更多</li>
                      <li class="OptionStyleLoading" @click.stop
                        v-if="selectList[1].totalPages > 1 && selectList[1].page >= selectList[1].totalPages">无更多内容</li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div v-else class="SelectStyle">无</div>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle">{{ $t('c.Common.Process') }}（{{ $t('b.Process.HintFour') }}）</p>
                <p>
                <div class="SelectStyle" @click.stop="SelectActive(2, $self)">
                  {{ selectList[2].datas[selectList[2].ix].name }}
                  <ul class="OptionStyleBox" v-if="selectList[2].active">
                    <li class='OptionStyleQuery' @click.stop><input
                        placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn"
                        @click.stop="selectListQuery(2,'process_list_condition')">查询</a></li>
                    <ul class="OptionStyleMain">
                      <li class="OptionStyleRow"
                        :class="{ OptionStyleRowActive: selectList[2].ix == index && !selectList[2].isHover }"
                        v-for="(item, index) in selectList[2].datas" v-bind:key="index" v-text="item.name"
                        @click="SelectData(2, index)" @mouseover="SelectHover($self, 2, true)"
                        @mouseout="SelectHover($self, 2, false)"></li>
                      <li class="OptionStyleLoading" @click.stop="selectListLoading(2)"
                        v-if="selectList[2].totalPages > 1 && selectList[2].page < selectList[2].totalPages">加载更多</li>
                      <li class="OptionStyleLoading" @click.stop
                        v-if="selectList[2].totalPages > 1 && selectList[2].page >= selectList[2].totalPages">无更多内容</li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                </p>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootLeft feedback"><span
                class="icon iconfont iconicon-test28"></span>{{ $t('c.Common.Document') }}</div>
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false, 2)"
                v-text="$t('c.Common.Cancel')"></a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)" v-text="$t('c.Common.Confirm')"></a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

  </div>
</template>

<script>
var self;
import axios from 'axios'
import $axios from '@/hooks/axios-common.js'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import SelectView from '@/components/common/SelectView.vue'
export default {
  name: 'ProcessAllot',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast,
    'K-Select': SelectView
  },
  data() {
    return {
      $self: null,
      page: 1,
      role: null,
      subHint: '数据加载中...',
      isLoding: true,
      totalPages: 0,
      datas: null,
      ModelShow: false,
      ModelTwoShow: false,
      loading: false,
      pix:1, //产品容器索引
      Toast: {
        state: false,
        type: 0,
        txt: null
      },
      selectList: [{
        name:'发货方式',
        ix: 0,
        active: false,
        type: 'static',
        title: 'b.Process.AllotType',
        hint: 'b.Process.HintTwo',
        datas: ["b.Process.ScanAllot", "b.Process.ExtentAllot", "b.Process.FileAllot"]
      }, {
        name:'产品',
        ix: 0,
        page: 1,
        totalPages: 0,
        active: false,
        search: true,
        listUrl: 'production_list',
        searchUrl: 'production_list_condition',
        title: 'c.Common.Product',
        hint: 'b.Dealer.HintThree',
        datas: []
      }, {
        name:'工序',
        ix: 0,
        page: 1,
        totalPages: 0,
        active: false,
        search: true,
        listUrl: 'process_list',
        searchUrl: 'process_list_condition',
        title: 'c.Common.Process',
        hint: 'b.Dealer.HintFour',
        datas: []
      }],
      Codes: [],
      code: null,
      first_code: null,
      last_code: null,
      fileTxt: null,
      ds_id: null
    }
  },
  created() {
    self = this;
    this.$self = this;
    self.role = localStorage.getItem("wwmxt_Role");
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型

    //请求下拉列表数据-产品
    $axios.selectList.call(this,1,"post","production_list_condition",{no_page:true},value=>{
      $axios.getList.call(this,"ds/1/"); //产品不为空，加载列表
    });

    //请求下拉列表数据-经销商
    $axios.selectList.call(this,2,"get","process_list");

  },
  methods: {
    ToastFun(type, txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast() {
      self.Toast.state = false;
    },
    ChangePage(data) {  //上下翻页
      if (data.te == 1) {
        if (self.page > 1) {
          self.page--
        }
      } else {
        if (self.page < self.totalPages) {
          self.page++
        }
      }
      $axios.getList.call(this,"ds/1/"); //加载列表
    },
    SkipPage(data) {  //跳转分页
      self.page = data.ix;
      $axios.getList.call(this,"ds/1/"); //加载列表
    },
    SubmitForm(te) {  //提交表单
      let ix, Datas, ProductID, FormRootID, RootID;
      if (te == 1) {
        ix = self.selectList[0].ix;
      } else {
        ix = 3;  //数据替换
      }

      RootID = localStorage.getItem("wwmxt_Rootid");
      this.selectList.forEach(item=>{
        if(item.name == "产品"){
          ProductID = item.datas[item.ix].production_id;
        }else if(item.name == "工序"){
          FormRootID = item.datas[item.ix].rootid + "" + item.datas[item.ix].rootid_extid1;
        }
      });

      if (ix == 0) {
        Datas = { code: self.Codes, form_rootid: FormRootID, shipping_rootid: RootID, batch: 0 };
        if (self.Codes.length < 1) {
          self.ToastFun(1, "码标不能为空！");
          return;
        }
      } else if (ix == 1) {
        let first_code, last_code;
        if (self.first_code) {
          first_code = self.FiltrationCode(self.first_code);
        } else {
          self.ToastFun(1, "首码不能为空！");
          return;
        }
        if (self.last_code) {
          last_code = self.FiltrationCode(self.last_code);
        } else {
          self.ToastFun(1, "尾码不能为空！");
          return;
        }

        //首码格式校验
        if (first_code.length != 18 && first_code.length != 32) {
          self.ToastFun(1, "首码格式不正确！");
          return;
        }
        //尾码格式校验
        if (last_code.length != 18 && last_code.length != 32) {
          self.ToastFun(1, "尾码格式不正确！");
          return;
        }
        Datas = { first_code: first_code, last_code: last_code, form_rootid: FormRootID, shipping_rootid: RootID, batch: 0 };
      } else if (ix == 2) {
        if (!self.fileTxt) {
          self.ToastFun(1, "文件不能为空！");
          return;
        }
        let fileDOM;
        fileDOM = this.$refs.file;
        Datas = new FormData();
        Datas.append("file", fileDOM.files[0]);
        Datas.append("batch", 0);
        Datas.append("form_rootid", FormRootID);
        Datas.append("shipping_rootid", RootID);
      } else {
        Datas = { form_rootid: FormRootID, shipping_rootid: RootID, ds_id: self.ds_id, batch: 0 };
      }
      if (ix == 2) {
        Datas.append("ds_type", ix);

        //企业需要传产品ID
        if(self.role == 30){
          Datas.append("production_id", ProductID);
        }
      } else {
        Datas.ds_type = ix;

        //企业需要传产品ID
        if(self.role == 30){
          Datas.production_id = ProductID;
        }
      }

      self.loading = true;
      axios.post(self.GetServerUrl(1) + "ds", Datas)
        .then(function (response) {
          self.loading = false;

          if (te == 1) {
            self.ModelShow = false;
          } else {
            self.ModelTwoShow = false;
          }

          self.ToastFun(0, response.data.message);
          $axios.getList.call(self,"ds/1/"); //加载列表

          //清空历史数据
          if (ix == 0) {
            self.Codes = [];
          } else if (ix == 1) {
            self.code = null;
            self.first_code = null;
            self.last_code = null;
          }
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2, self.ErrorHint(error));  //弹窗提醒
        })
    },
    Remove(ix) {
      if (ix == "all") {
        self.Codes = [];
      } else {
        self.Codes.splice(ix, 1);
      }
    },
    AddCode() {
      let code = self.FiltrationCode(self.code);
      if (code) {
        //重复校验
        if (self.RepeatCheck(self.Codes, code) == 1) {
          //跨级校验
          if (self.Codes.length > 0) {
            if (code.substring(0, 1) != self.Codes[0].substring(0, 1)) {
              self.ToastFun(1, "请勿跨级激活码标！");
              return;
            } else {
              self.Codes.push(code);
              self.code = null;
            }
          } else {
            self.Codes.push(code);
            self.code = null;
          }
        } else {
          self.ToastFun(1, "不能重复插入码标！");
        }
      } else {
        self.ToastFun(1, "码标不正确！");
      }
    },
    UploadFile() {
      if (this.$refs.file.files[0].type != "text/plain") {
        self.ToastFun(1, "文件格式必须为txt！");
        self.fileTxt = null;
        return;
      }
      let size = (this.$refs.file.files[0].size / (1024 * 1024)).toFixed(2);
      if (size < 0.01) {
        size = 0.01;
      }
      if (size > 20) {
        self.ToastFun(1, "文件大小不能超过20M，当前大小为：" + size + "M！");
        self.fileTxt = null;
        return;
      }
      self.fileTxt = '（' + size + 'M）' + this.$refs.file.files[0].name;
    },
    DownCodeFile(etype, id) {
      if (id) {
        window.open("https://syapi.oid.plus/v1/downCodeFile/" + etype + "/" + id);
      }
    },
    HandleModel(status, te, id) {  //隐藏显示生码模态框
      if (self.selectList[1].datas.length > 0 && self.selectList[2].datas.length > 0) {
        if (te == 1) {
          self.ModelShow = status;
        } else {
          self.ds_id = id;
          self.ModelTwoShow = status;
        }
      } else {
        self.ToastFun(1, "产品和工序内容不能为空！");
      }
    },
    selectActive(params){
      this.keyword = params.keyword; //搜索框内容
      if(params.type === 'active'){ //点击展开

        //隐藏所有下拉内容
        self.selectList.forEach((value,index)=>{
          if(index !== params.selectIx) value.active = false;
        })
        //展开、隐藏当前下拉框
        self.selectList[params.selectIx].active = !self.selectList[params.selectIx].active;
      }else if(params.type === 'check'){ //选中属性
        self.selectList[params.selectIx].active = false;
        self.selectList[params.selectIx].ix = params.optionIx;
      }else if(params.type === 'search'){ //搜索
        $axios.searchSelectData.call(this,params.selectIx,params.url);
      }else if(params.type === 'more'){ //查看更多
        $axios.selectLoadMore.call(this,params.selectIx,params.url);
      }
    }
  }
}
</script>

<style scoped>
.iconicon-test38 {
  font-size: 15px !important;
}
</style>
