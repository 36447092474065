<template>
	<div>
		<header class="SiginHead">
		  <ul class="SiginHeadLeft"><img src="static/images/logo.png"></ul>
		  <ul class="SiginHeadRight">
		    <li class="SiginHeadRightRow SiginHeadRightTxt feedback" v-text="$t('c.HeadBar.GoLogin')" @click="OpenURL('/')"></li>
		    <li class="SiginHeadRightRow" title="语言（Language）" @click="SwicthLanguage">
		      <span class="icon iconfont iconicon-test8"></span>
		      <span class="SiginHeadRightTxt SiginHeadRightLanguage feedback" v-text="$t('c.Language.name')"></span>
		      <span class="icon iconfont iconicon-test25"></span>
		    </li>
		  </ul>
		</header>
	</div>
</template>

<script>
var self;
export default {
  name: 'HeadBarTwo',
  data () {
    return {

    }
  },
  created () {
    self = this;
  },
  mounted () {

  },
  methods: {
    OpenURL (url) {
      self.GoToPage(url); // 路由跳转
    },
    SwicthLanguage () {
      if(self.$i18n.locale == "zh"){
        localStorage.setItem("wwmxt_locale","en");
        self.$i18n.locale = "en"
      }else{
        localStorage.setItem("wwmxt_locale","zh");
        self.$i18n.locale = "zh"
      }
    }
  }
}
</script>

<style scoped>
  .SiginHead{
    position: fixed;
    top:0;left:0;
    right:0;
    height:60px;
    background-color: #FFF;
    box-shadow:0 5px 10px rgba(0,0,0,0.1);
    z-index: 99;
  }
  .SiginHeadLeft{
    float: left;
    padding:0 15px;
  }
  .SiginHeadLeft img{
    display: block;
    height:40px;
    margin-top:10px;
  }
  .SiginHeadRight{
    float: right;
    overflow: hidden;
    padding:0 20px;
  }
  .SiginHeadRightRow{
    float: left;
    margin-right:20px;
    line-height:60px;
  }
  .SiginHeadRightRow:last-child{
    margin-right:0;
  }
  .iconicon-test8{
    font-size:18px;
  }
  .iconicon-test25{
    font-size:8px;
    position: relative;
    top:-3px;
    right:0;
  }
  .SiginHeadRightTxt{
    font-size:14px;
    cursor: pointer;
  }
  .SiginHeadRightLanguage{
    position: relative;
    top:-2px;
  }

</style>
