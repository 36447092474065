<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 表单内容 -->
    <div class="box FloatBox">
      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <viewer @inited="inited" ref="viewer">
          <li class="FormStyleTwoImgBox FormStyleTwoRow">
            <div class='FormStyleTwoImg backImg' v-if="FormDatas.cover_img" :style="{backgroundImage: 'url(' + FormDatas.cover_img + ')'}">
              <img v-if="FormDatas.cover_img" class="tableImgShow" :src="FormDatas.cover_img" @click="PreviewImgs">
              <span class="icon iconfont iconicon-test33 RemoveImgIcon" @click.stop="RemoveImg"></span>
            </div>
            <div class='FormStyleTwoImg backImg' v-else></div>
            <div class="FormStyleTwoImgMore">
              <p class="hint" style="width:230px">{{$t('c.Common.ImageSize')}}</p>
              <p class="btnStyleTwo btnRadius blueBackColor" style="display:table;" @click="HandleModel(1,true)">{{$t('b.Material.UploadImages')}}</p>
            </div>
          </li>
          </viewer>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">可出库激活（可以发货给经销商）</p>
            <p>
              <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                {{SelectArr[0].datas[SelectArr[0].ix]}}
                <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
            </p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">可批次管理（可以添加、修改，删除发货批次）</p>
            <p>
              <div class="SelectStyle" @click.stop="SelectActive(1,$self)">
                {{SelectArr[1].datas[SelectArr[1].ix]}}
                <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item" @click="SelectData(1,index)"></li>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
            </p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>{{$t('c.Common.Name')}}</p>
            <p><input placeholder="请输入工序名称" v-model="FormDatas.name" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="!id">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>{{$t('c.Common.Account')}}</p>
            <p><input placeholder="请输入登录账号" v-model="FormDatas.username" /></p>
          </li>
        </ul>
      </div>
      <div class="FormStyleTwoRight FloatBoxRow">
        <div class="FormStyleTwo">
          <li class="FormStyleTwoRow" v-if="!id">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>{{$t('c.Login.Password')}}</p>
            <p><input placeholder="请输入登录密码" type="password" v-model="FormDatas.password" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{$t('c.Common.Linkman')}}</p>
            <p><input placeholder="可输入联系人名称" v-model="FormDatas.linkman" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{$t('c.Common.Phone')}}</p>
            <p><input placeholder="可输入联系人电话" v-model="FormDatas.linkphone" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{$t('c.Common.Address')}}</p>
            <p><input placeholder="可输入联系人地址" v-model="FormDatas.linkaddress" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">{{$t('c.Common.Describe')}}</p>
            <p>
              <textarea class="form-control" v-model="FormDatas.intro"  placeholder="可输入有关工序的描述介绍"></textarea>
            </p>
          </li>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>{{$t('c.Common.Save')}}</a>
      <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>{{$t('c.Common.Back')}}</a>
    </div>

    <!-- 图片空间窗口 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          图片空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>上传图片
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click.stop="SelectActive(2,$self)" v-if="SelectArr[2].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[2].datas[SelectArr[2].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(2,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox ModalMainImgBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table">
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(false)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Column from '@/components/common/Column.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'AddProcess',
  components: {
    'K-Paging': Paging,
    'K-Column': Column,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      ModelShow:false,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      column:[
        {name:'ProcessList',url:'ProcessList'}
      ],
      SelectArr:[{
        ix:0,
        active:false,
        datas:["否","是"]
      },{
        ix:0,
        active:false,
        datas:["否","是"]
      },{
        ix:0,
        active:false,
        datas:[]
      }],
      pid:0, //分组id
      FormDatas:{
        cover_img:null
      },
      imgsList:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.$route.query.id){
      self.id = self.$route.query.id;
      self.column[1] = {name:'EditProcess',url:'ProcessEdit?id='+self.$route.query.id};
      self.GetData();
    }else{
      self.column[1] = {name:'AddProcess',url:'ProcessEdit'};
    }
  },
  mounted () {
    self.GetPacketList();
    self.GetImgsList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if(ix == 2){  //列表分组选择
        self.page = 1;  //重置页数
        self.totalPages = null;  //清空分页总数
        self.pid = id;  //清楚分组ID
        self.imgsList = null;  //清除图片列表
        self.GetImgsList();  //获取空间图片列表
      }
    },
    HandleModel (te,status,ix) {  //隐藏显示生码模态框
      self.uploadType = te;
      self.ModelShow = status;
      self.uploadImgIndex = ix;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        javascript:history.back();
      }
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"process/"+self.id)
      .then(function (response){
        self.FormDatas = response.data.meta;
        self.SelectArr[0].ix = response.data.meta.is_ex_role;
        self.SelectArr[1].ix = response.data.meta.is_batch_role;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[2].datas = response.data.meta
        self.SelectArr[2].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[2].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    selectImg (img) {
      self.FormDatas.cover_img = img;
      self.HandleModel(false);
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SubmitForm () {
      if(!self.id && !self.FormDatas.username && !self.FormDatas.password && !self.FormDatas.name){
        self.ToastFun(2,"*号必填项不能为空！");
        return;
      }

      let type;
      if(self.id){
        type = "patch";
        self.FormDatas.id = self.FormDatas.rootid +""+ self.FormDatas.rootid_extid1;
      }else{
        type = "post";
      }
      self.FormDatas.is_ex_role = self.SelectArr[0].ix;
      self.FormDatas.is_batch_role =  self.SelectArr[1].ix;
      self.loading = true;
      axios({
        method:type,
        url:self.GetServerUrl(1)+"process",
        data:self.FormDatas
      }).then(function (response) {
        self.loading = false;
        self.Confirm.style = 1;
        self.Confirm.state = true;
        self.Confirm.txt = response.data.message+"，是否确定返回列表？"
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveImg () {
      self.FormDatas.cover_img = null;
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width: 300px;
  }
</style>
