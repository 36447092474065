<template>
	<div class="MainBox">

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th>产品图片</th>
            <th>产品名称</th>
            <th>码标等级</th>
            <th>库存数量</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.production.img + ')'}" v-if="item.production.img"><img class="tableImgShow" :src="item.production.img" @click="PreviewImgs"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td v-text="item.production.name"></td>
            <td v-text="item.level+'级'"></td>
            <td v-text="item.number"></td>
            <td class="LastTdWidth">
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="GoURL(item)">详情记录</a>
                <!-- <a class="btnStyleTwo btnRadius blueBackColor" @click="GoToPage('StockDetails?type='+te+'&name='+name+'&pid='+item.id+'&pname='+item.production.name)">详情记录</a> -->
                <!-- <a class="btnStyleTwo btnRadius cyanBackColor" @click="GoToPage('ManualStockRecord?type='+te+'&name='+name+'&pid='+item.id+'&pname='+item.name)">手动记录</a> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Column from '@/components/common/Column.vue'
export default {
  name: 'StockManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Column': Column
  },
  data () {
    return {
      te:null,
      name:null,
      id:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      column:[]
    }
  },
  mounted () {
    self = this;
    self.id = localStorage.getItem("wwmxt_Rootid")
    self.GetList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
    },
    HandleModel (status) {  //隐藏显示生码模态框
      self.ModelShow = status;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      self.subHint = "数据加载中...";

      let datas = {page:self.page,rootid:self.id};
      axios({
        method:"post",
        url:self.GetServerUrl(1)+"stock",
        data:datas
      }).then(function (response) {
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error) {
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    inited (viewer) {
      self.$viewer = viewer
      console.log(self.$viewer)
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    },
    GoURL (item) {
      var stock = {type:self.te,level:item.level,uid:self.id,name:self.name,pid:item.production_id,pname:item.production.name};
      localStorage.setItem("wwmxt_stockDetail",JSON.stringify(stock));
      self.GoToPage("BrancStockDetails");
    }
  }
}
</script>

<style scoped>
  .iconicon-test38{
    font-size:15px !important;
  }
  .LastTdWidth{
    width:350px;
  }
  .TableMain{
    padding-top:20px;
  }
</style>
