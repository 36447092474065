<template>
	<div class="ImagesListModel transition" :class="{ImagesListModelShow:ImgsData.type}">
    <div class="ImagesListModelHead">
      <div class="ImagesListModelHeadBtn NoSelect"><span class="icon iconfont iconicon-test20"></span>上传图片
        <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
      </div>
      <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="ImagesListModelFun(false)"></span>
    </div>
    <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
    <div v-else class="ImagesListModelMain" :style="{height:boxHeight+'px'}">
      <table class="table">
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url,item.name)">
            <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
            <td v-text="item.name"></td>
            <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
          </tr>
        </tbody>
      </table>
      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
export default {
  name: 'ImagesList',
  props:["ImgsData"],
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading
  },
  data () {
    return {
      page:1,
      pid:0,
      isLoding:true,
      loading:false,
      subHint:'数据加载中...',
      imgsList:null
    }
  },
  created () {
    self = this;
    this.boxHeight = document.documentElement.clientHeight - 60;

    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetImgsList();
  },
  methods:{
    ImagesListModelFun (te) {
      self.$emit('ImagesListModelFun',{type:te});
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        let msg;
        if (error.response) {
          if(error.response.data.message){
            msg = error.response.data.message;
          }else{
            msg = error.response.statusText;
          }
        }else{
          msg = "请检查网络连接！";
        }
        self.subHint = msg;
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",0);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.$emit('SonToastFun',{te:2,txt:"文件必须为图片格式"});
          }else{
            self.$emit('SonToastFun',{te:0,txt:response.data.message});
          }
          self.GetImgsList();
      }).catch(function (error) {
          let msg;
          if (error.response) {
            if(error.response.data.message){
              msg = error.response.data.message;
            }else{
              msg = error.response.statusText;
            }
          }else{
            msg = "请检查网络连接！";
          }
          self.loading = false;
            self.$emit('SonToastFun',{te:2,txt:"文件必须为图片格式"});
      })
    },
    selectImg (img,name) {
      self.$emit('ImagesListModelFun',{type:false,img:img,name:name});
    }
  }
}
</script>

<style scoped>
  .ImagesListModel{
    position: fixed;
    right:-400px;top:0;
    width:400px;
    bottom:0;
    background-color: #FFF;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
  .ImagesListModelShow{
    right:0;
  }
  .ImagesListModelHead{
    height:60px;
    overflow: hidden;
    position: relative;
    background-color: #FFF;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
  .ImagesListModelHeadBtn{
    padding:10px 12px;
    border:1px solid #D8E4ED;
    display: table;
    margin:10px 20px;
    border-radius: 3px;
    position: relative;
  }
  .ImagesListModelHeadBtn input{
    cursor: pointer;
  }
  .ImagesListModelHeadBtn:hover{
    background-color: #F9F9F9;
  }
  .ImagesListModelHeadBtn span{
    font-size:14px;
    margin-right:3px;
    position: relative;
  }
  .ImagesListModelMain{
    overflow-y: auto;
  }
  .table{
    table-layout:fixed;
    word-break:break-all;
  }
</style>
