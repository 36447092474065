<template>
	<div class="MainBox">
		<div class="BasicInfoHead">
      <p class="BasicInfoHeadLogo backImg" v-if="datas.logo" :style="{backgroundImage:'url('+datas.logo+')'}"></p>
      <p class="BasicInfoHeadLogo backImg" v-else></p>
      <p class="BasicInfoHeadName" v-if="datas.id_code_base">
        {{datas.name}}
        <i class="authenticated BlueState" v-if="datas.id_code_base.status_id == 100">{{$t('c.Common.Verified')}}</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == 0">{{$t('c.Common.InReview')}}</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -1">{{$t('c.Common.Refer')}}</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -2">{{$t('c.Common.Disabled')}}</i>
        <i class="authenticated RedState" v-else-if="datas.id_code_base.status_id == -100">{{$t('c.Common.Corvidae')}}</i>
      </p>
      <p class="BasicInfoHeadName" v-else>{{datas.name}}</p>
      <p class="BasicInfoHeadDate" v-if="datas.created_at">{{$t('b.BasicInfo.Regdate')}}：{{datas.created_at}}</p>
      <p class="BasicInfoHeadExpire" v-if="datas.company_OID">{{$t('b.BasicInfo.OIDNumber')}}：{{datas.company_OID}}</p>
      <p class="BasicInfoHeadExpire" v-else>{{$t('b.BasicInfo.MerchantNumber')}}：{{datas.rootid}}</p>
      <a class="BasicInfoHeadUpLogo NoSelect" @click="HandleModel(true,1)">{{$t('b.BasicInfo.UploadLogo')}}</a>
    </div>

    <div class="BasicInfoNav">
      <a class="feedback" v-for="(item,index) in NavList" v-bind:key="index" v-text="$t('b.BasicInfo.'+item+'')" :class="{NavActive:index == ix}" @click="ClickNav(index)"></a>
    </div>

    <!-- 商户信息 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-if="ix == 0">
      <div class="BasicInfoOneBoxLeft">
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">{{$t('b.BasicInfo.BusinessType')}}</li>
          <li>
            <p><input disabled="true" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">{{$t('b.BasicInfo.BusinessName')}}</li>
          <li>
            <p><input v-model="datas.name" disabled="true" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne" v-if="datas.trade">
          <li class="FormStyleOneTitle">{{$t('b.BasicInfo.Industrial')}}</li>
          <li>
            <p><input v-model="datas.trade.trade_name" disabled="true" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne" v-if="datas.btype == 1">
          <li class="FormStyleOneTitle">{{$t('c.Common.Address')}}</li>
          <li>
            <p><input v-model="datas.baddress" disabled="true" class="disabledInput" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne" v-if="datas.btype == 1 && datas.id_code_base">
          <li class="FormStyleOneTitle">{{$t('b.BasicInfo.CreditCode')}}</li>
          <li>
            <p><input v-model="datas.organization_code" class="disabledInput" :disabled="datas.id_code_base.status_id != -1 && datas.id_code_base.status_id != -100"/></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">AppID</li>
          <li>
            <p><input v-model="datas.appid" class="disabledInput" placeholder="请输入小程序AppID(小程序ID)" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">AppSecret</li>
          <li>
            <p><input v-model="datas.secret" class="disabledInput" placeholder="请输入小程序AppSecret(小程序密钥)" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">经纬度</li>
          <li>
            <p><input v-model="datas.lon_lat" placeholder="请点击获取经销商经纬度" @focus="ClickMap(true)" @click="ClickMap(true)"  /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">{{$t('c.Common.Linkman')}}</li>
          <li>
            <p><input v-model="datas.linkman" /></p>
          </li>
        </ul>
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">{{$t('c.Common.Phone')}}</li>
          <li>
            <p><input v-model="datas.linkphone"/></p>
          </li>
        </ul>
        <!-- <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">关键词</li>
          <li>
            <p><input placeholder="多个关键词请用英文逗号分割" v-model="datas.keyword" /></p>
          </li>
        </ul> -->
        <ul class="FormStyleOne">
          <li class="FormStyleOneTitle">{{$t('b.BasicInfo.BusinessIntroduction')}}</li>
          <li>
            <p>
              <textarea class="form-control"  v-model="datas.intro"></textarea>
            </p>
          </li>
        </ul>
        <div><a class="FormStyleOneBottomBtn btnRadius blueBackColor NoSelect" @click="ChangeBusinessInfo">{{$t('c.Common.Save')}}</a></div>
      </div>

      <div class="BasicInfoOneBoxRight" v-if="datas.btype == 1">
        <ul class="BasicInfoOneBoxImgs">
          <li>
            <p class="BasicInfoOneBoxImg backImg" v-if="datas.blicense" :style="{backgroundImage:'url('+datas.blicense+')'}"><a class="PreviewsImgBox" :href="datas.blicense" target="view_window"></a></p>
            <p class="BasicInfoOneBoxImg backImg" v-else></p>
            <p class="BasicInfoOneBoxTxt">{{$t('b.BasicInfo.BusinessLicense')}}</p>
            <p class="BasicInfoOneBoxBtn NoSelect" v-if="datas.id_code_base && (datas.id_code_base.status_id == -1 || datas.id_code_base.status_id == -100)" @click="HandleModel(true,2)">{{$t('b.BasicInfo.UploadLicense')}}</p>
          </li>
        </ul>

        <div style="margin-top: 10px;">
          <ul class="FormStyleTwo FormStyleTwoRow" v-for="(item,index) in datas.customize_data" v-bind:key="index">
            <li class="FormStyleTwo" style="padding:2px 0;">
              <input class="FormStyleTwoInputLeft" :placeholder="$t('b.ProductManage.HintFive')" v-model="item.key"/>
              <input class="FormStyleTwoInputRight" :placeholder="$t('b.ProductManage.HintSix')" v-model="item.value"/>
              <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,2,index)">{{$t('c.Common.Image')}}</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{$t('c.Common.Delete')}}</a>
              </p>
            </li>
          </ul>
          <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="addAttributeRow"><span class="icon iconfont iconicon-test20"></span>{{$t('b.ProductManage.AddLine')}}</div>
        </div>
      </div>
      <div class="BasicInfoOneBoxRight" v-if="datas.btype == 2">
        <ul class="BasicInfoOneBoxImgs">
          <li>
            <p class="BasicInfoOneBoxImg backImg" v-if="datas.idcard_img" :style="{backgroundImage:'url('+datas.idcard_img+')'}"><a class="PreviewsImgBox" :href="datas.idcard_img" target="view_window"></a></p>
            <p class="BasicInfoOneBoxImg backImg" v-else></p>
            <p class="BasicInfoOneBoxTxt">手持身份证照</p>
          </li>
        </ul>
      </div>
    </div>

    <!-- 功能列表 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-else-if="ix == 1">
      <table class="tableTwo">
        <thead>
          <tr>
            <th>{{$t('c.Common.Function')}}</th>
            <th>{{$t('c.Common.Describe')}}</th>
            <th>{{$t('c.Common.Cost')}}</th>
            <th>{{$t('c.Common.ExpirationDate')}}</th>
            <th>{{$t('c.Common.State')}}</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in ListDatas" v-bind:key="index">
            <td><a v-text="item.name"></a></td>
            <td v-text="item.intro"></td>
            <td v-text="item.fee"></td>
            <td>
              <a v-if="item.due_date" v-text="item.due_date"></a>
              <a v-else>-</a>
            </td>
            <td>
              <a v-if="item.status == 0" class="grayColor">{{$t('c.Common.CreditCard')}}</a>
              <a v-else-if="item.status == 1" class="grayColor">{{$t('c.Common.Expired')}}</a>
              <a v-else-if="item.status == 2">{{$t('c.Common.HaveOpened')}}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!ListDatas"></K-Transition>
    </div>

    <!-- 开发者中心 -->
    <div class="BasicInfoBox BasicInfoOneBox" v-else>
      <div class="DeveloperExplain" v-if="!isDeveloper">
        <div class="ListStyleOneTitle">你还没有成为开发者</div>
        <p class="DeveloperExplainTxt">成为微信公众平台开发者，你将可以使用公众平台的开发接口，在你自身服务器上接收用户的微信消息，并可按需回复。此外，我们还提供了更多更高级的接口来完善公众号的功能：</p>
        <p class="DeveloperExplainRow">1. 会话界面的自定义菜单</p>
        <p class="DeveloperExplainRow">2. 多客服接口，提供贴心快捷的客服服务</p>
        <p class="DeveloperExplainRow">3. 获取用户地址位置，精确提供服务</p>
        <p class="DeveloperExplainRow">4. 高级群发接口，实现更灵活的群发能力</p>
        <div class="DeveloperAgreement">
          <a><input type="checkbox" /></a>
          <a>同意</a>
          <a class="DeveloperAgreementData">《链接OID开发者协议》</a>
        </div>
        <div class="BecomeDeveloper btnRadius blueBackColor" @click="BecomeDeveloper">成为开发者</div>
      </div>

      <div v-else>
        <div class="ListStyleOneTitle">链接OID开发信息</div>
        <ul class="ListStyleOne DeveloperBox">
          <li>
            <div class="ListStyleOneLeft">开发者ID(AppID)</div>
            <div class="ListStyleOneRight">
              <p>wx05754b8136538a08</p>
              <p class="ListStyleOneRTxt">开发者ID是公众号开发识别码，配合开发者密码可调用公众号的接口能力。</p>
            </div>
          </li>
          <li>
            <div class="ListStyleOneLeft">开发者密钥(AppSecret)</div>
            <div class="ListStyleOneRight">
              <p class="blueColor ListStyleOneBtn">查看密钥</p>
              <p class="ListStyleOneRTxt">开发者密码是校验公众号开发者身份的密码，具有极高的安全性。切记勿把密码直接交给第三方开发者或直接存储在代码中。如需第三方代开发公众号，请使用授权方式接入。</p>
            </div>
          </li>
        </ul>

        <div class="ListStyleOneTitle ListStyleOneTitleTop">服务器配置(未启用)
          <div class="DeveloperBtnArray">
            <a class="DeveloperBtnEdit">修改配置</a>
            <a class="DeveloperBtnStar">启动</a>
          </div>
        </div>
        <ul class="ListStyleOne DeveloperBox">
          <li>
            <div class="ListStyleOneLeft">接口地址(URL)</div>
            <div class="ListStyleOneRight">
              <p>未填写</p>
            </div>
          </li>
          <li>
            <div class="ListStyleOneLeft">生码地址(URL)</div>
            <div class="ListStyleOneRight">
              <p>未填写</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- 图片空间 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          图片空间
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>上传图片
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click="SelectActive(0)" v-if="SelectArr[0].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(0,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table ModalMainImgBox" >
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url,item.name)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm(2)">确定</a>
          </div>
        </div>
      </div>
    </div>

    <div class="mapbox" v-if="mapShow">
      <div class="mapShade" @click="ClickMap(false)"></div>
      <baidu-map :center="{lng:120.548984,lat:27.574843}" :zoom="14" @ready="handler" :scroll-wheel-zoom="true" class="MapMain" @click="getClickInfo">
        <bm-marker :position="{lng:120.548984, lat:27.574843}" :dragging="true">
          <bm-label content="温州链接科技有限公司" :labelStyle="{color:'red',fontSize:'13px'}" :offset="{width:-58,height:30}"/>
        </bm-marker>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
      </baidu-map>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'BasicInfo',
  components: {
    'K-Paging': Paging,
    'K-Loading': Loading,
    'K-Transition': Transition,
    'K-Toast': Toast
  },
  data () {
    return {
      ix:0,
      cix:0,
      page:1,
      totalPages:0,
      isDeveloper:false,
      loading:false,
      subHint:'数据加载中...',
      isLoding:true,
      ModelShow:false,
      pid:0, //分组id
      imgsList:null,
      UploadType:null,
      mapShow:false,
      Confirm:{
        state:false,
        txt:null
      },
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      SelectArr:[{
        ix:0,
        active:false,
        datas:[]
      }],
      // NavList:["BusinessInformation","FunctionList","Developer"],
      NavList:["BusinessInformation","FunctionList"],
      datas:{
        logo:null,
        name:null,
        linkman:null,
        linkphone:null,
        intro:null,
        keyword: null,
        organization_code:null,
        id_code_base:null
      },
      ListDatas:null
    }
  },
  created () {
    self = this;
    self.datas.name = localStorage.getItem("wwmxt_Name");
    self.datas.logo = localStorage.getItem("wwmxt_Logo");
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetData();
    self.GetPacketList();
    self.GetImgsList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    HandleModel (status,te,ix) {  //隐藏显示生码模态框
      self.ModelShow = status;
      self.UploadType = te;
      self.cix = ix;
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.page = 1;  //重置页数
      self.totalPages = null;  //清空分页总数
      self.pid = id;
      self.GetImgsList();  //获取空间图片列表
    },
    SelectActive (ix) {  //隐藏显示下拉内容
      if(self.SelectArr[ix].active){
        self.SelectArr[ix].active = false;
      }else{
        //隐藏所有下拉内容
        for(let item in self.SelectArr){
          self.SelectArr[item].active = false;
        }
        self.SelectArr[ix].active = true;
      }
    },
    selectImg (img,name) {
      if(self.UploadType == 1){
        self.datas.logo = img;
      }else{
        self.datas.customize_data[self.cix].key = name;
        self.datas.customize_data[self.cix].value = img;
      }
      self.HandleModel(false);
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ClickNav (ix) {
      self.ix = ix;
      if(ix == 1){
        self.GetFunctionList();
      }
    },
    BecomeDeveloper () {
      self.isDeveloper = true;
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"business")
      .then(function (response) {
        self.datas = response.data.meta;

        //更新头部导航LOGO和状态
        localStorage.setItem("wwmxt_Logo",response.data.meta.logo);
        localStorage.setItem("wwmxt_OIDState",response.data.meta.id_code_base.status_id);
        self.$emit('ListenBack');
        self.loading = false;
      }).catch(function (error){
        let msg;
        console.log(error)
        if (error.response) {
          if(error.response.data.message){
            msg = error.response.data.message;
          }else{
            msg = error.response.statusText;
          }
        }else{
          msg = "请检查网络连接！";
        }
        self.loading = false;
        self.ToastFun(2,msg);  //弹窗提醒
      })
    },
    ChangeBusinessInfo () {
      self.loading = true;

      //FormDatas格式提交
      let datas = {
        logo:self.datas.logo,
        linkphone:self.datas.linkphone,
        linkman:self.datas.linkman,
        intro:self.datas.intro,
        keyword: self.datas.keyword,
        appid:self.datas.appid,
        secret: self.datas.secret,
        lon_lat:self.datas.lon_lat,
        customize_data:JSON.stringify(self.datas.customize_data)
      };
      if(self.datas.id_code_base.status_id == -1 || self.datas.id_code_base.status_id == -100){
        datas.blicense = self.datas.blicense;
        datas.organization_code = self.datas.organization_code;
      }
      axios.patch(self.GetServerUrl(1)+"business",datas)
      .then(function (response){
        self.GetData();
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error){
        let msg;
        if (error.response) {
          if(error.response.data.message){
            msg = error.response.data.message;
          }else{
            msg = error.response.statusText;
          }
        }else{
          msg = "请检查网络连接！";
        }
        self.loading = false;
        self.ToastFun(2,msg);  //弹窗提醒
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[0].datas = response.data.meta
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = "数据为空";
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    GetFunctionList () {
      self.ListDatas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"module/")
      .then(function (response){
          self.ListDatas = response.data.meta;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      if(self.UploadType == 1){  //logo
        FormDatas.append("belong","logo");
      }else{
        FormDatas.append("belong","blicense");
      }
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
      })
    },
    addAttributeRow () {
      if(!self.datas.customize_data){
        self.datas.customize_data = [];
      }
      self.datas.customize_data.push({key:"",value:""});
      self.$forceUpdate();
    },
    RemoveRow (ix) {
      self.datas.customize_data.splice(ix,1);
      self.$forceUpdate();
    },
    ClickMap (status) {
      self.mapShow = status;
    },
    handler ({BMap, map}) {

    },
    getClickInfo (e) {
      var point = new BMap.Point(e.point.lng, e.point.lat);
      var gc = new BMap.Geocoder();
      self.datas.lon_lat = e.point.lng+","+e.point.lat;
      self.mapShow = false;
    }
  }
}
</script>

<style scoped>
  .FormStyleOne input,.SelectStyle{
    width: 300px;
  }
  .FormStyleOneBottomBtn{
    padding:10px 18px;
    display: table;
    margin-top:25px;
    margin-left:90px;
    cursor: pointer;
  }
  .disabledInput{
    border:1px solid #DDD;
  }
  .tableTwo{
    margin-top:0;
  }
  .tableTwo > tbody > tr > td{
    color:#585A64;
  }

  /* 开发者 */
  .DeveloperExplainTxt{
    margin:20px 0;
    color:#585A64;
  }
  .DeveloperExplainRow{
    color:#585A64;
    margin-bottom: 15px;
  }
  .DeveloperAgreement{
    display: table;
    margin:0 auto;
    margin-top:25px;
  }
  .DeveloperAgreement input{
    position: relative;
    margin-right: 4px;
    top:2px;
  }
  .DeveloperAgreementData{
    cursor: pointer;
    color:#278BF5;
  }
  .BecomeDeveloper{
    padding:10px 15px;
    display: table;
    cursor: pointer;
    margin:20px auto;
    margin-top:25px;
  }
  .DeveloperBox{
    border-bottom: 1px solid #F3F3F3;
  }
</style>
