<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('ORGProductEdit')"><span class="icon iconfont iconicon-test20"></span>{{$t('b.ProductManage.AddProduct')}}</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input :placeholder="$t('b.ProductManage.SearchHint')" v-model="QueryName" v-on:keyup.13="QueryData" />
          <a class="SearchBtn" @click="QueryData" v-text="$t('c.Common.Search')"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-if="tradeType == 1">登记证号</th>
            <th v-if="tradeType == 1">农药名称</th>
            <th v-text="$t('c.Common.Name')"></th>
            <th v-if="tradeType == 1">规格码</th>
            <th v-text="$t('c.Common.Image')"></th>
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.reg_code_str" v-if="tradeType == 1"></td>
            <td v-text="item.alias_name" v-if="tradeType == 1"></td>
            <td v-text="item.name"></td>
            <td v-text="item.product_code" v-if="tradeType == 1"></td>
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.img + ')'}" v-if="item.img"><img v-if="item.img" class="tableImgShow" :src="item.img" @click="PreviewImgs"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td class="TdStatus">
              <a v-if="item.estate == 1"><i class="wait"></i>{{$t('c.Common.InReview')}}</a>
              <a v-else-if="item.estate == 2"><i class="reject"></i>{{$t('c.Common.Refer')}}</a>
              <a v-else-if="item.estate == 3"><i class="pass"></i>{{$t('c.Common.Passed')}}</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius greenBackColor" @click="GoToPage('ORGProductEdit?id='+item.production_id)" v-text="$t('c.Common.Edit')"></a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.production_id)" v-text="$t('c.Common.Delete')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 添加商标模板窗口 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            {{$t('c.Common.SecurityAuthentication')}}
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow" v-if="SelectArr[0].datas.length > 0">
                <p class="FormStyleTwoTitle"><i>*</i> {{$t('b.Brand.Brand')}}（{{$t('b.Brand.HintTwo')}}）</p>
                <p>
                  <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                    {{SelectArr[0].datas[SelectArr[0].ix].trademark_name}}
                    <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                      <li class='OptionStyleQuery' v-if="SelectArr[0].totalPages > 1" @click.stop><input placeholder="可在此查询内容" v-model="SQueryName" /><a class="OptionStyleQueryBtn" @click.stop="SelectArrQuery">查询</a></li>
                      <ul class="OptionStyleMain">
                        <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[0].ix == index && !SelectArr[0].isHover}" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.trademark_name" @click="SelectData(0,index)" @mouseover="SelectHover($self,0,true)" @mouseout="SelectHover($self,0,false)"></li>
                        <li class="OptionStyleLoading" @click.stop="SelectArrLoading(0)" v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page < SelectArr[0].totalPages">加载更多</li>
                        <li class="OptionStyleLoading" @click.stop v-if="SelectArr[0].totalPages > 1 && SelectArr[0].page >= SelectArr[0].totalPages">无更多内容</li>
                      </ul>
                    </ul>
                    <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                  </div>
                </p>
              </li>
              <div class="BrandHint" v-else @click="OpenLeftNavPage(true,'Brand')"><i class="redColor">*</i> 还没有可使用的商标模板，点击<a>前往添加</a></div>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">{{$t('c.Common.Cancel')}}</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">{{$t('c.Common.Confirm')}}</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>
<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'ProductManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      spage:1,
      id:null,
      btype:null,
      tradeType:null,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      stotalPages:0,
      datas:null,
      ModelShow:false,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      ConfirmType:null,
      isHover:false,
      SelectArr:[{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      }],
      QueryName:null,
      SQueryName:null,
      fileTxt:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    self.btype = localStorage.getItem("wwmxt_BType");  //商户类型
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.SelectArr[0].isHover = false;
    },
    HandleModel (status,id) {  //隐藏显示生码模态框
      self.id = id;
      self.ModelShow = status;
    },
    SubmitForm () {  //提交表单
      self.loading = true;
      axios({
        method:"PATCH",
        url:self.GetServerUrl(1)+"bind_trademark_product",
        data:{trademark_id:self.SelectArr[0].datas[self.SelectArr[0].ix].trademark_id,production_id:self.id}
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"production_list/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.datas = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    RemoveData (id) {
      self.id = id;
      self.ConfirmType = 1;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该产品？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        if(self.ConfirmType == 1){  //删除产品
          axios({
            method:"delete",
            url:self.GetServerUrl(1)+"production/"+self.id,
          }).then(function (response) {
              self.loading = false;
              self.ToastFun(0,response.data.message);  //弹窗提醒
              self.GetList();
          }).catch(function (error) {
            self.loading = false;
            self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
          })
        }else{  //导入数据

          let fileDOM;
          fileDOM = this.$refs.file;

          //FormDatas格式提交
          let Datas = new FormData();
          Datas.append("file",fileDOM.files[0]);
          Datas.append("rootid",localStorage.getItem("wwmxt_Rootid"));

          axios.post(self.GetServerUrl(1)+"productImport",Datas)
          .then(function (response){
            self.loading = false;
            self.ToastFun(0,response.data.message);
            self.GetList();
          }).catch(function (error){
            self.loading = false;
            self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
          })
        }
      }
    },
    QueryData () {
      self.datas = null;
      self.isLoding = true;
      axios.post(self.GetServerUrl(1)+"production_list_condition",{name:self.QueryName})
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.datas = null;
      })
    },
    SelectArrQuery () {
      self.loading = true;
      axios.post(self.GetServerUrl(1)+"trademark_list_condition",{name:self.SQueryName})
      .then(function (response){
        self.SelectArr[0].ix = 0;
        self.SelectArr[0].datas = response.data.meta.data;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SelectArrLoading () {
      self.SelectArr[0].page++;
      self.loading = true;
      self.GetProductList(true);
    },
    OpenLeftNavPage (te,url) {
      self.$emit('OpenLeftNavPage',{te:te,url:url});
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    },
    UploadFile () {
      let fileTxt;
      if(this.$refs.file.files[0].type != "text/plain"){
        self.ToastFun(1,"文件格式必须为txt！");
        fileTxt = null;
        return;
      }
      let size = (this.$refs.file.files[0].size/(1024 * 1024)).toFixed(2);
      if(size < 0.01){
        size = 0.01;
      }
      if(size > 20){
        self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
        fileTxt = null;
        return;
      }
      fileTxt = '（'+size+'M）' + this.$refs.file.files[0].name;
      self.ConfirmType = 2;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定导入 "+fileTxt+" 文件？"
    }
  }
}
</script>

<style scoped>
  .BrandHint{
    color:#404A59;
  }
  .BrandHint a{
    color:#278BF5;
    cursor: pointer;
    margin-left:5px;
  }
  .BrandHint a:hover{
    text-decoration: underline;
  }
</style>
