<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>{{$t('b.CodeManage.CreateBarCodes')}}</li>
        <!-- <li class="TableHeadLeftRow btnStyleTwo cyanBackColor btnRadius"><span class="icon iconfont iconicon-test2"></span>{{$t('b.CodeManage.QRPrinting')}}</li> -->
      </ul>
      <ul class="clear"></ul>
    </div>
    <div class="TableHint"><i>*</i> {{$t('c.Common.FileHint')}}</div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Numbering')"></th>
            <th v-text="$t('c.Common.Amount')"></th>
            <th v-text="$t('c.Common.SuccessAmount')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.batch_id"></td>
            <td v-text="item.number"></td>
            <td v-text="item.snumber"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="DownCodeFile(item.batch_id)" v-text="$t('c.Common.Download')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>

    <!-- 生码窗口 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          {{$t('b.CodeManage.CreateCodes')}}
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">请选择条码位数</p>
              <p>
                <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                  {{SelectArr[0].datas[SelectArr[0].ix]}}
                  <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodeManage.BarCodeNumber')}}</p>
              <p><input :placeholder="$t('b.CodeManage.HintThirteen')" maxlength="7" v-model="FormDatas.number" /></p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)" v-text="$t('c.Common.Cancel')"></a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm" v-text="$t('c.Common.Confirm')"></a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self,Timeout;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'CodeManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      btype:null,
      tradeType:null,
      $self:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      totalPages:1,
      datas:null,
      SQueryName:null,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["10位","8位(8开头)","8位(9开头)","5位"]
      }],
      ModelShow:false,
      FormDatas:{},
      CodeNumber:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    self.btype = localStorage.getItem("wwmxt_BType");  //商户类型
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  beforeDestroy () {
    clearInterval(Timeout);
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.SelectArr[ix].isHover = false;
    },
    HandleModel (status) {  //隐藏显示生码模态框
      console.log(status)
      self.ModelShow = status;
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      self.subHint = "数据加载中...";
      axios.get(self.GetServerUrl(1)+"barcode_list/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    SubmitForm () {  //提交表单
      let datas = self.FormDatas;
      const numObj = {
        '10位': 10,
        '8位(8开头)': 8,
        '8位(9开头)': 8.9,
        '5位': 5
      }
      datas.codeLen = numObj[self.SelectArr[0].datas[self.SelectArr[0].ix]];
      if(self.FormDatas.number > 1000000){
        self.ToastFun(1,"单次生码量不能超过100万！");
        return;
      }
      if(!self.FormDatas.number){
        self.ToastFun(1,"条码数量不能为空！");
        return;
      }
      self.loading = true;
      axios({
        method:"post",
        url:self.GetServerUrl(1)+"barcode_create",
        data:datas
      }).then(function (response) {
        self.GetList();

        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,"生码成功！");  //弹窗提醒

        //重置表单内容
        self.FormDatas.number = null;
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    DownCodeFile (id) {
      if(id){
        window.open("https://syapi.oid.plus/v1/downCodeFile/5/"+id);
      }
    }
  }
}
</script>

<style scoped>
  .RemainCode{
    display: block;
    color:#FFF;
    font-size:13px;
    margin-top:5px;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #F77851;
  }
</style>
