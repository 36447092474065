<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 表单内容 -->
    <div class="box FloatBox">
      <div>
        <div class="hint">* {{$t('b.BatchManage.HintFive')}}</div>
        <div class="CustomTwoBox">
          <div class="CustomTwoMain" v-for="(item,index) in qc" v-bind:key="index">
            <ul class="CustomTwoLeft">
              <div class="SelectStyle" style="width: 80px;" @click.stop="CSelectActive(index)">
                <div class="SelectStyleValue">
                  <a v-if="item.ix == 0">{{$t('c.Common.Text')}}</a>
                  <a v-else-if="item.ix == 1">{{$t('c.Common.Image')}}</a>
                  <a v-else>{{$t('c.Common.Title')}}</a>
                </div>
                <ul class="OptionStyleBox" v-if="item.active">
                  <ul class="OptionStyleMain">
                    <li class="OptionStyleRow" @click.stop="CSelectData(index,0)">{{$t('c.Common.Text')}}</li>
                    <li class="OptionStyleRow" @click.stop="CSelectData(index,1)">{{$t('c.Common.Image')}}</li>
                    <li class="OptionStyleRow" @click.stop="CSelectData(index,2)">{{$t('c.Common.Title')}}</li>
                  </ul>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
            </ul>
            <ul class="CustomTwoRight">
              <input v-if="item.ix != 2" :placeholder="$t('b.BatchManage.HintSix')" v-model="item.key" style="width: 30%" />
              <input v-if="item.ix == 2" :placeholder="$t('b.BatchManage.HintSix')" v-model="item.key" style="width: calc(100% - 24px)" />
              <input v-if="item.ix != 2" :placeholder="$t('b.BatchManage.HintSeven')" v-model="item.value" style="width: calc(70% - 48px)" />
              <a v-if="item.ix == 1 && item.value" class="PreviewsImg cyanBackColor" :href="item.value" target="view_window">{{$t('c.Common.Preview')}}</a>
            </ul>
            <p class="btnArray FormStyleTwoImgBtnArrAbsolute">
              <a v-if="item.ix == 1" class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,index)">{{$t('c.Common.Image')}}</a>
              <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveRow(index)">{{$t('c.Common.Delete')}}</a>
            </p>
            <ul class="clear"></ul>

          </div>
          <div class="btnStyleTwo greenBackColor btnRadius AddDefine" @click="addAttributeRow"><span class="icon iconfont iconicon-test20"></span>{{$t('b.ProductManage.AddLine')}}</div>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm()"><span class="icon iconfont iconicon-test17"></span>{{$t('c.Common.Save')}}</a>
      <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>{{$t('c.Common.Back')}}</a>
    </div>

    <!-- 图片空间 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          {{$t('c.LeftNav.ImagesSpace')}}
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="TableHead ModalMainImgHead">
          <ul class="TableHeadLeft">
            <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius">
              <span class="icon iconfont iconicon-test20"></span>{{$t('b.Material.UploadImages')}}
              <input type="file" class="upload" @change="AddImg()" ref="imgs" multiple accept="image/png,image/jpeg,image/gif,image/jpg"/>
            </li>
          </ul>
          <ul class="TableHeadRight">
            <div class="SelectStyle ModalSelect" @click.stop="SelectActive(0,$self)" v-if="SelectArr[0].datas.length > 0">
              <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix].group_name}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                <ul class="OptionStyleMain">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.group_name" @click="SelectData(0,index,item.space_group_id)"></li>
                </ul>
              </ul>
              <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
            </div>
          </ul>
          <ul class="clear"></ul>
        </div>
        <div class="ModalMainBox ModalMainImgBox">
          <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!imgsList"></K-Transition>
          <div v-else :class="{ModalMainBoxScroll:imgsList.length > 5}">
            <table class="table">
              <tbody class="TRHoverStyle">
                <tr v-for="(item,index) in imgsList" v-bind:key="index" @click="selectImg(item.url)">
                  <td class="ModalMaiImgMainBox"><div class="ModalMaiImgMain backImg" :style="{backgroundImage: 'url(' + item.url + ')'}"></div></td>
                  <td v-text="item.name"></td>
                  <td class="ModalMaiImgIconBox"><span class="icon iconfont iconicon-test55 ModalMaiImgIcon"></span></td>
                </tr>
              </tbody>
            </table>
            <!-- 分页器 -->
            <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>
          </div>

        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">{{$t('c.Common.Cancel')}}</a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(false)">{{$t('c.Common.Confirm')}}</a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Column from '@/components/common/Column.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'QualityInformation',
  components: {
    'K-Paging': Paging,
    'K-Column': Column,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      ix:null,
      ModelShow:false,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      column:[
        {name:'BatchManage',url:'BatchManage'}
      ],
      SelectArr:[{
        ix:0,
        active:false,
        datas:[]
      }],
      pid:0, //分组id
      qc:null,
      imgsList:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.$route.query.id){
      self.id = self.$route.query.id;
      self.column[1] = {name:'QualityInformation',url:'QualityInformation?id='+self.$route.query.id};
      self.GetData();
    }else{
      javascript:history.back();
    }
  },
  mounted () {
    self.GetPacketList();
    self.GetImgsList();
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
          self.GetImgsList();
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
          self.GetImgsList();
        }
      }
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetImgsList();
    },
    SelectData (ix,six,id) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      if(ix == 2){  //列表分组选择
        self.page = 1;  //重置页数
        self.totalPages = null;  //清空分页总数
        self.pid = id;  //清楚分组ID
        self.imgsList = null;  //清除图片列表
        self.GetImgsList();  //获取空间图片列表
      }
    },
    HandleModel (status,ix) {
      self.ix = ix;
      self.ModelShow = status;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        javascript:history.back();
      }
    },
    addAttributeRow () {
      self.qc.push({ix:0,active:false,value:""});
    },
    RemoveRow (ix) {
      self.qc.splice(ix,1);
    },
    CSelectActive (ix) {
      if(self.qc[ix].active){
        self.qc[ix].active = false;
      }else{
        self.qc[ix].active = true;
      }
    },
    CSelectData (ix,six) {
      self.qc[ix].ix = six;
      self.qc[ix].active = false;
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"batch/"+self.id)
      .then(function (response){
        let qc = response.data.meta.qc;
        if(qc){
          qc = JSON.parse(qc)
        }
        if(qc && qc.length > 0){
          self.qc = qc;
        }else{
          self.qc = [{ix:0,active:false,value:""}];

          let uid = localStorage.getItem("wwmxt_Rootid");
          if(uid == "1004" || uid == "100010022"){
            self.defaultDatas();
          }
        }

        self.loading = false;
      }).catch(function (error){
        console.log(error)
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));
      })
    },
    GetPacketList (id) {
      axios.get(self.GetServerUrl(1)+"space_group_list/img")
      .then(function (response){
        self.SelectArr[0].datas = response.data.meta
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      }).catch(function (error){
        self.SelectArr[0].datas.unshift({space_group_id:0,group_name:"默认分组"});
      })
    },
    GetImgsList () {
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"resource/img/"+self.pid+"/"+self.page)
      .then(function (response){
          let datas = [];
          for(let item in response.data.meta.data){
            datas.push(response.data.meta.data[item])
            datas[item].active = false;
          }
          self.imgsList = datas;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.imgsList = null;
      })
    },
    selectImg (img) {
      self.qc[self.ix].value = img;
      self.HandleModel(false);
    },
    AddImg () {
      self.loading = true;

      let inputDOM;
      inputDOM = this.$refs.imgs;
      self.fileLength = inputDOM.files.length;

      //FormDatas格式提交
      let FormDatas = new FormData();
      FormDatas.append("type","img");
      FormDatas.append("space_group_id",self.pid);
      for(let i=0;i< inputDOM.files.length;i++){
        FormDatas.append("resource[]",inputDOM.files[i]);
      }

      axios({
        method:"post",
        url:self.GetServerUrl(1)+"resource",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          if(self.fileLength > response.data.meta.length){
            self.ToastFun(2,"文件必须为图片格式");  //弹窗提醒
          }else{
            self.ToastFun(0,response.data.message);  //弹窗提醒
          }
          self.GetImgsList();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SubmitForm () {
      self.loading = true;
      let qc = self.qc;
      if(self.qc.length <= 1){
        if(self.qc.length < 1 || (self.qc.length == 1 && !self.qc[0].value)){
          qc = null;
        }
      }

      axios({
        method:"patch",
        url:self.GetServerUrl(1)+"batch",
        data:{batch_id:self.id,qc:JSON.stringify(qc)},
      }).then(function (response) {
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    defaultDatas () {
      var arr = [
        {ix:2,active:false,key:"一、养殖场信息",value:null},
        {ix:0,active:false,key:"1、养殖场名称",value:"XXX养殖场　地址：XXX县XXX乡XXX村"},
        {ix:0,active:false,key:"2、养殖场备案号",value:"滑县鲜品达食品有限公司无抗养殖XXXXXX场 XX栋"},
        {ix:2,active:false,key:"二、老母鸡养殖信息",value:null},
        {ix:0,active:false,key:"1、进雏时间",value:"XXXX年XX月XX日"},
        {ix:0,active:false,key:"2、出栏日期",value:"XXXX年XX月XX日"},
        {ix:0,active:false,key:"3、运输车辆",value:"豫XXXXXX"},
        {ix:2,active:false,key:"三、老母鸡防疫信息",value:null},
        {ix:0,active:false,key:"防疫时间",value:"XXXX年XX月XX日　疫苗名称：XXXX"},
        {ix:2,active:false,key:"四、老母鸡用料信息",value:null},
        {ix:0,active:false,key:"饲料名称",value:"XXXX　厂家：XXX有限公司"},
        {ix:2,active:false,key:"五、屠宰厂信息",value:null},
        {ix:0,active:false,key:"1、屠宰工厂",value:"河南永达道口食品有限公司"},
        {ix:0,active:false,key:"2、经检验",value:"所检项目符合SB/T 10379标准要求"},
        {ix:0,active:false,key:"3、工厂地址",value:"滑县新区人民大道与南三环交叉路口西南角"},
        {ix:0,active:false,key:"4、屠宰日期",value:"XXXX年XX月XX日"},
      ]
      self.qc = arr;
    }
  }
}
</script>

<style scoped>
  .btnArray a{
    margin:0;
    margin-right: 15px;
  }
  .CustomTwoBox{
    margin-bottom:15px;
  }
  .CustomTwoMain{
    position: relative;
    margin-bottom:15px;
  }
  .CustomTwoLeft{
    float: left;
    width: 100px;
  }
  .CustomTwoRight{
    float: left;
    width: 50%;
    margin-left:13px;
    position: relative;
  }
  .CustomTwoRight input{
    width: 245px;
    height: 38px;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid #D8E4ED;
  }
  .FormStyleTwoImgBtnArrAbsolute{
    left:calc(50% + 120px);
  }
  .PreviewsImg{
    position: absolute;
    right:-1px;
    top:1px;
    line-height:38px;
    width:60px;
    text-align: center;
    cursor: pointer;
  }
</style>
