<template>
  <div class="ToastMain" :class="[{ToastRightOne:!Toast.state},{ToastRightTwo:Toast.state}]">
    <span class="icon iconfont iconicon-test65 ToastIcon greenColor" v-if="Toast.type == 0"></span>
    <span class="icon iconfont iconicon-test44 ToastIcon orangeColor" v-else-if="Toast.type == 1"></span>
    <span class="icon iconfont iconicon-test33 ToastIcon redColor" v-else></span>
    <div class="ToastTxt" v-text="Toast.txt"></div>
  </div>
</template>

<script>
var self;
export default {
  name: 'Toast',
  props: ['Toast'],
  data () {
    return {
      m:2000
    }
  },
  created () {
    self = this;
  },
  updated () {
    if(self.Toast.state){
      setTimeout(function(){
        self.$emit('ChangeToast');
      },self.Toast.time || self.m)
    }
  }
}
</script>

<style scoped>
  .ToastMain{
    position: fixed;
    top:95px;
    max-width: 200px;
    padding:20px 25px;
    padding-right:45px;
    border-radius: 5px 0 0 5px;
    background: rgba(255,255,255,0.9);
    transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    -o-transition:.5s;
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
    z-index: 1002;
  }
  .ToastIcon{
    position: absolute;
    top:calc(50% - 8px);
    left:15px;
    font-size:15px;
  }
  .ToastTxt{
    margin-left:15px;
  }
  .ToastRightOne{
    right:-280px;
  }
  .ToastRightTwo{
    right:0;
  }
</style>
