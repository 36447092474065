<template>
  <div>
    <K-HeadBarTwo></K-HeadBarTwo>

    <div class="MainBox">
      <div class="SiginBoxHead">
        <ul class="SiginBoxHeadMain">
          <li class="SiginBoxHeadTxt" :class="{ActiveNav:ix == 0}">1 身份验证</li>
          <li class="SiginBoxHeadLine"></li>
          <li class="SiginBoxHeadTxt" :class="{ActiveNav:ix == 1}">2 修改密码</li>
          <li class="SiginBoxHeadLine"></li>
          <li class="SiginBoxHeadTxt" :class="{ActiveNav:ix == 2}">3 完成</li>
        </ul>
      </div>

      <div class="ChangePasswordMain">
        <div class="ChangePasswordOneBox">
          <div class="CPMTitle">为确认是你本人操作，请选择验证方式完成身份验证：</div>
          <div class="ListStyleTwo" v-if="subIx == 0">
            <ul>
              <li class="ListStyleTwoTitle">通过 手机验证码</li>
              <li class="ListStyleTwoTxt">如果你的手机还在正常使用，请选择此方式</li>
              <a class="ListStyleTwoBtn" @click="verification(1,2)">立即验证</a>
            </ul>
            <ul>
              <li class="ListStyleTwoTitle">通过 邮箱验证码</li>
              <li class="ListStyleTwoTxt">如果你的手机还在正常使用，请选择此方式</li>
              <a class="ListStyleTwoBtn" @click="verification(1,1)">立即验证</a>
            </ul>
            <ul>
              <li class="ListStyleTwoTitle">通过 联系客服</li>
              <li class="ListStyleTwoTxt">如果上述验证方式均无法正常使用，你可以选择人工申诉</li>
              <a class="ListStyleTwoBtn" @click="verification(1,3)">立即验证</a>
            </ul>
          </div>

          <div class="SiginMainTwo SiginMainOneCenter" v-else-if="subIx == 1">

            <ul class="FormStyleOne" style="padding-right:100px;" v-if="etype == 1">
              <li class="FormStyleOneTitle">手机</li>
              <li>
                <p><input v-model="email" placeholder="请输入手机号" /></p>
              </li>
              <li class="FormStyleOneBtn btnStyleOne" @click="GetCode" v-if="CodeTime <= 1">发送验证码</li>
              <li class="FormStyleOneBtn btnStyleOne" v-text="CodeTime+'s'" v-else>获取验证码</li>
            </ul>
            <ul class="FormStyleOne" style="padding-right:100px;" v-else>
              <li class="FormStyleOneTitle">邮箱</li>
              <li>
                <p><input v-model="email" placeholder="请输入邮箱" /></p>
              </li>
              <li class="FormStyleOneBtn btnStyleOne" @click="GetCode" v-if="CodeTime <= 1">发送验证码</li>
              <li class="FormStyleOneBtn btnStyleOne" v-text="CodeTime+'s'" v-else>获取验证码</li>
            </ul>

            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle">邮箱验证码</li>
              <li>
                <p><input placeholder="请输入验证码" v-model="code" /></p>
              </li>
            </ul>
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle">新密码</li>
              <li>
                <p><input placeholder="请输入新密码" type="password" v-model="password" /></p>
              </li>
            </ul>
            <ul class="FormStyleOne">
              <li class="FormStyleOneTitle">重复密码</li>
              <li>
                <p><input placeholder="请重复输入新密码" type="password" v-model="repeat_password" /></p>
              </li>
            </ul>

            <div class="SiginButton">
              <div class="SiginButtonBox">
                <a class="SiginButtonRight" v-on:click="ClickBtn">确定</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

  </div>
</template>

<script>
var self;
import axios from 'axios'
import Toast from '@/components/common/Toast.vue'
import Loading from '@/components/common/Loading.vue'
import HeadBarTwo from '@/components/common/HeadBarTwo.vue'
export default {
  name: 'ChangePassword',
  components: {
    'K-HeadBarTwo': HeadBarTwo,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      ix:0,
      subIx:0,
      etype:1,
      email:null,
      phone:null,
      CodeTime:1,
      code:null,
      loading:false,
      password:null,
      repeat_password:null,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
    }
  },
  created () {
    self = this;
  },
  mounted () {

  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    verification (ix,te) {
      self.subIx = ix;
      self.etype = te;
    },
    GetCode () {
      var _url,_data;
      if(self.etype == 2){
        if(!self.email){
          self.ToastFun(2,"邮箱不能为空！");  //弹窗提醒
          return;
        }
        _url = "email_verif";
        _data = {email:self.email};
      }else if(self.etype == 1){
        if(!self.phone){
          self.ToastFun(2,"手机号码不能为空！");  //弹窗提醒
          return;
        }
        _url = "verif";
        _data = {btype:1,mobile:self.phone};
      }

      self.CodeTime = 60;
      let timer = setInterval(function(){
        self.CodeTime--;
        if(self.CodeTime <= 1){
          clearInterval(timer);
        }
      },1000)

      self.loading = true;
      axios.post(self.GetServerUrl(1)+_url,_data)
      .then(function (response) {
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error) {
        self.loading = false;
        console.log(error);
      })
    },
    ClickBtn () {
      if(!self.code){
        self.ToastFun(2,"验证码不能为空！");  //弹窗提醒
        return;
      }
      if(!self.password){
        self.ToastFun(2,"新密码不能为空！");  //弹窗提醒
        return;
      }
      if(!self.repeat_password){
        self.ToastFun(2,"重复密码不能为空！");  //弹窗提醒
        return;
      }
      if(self.password != self.repeat_password){
        self.ToastFun(2,"两次密码不一致！");  //弹窗提醒
        return;
      }
      var _data = {verification_code:self.code,password:self.password};
      if(self.etype == 2){
        if(!self.email){
          self.ToastFun(2,"邮箱不能为空！");  //弹窗提醒
          return;
        }
        _data.etype = self.etype;
        _data.email = self.email;
      }else{
        if(!self.phone){
          self.ToastFun(2,"手机号不能为空！");  //弹窗提醒
          return;
        }
        _data.etype = self.etype;
        _data.phone = self.phone;
      }
      axios.patch(self.GetServerUrl(1)+"forget_password",_data)
      .then(function (response) {
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
      }).catch(function (error) {
        self.loading = false;
        console.log(error);
      })
    }
  }
}
</script>

<style scoped>
  .ChangePasswordMain{
    padding:20px;
    width: 1000px;
    margin:20px auto;
  }
  .CPMTitle{
    color:#585A64;
    font-size:15px;
    margin-bottom:30px;
  }
  .ListStyleTwo ul{
    padding:20px;
    margin-top:20px;
    border:1px solid #EEE;
    position: relative;
  }
  .ListStyleTwoTitle{
    font-size:15px;
  }
  .ListStyleTwoTxt{
    margin-top:8px;
    color:#585A64;
  }
  .ListStyleTwoBtn{
    position: absolute;
    right:20px;
    color:#FFF;
    width:85px;
    text-align: center;
    line-height:30px;
    top:calc(50% - 17.5px);
    background-color: #3399FF;
    cursor: pointer;
  }
  .ListStyleTwoBtn:hover{
    background-color: #278BF5;
  }

  .SiginMainTwo{
    margin-top:20px;
  }
  .FormStyleOne{
    /* width:350px; */
  }
  .FormStyleOne input{
    width:325px;
  }
  .SiginButton{
    margin-top:25px;
  }
  .FormStyleOneBtn {
      left: 460px;
  }
</style>
