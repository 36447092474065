<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <!-- 表单内容 -->
    <div class="box FloatBox">
      <div class="FormStyleTwoLeft FloatBoxRow">
        <ul class="FormStyleTwo">
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>商户类型</p>
            <p>
              <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                {{SelectArr[0].datas[SelectArr[0].ix]}}
                <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                  <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
            </p>
          </li>
          <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 0">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>单位性质</p>
            <p>
              <div class="SelectStyle" @click.stop="SelectActive(1,$self)" v-if="SelectArr[1].datas">
                <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix].unit_type_name}}</div>
                <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                  <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[1].datas.length > 6}">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.unit_type_name" @click="SelectData(1,index,item.code)"></li>
                  </ul>
                </ul>
                <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
              </div>
            </p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>所属行业</p>
            <p>
              <div class='SelectStyleArr'>
                <div class="SelectStyle SelectStyle2" @click.stop="SelectActive(2,$self)" v-if="SelectArr[2].datas">
                  <div class="SelectStyleValue">{{SelectArr[2].datas[SelectArr[2].ix].trade_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[2].datas.length > 6}">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.trade_name" @click="SelectData(2,index,item.trade_id,item.trade_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle2" @click.stop="SelectActive(3,$self)" v-if="SelectArr[3].datas">
                  <div class="SelectStyleValue">{{SelectArr[3].datas[SelectArr[3].ix].trade_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[3].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[3].datas.length > 6}" v-if="SelectArr[3].datas.length">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[3].datas" v-bind:key="index" v-text="item.trade_name" @click="SelectData(3,index,item.trade_id,item.trade_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="clear"></div>
              </div>
            </p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>商户名称</p>
            <p><input placeholder="请输入商户名称" v-model="datas.name" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 0">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>信用代码</p>
            <p><input placeholder="请填写营业执照上的信用代码" v-model="datas.organization_code" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 0">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>单位住所</p>
            <p><input placeholder="单位住所必须与营业执照上一致" v-model="datas.baddress" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>邮箱（用于接收推送消息）</p>
            <p><input placeholder="请输入邮箱" type="email" v-model="datas.email" /></p>
          </li>
          <li class="FormStyleTwoRow" v-if="SelectArr[0].ix == 1">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>身份证号</p>
            <p><input placeholder="请填写负责人的身份证号码" v-model="datas.idcard" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>证件资料</p>
            <div class="FormStyleTwoImgs">
              <ul class="FormStyleTwoImgsRow" v-if="SelectArr[0].ix == 0">
                <li class="FormStyleTwoImgsBox backImg">
                  <div class="UploadFinishImg backImg" v-if="blicenseImg" :style="{backgroundImage:'url('+blicenseImg+')'}"></div>
                  <input type="file" class="upload" @change="AddImg(1)" ref="blicense" accept="image/png,image/jpeg,image/gif,image/jpg"/>
                </li>
                <li class='FormStyleTwoImgTitle'>营业执照</li>
              </ul>
              <ul class="FormStyleTwoImgsRow" v-if="SelectArr[0].ix == 1">
                <li class="FormStyleTwoImgsBox backImg">
                  <div class="UploadFinishImg backImg" v-if="idcardImg" :style="{backgroundImage:'url('+idcardImg+')'}"></div>
                  <input type="file" class="upload" @change="AddImg(2)" ref="idcardImg" accept="image/png,image/jpeg,image/gif,image/jpg"/>
                </li>
                <li class='FormStyleTwoImgTitle'>手持身份证</li>
              </ul>
              <ul class="FormStyleTwoImgsRow">
                <li class="FormStyleTwoImgsBox backImg">
                  <div class="UploadFinishImg backImg" v-if="logoImg" :style="{backgroundImage:'url('+logoImg+')'}"></div>
                  <input type="file" class="upload" @change="AddImg(3)" ref="logo" accept="image/png,image/jpeg,image/gif,image/jpg"/>
                </li>
                <li class='FormStyleTwoImgTitle'>LOGO</li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div class="FormStyleTwoRight FloatBoxRow">
        <div class="FormStyleTwo">
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>所属地区</p>
            <p>
              <div class='SelectStyleArr'>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(4,$self)" v-if="SelectArr[4].datas">
                  <div class="SelectStyleValue">{{SelectArr[4].datas[SelectArr[4].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[4].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[4].datas.length > 6}">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[4].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(4,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(5,$self)" v-if="SelectArr[5].datas">
                  <div class="SelectStyleValue">{{SelectArr[5].datas[SelectArr[5].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[5].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[5].datas.length > 6}" v-if="SelectArr[5].datas">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[5].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(5,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="SelectStyle SelectStyle3" @click.stop="SelectActive(6,$self)" v-if="SelectArr[6].datas">
                  <div class="SelectStyleValue">{{SelectArr[6].datas[SelectArr[6].ix].address_name}}</div>
                  <ul class="OptionStyleBox" v-if="SelectArr[6].active">
                    <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[6].datas.length > 6}" v-if="SelectArr[6].datas">
                      <li class="OptionStyleRow" v-for="(item,index) in SelectArr[6].datas" v-bind:key="index" v-text="item.address_name" @click="SelectData(6,index,item.address_id,item.address_level)"></li>
                    </ul>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
                <div class="clear"></div>
              </div>
            </p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>账号</p>
            <p><input placeholder="请输入登录账号" v-model="datas.username" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>密码</p>
            <p><input placeholder="字母、数字或者英文符号，最短6位，区分大小写" type="password" v-model="datas.password" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>确认密码</p>
            <p><input placeholder="字母、数字或者英文符号，最短6位，区分大小写" type="password" v-model="datas.password_confirmation" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>联系人</p>
            <p><input placeholder="请输入联系人名称" v-model="datas.linkman" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>电话（用于接收推送消息）</p>
            <p><input placeholder="请输入联系人电话" v-model="datas.linkphone" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle"><i class="redColor">*</i>经纬度</p>
            <p><input placeholder="点击选择经纬度" v-model="datas.lon_lat" /></p>
          </li>
          <li class="FormStyleTwoRow">
            <p class="FormStyleTwoTitle">企业简介</p>
            <p>
              <textarea class="form-control" v-model="datas.intro" placeholder="可输入企业简介"></textarea>
            </p>
          </li>
        </div>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="btnArray formFootBtnArr">
      <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>保存</a>
      <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>返回</a>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Column from '@/components/common/Column.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'BusinessEdit',
  components: {
    'K-Column': Column,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      id:null,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      column:[
        {name:'商户列表',url:'Business'}
      ],
      SelectArr:[{
        ix:0,
        active:false,
        datas:["企业","个人"]
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      },{
        ix:0,
        active:false,
        datas:null
      }],
      datas:{
        btype:1,
        username:null,
        password:null,
        password_confirmation:null,
        trade_id:null,
        email:null,
        name:null,
        code:1,
        province_id:null,
        city_id:null,
        area_id:null,
        linkman:null,
        linkphone:null,
        logo:null,
        idcard:null,
        idcard_img:null,
        verification_code:null,
        blicense:null,
        baddress:null,
        organization_code:null
      },
      blicenseImg:null,
      idcardImg:null,
      logoImg:null,
    }
  },
  created () {
    self = this;
    this.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.$route.query.id){
      self.id = self.$route.query.id;
      self.column[1] = {name:'编辑商户',url:'BusinessEdit?id='+self.$route.query.id};
      self.GetData();
    }else{
      self.column[1] = {name:'添加商户',url:'BusinessEdit'};
    }
  },
  mounted () {
    self.GetUnittypes(); //获取单位性质
    self.GetAddress(); //获取地区
    self.GetTrade(); //获取行业
  },
  methods: {
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    GetTrade (rank,id) {
      var tid = 0;
      var trank = 0;
      if(id){
        tid = id;
      }
      if(rank){
        trank = rank;
      }
      axios.get(self.GetServerUrl(1)+"trade/"+tid+"/"+self.datas.btype)
      .then(function (response) {
          if(trank == 0){
            self.SelectArr[2].datas = response.data.meta
            self.GetTrade(response.data.meta[0].trade_level,response.data.meta[0].trade_id);
          }else{
            self.SelectArr[3].datas = response.data.meta
          }

          //填充默认行业ID
          self.datas.trade_id = response.data.meta[0].trade_id;
      }).catch(function (error) {
        console.log("获取行业失败");
      })
    },
    GetUnittypes () {
      axios.get(self.GetServerUrl(1)+"unittypes/")
      .then(function (response) {
        self.SelectArr[1].datas = response.data.meta

        //填充默认单位性质ID
        self.datas.code = response.data.meta[0].trade_id;
      }).catch(function (error) {
        console.log("获取单位性质失败");
      })
    },
    GetAddress (rank,id) {
      var aid = 0;
      var arank = 0;
      if(id){
        aid = id;
      }
      if(rank){
        arank = rank;
      }
      axios.get(self.GetServerUrl(1)+"address/"+aid)
      .then(function (response) {
          if(arank == 0){
            self.datas.province_id = response.data.meta[0].address_id  //省id
            self.SelectArr[4].datas = response.data.meta
            self.GetAddress(response.data.meta[0].address_level,response.data.meta[0].address_id);  //获取市
          }else if(arank == 1){
            //如果不是直辖市获取县、区
            if(response.data.meta[0].address_level != 3){
              self.GetAddress(response.data.meta[0].address_level,response.data.meta[0].address_id);  //获取市
              self.datas.city_id = response.data.meta[0].address_id;  //市id
              self.SelectArr[5].datas = response.data.meta
            }else{
              self.datas.area_id = response.data.meta[0].address_id  //县区id
              self.SelectArr[6].datas = response.data.meta
            }
          }else if(arank == 2){
            self.datas.area_id = response.data.meta[0].address_id  //县区id
            self.SelectArr[6].datas = response.data.meta
          }
      }).catch(function (response) {
        console.log("获取地址失败");
      })
    },
    SelectData (ix,six,id,rank) {
      self.SelectArr[ix].ix = six;
      if(ix == 0){
        self.datas.btype = ++six;

        //重置行业ID
        self.SelectArr[2].ix = 0;
        self.SelectArr[3].ix = 0;

        self.GetTrade();
      }else if(ix == 1){
        self.datas.code = id;
      }else if(ix == 2){  //选中行业
        self.datas.trade_id = id  //行业id
        self.SelectArr[3].ix = 0;
        self.SelectArr[3].datas = null;
        self.GetTrade(rank,id);
      }if(ix == 3){  //选中行业
        self.datas.trade_id = id  //行业id
      }else if(ix == 4){  //选中省清除市和区的内容并重置索引
        self.datas.province_id = id  //省id
        self.datas.city_id = null
        self.datas.area_id = null
        self.SelectArr[5].ix = 0;
        self.SelectArr[5].datas = null;
        self.SelectArr[6].ix = 0;
        self.SelectArr[6].datas = null;
      }else if(ix == 5){  //选中市清除区的内容并重置索引
        self.datas.city_id = id;  //市id
        self.datas.area_id = null
        self.SelectArr[6].ix = 0;
        self.SelectArr[6].datas = null;
      }else if(ix == 6){
        self.datas.area_id = id  //县区id
      }

      //地址选择
      if(ix > 3 && ix < 6 && rank != 3 && id != 710000 && id != 810000 && id != 820000){
        self.GetAddress(rank,id);
      }
    },
    AddImg (te) {
      let inputDOM;
      if(te == 1){
        inputDOM = this.$refs.blicense;
      }else if(te == 2){
        inputDOM = this.$refs.idcardImg;
      }else{
        inputDOM = this.$refs.logo;
      }
      let file = inputDOM.files[0];
      var type = file.type.split('/')[0];
      let size = Math.floor(file.size / 1024);

      if ( type === 'image' ){
        //将图片img转化为base64
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          if(te == 1){  //营业执照
            self.blicenseError = false;
            self.datas.blicense = file;
            self.blicenseImg = reader.result;
          }else if(te == 2){  //手持身份证照
            self.idcardError = false;
            self.datas.idcard_img = file;
            self.idcardImg = reader.result;
          }else{  //商户logo
            self.logoError = false;
            self.datas.logo = file;
            self.logoImg = reader.result;
          }
        }
      }else{
        if(te == 1){
          self.blicenseError = true;
        }else{
          self.idcardError = true;
        }
      }
    },
    SubmitForm () {

      //地址id特殊处理
      if(!self.datas.city_id){
        self.datas.city_id = self.datas.province_id
      }
      if(!self.datas.area_id){
        self.datas.area_id = self.datas.city_id
      }

      //FormDatas格式提交
      let FormDatas = new FormData();
      for(var key in self.datas){
        if(self.datas[key]){
          FormDatas.append(key,self.datas[key]);
        }
      }

      self.loading = true;
      axios({
        method:"post",
        url:self.GetServerUrl(1)+"business",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data:FormDatas
      }).then(function (response) {
          self.loading = false;
          self.ToastFun(0,response.data.message);  //弹窗提醒
          javascript:history.back();
      }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })

    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width: 300px;
  }
  .SelectStyle2{
    float: left;
    width:130px;
    margin-right:18px;
  }
  .SelectStyle2:last-child{
    margin-right: 0;
  }
  .SelectStyle3{
    float: left;
    width:100px;
    margin-right:18px;
  }
  .SelectStyle3:last-child{
    margin-right: 0;
  }
  .FormStyleTwoRight {
    min-width: 450px;
  }
  .FormStyleTwoRight > .FormStyleTwo input,.FormStyleTwoRight > .FormStyleTwo textarea{
    width: 380px;
  }
  .FormStyleTwo textarea{
    height:39px;
  }
  .FormStyleTwoImgs{
    overflow: hidden;
  }
  .FormStyleTwoImgsRow{
    float: left;
    overflow: hidden;
    margin-right:20px;
  }
  .FormStyleTwoImgsBox{
    width:120px;
    height:120px;
    border:1px solid #D8E4ED;
    position: relative;
    background-image: url('/public/static/images/add.png');
  }
  .FormStyleTwoImgTitle{
    padding-top:11px;
    text-align: center;
    color:#585A64;
  }
</style>
