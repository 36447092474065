<template>
	<div class="MainBox">
    <div class="iconTitle"><span class="icon iconfont iconicon-test16"></span>发票功能设置</div>
		<div class="FunctionListMain">
		  <ul class="FunctionListContent">
		    <li class="FunctionListContentBox">
          <div class="FunctionListContentRow">
            <p class="FunctionListContentTitle">开票金额（开票的最小金额和最大金额设置）</p>
            <p class="FunctionListContentMsg"><input placeholder="最小多少金额可开票" /><input placeholder="最大多少金额可开票" /></p>
          </div>
		    </li>
		  </ul>
		</div>
	</div>
</template>

<script>
var self;
export default {
  name: 'InvoiceSetting',
  data () {
    return {
      functionListDatas:[{
        active:true,
        title:"IsWSA",
        content:"IsWSAHint"
      },{
        active:true,
        title:"IsWithdrawalAudit",
        content:"IsWithdrawalAuditHint"
      },{
        active:true,
        title:"IsGetUserPhone",
        content:"IsGetUserPhoneHint"
      }]
    }
  },
  created () {
    self = this;
  },
  mounted () {

  },
  methods: {
    SwicthSlide (ix) {
      if(self.functionListDatas[ix].active){
        self.functionListDatas[ix].active = false;
      }else{
        self.functionListDatas[ix].active = true;
      }
    }
  }
}
</script>

<style scoped>
  .FunctionListMain{
    padding:0;
  }
  .FunctionListContentBox{
    border-top:1px solid #F3F3F3;
  }
  .FunctionListContentRow{
    border-bottom: 1px solid #F3F3F3;
  }
</style>
