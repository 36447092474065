<template>
	<div class="MainBox">
    <div class="iconTitle"><span class="icon iconfont iconicon-test16"></span>{{$t('b.PromotionSet.PromotionSet')}}</div>
		<div class="FunctionListMain">
		  <ul class="FunctionListContent">
		    <li class="FunctionListContentBox">
		      <div class="FunctionListContentRow" v-for="(item,index) in functionListDatas" v-bind:key="index">
		        <p class="FunctionListContentTitle" v-text="$t('b.PromotionSet.'+item.title+'')"></p>
		        <p class="FunctionListContentMsg" v-text="$t('b.PromotionSet.'+item.content+'')"></p>
		        <a class="slidebtn slidebtnRight" :class="[{slidebtnOpen:item.active},{slidebtnClose:!item.active}]" @click="SwicthSlide(index)"><i class="transition"></i></a>
		      </div>
          <div class="FunctionListContentRow">
            <p class="FunctionListContentTitle">{{$t('b.PromotionSet.Withdrawal')}}（{{$t('b.PromotionSet.WithdrawalHint')}}）</p>
            <p class="FunctionListContentMsg"><input :placeholder="$t('b.PromotionSet.WithdrawalHintOne')" value="1" maxlength="4"/><input :placeholder="$t('b.PromotionSet.WithdrawalHintTwo')" value="2000" maxlength="4"/></p>
          </div>


          <div class="FunctionListContentRow">
            <p class="FunctionListContentTitle">{{$t('b.PromotionSet.Remind')}}（{{$t('b.PromotionSet.RemindHint')}}）</p>
            <p class="FunctionListContentMsg"><input :placeholder="$t('b.PromotionSet.RemindHintOne')" /><input :placeholder="$t('b.PromotionSet.RemindHintTwo')" maxlength="7" /></p>
          </div>
		    </li>
		  </ul>
		</div>
	</div>
</template>

<script>
var self;
export default {
  name: 'PromotionSet',
  data () {
    return {
      functionListDatas:[{
        active:true,
        title:"IsWSA",
        content:"IsWSAHint"
      },{
        active:true,
        title:"IsWithdrawalAudit",
        content:"IsWithdrawalAuditHint"
      },{
        active:true,
        title:"IsIntegral",
        content:"IsIntegralHint"
      },{
        active:true,
        title:"IsGetUserPhone",
        content:"IsGetUserPhoneHint"
      }]
    }
  },
  created () {
    self = this;
  },
  mounted () {

  },
  methods: {
    SwicthSlide (ix) {
      if(self.functionListDatas[ix].active){
        self.functionListDatas[ix].active = false;
      }else{
        self.functionListDatas[ix].active = true;
      }
    }
  }
}
</script>

<style scoped>
  .FunctionListMain{
    padding:0;
  }
  .FunctionListContentBox{
    border-top:1px solid #F3F3F3;
  }
  .FunctionListContentRow{
    border-bottom: 1px solid #F3F3F3;
  }
</style>
