<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius"><span class="icon iconfont iconicon-test23"></span>显示全部</li>
        <li class="TableHeadLeftRow btnStyleTwo cyanBackColor btnRadius"><span class="icon iconfont iconicon-test49"></span>行业统计</li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input placeholder="输入商户名称查询" v-model="QueryName" v-on:keyup.13="QueryData(2)" />
          <a class="SearchBtn" @click="QueryData(2)">搜索</a>
        </div>
      </ul>
      <ul class="TableHeadRight" style="margin-right:20px;">
        <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
          {{SelectArr[0].datas[SelectArr[0].ix]}}
          <ul class="OptionStyleBox" v-if="SelectArr[0].active" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
            <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
          </ul>
          <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable">
        <thead>
          <tr>
            <th>套餐类型</th>
            <th>商户类型</th>
            <th>商户名称</th>
            <th>行业</th>
            <th>生码量</th>
            <th>联系人</th>
            <th>到期时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td>
              <a v-if="item.user_package" v-text="item.user_package.name"></a>
              <a v-else>-</a>
            </td>
            <td>
              <a v-if="item.btype == 1">企业</a>
              <a v-if="item.btype == 2">个人</a>
            </td>
            <td v-text="item.name"></td>
            <td>
              <a v-if="item.trade" v-text="item.trade.trade_name"></a>
            </td>
            <td>
              <a v-if="item.user_package" v-text="item.user_package.created_code_limited"></a>
              <a v-else>-</a>
            </td>
            <td>
              <a v-if="item.linkman" v-text="item.linkman"></a>
              <a v-else>-</a>
            </td>
            <td>
              <a class="redColor" v-if="item.user_package" v-text="item.user_package.due_date"></a>
              <a v-else>-</a>
            </td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="GoToPage('BusinessDetail?id='+item.rootid)">商户详情</a>
                <a class="btnStyleTwo btnRadius cyanBackColor">推送通知</a>
                <a class="btnStyleTwo btnRadius redBackColor">禁用</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>
	</div>
</template>

<script>
var self;
import axios from 'axios'
import Column from '@/components/common/Column.vue'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'Business',
  components: {
    'K-Column': Column,
    'K-Paging': Paging,
    'K-Transition': Transition
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      QueryName:null,
      column:[],
      SelectArr:[{
        ix:0,
        active:false,
        datas:["全部","企业","个人"]
      }]
    }
  },
  created () {
    self = this;
    this.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    if(self.$route.query.id){
      self.id = self.$route.query.id;
      self.column[0] = {name:'组织列表（'+self.$route.query.name+'）',url:'Organization'};
      self.column[1] = {name:'企业列表',url:'JuniorBusiness?id='+self.$route.query.id};
      self.GetList();
    }else{
      javascript:history.back();
    }
  },
  methods: {
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.QueryData(1);
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.post(self.GetServerUrl(1)+"business_list",{id:self.id,page:self.page})
      .then(function (response){
          self.datas = response.data.meta.data;
          self.isLoding = false;

          //总页数
          self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    QueryData (te) {
      self.datas = null;
      self.isLoding = true;

      let datas;
      if(te == 1){
        datas = {id:self.id,btype:self.SelectArr[0].ix};
      }else{
        datas = {id:self.id,name:self.QueryName};
      }

      axios.post(self.GetServerUrl(1)+"business_list",datas)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
        self.datas = null;
      })
    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width:100px;
  }
</style>
