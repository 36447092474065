<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
		<div class="FunctionPackageNav">安全问题设置</div>
    <div class="FormStyleTwoBox">
      <div class="FormStyleTwo">
        <li class="FormStyleTwoRow">
          <p class="FormStyleTwoTitle"><i class="redColor">*</i>安全问题一</p>
          <p>
            <div class="SelectStyle" @click.stop="SelectActive(0,$self)" v-if="SelectArr[0].datas.length > 0">
              <div>{{SelectArr[0].datas[SelectArr[0].ix].problem}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[0].ix == index && !isHover}" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item.problem" @click="SelectData(0,index)" @mouseover="SelectHover(true)" @mouseout="SelectHover(false)"></li>
              </ul>
              <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
            </div>
          </p>
        </li>
        <li class="FormStyleTwoRow FormStyleTwoInput">
          <p><input placeholder="请输入答案一" v-model="FormDatas.one" /></p>
        </li>
        <li class="FormStyleTwoRow">
          <p class="FormStyleTwoTitle"><i class="redColor">*</i>安全问题二</p>
          <p>
            <div class="SelectStyle" @click.stop="SelectActive(1,$self)" v-if="SelectArr[0].datas.length > 0">
              <div>{{SelectArr[1].datas[SelectArr[1].ix].problem}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[1].ix == index && !isHover}" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item.problem" @click="SelectData(1,index)" @mouseover="SelectHover(true)" @mouseout="SelectHover(false)"></li>
              </ul>
              <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
            </div>
          </p>
        </li>
        <li class="FormStyleTwoRow FormStyleTwoInput">
          <p><input placeholder="请输入答案二" v-model="FormDatas.two" /></p>
        </li>
        <li class="FormStyleTwoRow">
          <p class="FormStyleTwoTitle"><i class="redColor">*</i>安全问题三</p>
          <p>
            <div class="SelectStyle" @click.stop="SelectActive(2,$self)" v-if="SelectArr[0].datas.length > 0">
              <div>{{SelectArr[2].datas[SelectArr[2].ix].problem}}</div>
              <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                <li class="OptionStyleRow" :class="{OptionStyleRowActive:SelectArr[2].ix == index && !isHover}" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.problem" @click="SelectData(2,index)" @mouseover="SelectHover(true)" @mouseout="SelectHover(false)"></li>
              </ul>
              <span class="icon iconfont iconicon-test22 OptionStyleIcon"></span>
            </div>
          </p>
        </li>
        <li class="FormStyleTwoRow FormStyleTwoInput">
          <p><input placeholder="请输入答案三" v-model="FormDatas.three" /></p>
        </li>
      </div>

      <!-- 底部按钮 -->
      <div class="btnArray formFootBtnArr">
        <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm"><span class="icon iconfont iconicon-test17"></span>保存</a>
        <a class="btnStyleTwo btnRadius cyanBackColor" href="javascript:history.back();"><span class="icon iconfont iconicon-test18"></span>返回</a>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'ChangePassword',
  components: {
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      $self:null,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      SelectArr:[{
        ix:0,
        active:false,
        datas:[]
      },{
        ix:0,
        active:false,
        datas:[]
      },{
        ix:0,
        active:false,
        datas:[]
      }],
      datas:null,
      isHover:false,
      FormDatas:{
        one:null,
        two:null,
        three:null
      }
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    SelectHover (status) {
      self.isHover = status;
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.isHover = false;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        javascript:history.back();
      }
    },
    GetList () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"safe_problem/")
      .then(function (response){
        self.loading = false;
        let datas = response.data.meta;
        for(let item in datas){
          if(item <= 5){
            self.SelectArr[0].datas.push(datas[item]);
          }else if(item > 5 && item < 12){
            self.SelectArr[1].datas.push(datas[item]);
          }else if(item >= 12){
            self.SelectArr[2].datas.push(datas[item]);
          }
        }
      }).catch(function (error){
        self.loading = false;
      })
    },
    SubmitForm () {
      if(!self.FormDatas.one && !self.FormDatas.two && !self.FormDatas.three){
        self.ToastFun(2,"安全问题不能为空！");
        return;
      }
      let datas = [
        {id:self.SelectArr[0].datas[self.SelectArr[0].ix].id,value:self.FormDatas.one},
        {id:self.SelectArr[1].datas[self.SelectArr[1].ix].id,value:self.FormDatas.two},
        {id:self.SelectArr[2].datas[self.SelectArr[2].ix].id,value:self.FormDatas.three},
      ];

      self.loading = true;
      axios({
        method:"patch",
        url:self.GetServerUrl(1)+"safe_problem/",
        data:{type:"safe_problem",value:datas}
      }).then(function (response) {
          self.loading = false;
          self.Confirm.style = 1;
          self.Confirm.state = true;
          self.Confirm.txt = response.data.message+"，是否确定返回上一级？"
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  .SelectStyle{
    width:300px;
  }
  .FunctionPackageNav{
    padding:15px 20px;
    border-bottom: 1px solid #F3F3F3;
  }
  .FormStyleTwoBox{
    padding:20px;
    padding-bottom: 150px;
  }
  .formFootBtnArr{
    display: block;
  }
  .FormStyleTwoInput{
    margin-top:10px;
  }
</style>
