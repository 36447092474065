<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>添加模板</li>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <table class="table StripeTable">
        <thead>
          <tr>
            <th>类型</th>
            <th>行业</th>
            <th>编号</th>
            <th>名称</th>
            <th>图片</th>
            <th>时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle" v-if="datas">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td>
              <a v-if="item.template_type == 0">普通</a>
              <a v-else-if="item.template_type == 1">防伪</a>
              <a v-else-if="item.template_type == 2">促销</a>
            </td>
            <td>
              <a v-if="item.trade_type == 0">普通行业</a>
              <a v-else-if="item.trade_type == 1">农药行业</a>
              <a v-else-if="item.trade_type == 2">医疗行业</a>
              <a v-else-if="item.trade_type == 3">通用模板</a>
            </td>
            <td v-text="item.id"></td>
            <td v-text="item.name"></td>
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.cover_img + ')'}" v-if="item.cover_img"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="HandleModel(true,item)">编辑</a>
                <a class="btnStyleTwo btnRadius redBackColor" @click="RemoveData(item.template_id)">删除</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

      <!-- 添加手机模板 -->
      <div class="ModalBox" v-if="ModelShow">
        <div class="ModalMain">
          <div class="ModalMainHead">
            添加手机模板
            <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
          </div>
          <div class="ModalMainBox">
            <ul class="FormStyleTwo">
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>模板图片</p>
                <p>
                  <p class="FormStyleOneImgs">
                    <a class="UploadBtn" @click="ImagesListModelFun({type:true})">
                      <div class="UploadFinishImg backImg" v-if="FormDatas.cover_img" :style="{backgroundImage:'url('+FormDatas.cover_img+')'}"></div>
                    </a>
                  </p>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>模板类型</p>
                <p>
                  <div class='SelectStyleArr'>
                    <div class="SelectStyle" @click.stop="SelectActive(0,$self)">
                      <div class="SelectStyleValue">{{SelectArr[0].datas[SelectArr[0].ix]}}</div>
                      <ul class="OptionStyleBox" v-if="SelectArr[0].active">
                        <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[0].datas.length > 6}">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[0].datas" v-bind:key="index" v-text="item" @click="SelectData(0,index)"></li>
                        </ul>
                      </ul>
                      <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                    </div>
                    <div class="clear"></div>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow" v-if="SelectArr[0].ix != 2">
                <p class="FormStyleTwoTitle"><i>*</i>行业类型</p>
                <p>
                  <div class='SelectStyleArr'>
                    <div class="SelectStyle" @click.stop="SelectActive(1,$self)">
                      <div class="SelectStyleValue">{{SelectArr[1].datas[SelectArr[1].ix]}}</div>
                      <ul class="OptionStyleBox" v-if="SelectArr[1].active">
                        <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[1].datas.length > 6}">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[1].datas" v-bind:key="index" v-text="item" @click="SelectData(1,index)"></li>
                        </ul>
                      </ul>
                      <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                    </div>
                    <div class="clear"></div>
                  </div>
                </p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 模板编号</p>
                <p><input placeholder="请输入模板编号" v-model="FormDatas.id" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 模板名称</p>
                <p><input placeholder="请输入模板名称" v-model="FormDatas.name" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i> 模板描述</p>
                <p><input placeholder="请输入模板描述" v-model="FormDatas.describe" /></p>
              </li>
              <li class="FormStyleTwoRow">
                <p class="FormStyleTwoTitle"><i>*</i>功能选择（选择后点击后方 “添加” 按钮，添加到列表）</p>
                <p>
                  <div class='SelectStyleArr'>
                    <div class="SelectStyle" style="margin-right:70px;" @click.stop="SelectActive(2,$self)">
                      <div class="SelectStyleValue">{{SelectArr[2].datas[SelectArr[2].ix].name}}</div>
                      <ul class="OptionStyleBox" v-if="SelectArr[2].active">
                        <ul class="OptionStyleMain" :class="{ShowScrollBar:SelectArr[2].datas.length > 6}">
                          <li class="OptionStyleRow" v-for="(item,index) in SelectArr[2].datas" v-bind:key="index" v-text="item.name" @click="SelectData(2,index)"></li>
                        </ul>
                      </ul>
                      <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                    </div>
                    <div class="SelectStyleArrRightBtn btnStyleTwo btnRadius greenBackColor" @click="AddFunction">添加</div>
                    <div class="clear"></div>
                  </div>
                </p>
              </li>
              <li class="ListBtnArray">
                <a class="feedback NoSelect" v-if="FunctionList" v-for="(item,index) in FunctionList" v-bind:key="index" v-text="item.name" @click="RemoveTrade(item)"></a>
              </li>
            </ul>
          </div>
          <div class="ModalMainFoot">
            <div class="ModalMainFootRight btnArray">
              <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)">取消</a>
              <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm">确定</a>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

    <!-- 图片列表 -->
    <K-ImagesList :ImgsData="ImgsData" @SonToastFun="SonToastFun" @ImagesListModelFun="ImagesListModelFun"></K-ImagesList>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
import ImagesList from '@/components/common/ImagesList.vue'
export default {
  name: 'CreateCodeAudit',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-ImagesList': ImagesList,
    'K-Loading': Loading,
    'K-Toast': Toast,
    'K-Confirm': Confirm
  },
  data () {
    return {
      $self:null,
      page:1,
      id:null,
      ImgsData:{
        type:false
      },
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      totalPages:0,
      datas:null,
      ModelShow:false,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["普通","防伪","促销"]
      },{
        ix:0,
        active:false,
        datas:["普通行业","农药行业","医疗行业"]
      },{
        ix:0,
        active:false,
        datas:[
          {te:"basic_is_open",name:"基本信息"},
          {te:"focus_is_open",name:"焦点图"},
          {te:"prod_is_open",name:"产品信息"},
          {te:"antifake_is_open",name:"防伪信息"},
          {te:"batch_is_open",name:"批次信息"},
          {te:"trace_is_open",name:"溯源信息"},
          {te:"activity_is_open",name:"促销活动"},
          {te:"music_is_open",name:"音乐"},
          {te:"video_is_open",name:"视频"},
          {te:"business_is_open",name:"商户信息"},
          {te:"ref_is_open",name:"自定义栏目"},
          {te:"menu_is_open",name:"底部菜单"},
          {te:"module_index_is_open",name:"模块索引"},
        ]
      }],
      FormDatas:{
        id:null,
        name:null,
        describe:null,
        cover_img:null,
        basic_is_open:0,
        focus_is_open:0,
        prod_is_open:0,
        antifake_is_open:0,
        activity_is_open:0,
        music_is_open:0,
        video_is_open:0,
        business_is_open:0,
        ref_is_open:0,
        menu_is_open:0,
        module_index_is_open:0,
      },
      FunctionList:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ImagesListModelFun (data) {
      self.ImgsData.type = data.type;
      if(data.img){
        self.FormDatas.cover_img = data.img;
      }
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    SonToastFun (data) {
      self.Toast.state = true;
      self.Toast.type = data.te;
      self.Toast.txt = data.txt;
    },
    HandleModel (status,data) {  //隐藏显示生码模态框
      self.ModelShow = status;
      if(data){
        self.id = data.template_id;
        self.SelectArr[0].ix = data.template_type;
        self.SelectArr[1].ix = data.trade_type;
        self.FormDatas.id = data.id;
        self.FormDatas.name = data.name;
        self.FormDatas.describe = data.describe;
        self.FormDatas.cover_img = data.cover_img;

        self.FunctionList = [];
        if(data.basic_is_open == 1){
          self.FunctionList.push({te:"basic_is_open",name:"基本信息"});
        }
        if(data.focus_is_open == 1){
          self.FunctionList.push({te:"focus_is_open",name:"焦点图"});
        }
        if(data.prod_is_open == 1){
          self.FunctionList.push({te:"prod_is_open",name:"产品信息"});
        }
        if(data.antifake_is_open == 1){
          self.FunctionList.push({te:"antifake_is_open",name:"防伪信息"});
        }
        if(data.batch_is_open == 1){
          self.FunctionList.push({te:"batch_is_open",name:"批次信息"});
        }
        if(data.trace_is_open == 1){
          self.FunctionList.push({te:"trace_is_open",name:"溯源信息"});
        }
        if(data.activity_is_open == 1){
          self.FunctionList.push({te:"activity_is_open",name:"促销信息"});
        }
        if(data.music_is_open == 1){
          self.FunctionList.push({te:"music_is_open",name:"音乐"});
        }
        if(data.video_is_open == 1){
          self.FunctionList.push({te:"video_is_open",name:"视频"});
        }
        if(data.business_is_open == 1){
          self.FunctionList.push({te:"business_is_open",name:"商户信息"});
        }
        if(data.ref_is_open == 1){
          self.FunctionList.push({te:"ref_is_open",name:"自定义栏目"});
        }
        if(data.menu_is_open == 1){
          self.FunctionList.push({te:"menu_is_open",name:"底部菜单"});
        }
        if(data.module_index_is_open == 1){
          self.FunctionList.push({te:"module_index_is_open",name:"模块索引"});
        }
        self.SelectArr[2].ix = 0;
      }else{
        self.id = null;
        self.FormDatas.id = null;
        self.FormDatas.name = null;
        self.FormDatas.describe = null;
        self.FormDatas.cover_img = null;
      }
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    SelectData (ix,six,id,rank) {
      self.SelectArr[ix].ix = six;
    },
    GetList () {
      axios.get(self.GetServerUrl(1)+"template_list/all/"+self.page)
      .then(function (response) {
        self.datas = response.data.meta.data
        self.isLoding = false;
        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.datas = null;
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    AddFunction () {
      let ix = self.SelectArr[2].ix;
      let iSRepeat = false;
      if(self.FunctionList&&self.FunctionList.length > 0){
        for(let item in self.FunctionList){
          if(self.FunctionList[item].te == self.SelectArr[2].datas[ix].te){
            iSRepeat = true;
            self.ToastFun(2,"不允许重复插入！");
            return;
          }
          if(item == self.FunctionList.length-1 && !iSRepeat){
            self.FunctionList.push(self.SelectArr[2].datas[ix]);
          }
        }
      }else{
        self.FunctionList = [self.SelectArr[2].datas[ix]];
      }
    },
    RemoveTrade (data) {
      for(let item in self.FunctionList){
        if(self.FunctionList[item].te == data.te){
          self.FunctionList.splice(item,1);
        }
      }
    },
    SubmitForm () {
      let te = "post";
      if(self.id){
        te = "patch"
        self.FormDatas.template_id = self.id;
      }

      self.FormDatas.template_type = self.SelectArr[0].ix;
      self.FormDatas.trade_type = self.SelectArr[1].ix;

      //促销模板不区分行业
      if(self.SelectArr[0].ix == 2){
        self.FormDatas.trade_type = 3;
      }

      //初始化
      self.FormDatas.basic_is_open = 0;
      self.FormDatas.focus_is_open = 0;
      self.FormDatas.prod_is_open = 0;
      self.FormDatas.antifake_is_open = 0;
      self.FormDatas.batch_is_open = 0;
      self.FormDatas.trace_is_open = 0;
      self.FormDatas.activity_is_open = 0;
      self.FormDatas.music_is_open = 0;
      self.FormDatas.video_is_open = 0;
      self.FormDatas.business_is_open = 0;
      self.FormDatas.ref_is_open = 0;
      self.FormDatas.menu_is_open = 0;
      self.FormDatas.module_index_is_open = 0;
      for(let item in self.FunctionList){
        if(self.FunctionList[item].te == "basic_is_open"){
          self.FormDatas.basic_is_open = 1;
        }
        if(self.FunctionList[item].te == "focus_is_open"){
          self.FormDatas.focus_is_open = 1;
        }
        if(self.FunctionList[item].te == "prod_is_open"){
          self.FormDatas.prod_is_open = 1;
        }
        if(self.FunctionList[item].te == "antifake_is_open"){
          self.FormDatas.antifake_is_open = 1;
        }
        if(self.FunctionList[item].te == "batch_is_open"){
          self.FormDatas.batch_is_open = 1;
        }
        if(self.FunctionList[item].te == "trace_is_open"){
          self.FormDatas.trace_is_open = 1;
        }
        if(self.FunctionList[item].te == "activity_is_open"){
          self.FormDatas.activity_is_open = 1;
        }
        if(self.FunctionList[item].te == "music_is_open"){
          self.FormDatas.music_is_open = 1;
        }
        if(self.FunctionList[item].te == "video_is_open"){
          self.FormDatas.video_is_open = 1;
        }
        if(self.FunctionList[item].te == "business_is_open"){
          self.FormDatas.business_is_open = 1;
        }
        if(self.FunctionList[item].te == "ref_is_open"){
          self.FormDatas.ref_is_open = 1;
        }
        if(self.FunctionList[item].te == "menu_is_open"){
          self.FormDatas.menu_is_open = 1;
        }
        if(self.FunctionList[item].te == "module_index_is_open"){
          self.FormDatas.module_index_is_open = 1;
        }
      }

      axios({
        method:te,
        url:self.GetServerUrl(1)+"template",
        data:self.FormDatas
      }).then(function (response) {
        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,response.data.message);  //弹窗提醒
        self.GetList();
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    RemoveData (id) {
      self.id = id;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定删除该规则？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        axios({
          method:"delete",
          url:self.GetServerUrl(1)+"template/"+self.id,
        }).then(function (response) {
            self.loading = false;
            self.ToastFun(0,response.data.message);  //弹窗提醒
            self.GetList();
        }).catch(function (error) {
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    }
  }
}
</script>

<style scoped>
  .ListBtnArray{
    overflow: hidden;
  }
  .ListBtnArray a{
    float: left;
    padding:10px 16px;
    border:1px solid #DDD;
    border-radius: 5px;
    margin-right:17px;
    color:#585A64;
    cursor: pointer;
    margin-top:15px;
  }
  .ListBtnActive{
    color:#278BF5 !important;
    border:1px solid #278BF5 !important;
  }
  .SelectStyle2{
    float: left;
    width:200px;
    margin-right:18px;
  }
  .SelectStyle2:last-child{
    margin-right: 0;
  }
  .SelectStyleArrRightBtn{
    position: absolute;
    right:0;top:0;
  }
</style>
