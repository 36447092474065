<template>
	<div class="MainBox">
    <div class="NotificationsBox">
      <div class="NotificationsBtnArray NoSelect">
        <a v-for="(item,index) in NavList" v-bind:key="index" v-text="item.title+'('+item.number+')'" :class="{NotificationsBtnActive:index == ix,NotificationsBtn:index != ix}" @click="ClickNav(index)"></a>
      </div>

      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="tableTwo" v-else>
        <thead>
          <tr>
            <th style="width:10px"><input type="checkbox" /></th>
            <th>类型</th>
            <th>主题</th>
            <th>标题内容</th>
            <th>时间</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index" :class="{read:item.status}">
            <td><input type="checkbox" /></td>
            <td v-text="item.te"></td>
            <td v-text="item.theme"></td>
            <td><a v-text="item.title" class="HighlightTitle"></a></td>
            <td v-text="item.date"></td>
            <td>
              <a class="tableTwoA NoSelect">查看详情</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="NotificationsBottom NoSelect">
        <ul class="NotificationsBottomLeft">
          <li class="NotificationsBottomLeftCheck"><input type="checkbox" /></li>
          <li class="NotificationsBottomLeftBtn" :class="{NoClick:!selectDatas}">删除</li>
          <li class="NotificationsBottomLeftBtn">全部删除</li>
        </ul>
        <ul class="NotificationsBottomRight">
          <li class="NotificationsBottomArrows"><<</li>
          <li class="NotificationsBottomArrows"><</li>
          <li class="NotificationsBottomActive">1</li>
          <li>2</li>
          <li>3</li>
          <li class="NotificationsBottomArrows">>></li>
          <li class="NotificationsBottomArrows">></li>
        </ul>
      </div>
    </div>
	</div>
</template>

<script>
var self;
import Transition from '@/components/common/Transition.vue'
export default {
  name: 'Notifications',
  data () {
    return {
      ix:0,
      isLoding:true,
      NavList:[{
        title:"全部消息",
        number:17
      },{
        title:"产品消息",
        number:2
      },{
        title:"活动消息",
        number:6
      },{
        title:"系统消息",
        number:1
      }],
      datas:[],
      selectDatas:null
    }
  },
  created () {
    self = this;
    console.log(this.$route.query.id);
  },
  mounted () {
    setTimeout(function(){
      self.datas = [{
        te:'所有用户',
        theme:'维护消息',
        title:"2019年10月30号凌晨0点维护，如给您带来不便非常抱歉！",
        date:'2019-09-08 13:22'
      },{
        te:'经销代理',
        theme:'产品消息',
        title:"2020年1月30号起经销代理提成自30%调整为35%！",
        date:'2019-09-08 13:22'
      },{
        te:'所有商户',
        theme:'活动消息',
        title:"2020年1月30号庆祝链接OID10周年所有套餐打8折！",
        date:'2019-09-08 13:22'
      }],
      self.totalPages = 2;
      self.subHint = "数据为空";
      self.isLoding = false;
    },300)
  },
  methods: {
    ClickNav (ix) {
      self.ix = ix;
    }
  }
}
</script>

<style scoped>
  .NotificationsBox{
    padding:20px;
    font-size:13px;
  }
  .NotificationsBtnArray{
    overflow: auto;
  }
  .NotificationsBtnArray a{
    float: left;
    display: block;
    padding:10px 15px;
    margin-left:10px;
    cursor: pointer;
  }
  .NotificationsBtnArray a:first-child{
    margin-left:0;
  }
  .NotificationsBtn{
    color:#333;
    background-color: #EBEDEF;
  }
  .NotificationsBtn:hover{
    background-color: #E6E8E9;
  }
  .NotificationsBtnActive{
    color:#FFF;
    background-color: #3595FF;
  }
  .NotificationsBtnActive:hover{
    background-color: #278BF5;
  }

  .NotificationsBottom{
    overflow: hidden;
    border:1px solid #E1E6EB;
    border-top:0;
    padding:10px 20px;
  }
  .NotificationsBottom input{
    position: relative;
    top:15px;
  }
  .NotificationsBottomLeft{
    float: left;
    overflow: hidden;
  }
  .NotificationsBottomLeft li{
    float: left;
  }
  .NotificationsBottomLeftCheck{
    margin-right:5px;
  }
  .NotificationsBottomLeftBtn{
    padding:10px 15px;
    color:#333;
    margin-left:10px;
    cursor: pointer;
    border: 1px solid #DDD;
    background-color: #F7F7F7;
  }
  .NotificationsBottomLeftBtn:hover{
    background-color: #F3F3F3;
  }
  .NoClick{
    color:#BBB;
  }
  .NotificationsBottomRight{
    float: right;
    overflow: hidden;
    margin-top:4px;
  }
  .NotificationsBottomRight li{
    float: left;
    height:30px;
    line-height: 30px;
    width:30px;
    text-align: center;
    border: 1px solid #CCC;
    border-right: 0;
    color:#333;
    cursor: pointer;
  }
  .NotificationsBottomRight li:first-child{
    border-radius:3px 0 0 3px;
  }
  .NotificationsBottomRight li:last-child{
    border-right:1px solid #CCC;
    border-radius: 0 3px 3px 0;
  }
  .NotificationsBottomArrows{
    font-size:10px;
  }
  .NotificationsBottomActive{
    color:#FFF !important;
    box-shadow:0 0 5px rgba(53,149,255,0.6);
    border:1px solid #3595FF !important;
    background-color: #3595FF;
  }
  .tableTwoA{
    color:#404A59;
    cursor: pointer;
  }
  .tableTwoA:hover{
    color:#278BF5;
    text-decoration: underline;
  }
</style>
