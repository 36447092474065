<template>
	<div class="MainBox">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="GoToPage('DealerEdit?type=add')"><span class="icon iconfont iconicon-test20"></span>{{$t('b.Dealer.AddDealer')}}</li>
        <li class="TableHeadLeftRow btnStyleTwo cyanBackColor btnRadius">
          <span class="icon iconfont iconicon-test19"></span>{{$t('c.Common.DatasImport')}}
          <input type="file" class="upload" @change="UploadFile" ref="file" />
        </li>
      </ul>
      <ul class="TableHeadRight">
        <div class="SearchBox">
          <input :placeholder="$t('b.Dealer.HintOne')" v-model="QueryName" v-on:keyup.13="QueryData" />
          <a class="SearchBtn" @click="QueryData" v-text="$t('c.Common.Search')"></a>
        </div>
      </ul>
      <ul class="clear"></ul>
    </div>

    <div class="TableMain">
      <viewer @inited="inited" ref="viewer">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Name')"></th>
            <th v-text="$t('c.Common.Alias')"></th>
            <th v-text="$t('c.Common.Image')"></th>
            <th v-text="$t('c.Common.Linkman')"></th>
            <th v-text="$t('c.Common.Phone')"></th>
            <th v-text="$t('c.Common.Address')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.name"></td>
            <td v-text="item.alias"></td>
            <td>
              <div class="tableImg backImg" :style="{backgroundImage: 'url(' + item.cover_img + ')'}" v-if="item.cover_img"><img v-if="item.cover_img" class="tableImgShow" :src="item.cover_img" @click="PreviewImgs"></div>
              <div class="tableImg backImg backImgNull" v-else></div>
            </td>
            <td v-text="item.linkman"></td>
            <td v-text="item.linkphone"></td>
            <td v-text="item.linkaddress"></td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius blueBackColor" @click="GoURL('DealerEdit',item.rootid,item.rootid_extid1,item.rootid_extid2,item.rootid_extid3)" v-text="$t('c.Common.Edit')"></a>
                <a class="btnStyleTwo btnRadius cyanBackColor"  @click="GoURL('UserManage',item.rootid,item.rootid_extid1,item.rootid_extid2,item.rootid_extid3)" v-text="$t('c.Common.Manage')"></a>
                <a class="btnStyleTwo btnRadius greenBackColor" @click="GoURL('StockManage',item.rootid,item.rootid_extid1,item.rootid_extid2,item.rootid_extid3,item.name)" v-text="$t('c.Common.Stock')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      </viewer>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'DealerManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Confirm': Confirm,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      Confirm:{
        state:false,
        txt:null
      },
      ConfirmType:null,
      QueryName:null
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"dealer_list/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    QueryData () {
      self.datas = null;
      self.isLoding = true;
      axios.post(self.GetServerUrl(1)+"dealer_list_condition",{name:self.QueryName})
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    inited (viewer) {
      self.$viewer = viewer
    },
    PreviewImgs () {
      self.$viewer.show();
      self.$viewer.update();
    },
    GoEdit (idOne,idTwo,idThree,idFour) {
      self.GoToPage('DealerEdit?id='+self.GoDealerID(idOne,idTwo,idThree,idFour));
    },
    GoURL(url,idOne,idTwo,idThree,idFour,name) {
      if(name){
        var stock = {id:self.GoDealerID(idOne,idTwo,idThree,idFour),type:"Dealer",name:name};
        localStorage.setItem("wwmxt_stock",JSON.stringify(stock));
        self.GoToPage(url);
      }else{
        self.GoToPage(url+'?id='+self.GoDealerID(idOne,idTwo,idThree,idFour));
      }
    },
    UploadFile () {
      let fileTxt;
      let size = (this.$refs.file.files[0].size/(1024 * 1024)).toFixed(2);
      if(size < 0.01){
        size = 0.01;
      }
      if(size > 20){
        self.ToastFun(1,"文件大小不能超过20M，当前大小为："+size+"M！");
        fileTxt = null;
        return;
      }
      fileTxt = '（'+size+'M）' + this.$refs.file.files[0].name;
      self.ConfirmType = 2;
      self.Confirm.state = true;
      self.Confirm.txt = "是否确定导入 "+fileTxt+" 文件？"
    },
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){
        self.Confirm.state = false;
        self.loading = true;

        let fileDOM;
        fileDOM = this.$refs.file;

        //FormDatas格式提交
        let Datas = new FormData();
        Datas.append("file",fileDOM.files[0]);
        Datas.append("rootid",localStorage.getItem("wwmxt_Rootid"));

        axios.post(self.GetServerUrl(1)+"importUserBy50",Datas)
        .then(function (response){
          self.$refs.file.value = null; //清空input-file
          self.loading = false;
          self.ToastFun(0,response.data.message);
          self.GetList();
        }).catch(function (error){
          self.$refs.file.value = null; //清空input-file
          self.loading = false;
          self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
