<template>
	<div class="MainBox">
    <div class="CodeQueryBox FloatBox">
      <div class="CodeQueryLeft FormStyleThree">
        <div class="FormStyleThreeQuery">
          <input placeholder="可键入码标进行查询" v-model="code" v-on:keyup.13="QueryData" />
          <a class="FormStyleThreeBtn blueBackColor NoSelect" v-on:click="QueryData">查询</a>
        </div>

        <div class="CodeQueryProduct">
          <p class="CodeQueryProductTitle"><span class="icon iconfont iconicon-test28"></span>窜货信息</p>
          <ul class="FormStyleFour">
            <li>
              <a class="FormStyleFourKey">窜货码标：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.code"></a>
            </li>
            <li>
              <a class="FormStyleFourKey">发货时间：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.code_shipping_time"></a>
            </li>
            <li>
              <a class="FormStyleFourKey">用户扫码地区：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.dealer_province"></a>
            </li>
            <li>
              <a class="FormStyleFourKey">首次扫码时间：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.first_time"></a>
            </li>
            <li>
              <a class="FormStyleFourKey">最后收货网点：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.province"></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="CodeQueryRight" id="MapBox"></div>
    </div>

    <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
    <table class="table StripeTable" v-else>
      <thead>
        <tr>
          <th>窜货码标</th>
          <th>经销商地区</th>
          <th>扫码地区</th>
          <th>扫码时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody class="TRHoverStyle">
        <tr v-for="(item,index) in datas" v-bind:key="index">
          <td v-text="item.code"></td>
          <td v-text="item.dealer_province"></td>
          <td v-text="item.province"></td>
          <td v-text="item.first_time"></td>
          <td>
            <div class="btnArray">
              <a class="btnStyleTwo btnRadius blueBackColor">码标查询</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self,MapMain;
var echarts = require('echarts');
require('echarts/map/js/china');
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'CodeQuery',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      totalPages:0,
      datas:null,
      chartDatas:[],
      code:null,
      FormDatas:{
        code:null,
        dealer_province:null,
        first_time:null,
        province:null
      },
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      }
    }
  },
  created () {
    self = this;
  },
  mounted () {
    self.GetList();
    self.GetChartList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ShowMap () {
      MapMain = echarts.init(document.getElementById('MapBox'));
      MapMain.setOption({
        title: {
          text: '窜货警报',
          subtext: '红色区域为串入地区扫码',
          top:20,
          left:20,
          textStyle: {
            fontWeight:"normal",
            fontSize:15,
            color:"#fff"
          }
        },
				tooltip: {
					trigger: 'item',
					showDelay: 0,
					transitionDuration: 0.2,
					formatter: function (params) {
						var value = (params.value + '').split('.');
						value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,');
            if(value > 0){
              return params.seriesName + '<br/>' + params.name + ': ' + value;
            }else{
              return params.seriesName + '<br/>' + params.name + ': ' + 0;
            }
					}
				},
        backgroundColor: '#404a59',
        series: [{
          name: '窜入次数',
          type: 'map',
          map: 'china',
          itemStyle: {
            normal: {
              areaColor:"#999",
            },
            emphasis:{label:{show:true}}
          },
          data:self.chartDatas
        }]
			});
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      axios.get(self.GetServerUrl(1)+"abnormity/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    GetChartList () {
      axios.get(self.GetServerUrl(1)+"abnormityArea")
      .then(function (response){
        let datas = response.data.meta;
        for(let item in datas){
          datas[item].name = datas[item].name.replace('省','');
          datas[item].itemStyle = {areaColor:"#DD4B39"};
        }
        self.chartDatas = datas;
        setTimeout(function(){
          self.ShowMap(); //窜货图表加载
        },100)
      }).catch(function (error){
        console.log(self.ErrorHint(error));
        self.ShowMap(); //窜货图表加载
      })
    },
    QueryData () {
      let code = self.FiltrationCode(self.code);
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"abnormityQuery/"+code)
      .then(function (response){
        self.FormDatas = response.data.meta;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  #MapBox{
    margin-left:20px;
    width: calc(100% - 422px);
    height:402px;
    background-color: #404a59;
  }
</style>
