<template>
	<div class="MainBox CodeQueryBox">

    <div class="FormStyleThree">
      <div class="FormStyleThreeQuery">
        <input placeholder="请键入查询的码标" v-model="code" v-on:keyup.13="QueryData" />
        <a class="FormStyleThreeBtn blueBackColor NoSelect" v-on:click="QueryData">查询</a>
      </div>
    </div>

    <div class="FloatBox">
      <div class="CodeQueryLeft FormStyleThree">
        <div class="CodeQueryProduct">
          <p class="CodeQueryProductTitle"><span class="icon iconfont iconicon-test28"></span>码标信息</p>
          <ul class="FormStyleFour">
            <li>
              <a class="FormStyleFourKey">生码批次：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.plan_id"></a>
            </li>
            <li>
              <a class="FormStyleFourKey">发货编号：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.shipping_first_id"></a>
            </li>
            <li>
              <a class="FormStyleFourKey">产品名称：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.product.name"></a>
            </li>
            <li>
              <a class="FormStyleFourKey">防伪码：</a>
              <a class="FormStyleFourValue" v-text="FormDatas.verify"></a>
            </li>
            <div v-if="FormDatas.verify">
              <li>
                <a class="FormStyleFourKey">查询次数：</a>
                <a class="FormStyleFourValue" v-text="FormDatas.query_count"></a>
              </li>
              <li>
                <a class="FormStyleFourKey">首次查询时间：</a>
                <a class="FormStyleFourValue" v-text="FormDatas.query_first_time"></a>
              </li>
            </div>
            <div v-else>
              <li>
                <a class="FormStyleFourKey">查询次数：</a>
                <a class="FormStyleFourValue" v-text="FormDatas.scan_count"></a>
              </li>
              <li>
                <a class="FormStyleFourKey">首次查询时间：</a>
                <a class="FormStyleFourValue" v-text="FormDatas.scan_first_time"></a>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div class="CodeQueryRight" v-if="FormDatas.process&&FormDatas.process.length > 0">
        <div>
          <p class="CodeQueryProductTitle"><span class="icon iconfont iconicon-test39"></span>工序信息</p>
          <ul class="Flowline">
            <li v-for="(item,index) in FormDatas.process" v-bind:key="index">
              <i class="FlowlineDot FlowlineTopDot" v-if="index == 0"></i>
              <i class="FlowlineDot FlowlineRowDot" v-if="FormDatas.process.length > 2 && index > 0 && index < FormDatas.process.length-1"></i>
              <i class="FlowlineDot FlowlineBottomDot" v-if="index == FormDatas.process.length-1"></i>
              <p class="FlowlineTitle" v-text="item.name" v-if="item.name"></p>
              <p class="FlowlineTitle" v-else>未知</p>
              <p class="FlowlineDate" v-text="item.created_at"></p>
            </li>
          </ul>
        </div>
      </div>
      <div class="CodeQueryRight" v-if="FormDatas.dealer&&FormDatas.dealer.length > 0">
        <div>
          <p class="CodeQueryProductTitle"><span class="icon iconfont iconicon-test12"></span>经销商信息</p>
          <ul class="Flowline">
            <li v-for="(item,index) in FormDatas.dealer" v-bind:key="index">
              <i class="FlowlineDot FlowlineTopDot" v-if="index == 0"></i>
              <i class="FlowlineDot FlowlineRowDot" v-if="FormDatas.dealer.length > 2 && index > 0 && index < FormDatas.dealer.length-1"></i>
              <i class="FlowlineDot FlowlineBottomDot" v-if="index == FormDatas.dealer.length-1"></i>
              <p class="FlowlineTitle" v-text="item.name"></p>
              <p class="FlowlineDate" v-text="item.created_at"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'CodeQuery',
  components: {
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      code:null,
      FormDatas:{
        plan_id:null,
        verify:null,
        scan_count:null,
        scan_first_time:null,
        product:{},
        process:[],
        dealer:[],
      },
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      }
    }
  },
  created () {
    self = this;
  },
  mounted () {

  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    QueryData () {
      let code = self.FiltrationCode(self.code);
      self.loading = true;
      self.FormDatas = {product:{},process:[],dealer:[]};
      axios.get(self.GetServerUrl(1)+"queryInSide/"+code)
      .then(function (response){
        self.FormDatas = response.data.meta;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  .CodeQueryLeft{
    width: 402px;
  }
  .CodeQueryRight{
    margin-left:50px;
    width: 300px;
  }
  .FormStyleThree input{
    width: 380px;
  }

  .Flowline{
    margin:20px 7px;
    border-left:2px solid #DDD;
  }
  .Flowline li{
    margin-left:25px;
    margin-top:30px;
    position: relative;
  }
  .Flowline li:first-child{
    margin-top:0;
  }
  .FlowlineTitle{
    color:#292728;
  }
  .FlowlineDate{
    color:#646B71;
    margin-top:5px;
  }
  .FlowlineDot{
    position: absolute;
    height:10px;
    width:10px;
    left:-31px;
    border-radius: 10px;
    background-color: #DDD;
  }
  .FlowlineTopDot{
    top:0;
  }
  .FlowlineRowDot{
    top:16px;
  }
  .FlowlineBottomDot{
    bottom:0;
  }
</style>
