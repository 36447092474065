<template>
	<div class="MainBox">

    <!-- 面包屑导航 -->
    <K-Column :column="column"></K-Column>

    <div class="box" v-if="datas">
      <div class="MessageBox">
        <div class="MessageHead">
          <div class="MessageTitle" v-text="datas.title"></div>
          <div class="MessageDate" v-text="datas.created_at"></div>
        </div>

        <div class="MessageMain" v-html="datas.body"></div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Column from '@/components/common/Column.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'NotificationsDetail',
  components: {
    'K-Column': Column,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      datas:null,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      column:[{
        name:'消息列表（点击返回上一级）',url:'Notifications'
      }]
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
    self.id = self.$route.query.id;
    self.column[1] = {name:'消息详情',url:'NotificationsDetail?id='+self.$route.query.id};
  },
  mounted () {
    self.GetData();
  },
  watch:{
    $route(){
      self.id = this.$route.query.id
      self.GetData();
    }
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    GetData () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"message/"+self.id)
      .then(function (response){
        self.datas = response.data.meta;
        self.datas.created_at = self.datas.created_at.substring(0,10);
        self.loading = false;

      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    }
  }
}
</script>

<style scoped>
  .MessageBox{
    padding:0 50px;
    border:1px solid #E1E6EB;
  }
  .MessageHead{
    padding:25px 0;
    text-align: center;
    border-bottom: 1px solid #E1E6EB;
  }
  .MessageTitle{
    font-size:17px;
    letter-spacing: 1px;
  }
  .MessageDate{
    color:#666;
    font-size:14px;
    margin-top:8px;
  }
  .MessageMain{
    padding:30px 0 40px 0;
  }
</style>
