<template>
	<div class="MainBox" @click.stop="SelectActive(-1,$self)">
    <div class="TableHead">
      <ul class="TableHeadLeft">
        <li class="TableHeadLeftRow btnStyleTwo blueBackColor btnRadius" @click="HandleModel(true)"><span class="icon iconfont iconicon-test20"></span>{{$t('b.CodeManage.CreateCodes')}}</li>
        <!-- <li class="TableHeadLeftRow btnStyleTwo cyanBackColor btnRadius"><span class="icon iconfont iconicon-test2"></span>{{$t('b.CodeManage.QRPrinting')}}</li> -->
      </ul>
      <ul class="clear"></ul>
    </div>
    <div class="TableHint"><i>*</i> {{$t('c.Common.FileHint')}}</div>

    <div class="TableMain">
      <K-Transition :isLoding="isLoding" :subHint="subHint" v-if="!datas"></K-Transition>
      <table class="table StripeTable" v-else>
        <thead>
          <tr>
            <th v-text="$t('c.Common.Numbering')"></th>
            <!-- <th v-if="btype == 1" v-text="$t('c.Common.OIDAuthentication')"></th> -->
            <!-- <th v-text="$t('c.Common.ProductName')"></th> -->
            <th v-text="$t('c.Common.Amount')"></th>
            <th v-text="$t('c.Common.ActivateWay')"></th>
            <th v-text="$t('c.Common.SecurityCode')"></th>
            <th v-text="$t('c.Common.SequenceCode')"></th>
            <!-- <th v-text="$t('c.Common.Type')"></th> -->
            <th v-text="$t('c.Common.State')"></th>
            <th v-text="$t('c.Common.CreationTime')"></th>
            <th v-text="$t('c.Common.Operation')"></th>
          </tr>
        </thead>
        <tbody class="TRHoverStyle">
          <tr v-for="(item,index) in datas" v-bind:key="index">
            <td v-text="item.plan_id"></td>
            <!-- <td v-if="btype == 1">
              <span v-if="item.is_OID == 1" class="icon iconfont iconicon-test61 whether blueColor"></span>
              <span v-else class="icon iconfont iconicon-test62 whether"></span>
            </td> -->
          <!--  <td>
              <a v-text="item.production_name" v-if="item.production_name"></a>
              <a v-else>-</a>
            </td> -->
            <td v-text="item.number"></td>
            <td>
              <a v-if="item.active_way == 0" v-text="$t('c.Common.DefaultActivation')"></a>
              <a v-else v-text="$t('c.Common.OutboundActivation')"></a>
            </td>
            <td>
              <span v-if="item.is_code == 0" class="icon iconfont iconicon-test62 whether"></span>
              <a v-else-if="item.is_code == 1" v-text="$t('b.CodeManage.Figure')"></a>
              <a v-else-if="item.is_code == 2" v-text="$t('b.CodeManage.EnglishFigures')"></a>
            </td>
            <td>
              <span v-if="item.is_flow == 1" class="icon iconfont iconicon-test61 whether blueColor"></span>
              <span v-else class="icon iconfont iconicon-test62 whether"></span>
            </td>
            <!-- <td>
              <a v-if="item.tag_type == 0 && item.level == 0">1{{$t('c.Common.Level')}}</a>
              <a v-else-if="item.tag_type == 0 && item.level == 1">2{{$t('c.Common.Level')}}</a>
              <a v-else-if="item.tag_type == 0 && item.level == 2">3{{$t('c.Common.Level')}}</a>
              <a v-else-if="item.tag_type == 0 && item.level == 3">4{{$t('c.Common.Level')}}</a>
              <a v-else-if="item.tag_type == 1">{{$t('b.CodeManage.Casecode')}}</a>
            </td> -->
            <td class="TdStatus">
              <a v-if="item.estate == 100"><i class="wait"></i>{{$t('c.Common.InProgress')}}</a>
              <a v-else-if="item.estate == 200"><i class="reject"></i>{{$t('c.Common.Failure')}}</a>
              <a v-else-if="item.estate == 300"><i class="pass"></i>{{$t('c.Common.Completed')}}</a>
              <a v-else-if="item.estate == 400"><i class="reject"></i>{{$t('c.Common.Expired')}}</a>
            </td>
            <td v-text="item.created_at"></td>
            <td>
              <div class="btnArray">
                <a class="btnStyleTwo btnRadius" :class="{blueBackColor:item.estate == 300,ProhibitBtn:item.estate != 300}" @click="DownCodeFile(item.url)" v-text="$t('c.Common.Download')"></a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 分页器 -->
      <K-Paging :page="page" :totalPages="totalPages" @ChangePage="ChangePage" @SkipPage="SkipPage"></K-Paging>

    </div>

    <!-- 生码窗口 -->
    <div class="ModalBox" v-if="ModelShow">
      <div class="ModalMain">
        <div class="ModalMainHead">
          {{$t('b.CodeManage.CreateCodes')}}
          <span class="icon iconfont iconicon-test29 ModalMainHeadClose feedback" @click="HandleModel(false)"></span>
        </div>
        <div class="ModalMainBox">
          <ul class="FormStyleTwo">
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">{{$t('c.Common.SecurityCode')}}（{{$t('b.CodeManage.HintThree')}}）</p>
              <p>
                <div class="SelectStyle" @click.stop="SelectActive(3,$self)">
                  {{$t('b.CodeManage.'+SelectArr[3].datas[SelectArr[3].ix]+'')}}
                  <ul class="OptionStyleBox" v-if="SelectArr[3].active">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[3].datas" v-bind:key="index" v-text="$t('b.CodeManage.'+item+'')" @click="SelectData(3,index)"></li>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">{{$t('c.Common.SequenceCode')}}（{{$t('b.CodeManage.HintFour')}}）</p>
              <p>
                <div class="SelectStyle" @click.stop="SelectActive(4,$self)">
                  {{$t('c.Common.'+SelectArr[4].datas[SelectArr[4].ix]+'')}}
                  <ul class="OptionStyleBox" v-if="SelectArr[4].active">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[4].datas" v-bind:key="index" v-text="$t('c.Common.'+item+'')" @click="SelectData(4,index)"></li>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
              </p>
            </li>
            <li class="FormStyleTwoRow">
              <p class="FormStyleTwoTitle">{{$t('c.Common.Type')}}（{{$t('b.CodeManage.HintFive')}}）</p>
              <p>
                <div class="SelectStyle" @click.stop="SelectActive(5,$self)">
                  {{$t('b.CodeManage.'+SelectArr[5].datas[SelectArr[5].ix]+'')}}
                  <ul class="OptionStyleBox" v-if="SelectArr[5].active">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[5].datas" v-bind:key="index" v-text="$t('b.CodeManage.'+item+'')" @click="SelectData(5,index)"></li>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
              </p>
            </li>
            <!-- <li class="FormStyleTwoRow" v-if="SelectArr[5].ix == 0">
              <p class="FormStyleTwoTitle">{{$t('b.CodeManage.CodeLevel')}}（{{$t('b.CodeManage.HintSix')}}）</p>
              <p>
                <div class="SelectStyle" @click.stop="SelectActive(6,$self)">
                  {{SelectArr[6].datas[SelectArr[6].ix]}}
                  <ul class="OptionStyleBox" v-if="SelectArr[6].active">
                    <li class="OptionStyleRow" v-for="(item,index) in SelectArr[6].datas" v-bind:key="index" v-text="item" @click="SelectData(6,index)"></li>
                  </ul>
                  <a class="icon iconfont iconicon-test22 OptionStyleIcon"></a>
                </div>
              </p>
            </li> -->
            <li class="FormStyleTwoRow" v-if="SelectArr[5].ix == 1">
              <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodeManage.BoxCodeNumber')}}（{{$t('b.CodeManage.HintEleven')}}）</p>
              <p><input maxlength="6" v-model="FormDatas.box_code_number" @input="CodeCalculate" /></p>
            </li>
            <li class="FormStyleTwoRow" v-if="SelectArr[5].ix == 1">
              <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodeManage.EachBoxNumber')}}（{{$t('b.CodeManage.HintTwelve')}}）</p>
              <p><input maxlength="6" v-model="FormDatas.p_code_number" @input="CodeCalculate" /></p>
            </li>
            <li class="FormStyleTwoRow" v-if="SelectArr[5].ix == 1">
              <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodeManage.CodeNumber')}}（{{$t('b.CodeManage.HintSeven')}} <a class="FocusA">{{$t('b.CodeManage.HintNine')}}</a>）</p>
              <p><input :placeholder="$t('b.CodeManage.HintThirteen')" maxlength="7" disabled="disabled" v-model="FormDatas.number" /></p>
            </li>
            <li class="FormStyleTwoRow" v-if="SelectArr[5].ix == 0">
              <p class="FormStyleTwoTitle"><i>*</i>{{$t('b.CodeManage.CodeNumber')}}（{{$t('b.CodeManage.HintSeven')}} <a class="FocusA">{{$t('b.CodeManage.HintNine')}}</a>）</p>
              <p><input :placeholder="$t('b.CodeManage.HintThirteen')" maxlength="7" v-model="FormDatas.number" /></p>
            </li>
          </ul>
        </div>
        <div class="ModalMainFoot">
          <div class="ModalMainFootLeft feedback"><span class="icon iconfont iconicon-test28"></span>{{$t('c.Common.Document')}}</div>
          <div class="ModalMainFootRight btnArray">
            <a class="btnStyleTwo btnRadius grayBackColor" @click="HandleModel(false)" v-text="$t('c.Common.Cancel')"></a>
            <a class="btnStyleTwo btnRadius blueBackColor" @click="SubmitForm" v-text="$t('c.Common.Confirm')"></a>
          </div>
        </div>
      </div>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self,Timeout;
import axios from 'axios'
import Paging from '@/components/common/Paging.vue'
import Transition from '@/components/common/Transition.vue'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'CodeManage',
  components: {
    'K-Paging': Paging,
    'K-Transition': Transition,
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      btype:null,
      tradeType:null,
      $self:null,
      page:1,
      subHint:'数据加载中...',
      isLoding:true,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      totalPages:1,
      datas:null,
      SQueryName:null,
      SelectArr:[{
        ix:0,
        active:false,
        datas:["no","yes"]
      },{
        ix:0,
        active:false,
        datas:["DefaultActivation","OutboundActivation"]
      },{
        ix:0,
        page:1,
        totalPages:0,
        active:false,
        isHover:false,
        datas:[]
      },{
        ix:0,
        active:false,
        datas:["no","Figure","EnglishFigures"]
      },{
        ix:0,
        active:false,
        datas:["no","yes"]
      },{
        ix:0,
        active:false,
        datas:["Monocode","Casecode"]
      },{
        ix:0,
        active:false,
        datas:["1","2","3","4"]
      },{
        ix:0,
        active:false,
        datas:["HolderCertificate","ManufacturingConsignment","EntrustRepackaging"]
      }],
      ModelShow:false,
      FormDatas:{},
      CodeNumber:null
    }
  },
  created () {
    self = this;
    self.$self = this;
    self.btype = localStorage.getItem("wwmxt_BType");  //商户类型
    self.tradeType = localStorage.getItem("wwmxt_TradeType");  //行业类型
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  beforeDestroy () {
    clearInterval(Timeout);
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    ChangePage (data) {  //上下翻页
      if(data.te == 1){
        if(self.page > 1){
          self.page--
        }
      }else{
        if(self.page < self.totalPages){
          self.page++
        }
      }
      self.GetList();
    },
    SkipPage (data) {  //跳转分页
      self.page = data.ix;
      self.GetList();
    },
    SelectData (ix,six) {  //选中选择框内容
      self.SelectArr[ix].ix = six;
      self.SelectArr[ix].isHover = false;

      if(ix == 0){
        self.SelectArr[2].page = 1;
        self.SelectArrQuery(false);
      }else if(ix == 5){
        if(self.SelectArr[ix].ix == 1){
          self.CodeCalculate();
        }else{
          self.FormDatas.number = null;
        }
      }
    },
    HandleModel (status) {  //隐藏显示生码模态框
      console.log(status)
      self.ModelShow = status;
    },
    CodeCalculate () {  //根据箱码数计算码标数量
      if(self.FormDatas.box_code_number > 0 || self.FormDatas.p_code_number > 0){
        if(self.FormDatas.box_code_number > 0 && self.FormDatas.p_code_number > 0){
          self.FormDatas.number = parseInt(self.FormDatas.box_code_number) * parseInt(self.FormDatas.p_code_number) + parseInt(self.FormDatas.box_code_number);
        }else if(self.FormDatas.box_code_number > 0){
          self.FormDatas.number = self.FormDatas.box_code_number;
        }else if(self.FormDatas.p_code_number > 0){
          self.FormDatas.box_code_number = 1;
          self.FormDatas.number = parseInt(self.FormDatas.box_code_number) * parseInt(self.FormDatas.p_code_number) + parseInt(self.FormDatas.box_code_number);
        }
      }else{
        self.FormDatas.number = null;
      }
    },
    SelectArrQuery (te) {
      self.loading = true;
      let datas = {name:self.SQueryName};

      if(te){
        datas.page = self.SelectArr[2].page;
      }else{
        self.SelectArr[2].page = 1;
      }
      if(self.SelectArr[0].ix == 1){
        datas.is_OID = 1;
        datas.estate = 3;
      }

      axios.post(self.GetServerUrl(1)+"production_list_condition",datas)
      .then(function (response){
        self.loading = false;
        if(!te){
          self.SelectArr[2].ix = 0;
          self.SelectArr[2].datas = response.data.meta.data;
        }else{
          let datas = response.data.meta.data;
          for(let item in datas){
            self.SelectArr[2].datas.push(datas[item]);
          }
        }
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    SelectArrLoading (ix) {
      self.SelectArr[ix].page++;
      self.loading = true;
      self.SelectArrQuery(true);
    },
    GetList () {
      self.datas = null;
      self.isLoding = true;
      self.subHint = "数据加载中...";
      axios.get(self.GetServerUrl(1)+"code_plan_list/"+self.page)
      .then(function (response){
        self.datas = response.data.meta.data;
        self.isLoding = false;

        //检测是否有生成中的记录
        // let isRefresh = false;
        // for(let item in self.datas){
        //   if(self.datas[item].estate == 100){
        //     if(!isRefresh){
        //       isRefresh = true;
        //       Timeout = setTimeout(function(){
        //         self.GetList();  //定时刷新
        //       },5000)
        //     }
        //   }
        // }

        //总页数
        self.totalPages = response.data.meta.last_page;
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.isLoding = false;
      })
    },
    SubmitForm () {  //提交表单

      let datas = self.FormDatas;
      datas.is_OID = self.SelectArr[0].ix;
      datas.active_way = 1;  //出库激活
      datas.is_code = self.SelectArr[3].ix;
      datas.tag_type = self.SelectArr[5].ix;
      datas.is_flow = self.SelectArr[4].ix;
      datas.level = self.SelectArr[6].ix;

      if(self.tradeType == 1){
        datas.product_type = self.SelectArr[7].ix+1;
      }

      if(self.FormDatas.number > 1000000){
        self.ToastFun(1,"单次生码量不能超过100万！");
        return;
      }

      if(self.SelectArr[5].ix == 1){
        delete datas.level;
        if(!self.FormDatas.p_code_number){
          self.ToastFun(1,"每箱个数不能为空！");
          return;
        }
      }else{
        delete self.FormDatas.box_code_number;
        delete self.FormDatas.p_code_number;
        if(!self.FormDatas.number){
          self.ToastFun(1,"条码数量不能为空！");
          return;
        }
      }

      self.loading = true;
      axios({
        method:"post",
        url:self.GetServerUrl(1)+"code_plan",
        data:datas
      }).then(function (response) {
        self.GetList();

        self.ModelShow = false;
        self.loading = false;
        self.ToastFun(0,"生码数据已提交，请您耐心等待生成结果！");  //弹窗提醒

        //重置表单内容
        self.FormDatas.box_code_number = null;
        self.FormDatas.p_code_number = null;
        self.FormDatas.number = null;
      }).catch(function (error) {
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));  //弹窗提醒
      })
    },
    DownCodeFile (url) {
      if(url){
        window.location.href = url;
      }
    }
  }
}
</script>

<style scoped>
  .RemainCode{
    display: block;
    color:#FFF;
    font-size:13px;
    margin-top:5px;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: #F77851;
  }
</style>
