<template>
	<div class="MainBox SSBox">
		<div class="SSHead">
      <!-- <a class="SSHeadRank"><span class="icon iconfont iconicon-test46"></span>{{$t('b.SecuritySettings.SecurityLevel')}} :<i v-text="rank"></i></a>
      <a class="SSHeadProgress ">{{$t('b.SecuritySettings.CompletionSchedule')}} :</a>
      <a class="SSHeadProgressBar"><i v-for="(item,index) in datas" v-bind:key="index" v-if="item.status"></i></a> -->
      <a class="SSHeadRank"><span class="icon iconfont iconicon-test46"></span>{{$t('b.SecuritySettings.SecuritySet')}} :</a>
    </div>
    <div class='SSMain'>
      <ul>
        <li v-for="(item,index) in datas" v-bind:key="index">
          <a class="SSMainTitle">{{$t('b.SecuritySettings.'+item.title+'')}}</a>
          <a class="SSMainText" v-if="index == 1">{{$t('b.SecuritySettings.HintFour')}} <a class="blueColor">{{phone}}</a> {{$t('b.SecuritySettings.'+item.text+'')}}</a>
          <a class="SSMainText" v-else>{{$t('b.SecuritySettings.'+item.text+'')}}</a>
          <div class="SSMainBtnRight">
            <a class="SSMainBtnRightStatus blueColor" v-if="item.status">
              <span class="icon iconfont iconicon-test65 SSMainBtnRightIcon"></span>
              <span>{{$t('c.Common.HaveSet')}}</span>
            </a>
            <a class="SSMainBtnRightStatus orangeColor" v-else>
              <span class="icon iconfont iconicon-test44 SSMainBtnRightIcon"></span>
              <span>{{$t('c.Common.NotSet')}}</span>
            </a>
            <a class="SSMainBtnRightStatusSet feedback" v-if="item.status" @click="GoToPage(item.url)">{{$t('c.Common.Change')}}</a>
            <a class="SSMainBtnRightStatusSet feedback" v-else @click="GoToPage(item.url)">{{$t('c.Common.Settings')}}</a>
          </div>
        </li>
        <li>
          <a class="SSMainTitle">{{$t('b.SecuritySettings.Unsubscribe')}}</a>
          <a class="SSMainText">{{$t('b.SecuritySettings.HintEight')}}</a>
          <div class="SSMainBtnRight">
            <a class="SSMainBtnRightStatusSet orangeColor feedback" @click="ClearAccount">{{$t('b.SecuritySettings.Unsubscribe')}}</a>
          </div>
        </li>
      </ul>
    </div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- 确认框 -->
    <K-Confirm :Confirm="Confirm" @ConfirmBackFun="ConfirmBackFun"></K-Confirm>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Confirm from '@/components/common/Confirm.vue'
export default {
  name: 'SecuritySettings',
  components: {
    'K-Loading': Loading,
    'K-Confirm': Confirm
  },
  data () {
    return {
      loading:false,
      Confirm:{
        state:false,
        txt:null,
        style:1
      },
      rank:"中",
      email:null,
      phone:null,
      datas:[{
        status:false,
        url:"ChangePassword",
        title:"LoginPassword",
        text:"HintOne"
      },
      // {
      //   status:false,
      //   url:"ChangePassword",
      //   title:"BindEmail",
      //   text:"HintThree"
      // },
      // {
      //   url:"ChangePassword",
      //   title:"BindingPhone",
      //   text:"HintFive"
      // },
      // {
      //   status:false,
      //   url:"ChangePassword",
      //   title:"BindingWeChat",
      //   text:"HintSix"
      // },{
      //   status:false,
      //   url:"SafetyProblem",
      //   title:"SafetyProblem",
      //   text:"HintSeven"
      // },
      ]
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {
    self.GetList();
  },
  methods: {
    ConfirmBackFun (data) {
      self.Confirm.state = data.state;
      if(data.state){

      }
    },
    GetList () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"safe/")
      .then(function (response){
        self.loading = false;
        self.datas[0].status = response.data.meta.is_password;
        self.datas[1].status = response.data.meta.is_email;
        self.datas[2].status = response.data.meta.is_phone;
        self.datas[3].status = response.data.meta.is_wechat;
        self.datas[4].status = response.data.meta.is_safe_problem;
        self.email = response.data.meta.email;
        self.phone = response.data.meta.phone;
        if(self.datas[3].status && self.datas[4].status){
          self.rank = "高";
        }
      }).catch(function (error){
        self.loading = false;
      })
    },
    ClearAccount () {
      self.Confirm.style = 2;
      self.Confirm.state = true;
      self.Confirm.txt = "账号注销后将导致无法登录，数据也将全部清除！切无法恢复是否确定注销？"
    }
  }
}
</script>

<style scoped>
  .SSBox{
    padding:20px 20px 0 20px;
  }
  .SSHead{
    overflow: hidden;
    padding:20px 0;
  }
  .SSHead a{
    display: table;
    float: left;
  }
  .SSHeadRank span{
    margin-right:5px;
    position: relative;
    top:1px;
  }
  .SSHeadRank i{
    color:#278BF5;
    padding:0 5px;
  }
  .SSHeadProgress{
    margin-left:25px;
  }
  .SSHeadProgressBar{
    height:10px;
    width: 150px;
    display: table;
    border:1px solid #278BF5;
    margin-left:15px;
    margin-top:4px;
  }
  .SSHeadProgressBar i{
    width:20%;
    display: block;
    float: left;
    height:10px;
    background-color: #278BF5;
  }

  .SSMain{
    margin-top:10px;
  }
  .SSMain ul li{
    overflow: hidden;
    padding:30px 10px;
    position: relative;
    border-top:1px dashed #DDD;
  }
  .SSMainTitle{
    width: 150px;
    color:#444;
    float: left;
  }
  .SSMainText{
    float: left;
    color:#646B71;
    width: calc(100% - 350px);
  }
  .SSMainBtnRight{
    overflow: hidden;
    position: absolute;
    right:20px;
    top:calc(50% - 10px);
  }
  .SSMainBtnRight a{
    display: block;
    float: left;
    padding:0 10px;
    font-size:13px;
  }
  .SSMainBtnRightStatus{
    border-right: 1px solid #DDD;
  }
  .SSMainBtnRightIcon{
    padding-right:5px;
    position: relative;
    top:1px;
  }
  .SSMainBtnRightStatusSet{
    cursor: pointer;
  }
</style>
