<template>
	<div class="MainBox">
    <div class="iconTitle"><span class="icon iconfont iconicon-test10"></span>链接OID常见疑问</div>
		<div class="FunctionListMain">
		  <ul class="FunctionListContent">
		    <li class="FunctionListContentBox">
		      <div class="FunctionListContentRow" v-for="(item,index) in functionListDatas" v-bind:key="index">
		        <p class="FunctionListContentTitle" v-text="item.title"></p>
		        <p class="FunctionListContentMsg" v-text="item.content"></p>
		      </div>
		    </li>
		  </ul>

      <div class="QuestioningBox">
        <div class="QuestioningTitle"><span class="icon iconfont iconicon-test38"></span>其他疑问或意见反馈：</div>
        <div class="QuestioningMain">
          <textarea placeholder="在此输入内容" v-model="body"></textarea>
        </div>
        <div class="QuestioningBtnBox">
          <a class="QuestioningBtn btnRadius btnStyleTwo blueBackColor" @click="SubmitForm">提交问题</a>
        </div>
      </div>
		</div>

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'Question',
  components: {
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      body:null,
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      functionListDatas:[{
        title:"生码文件等会消耗素材空间的内存吗？",
        content:'答：不会，素材空间只包涵图片、语音和视频的存储量。'
      },{
        title:"码标管理、激活和发货提示一月文件包会被删除，删除后会影响码标的正常使用吗？",
        content:'答：不会，文件包删除后只是不能下载，并不影响码标的正常使用。'
      },{
        title:"生码套餐到期后未使用完的生码额度，还能生码吗？",
        content:'答：可以，生码额度没有使用完的还可以继续生码和激活，只是用户扫码的时候会提示："该码已过期！"'
      },{
        title:"生码套餐到期后生成的码标数据会被清空吗？",
        content:'答：不会，但是扫码后会提示："该码已过期！"，套餐续费后就可重新正常使用了。'
      },{
        title:"生码套餐到期后素材空间里的图片、语音，视频会被删除吗？",
        content:'答：会，套餐到期后如果1个月没有续费，素材空间里面的图片、语音，视频会被清空的。'
      },{
        title:"购买生码套餐包涵促销活动、开发者中心、短视频等付费功能吗？",
        content:"答：没有，生码套餐只包涵码标的生成和使用，并不包涵促销等收费功能模块的开通，如需使用请单独开通。"
      }]
    }
  },
  created () {
    self = this;
    axios.defaults.headers.common['token'] = localStorage.getItem("wwmxt_Token");
  },
  mounted () {

  },
  methods: {
    ChangeToast () {
      self.Toast.state = false;
    },
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    SwicthSlide (ix) {
      if(self.functionListDatas[ix].active){
        self.functionListDatas[ix].active = false;
      }else{
        self.functionListDatas[ix].active = true;
      }
    },
    SubmitForm () {
      if(!self.body){
        self.ToastFun(2,"提交的意见反馈不能为空哦！");
        return;
      }
      self.loading = true;
      axios.post(self.GetServerUrl(1)+"feelback",{body:self.body})
      .then(function (response){
        self.loading = false;
        self.ToastFun(0,"感谢您宝贵的意见！");  //弹窗提醒
      }).catch(function (error){
        self.loading = false;
        self.ToastFun(2,self.ErrorHint(error));
      })
    }
  }
}
</script>

<style scoped>
  .iconTitle{
    padding:20px 20px 5px 20px;
    font-size:16px;
  }
  .iconTitle span{
    margin-right: 5px;
    font-size:18px;
    position: relative;
    top:1px;
  }
  .FunctionListMain{
    padding:0;
  }
  .FunctionListContentBox{
    border-top:1px solid #F3F3F3;
  }
  .FunctionListContentRow{
    border-bottom: 1px solid #F3F3F3;
  }

  .QuestioningBox{
    padding:20px 15px;
  }
  .QuestioningTitle{
    color:#585A64;
    padding-bottom:15px;
  }
  .QuestioningTitle span{
    margin-right:3px;
  }
  .QuestioningMain textarea{
    border:1px solid #D8E4ED;
    width:400px;
    height:100px;
    padding:10px;
    font-size:14px;
    border-radius: 3px;
  }
  .QuestioningBtnBox{
    overflow: hidden;
  }
  .QuestioningBtn{
    display: table;
    margin-top:15px;
    cursor: pointer;
  }
</style>
