<template>
	<div class="HomePageMainBox">
    <!-- 总数统计 -->
    <ul class="StatsListBox">
      <li>
        <div class="StatsListMainBox">
          <div class="StatsListRow StatsListIcon blueBorder"><span class="icon iconfont iconicon-test2 blueColor"></span></div>
          <div class="StatsListRow StatsListMain">
            <a class="StatsListName" v-text="$t('b.HomePage.CreationCodeAmount')"></a>
            <a class="StatsListNumber" v-if="statsListBox.created_code_count" v-text="statsListBox.created_code_count"></a>
            <a class="StatsListNumber" v-else>0</a>
          </div>
        </div>
      </li>
      <li>
        <div class="StatsListMainBox">
          <div class="StatsListRow StatsListIcon redTwoBorder"><span class="icon iconfont iconicon-test3 redTwoColor"></span></div>
          <div class="StatsListRow StatsListMain">
            <a class="StatsListName" v-text="$t('b.HomePage.ActiveAmount')"></a>
            <a class="StatsListNumber" v-text="statsListBox.ac_count"></a>
          </div>
        </div>
      </li>
      <li>
        <div class="StatsListMainBox">
          <div class="StatsListRow StatsListIcon greenBorder"><span class="icon iconfont iconicon-test13 greenColor"></span></div>
          <div class="StatsListRow StatsListMain">
            <a class="StatsListName" v-text="$t('b.HomePage.ScanQRAmount')"></a>
            <a class="StatsListNumber" v-text="statsListBox.query_count"></a>
          </div>
        </div>
      </li>
      <li>
        <div class="StatsListMainBox">
          <div class="StatsListRow StatsListIcon violetBorder"><span class="icon iconfont iconicon-test1 violetColor"></span></div>
          <div class="StatsListRow StatsListMain">
            <a class="StatsListName" v-text="$t('b.HomePage.ProductsAmount')"></a>
            <a class="StatsListNumber" v-text="statsListBox.product_count"></a>
          </div>
        </div>
      </li>
      <li>
        <div class="StatsListMainBox">
          <div class="StatsListRow StatsListIcon cyanBorder"><span class="icon iconfont iconicon-test11 cyanColor"></span></div>
          <div class="StatsListRow StatsListMain">
            <a class="StatsListName" v-text="$t('b.HomePage.ProcessAmount')"></a>
            <a class="StatsListNumber" v-text="statsListBox.process_count"></a>
          </div>
        </div>
      </li>
      <li>
        <div class="StatsListMainBox">
          <div class="StatsListRow StatsListIcon pinkBorder"><span class="icon iconfont iconicon-test12 pinkColor"></span></div>
          <div class="StatsListRow StatsListMain">
            <a class="StatsListName" v-text="$t('b.HomePage.DealerAmount')"></a>
            <a class="StatsListNumber" v-text="statsListBox.dealer_count"></a>
          </div>
        </div>
      </li>
    </ul>

    <!-- 激活、扫码统计 -->
    <div class="HomeChartBox">
      <div class="HomeChartLeft">
        <div class="HomePageBox" id="HomeChartLeftMain"></div>
      </div>
      <div class='HomeChartRight'>
        <div class="HomePageBox" id="HomeChartRightMain"></div>
      </div>
    </div>

    <!-- 费用中心 -->
    <!-- <div class="HomePageBox HomeExpenseBox">
      <div class="NavbarStyleOne" @click="GoToPage('IncomeDisbursement?type=home')">{{$t('b.HomePage.ExpenseCenter')}}<a class="NavbarStyleOneMore feedback">{{$t('b.HomePage.BillingDetails')}}<i class="icon iconfont iconicon-test25"></i></a></div>
      <div class="FloatBox">
        <div class="FloatBoxRow FloatBoxRow3">
          <ul class="HomeExpenseMain">
            <li class='HomeExpenseMainOneTop'>
              <p v-text="$t('b.HomePage.Balance')"></p>
              <p class='HomeExpenseMainOneBalance'><i>￥</i>58.00</p>
              <p class='HomeExpenseMainOneBtnArray NoSelect'>
                <a v-text="$t('b.HomePage.Recharge')"></a>
                <a v-text="$t('b.HomePage.Withdraw')"></a>
              </p>
            </li>
            <li class="FloatBox HomeExpenseMainOneBottom">
              <ul class='FloatBoxRow FloatBoxRow5'>
                <li>
                  <p v-text="$t('b.HomePage.BasicEdition')"></p>
                  <p>2020年09月05日到期</p>
                </li>
              </ul>
              <ul class='FloatBoxRow FloatBoxRow5'>
                <li style="border:0">
                  <p v-text="$t('b.HomePage.PayTheTemplate')"></p>
                  <p>2</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="FloatBoxRow FloatBoxRow3">
          <ul class="HomeExpenseMain HomeExpenseMainTwo">
            <li v-text="$t('b.HomePage.InvoiceManagement')"></li>
            <li class="HomeExpenseMainTwoBalance">
              <p v-text="$t('b.HomePage.InvoicedAmount')"></p>
              <p>￥1582</p>
              <a class="HomeExpenseMainTwoBalanceBtn feedback" @click="GoToPage('InvoiceManage?type=home')">{{$t('b.HomePage.AskingForInvoice')}}</a>
            </li>
            <li class="HomeExpenseMainTwoBtnArray NoSelect">
              <a v-text="$t('b.HomePage.SetInvoiceTitle')" @click="GoToPage('SetInvoiceTitle?type=home')"></a>
              <a v-text="$t('b.HomePage.InvoiceMailingAddress')" @click="GoToPage('InvoiceAddress?type=home')"></a>
            </li>
          </ul>
        </div>
        <div class="FloatBoxRow FloatBoxRow10">
          <ul class="HomeExpenseMain HomeExpenseMainThree">
            <div id="HomeChartBottomMain"></div>
          </ul>
        </div>
      </div>
    </div> -->

    <!-- loading -->
    <K-Loading v-if="loading"></K-Loading>

    <!-- toast -->
    <K-Toast :Toast="Toast" @ChangeToast="ChangeToast"></K-Toast>

	</div>
</template>

<script>
var self;
var echarts = require('echarts')
var HomeChartLeftMain,HomeChartRightMain,HomeChartBottomMain;
import axios from 'axios'
import Loading from '@/components/common/Loading.vue'
import Toast from '@/components/common/Toast.vue'
export default {
  name: 'HomePage',
  components: {
    'K-Loading': Loading,
    'K-Toast': Toast
  },
  data () {
    return {
      language: 'zh',
      statsListBox: {},
      loading:false,
      Toast:{
        state: false,
        type: 0,
        txt: null
      },
      RightDatas:null
    }
  },
  created () {
    self = this;
    self.language = localStorage.getItem("wwmxt_locale");
  },
  updated () {
    if(self.language != localStorage.getItem("wwmxt_locale")){
      self.language = localStorage.getItem("wwmxt_locale");
      self.loadLeftChart();  //激活统计
      self.loadRightChart();  //扫码统计
      // self.loadBottomChart();  //消费统计
    }
  },
  mounted () {
    localStorage.setItem("wwmxt_TopPage","HomePage");

    self.getDatas();
    self.loadLeftChart();  //激活统计
    self.loadRightChart();  //扫码统计
    // self.loadBottomChart();  //消费统计

    //屏幕宽度改变图表自动适应
    window.addEventListener('resize', this.ChangeChartWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.ChangeChartWidth)
  },
  methods: {
    ToastFun (type,txt) {
      self.Toast.state = true;
      self.Toast.type = type;
      self.Toast.txt = txt;
    },
    ChangeToast () {
      self.Toast.state = false;
    },
    getDatas () {
      self.loading = true;
      axios.get(self.GetServerUrl(1)+"businessIndex/")
      .then(function (response){
        self.statsListBox = response.data.meta;
        self.loading = false;
      }).catch(function (error){
        self.loading = false;
      })
    },
    loadLeftChart () {
      let CharDatas;
      axios.get(self.GetServerUrl(1)+"acStatics/")
      .then(function (response){
        self.ShowLeftChart(response.data.message);
      }).catch(function (error){
        self.subHint = self.ErrorHint(error);
        self.loading = false;
      })
    },
    ShowLeftChart (datas) {
      var date = [],num = [];
      for(let i in datas){
        date.push(datas[i].day);
        num.push(datas[i].ac);
      }
      HomeChartLeftMain = echarts.init(document.getElementById('HomeChartLeftMain'));
      HomeChartLeftMain.setOption({
          title: {
            text: self.$t('b.HomePage.CodeActivationStatistics'),
            color: '#162029',
            textStyle: {
              fontSize: '16',
              fontWeight: 'lighter'
            },
            subtext: self.$t('b.HomePage.CodeActivationStatisticsDescribe')
          },
          color: ['#3595FF'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '15%',
            left: '3%',
            right: '3%',
            bottom: '8%'
          },
          xAxis: {
            axisLine: {
                lineStyle: {
                    color: ['#999'],
                }
            },
            data: date
          },
          yAxis: {
            axisLine: {
                lineStyle: {
                    color: ['#999'],
                }
            }
          },
          series: [{
              name: self.$t('b.HomePage.ToActivateTheNumber'),
              type: 'bar',
              barWidth: '50%',
              data: num
          }]
      });
    },
    loadRightChart () {
      axios.get(self.GetServerUrl(1)+"scanStatic")
      .then(function (response){

        let datas = response.data.meta;
        let datasArr = [0,0,0,0,0,0];
        for(let item in datas){
            datas[item].name = datas[item].name.replace('省','');
            if(item <= 4){
              datasArr.push(datas[item]);
            }
        }
        self.RightDatas = datasArr;
        setTimeout(function(){
          self.ShowRightChart();
        },100)
      }).catch(function (error){
        self.ShowRightChart();
        console.log(self.ErrorHint(error));
      })
    },
    ShowRightChart () {
      if(!self.RightDatas){
        self.RightDatas = [{value:0,name:this.$t('b.HomePage.Guangdong')},{value:0,name:this.$t('b.HomePage.Zhejiang')},{value:0,name:this.$t('b.HomePage.Shanghai')},{value:0,name:this.$t('b.HomePage.Hunan')},{value:0,name:"其他"}];
      }
      HomeChartRightMain = echarts.init(document.getElementById('HomeChartRightMain'));
      HomeChartRightMain.setOption({
          title : {
            text: this.$t('b.HomePage.ChinaScanQRStat'),
            subtext: this.$t('b.HomePage.ChinaScanQRStatDescribe'),
            color: '#162029',
            x:'center',
            textStyle: {
              fontSize: '16',
              fontWeight: 'lighter'
            }
          },
          tooltip : {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            top: '15%',
            left: 'center',
            data: [this.$t('b.HomePage.Guangdong'),this.$t('b.HomePage.Zhejiang'),this.$t('b.HomePage.Fujian'),this.$t('b.HomePage.Shanghai'),this.$t('b.HomePage.Hunan'),"其他"]
          },
          series : [{
              name: this.$t('b.HomePage.Referer'),
              type: 'pie',
              radius : '55%',
              center: ['50%', '60%'],
              data:self.RightDatas,
              itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
                normal:{
                  color:function(params) {
                    var colorList = ['#DD4B39', '#1DA1F2', '#FF5722', '#3B5998', '#967ADC', '#44b549'];
                    return colorList[params.dataIndex]
                  }
                }
              },
              label: {
                normal: {
                  show: false
                }
              }
            }
          ]
      });
    },
    loadBottomChart () {
      HomeChartBottomMain = echarts.init(document.getElementById('HomeChartBottomMain'));
      HomeChartBottomMain.setOption({
          title : {
            text: this.$t('b.HomePage.XpenseTracker'),
            subtext: this.$t('b.HomePage.XpenseTrackerDescribe'),
            color: '#162029',
            textStyle: {
              fontSize: '16',
              fontWeight: 'lighter'
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : ￥{c}"
          },
          xAxis: {
	            show:false,
              type: 'category',
              data: ["2019-09-12","2019-09-13","2019-09-14","2019-09-15","2019-09-16","2019-09-17","2019-09-18"]
          },
          yAxis: {
	            show:false,
              type: 'value'
          },
          grid: {
            top: '25%',
            left: '0%',
            right: '0%',
            bottom: '0%'
          },
          series: [{
              name: this.$t('b.HomePage.Xpense'),
              data: [100, 932, 500, 934, 1500, 1330, 1320],
              type: 'line',
              symbolSize: 8,
              lineStyle: {
                  normal: {
                      width: 3,
                      color:'#FF5722',
                  }
              },
              itemStyle: {
                borderColor: '#FF5722',
                borderWidth:2
              }
          }]
      });
    },
    ChangeChartWidth () {
      HomeChartLeftMain.resize();
      HomeChartRightMain.resize();
      HomeChartBottomMain.resize();
    }
  }
}
</script>

<style scoped>
  .StatsListBox li{
    width: 16.66%;
  }

  .HomeChartBox{
    overflow: hidden;
    margin-top:10px;
  }
  .HomeChartLeft{
    float: left;
    width:70%;
  }
  .HomePageBox{
    background-color: #FFF;
    border-radius: 5px;
    border:1px solid #E4ECF2;
  }
  #HomeChartLeftMain{
    padding:20px;
    margin-right:20px;
    height:500px;
  }
  #HomeChartRightMain{
    padding:20px;
    height:500px;
  }
  .HomeChartRight{
    float: right;
    width: 30%;
  }
</style>
